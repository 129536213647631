import { Stack, Typography, colors } from '@mui/material';
import greyScale from '../../../../themes/default/colors/greyscale';

const NftRarityName = ({ rarity }: { rarity: string }) => {
  const rarityObject = () => {
    switch (rarity) {
      case 'BRONZE':
        return { color: colors.orange[600], label: 'Bronze', imgUrl: '/images/rarities/bronz.png' };
      case 'SILVER':
        return { color: colors.grey[600], label: 'Argent', imgUrl: '/images/rarities/silver.png' };
      case 'GOLD':
        return { color: colors.yellow[700], label: 'Or', imgUrl: '/images/rarities/gold.png' };
      case 'DIAMOND':
        return { color: colors.blue[600], label: 'Diamant', imgUrl: '/images/rarities/diamond.png' };
      case 'UNIQUE':
        return { color: greyScale[600], label: 'Unique', imgUrl: '/images/rarities/unique.png' };
      default:
        return { color: '#C0C0C0', label: 'Regular', imgUrl: '/images/rarities/silver.png' };
    }
  };

  const e = rarityObject();

  return (
    <Stack direction="row" alignItems="center" sx={{ color: e.color, fontWeight: 700 }}>
      <img src={e.imgUrl} alt={e.label} height={20} style={{ marginRight: 8, marginBottom: 2, borderRadius: 2 }} />{' '}
      <Typography variant="xl" sx={{ color: e.color, fontWeight: 700 }}>
        {e.label}
      </Typography>
    </Stack>
  );
};

export default NftRarityName;
