import { useEffect, useState } from 'react';
import { Ticket } from '../services/api/ticket';

interface UseTokenUriInterface {
  ticket: Ticket;
}

const useTokenUri = ({ ticket }: UseTokenUriInterface) => {
  const [nftImage, setNftImage] = useState<string>();
  const [nftMeataData, setNftMeataData] = useState<any>();

  const getNfts = async () => {
    if (ticket.tokenUri) {
      const res = await fetch(ticket.tokenUri);
      const metadata = await res.json();
      setNftImage(metadata.image);
      setNftMeataData(metadata);
    }
  };

  useEffect(() => {
    if (ticket?.isOpened) {
      getNfts();
    }
  }, [ticket]);

  return { nftImage, nftMeataData };
};

export default useTokenUri;
