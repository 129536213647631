import { Button, TextField } from '@mui/material';
import { Stack } from '@mui/system';
import { t } from 'i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import promoCodeApi from '../../../../services/api/promo-code';
import { useAppDispatch } from '../../../../redux/hooks';
import shoppingCartSlice from '../../../../services/shoppingCart';

function PromoCodeInput() {
  const { eventId } = useParams();
  let [urlParams, setUrlParams] = useSearchParams();
  const promoCodeFromUrl = urlParams.get('promoCode');
  const dispatch = useAppDispatch();

  const [canUsePromoCode] = promoCodeApi.endpoints.canUsePromoCode.useLazyQuery();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      promoCode: promoCodeFromUrl ?? ''
    },
    onSubmit: async values => {
      try {
        const data = await canUsePromoCode({ eventUuid: eventId!, code: values.promoCode }).unwrap();
        setUrlParams({ promoCode: values.promoCode });
        // compute again final price and change promo code
        dispatch(shoppingCartSlice.actions.changePromo({ promo: data }));
      } catch (error) {
        formik.setErrors({ promoCode: t('reservation.promoCodeError') ?? '' });
      }
    }
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1} spacing={3}>
          <TextField
            name="promoCode"
            variant="outlined"
            value={formik.values.promoCode}
            onChange={formik.handleChange}
            placeholder={'Code promo'}
            fullWidth
            error={formik.touched.promoCode && Boolean(formik.errors.promoCode)}
            helperText={formik.touched.promoCode && formik.errors.promoCode}
            InputProps={{
              className: 'h-12 bg-neutral-200 dark:bg-black',
              endAdornment: (
                <Button
                  type="submit"
                  variant="outlined"
                  fullWidth
                  className="rounded-full border-gray-500 text-gray-500 w-min px-8"
                >
                  Appliquer
                </Button>
              )
            }}
          />
        </Stack>
      </form>
    </>
  );
}

export default PromoCodeInput;
