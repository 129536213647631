import {
  Alert,
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Snackbar,
  Typography,
  useMediaQuery
} from '@mui/material';
import { Stack } from '@mui/system';
import { t } from 'i18next';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import eventApi, { Collectible } from '../../services/api/event';
import Footer from '../../components/Footer';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import UnhandledError from '../../components/UnhandledError';
import EventDescription from '../EventDetails/components/Description';
import EventLottery from '../EventDetails/components/Lottery';
import noReveal from '../../assets/images/card/no-reveal.png';
import LoginModal from './components/LoginModal';
import RevealModal from './components/RevealModal';
import baseApi from '../../services/api/base';
import userApi from '../../services/api/user';

import { motion, useAnimation } from 'framer-motion';

const getRandomTransformOrigin = () => {
  const value = (16 + 40 * Math.random()) / 100;
  const value2 = (15 + 36 * Math.random()) / 100;
  return {
    originX: value,
    originY: value2
  };
};

const getRandomDelay = () => -(Math.random() * 0.7 + 0.05);

const randomDuration = () => Math.random() * 0.07 + 0.23;

const variants = {
  start: (i: number) => ({
    rotate: i % 2 === 0 ? [-1, 1.3, 0] : [1, -1.4, 0],
    transition: {
      delay: getRandomDelay(),
      repeat: Infinity,
      duration: randomDuration()
    }
  }),
  reset: {
    rotate: 0
  }
};

// export default function App() {
//   return (
//     <div>
//       <motion.h2
//         initial={{
//           y: -20
//         }}
//         animate={{
//           x: 50,
//           y: 10,
//           scale: 1.2
//         }}>
//         AnimationElements
//       </motion.h2>
//       <button
//         type="button"
//         onClick={() => {
//         }}>
//         start shaking
//       </button>
//       <span> </span>
//       <button
//         type="button"
//         onClick={() => {
//           controls.stop();
//           controls.set('reset');
//         }}>
//         stop shaking
//       </button>
//       <br />
//       <br />
//     </div>
//   );
// }

function Claim() {
  const { eventId } = useParams();
  const dispatch = useAppDispatch();
  const [userUuid, setUserUuid] = useState<string>('');
  const { data: event, isLoading, isError } = eventApi.endpoints.getEventDetails.useQuery(eventId!);

  const [getUser] = userApi.endpoints.getUser.useLazyQuery();
  const [claimCollectible, {}] = eventApi.endpoints.claimCollectible.useMutation();
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  const { isAuth } = useAppSelector(state => state.authSlice);
  const [openErrorSnackbar, setErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [collectible, setCollectible] = useState<Collectible | null>(null);
  const [openRevealModal, setRevealModal] = useState(false);
  const [isAnimationOpen, setAnimationOpen] = useState(false);
  const [isMinting, setMinting] = useState(false);

  const controls = useAnimation();

  const [openLoginModal, setOpenLoginModal] = useState(false);
  const handleOpenLoginModal = () => setOpenLoginModal(true);
  const handleCloseLoginModal = () => setOpenLoginModal(false);

  const setUser = async () => {
    const user = await getUser().unwrap();
    setUserUuid(user.uuid ?? '');
  };

  useEffect(() => {
    if (isAuth) {
      setUser();
    }
  }, [isAuth]);

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorSnackbar(false);
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={`calc(100vh)`}>
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return <UnhandledError />;
  }

  function AppBar() {
    return (
      <Stack direction="row" justifyContent="space-between" zIndex="toolpit" sx={{ display: { md: 'none' } }}>
        <Box height={50}></Box>
      </Stack>
    );
  }

  const cover = () => {
    return (
      <Box display="flex" my={3}>
        <Box sx={{ width: { xs: '100%', lg: 1200, md: 900, aspectRatio: '5/2' } }}>
          <img
            src={event?.cover!}
            width="100%"
            style={{ borderRadius: 24, objectFit: 'cover', aspectRatio: isDesktop ? '5/2' : '5/3' }}
          />
        </Box>
      </Box>
    );
  };

  if (isAnimationOpen) {
    return (
      <>
        <RevealModal
          event={event!}
          openRevealModal={openRevealModal}
          handleCloseRevealModal={() => setAnimationOpen(false)}
          collectible={collectible}
        />
      </>
    );
  }

  return (
    <>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: '100%', minHeight: 60, justifyContent: 'center', alignItems: 'center' }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <LoginModal handleCloseLoginModal={handleCloseLoginModal} openLoginModal={openLoginModal} />
      {event ? (
        <div>
          <header>
            {/* <DesktopAppBar eventId={eventId ?? ''} /> */}
            {AppBar()}
          </header>
          <Stack direction="column" justifyContent="center" alignItems="center">
            <Stack direction="column" m={3} mt={0} sx={{ width: { lg: 1000 }, mt: { xs: -3 } }}>
              <Box display="flex" justifyContent="center" sx={{ mt: { md: 6 } }}>
                <Avatar
                  src={event?.organization.logo}
                  sx={{ width: 80, height: 80 }}
                  imgProps={{ sx: { objectFit: 'contain' } }}
                />
              </Box>
              {cover()}
              <Box width="100%" sx={{ pr: { md: 3 } }}>
                <Typography variant="h3" fontWeight={700} mb={2} textAlign="center">
                  {event?.title}
                </Typography>
                <Typography variant="h6" fontWeight={700} my={6} textAlign="center">
                  Réclamez gratuitement votre billet souvenir NFT et tentez de gagner des récompenses exclusives 🎁
                  {event?.claim?.length > 0 && (event?.claim[0]?.quantity ?? 0) > 1
                    ? ` (x${event?.claim[0].quantity})`
                    : ''}
                </Typography>
                {/* <Typography variant="h1" fontWeight={700} my={6} textAlign="center">
                  {event?.claim?.length > 0 ? event?.claim[0].quantity : ''}
                </Typography> */}

                <Box display="flex" justifyContent="center">
                  <Button
                    variant="gradient"
                    disabled={isMinting}
                    sx={{ borderRadius: 100, mb: 1, height: 56, fontWeight: 800, fontSize: 20 }}
                    onClick={async () => {
                      if (isAuth) {
                        try {
                          controls.start('start');
                          setMinting(true);
                          const c = await claimCollectible({ eventUuid: event?.uuid }).unwrap();
                          setCollectible(c);
                          setAnimationOpen(true);
                          setMinting(false);
                          dispatch(baseApi.util.invalidateTags(['claim-event']));
                        } catch (error) {
                          controls.stop();
                          setMinting(false);
                          setAnimationOpen(false);
                          console.log('error');
                          console.log(error);
                          setErrorMessage((error as any).data?.message?.toString());
                          setErrorSnackbar(true);
                        }
                      } else {
                        handleOpenLoginModal();
                      }
                    }}
                  >
                    {isMinting ? <CircularProgress /> : t('myTickets.discoverTicket')}
                  </Button>
                </Box>

                {isMinting ? (
                  <Typography m={1} textAlign="center">
                    Découvrez votre récompense dans quelques instants...
                  </Typography>
                ) : null}

                <Stack alignItems="center" justifyContent="center" mt={2}>
                  <motion.div
                    // style={{
                    //   transformOrigin: getRandomTransformOrigin(),
                    // }}
                    style={{
                      ...getRandomTransformOrigin(),
                      backgroundImage: `url(${noReveal})`,
                      backgroundSize: 'cover',
                      height: 396,
                      width: 300,
                      margin: 3
                    }}
                    // -- controls --
                    custom={0}
                    variants={variants}
                    animate={controls}
                  />
                </Stack>
                {event?.collectibles?.length > 0 && (
                  <Typography variant="h4" fontWeight={700} m={3} mt={6} textAlign="left">
                    Mes billets souvenir
                  </Typography>
                )}
                {event?.collectibles?.map(collectible => (
                  <Stack alignItems="center" justifyContent="center">
                    <img height={396} width={300} src={collectible.url ?? ''} />
                    <Typography variant="h6" fontWeight={700} mt={2} textAlign="center">
                      {collectible.rarity} #{collectible.nftId}
                    </Typography>
                    <Typography variant="md" fontWeight={700} mb={3} textAlign="center">
                      {collectible.reward}
                    </Typography>
                  </Stack>
                ))}

                {/* <Box m={3}>
                  <Button
                    variant={isAfter(new Date(), new Date(event!.endAt)) ? 'contained' : 'gradient'}
                    fullWidth
                    disabled={isAfter(new Date(), new Date(event!.endAt))}
                    onClick={() => {
                      navigate(`/reservation/${event?.uuid}`);
                    }}
                    sx={{ boxShadow: shadows.primaryButton, height: 58, fontSize: 18 }}>
                    {t('event.order')}
                  </Button>
                </Box> */}

                <Stack direction="row">
                  {event?.tags.map(tag => (
                    <Chip key={tag} label={tag} variant="square" sx={{ mr: 1 }} />
                  ))}
                </Stack>
                <Box mt={2} mb={2} mr={2}>
                  <Divider sx={{ display: { md: 'none' } }} />
                </Box>

                <Box height={24} />
                <EventDescription event={event} />
                <Box mt={3} mb={3}>
                  <Divider />
                </Box>
                <EventLottery event={event} />
              </Box>
            </Stack>
          </Stack>
          <Box height={64} />
          {/* <Footer /> */}
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
}

export default Claim;
