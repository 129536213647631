import LeftChevronImage from './LeftChevronImage';
interface LeftChevronProps {
  click: () => void;
}

function LeftChevron({ click }: LeftChevronProps) {
  return (
    <div
      className="w-8 h-8 sm:w-8 sm:h-8 md:w-8 md:h-8 lg:w-10 lg:h-10  bg-[#787879]  dark:bg-[#1F222A] ml-4 rounded-full"
      onClick={click}
    >
      <div className="flex flex-col h-full w-full items-center justify-center">
        <div className="flex flex-col items-center justify-center h-full w-full mr-1">
          <LeftChevronImage width="50%" height="50%" />
        </div>
      </div>
    </div>
  );
}

export default LeftChevron;
