/* eslint-disable camelcase */

import baseApi from './base';
import { Event } from './event';

export type Season = {
  id: number;
  uuid: string;
  name: string;
  description: string | null;
  numberOfEvents: number;
  organizationUuid: string;
  events: Event[];
};

export type SeasonForm = {
  // categoryUuid?: string;
  name: string;
  description?: string;
  numberOfEvents: number;
  eventsUuids: string[];

  organizationUuid?: string;
  capacity?: number;
  remaining?: number;
};

export type AddEventsToSubscriptionForm = {
  subscriptionUuid: string;
  eventsUuids: string[];
};

const seasonApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getSeasons: builder.query<Season[], string>({
      query: orgaUuid => ({
        url: `/seasons/orga/${orgaUuid}`,
        method: 'GET'
      }),
      providesTags: (result, error, orgaUuid) => [{ type: 'seasons', id: orgaUuid }]
    }),
    getUserSeasons: builder.query<Season[], void>({
      query: () => ({
        url: `/seasons/user`,
        method: 'GET'
      })
      // providesTags: (result, error, uuid) => [{ type: 'subscription-details', id: uuid }]
    }),
    getSeason: builder.query<Season, string>({
      query: uuid => ({
        url: `/seasons/${uuid}`,
        method: 'GET',
        params: {
          isUser: true
        }
      })
      // providesTags: (result, error, uuid) => [{ type: 'subscription-details', id: uuid }]
    }),
    createSeason: builder.mutation<Season, SeasonForm>({
      query: body => ({
        url: `/seasons`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: (result, error, { organizationUuid }) => [{ type: 'seasons', id: organizationUuid }]
    }),
    updateSeason: builder.mutation<void, { body: SeasonForm; uuid: string }>({
      query: ({ body, uuid }) => ({
        url: `/seasons/${uuid}`,
        method: 'PATCH',
        body: body
      })
      // invalidatesTags: (result, error, { uuid }) => [{ type: 'subscription-details', id: uuid }]
    })
  })
});

export default seasonApi;
