import { IconButton } from '@mui/material';

interface SocialIconProps {
  url: string | undefined;
  img: string;
}

export function SocialFooterIcon({ img, url }: SocialIconProps) {
  return (
    <IconButton
      onClick={() => window.open(url)}
      sx={{
        width: 40,
        height: 40,
        mx: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: 'rgba(173, 173, 173, 0.50)',
        borderRadius: '50%'
      }}
    >
      <img src={img} alt="facebook" />
    </IconButton>
  );
}
