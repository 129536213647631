import { Stack, Box, Tooltip } from '@mui/material';
// import primary from '../../themes/default/colors/primary';
import transparent from '../../themes/default/colors/transparent';

interface DescriptionIconDecorationProps {
  children: any;
  title?: string;
}

const DescriptionIconDecoration = (props: DescriptionIconDecorationProps) => {
  return (
    <Tooltip title={props.title}>
      <Box height={60} width={60} minWidth={60} bgcolor={transparent.purple} borderRadius="100%">
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          height="100%"
          width="100%"
          color="primary.main"
        >
          {props.children}
        </Stack>
      </Box>
    </Tooltip>
  );
};

export default DescriptionIconDecoration;
