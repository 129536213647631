import ticketApi from '@/services/api/ticket';
import EditButton from '../EditButton';
import ModalEvent from '../ModalEvent';
import ModalTicket from '../ModalTicket';
import PersonImage from '../PersonImage';
import { Button, CircularProgress, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import VerifiedTransferModal from '../VerifiedTransferModal';
interface SelectedTicket {
  id: number;
  uuid: string;
}
//eventUuid={eventId} firstName={firstName} lastName={lastName} email={email}
interface ModalBodyProps {
  selectedTickets: SelectedTicket[];
  onClose: () => void;
  coverImage: string;
  eventName: string;
  eventUuid: string;
  firstName: string;
  lastName: string;
  email: string;
}

function ModalBody({
  selectedTickets,
  onClose,
  coverImage,
  eventName,
  eventUuid,
  firstName,
  lastName,
  email
}: ModalBodyProps) {
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();
  const [ticketTransfer, { isLoading: transferingTicket, isSuccess: isSuccess }] =
    ticketApi.endpoints.ticketTransfer.useMutation();
  const printInformations = async () => {
    const uuids = selectedTickets.map(ticket => ticket.uuid);
    const transferBody = {
      firstName,
      lastName,
      email,
      ticketUuids: uuids, // Array of ticket UUIDs to transfer
      eventUuid
    };
    console.log(transferBody);

    const reponse = await ticketTransfer(transferBody).unwrap();
    if (reponse) {
      navigate(
        `/transfer-verified?firstName=${firstName}&lastName=${lastName}&email=${email}&nbtickets=${uuids.length}&eventName=${eventName}`
      );
    }
  };
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  return (
    <div className="flex flex-col justify-center items-center">
      <p className="text-center">Assurez vous que toutes les informations soient correctes avant de les transférer.</p>
      <p className="text-red-500 text-center">🚨 Chaque billet n’est transférable qu’une seule fois</p>
      <div className="flex flex-row justify-center items-center w-full mb-0">
        <div className="flex-grow mb-0">
          <h3>Billets transférés ({selectedTickets.length})</h3>
        </div>
        <EditButton handleClick={onClose} />
      </div>
      {/* Event Modal */}
      <ModalEvent coverImage={coverImage} eventName={eventName} />
      {/* {selectedTickets.map((ticket, index) => (
      <ModalTicket key={index} id={ticket.id} />
    ))} */}
      <div className="flex items-center m-3">
        <input
          id="default-checkbox"
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
          value=""
          className="w-6 h-6 text-purple-600 bg-custom border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
        <label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
          <h3 className="text-lg font-bold">
            J’accepte votre <span className="text-purple-600">Politique de Confidentialité</span> ainsi que vos{' '}
            <span className="text-purple-600">Termes et Conditions</span>.
          </h3>
        </label>
      </div>
      <div className="flex flex-row justify-center items-center flex-grow mt-5">
        <Button
          onClick={onClose}
          variant="contained"
          sx={{ borderRadius: 3, height: 48, px: 4, width: { xs: '100%', md: 'auto' } }}
          className="bg-[#181632] m-5"
        >
          <Typography variant="lg" fontWeight={700} className="leading-10">
            Annuler
          </Typography>
        </Button>
        {transferingTicket ? (
          <CircularProgress />
        ) : (
          <Button
            onClick={printInformations}
            disabled={!isChecked}
            variant="contained"
            className="m-5"
            sx={{ borderRadius: 3, height: 48, px: 4, width: { xs: '100%', md: 'auto' } }}
          >
            <Typography variant="lg" fontWeight={700} className="leading-10">
              Suivant
            </Typography>
          </Button>
        )}
      </div>
    </div>
  );
}

export default ModalBody;
