import defaultTheme from '../../themes/default/theme';
import lesDeterminesTheme from '../../themes/lesDetermines/theme';
import NiceTheme from '../../themes/NiceTheme/theme';
import blagnacTheme from '../../themes/blagnac/theme';
import decathlonTheme from '../../themes/decathlon/theme';
import usSaintesTheme from '../../themes/saintes/theme';
import grizzlysTheme from '../../themes/grizzlys/theme';
import nantesTheme from '../../themes/nantes/theme';
import nancyTheme from '../../themes/nancy/theme';
import gayaTheme from '../../themes/nantes copy/theme';
import blackVisionTheme from '../../themes/black-vision/theme';

import { Theme } from '@mui/material';
import whiteLabelConfig, { WhiteLabelConfigEntry } from '../../config/whiteLabelConfig';

// Define your list of club hostnames and their corresponding themes
const clubThemeHostnameMappings: Record<string, (arg: boolean) => Theme> = {
  'app.tickie.io': defaultTheme,
  'billetterie-blagnac.tickie.io': blagnacTheme,
  'decathlon.tickie.io': decathlonTheme,
  'collectibles.lesdetermines.tickie.io': lesDeterminesTheme,
  'collectibles.nice.tickie.io': NiceTheme,
  'us-saintes-handball.tickie.io': usSaintesTheme,
  'grizzlys-catalans.tickie.io': grizzlysTheme,
  'hbcnantes.tickie.io': nantesTheme,
  'gaya-rixheim.tickie.io': gayaTheme,
  'asnl.tickie.io': nancyTheme,
  'black-vision.tickie.io': blackVisionTheme
};

// Function to get the theme based on the hostname
export const getClubThemeFromHostname = (darkTheme: boolean): Theme => {
  const currentSelectedClient = localStorage.getItem('currentClient');
  const currentHostname: string = currentSelectedClient ?? window.location.hostname;
  const clubThemeGetter: (arg: boolean) => Theme =
    clubThemeHostnameMappings[currentHostname] ??
    clubThemeHostnameMappings[
      import.meta.env.VITE_NODE_ENV == 'production' ? 'app.tickie.io' : 'billetterie-blagnac.tickie.io'
    ];
  const clubTheme = clubThemeGetter(darkTheme);

  return clubTheme;
};

// get the website config based on the hostname
export const getWebsiteConfig = (): WhiteLabelConfigEntry => {
  const currentSelectedClient = localStorage.getItem('currentClient');
  const currentHostname: string = currentSelectedClient ?? window.location.hostname;
  const config =
    whiteLabelConfig[currentHostname] ??
    whiteLabelConfig[import.meta.env.VITE_NODE_ENV == 'production' ? 'app.tickie.io' : 'billetterie-blagnac.tickie.io'];
  return config;
};
