import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import { Event } from '../../../../services/api/event';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import DirectionsTransitIcon from '@mui/icons-material/DirectionsTransit';
import GoogleMapReact from 'google-map-react';
import RoomIcon from '@mui/icons-material/Room';
import { t } from 'i18next';
import React from 'react';
import greyScale from '../../../../themes/default/colors/greyscale';
import { useAppSelector } from '../../../../redux/hooks';
import { LocationOn } from '@mui/icons-material';

interface EventMapProps {
  event: Event;
}

interface MapMarkerProps {
  image: string;
  lat: number;
  lng: number;
}

const MapMarker = (props: MapMarkerProps) => {
  return (
    <LocationOn
      sx={{
        color: 'red',
        height: 30,
        width: 30,
        mb: '30px',
        position: 'relative',
        top: '-30px',
        left: '-15px'
      }}
    />
  );
};

const EventMap = React.forwardRef((props: EventMapProps, ref) => {
  const { event } = props;
  const { darkTheme } = useAppSelector(state => state.themeSlice);
  const subtitleColor = darkTheme ? greyScale[300] : greyScale[700];

  const { address } = event;
  return (
    <>
      <Typography mt={3} mb={2} variant="h5">
        {t('event.place')}
      </Typography>
      <Stack direction="row" mb={2} spacing={1}>
        <RoomIcon fontSize="small" />
        <Typography
          variant="md"
          color={subtitleColor}
        >{`${event?.address.street}, ${event?.address.zipCode}, ${event?.address.city}`}</Typography>
      </Stack>
      <Box ref={ref} height="200px" bgcolor="#dfe1eb" width="100%" borderRadius="8px 8px 0px 0px" overflow="hidden">
        <GoogleMapReact
          bootstrapURLKeys={{ key: import.meta.env.VITE_GOOGLE_MAP_KEY }}
          defaultCenter={{ lat: address.lat, lng: address.lng }}
          center={{ lat: address.lat, lng: address.lng }}
          defaultZoom={13}
        >
          <MapMarker image={event?.organization.logo} lat={address.lat} lng={address.lng}></MapMarker>
        </GoogleMapReact>
      </Box>

      <Grid container spacing={2} mt={1} mb={3} justifyContent="center">
        <Grid item>
          <IconButton
            onClick={() => window.open(`https://www.google.com/maps?daddr=${address.lat},${address.lng}&mode=driving`)}
          >
            <DirectionsCarIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            onClick={() => window.open(`https://www.google.com/maps?daddr=${address.lat},${address.lng}&dirflg=w`)}
          >
            <DirectionsWalkIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            onClick={() =>
              window.open(`https://www.google.com/maps?daddr=${address.lat},${address.lng}&mode=transit&dirflg=r`)
            }
          >
            <DirectionsTransitIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            onClick={() =>
              window.open(`https://www.google.com/maps?daddr=${address.lat},${address.lng}&mode=bicycling&dirflg=b`)
            }
          >
            <DirectionsBikeIcon />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
});

export default EventMap;
