import React from 'react';

interface ImageWithSVGProps {
  width: string;
  height: string;
}

const InfoImage: React.FC<ImageWithSVGProps> = ({ width, height }) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <path d="M12 17V11" stroke="#b9becf" strokeWidth="1.5" strokeLinecap="round"></path>
      <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 11 9)" fill="#b9becf"></circle>
      <path
        d="M22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C21.5093 4.43821 21.8356 5.80655 21.9449 8"
        stroke="#b9becf"
        strokeWidth="1.5"
        strokeLinecap="round"
      ></path>
    </g>
  </svg>
);

export default InfoImage;
