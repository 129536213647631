interface PaginatorProps {
  ticketNumber: number;
  activeTicket: number;
}
function Paginator({ ticketNumber, activeTicket }: PaginatorProps) {
  return (
    <div className="flex flex-row  w-full items-center justify-center">
      <div className="bg-[#787879] dark:bg-[#1F222A] p-3 rounded-3xl flex flex-row items-center justify-center">
        {[...Array(ticketNumber)].map((_, index) => (
          <>
            {index == activeTicket ? (
              <div className="bg-white w-5 h-2 rounded-full m-1 sm:w-3 sm:h-1 md:w-4 md:h-2"></div>
            ) : (
              <div className="bg-[#9E9E9E] w-2 h-2 rounded-full m-1 sm:w-1 sm:h-1 md:w-2 md:h-2"></div>
            )}
          </>
        ))}
      </div>
    </div>
  );
}

export default Paginator;
