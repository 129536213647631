import { ContentCutOutlined, Mail, Person } from '@mui/icons-material';
import { Box, Button, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { t } from 'i18next';
import greyScale from '../../../themes/default/colors/greyscale';
import ticketApi from '@/services/api/ticket';
import { useAppSelector } from '@/redux/hooks';
import image from '../Checkbox.png';
import LocationImage from '../LocationImage';
import CalendarImage from '../CalendarImage';
import ModalTicket from '@/pages/TicketDetails/ModalTicket';

function IfLoggedIn() {
  const [approveTransfer, { isLoading: isApproving, isSuccess: isSuccess }] =
    ticketApi.endpoints.approveTransfer.useMutation();
  const [refuserTransfer, { isLoading: isRefusing }] = ticketApi.endpoints.refuserTransfer.useMutation();
  const navigate = useNavigate();
  const { transferId } = useParams();
  const {
    data: transfered,
    isLoading,
    isError
  } = ticketApi.endpoints.getTransferedTicketInformation.useQuery(transferId!, { skip: !transferId });

  const refuserTrans = async () => {
    const approveBody = {
      transferUuid: transferId!
    };
    const reponse = await refuserTransfer(approveBody).unwrap();
    console.log(reponse);
    if (reponse.response == 'transfer deleted succefully') {
      navigate('/my-tickets');
    }
  };
  const sendApproval = async () => {
    const approveBody = {
      transferUid: transferId!
    };
    console.log(approveBody);

    const reponse = await approveTransfer(approveBody).unwrap();
    console.log(reponse);
    if (reponse.reponse == 'There is no ticket to transfer') {
      console.log(reponse.reponse);
      navigate('/declined-transfers');
    } else if (reponse.reponse == 'Tickets transfer has been approved.') {
      navigate('/my-tickets');
    }
  };
  return isLoading ? (
    <div className="h-screen w-screen flex flex-col items-center justify-center">
      <CircularProgress size={50} />
    </div>
  ) : (
    <div className="flex flex-col justify-center items-center h-screen p-10 overflow-y-auto">
      <img src={image} alt="image" />
      {isApproving ? (
        <Typography variant="h4" textAlign="center" className="mt-5">
          {t('transfer.processPeutPrendre')}
        </Typography>
      ) : (
        <Typography variant="h4" textAlign="center" className="mt-5">
          {t('transfer.billetEnAttente')}
        </Typography>
      )}

      {(isError || (transfered && transfered.transfer.tickets.length == 0)) && (
        <div className="flex flex-col items-center justify-center w-full mt-4 space-y-4">
          <Typography variant="xl" color={greyScale[500]} sx={{ fontSize: '20px' }} mt={5}>
            {t('transfer.aucunBilletDisponible')}
          </Typography>
        </div>
      )}
      {!isError && transfered && transfered.transfer.tickets.length !== 0 && (
        <div className="flex flex-col justify-center">
          {transfered && (
            <Typography variant="xl" color={greyScale[500]} sx={{ fontSize: '16px' }} mt={5}>
              {/* {t('transfer.renseignezVosInformation')} */}
              {t('transfer.vousAvez')} {transfered.transfer.tickets.length} {t('transfer.billetsPour')}
            </Typography>
          )}
          <div className="flex flex-col w-full mt-4 space-y-4">
            <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1} spacing={2}>
              {/* <TextField
                    name="lastName"
                    value={userData.lastName}
                    onChange={handleChange}
                    variant="outlined"
                    placeholder={t('transfer.lastNameTextField') ?? ''}
                    fullWidth
                    InputProps={{
                      className: 'h-12 bg-gray-300 dark:bg-gray-800 ',
                      startAdornment: <Person className="h-[20px] mr-1"></Person>
                    }}
                  />
                  <TextField
                    name="firstName"
                    value={userData.firstName}
                    onChange={handleChange}
                    variant="outlined"
                    placeholder={t('transfer.firstNameTextField') ?? ''}
                    fullWidth
                    InputProps={{
                      className: 'h-12 bg-gray-300 dark:bg-gray-800',
                      startAdornment: <Person className="h-[20px] mr-1"></Person>
                    }}
                  /> */}
              {transfered && <img width="129px" src={transfered.transfer.tickets[0].event.cover} alt="img" />}
              <div className="flex flex-col lg:w-[400px] sm:w-[200px]">
                {transfered && transfered.transfer.tickets[0].event.title && (
                  <Typography variant="xl" sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                    {transfered.transfer.tickets[0].event.title}
                  </Typography>
                )}
                <div className="flex flex-row items-center justify-start">
                  {transfered &&
                    transfered.transfer.tickets[0].event.address.country &&
                    transfered.transfer.tickets[0].event.address.city && (
                      <>
                        <LocationImage width="14.17px" height="16.67px" />

                        <Typography variant="xl" color={greyScale[500]} sx={{ fontSize: '14px' }} ml={1}>
                          {transfered.transfer.tickets[0].event.address.country}{' '}
                          {transfered.transfer.tickets[0].event.address.city}{' '}
                        </Typography>
                      </>
                    )}
                </div>
                <div className="flex flex-row items-center justify-start">
                  {transfered && transfered.transfer.tickets[0].event.beginAt && (
                    <>
                      <CalendarImage width="13px" height="16px" />
                      <Typography variant="xl" color={greyScale[500]} sx={{ fontSize: '14px' }} ml={1}>
                        {formatDate(transfered.transfer.tickets[0].event.beginAt)}
                        {/* 23 août 2023 - 12:00 */}
                      </Typography>
                    </>
                  )}
                </div>
              </div>
            </Stack>
            {transfered &&
              transfered?.transfer.tickets.map(
                (ticket, index) =>
                  ticket.id &&
                  ticket.ticketCategory.name && (
                    <ModalTicket key={ticket.id} category={ticket.ticketCategory.name} id={ticket.id} />
                  )
              )}
            {/* <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <TextField
                    name="email"
                    value={userData.email}
                    onChange={handleEmailChange}
                    variant="outlined"
                    placeholder={t('transfer.emailTextField') ?? ''}
                    fullWidth
                    error={!isValidEmail}
                    helperText={!isValidEmail ? 'Please enter a valid email' : ''}
                    InputProps={{
                      className: 'h-12 bg-gray-300 dark:bg-gray-800',
                      startAdornment: <Mail className="h-[18px] mr-1"></Mail>
                    }}
                  />
                </Stack> */}
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Button
                className=" bg-gray-500 dark:bg-gray-800"
                variant="contained"
                onClick={refuserTrans}
                sx={{
                  marginRight: 2,
                  borderRadius: 3,
                  height: 48,
                  px: 4,
                  width: { xs: '100%', md: '100%' },
                  '&:hover': {
                    backgroundColor: '#6b7280', // Change background color on hover
                    color: 'white' // Change text color on hover
                  }
                }}
              >
                {isRefusing ? (
                  <CircularProgress size={20} />
                ) : (
                  <Typography variant="lg" fontWeight={700} className="leading-10">
                    {t('transfer.refuserBillet')}
                  </Typography>
                )}
              </Button>
              <Button
                onClick={sendApproval}
                variant="contained"
                sx={{
                  marginLeft: 2,
                  borderRadius: 3,
                  height: 48,
                  px: 4,
                  width: { xs: '100%', md: '100%' },
                  background: 'linear-gradient(90deg, #27D4FF 0%, #584CF4 100%)',
                  '&:hover': {
                    background: 'linear-gradient(90deg, #27D4FF 0%, #584CF4 100%)'
                  }
                }}
              >
                {isApproving ? (
                  <CircularProgress size={20} />
                ) : (
                  <Typography variant="lg" fontWeight={700} className="leading-10">
                    {t('transfer.accepterBillet')}
                  </Typography>
                )}
              </Button>
            </Stack>
          </div>
        </div>
      )}
    </div>
  );
}

export default IfLoggedIn;

function formatDate(dateString: string): string {
  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  };
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('fr-FR', options).format(date);
}
