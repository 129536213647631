// import { atcb_action } from 'add-to-calendar-button';
import { Button } from '@mui/material';
import { t } from 'i18next';
import { Add } from '@mui/icons-material';
import { atcb_action } from 'add-to-calendar-button';
import { format } from 'date-fns';

interface AddToCalendarButtonProps {
  title: string;
  beginAt: string;
  endAt: string;
}

const AddToCalendarButton = (props: AddToCalendarButtonProps) => {
  return (
    <Button
      variant="outlined"
      size="small"
      startIcon={<Add />}
      onClick={e => {
        e.preventDefault();
        atcb_action({
          name: props.title,
          startDate: format(new Date(props.beginAt), 'yyyy-MM-dd'),
          startTime: format(new Date(props.beginAt), 'HH:mm'),
          endDate: format(new Date(props.endAt), 'yyyy-MM-dd'),
          endTime: format(new Date(props.endAt), 'HH:mm'),
          options: ['Apple', 'Google', 'iCal', 'Microsoft365', 'Outlook.com', 'Yahoo'],
          timeZone: 'Europe/Berlin',
          iCalFileName: props.title
        });
      }}
      sx={{ pr: 3 }}
      className="whitespace-nowrap flex justify-start pl-3 dark:text-neutral-200 text-slate-900 rounded-full "
    >
      {t('event.addToCalendar')}
    </Button>
  );
};

export default AddToCalendarButton;
