import ticketApi from '@/services/api/ticket';
import EditButton from '../EditButton';
import ModalEvent from '../ModalEvent';
import ModalTicket from '../ModalTicket';
import PersonImage from '../PersonImage';
import { Button, CircularProgress, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import VerifiedTransferModalBody from '../VerifiedTransferModalBody';
import { DialogClose } from '@/components/ui/dialog';
import { t } from 'i18next';
interface SelectedTicket {
  id: number;
  uuid: string;
  category: string;
}
//eventUuid={eventId} firstName={firstName} lastName={lastName} email={email}
interface ModalBodyProps {
  resetTransferEnCours: () => void;
  selectedTickets: SelectedTicket[];
  EditForm: () => void;
  setTransferEnCours: () => void;
  editTicketSelection: () => void;
  coverImage: string;
  eventName: string;
  eventUuid: string;
  firstName: string;
  lastName: string;
  email: string;
  handleCancelTicketSelection: () => void;
}

function ModalBody({
  EditForm,
  selectedTickets,
  handleCancelTicketSelection,
  setTransferEnCours,
  editTicketSelection,
  coverImage,
  eventName,
  eventUuid,
  firstName,
  lastName,
  email,
  resetTransferEnCours
}: ModalBodyProps) {
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();
  const [ticketTransfer, { isLoading: transferingTicket, isSuccess }] =
    ticketApi.endpoints.ticketTransfer.useMutation();
  const printInformations = async () => {
    const uuids = selectedTickets.map(ticket => ticket.uuid);
    const transferBody = {
      firstName,
      lastName,
      email,
      ticketUuids: uuids, // Array of ticket UUIDs to transfer
      eventUuid
    };
    console.log(transferBody);

    const reponse = await ticketTransfer(transferBody).unwrap();
    setTransferEnCours();
    // if (reponse) {
    //   navigate(
    //     `/transfer-verified?firstName=${firstName}&lastName=${lastName}&email=${email}&nbtickets=${uuids.length}&eventName=${eventName}`
    //   );
    // }
  };
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  return isSuccess ? (
    <VerifiedTransferModalBody
      resetTransferEnCours={resetTransferEnCours}
      nbBillet={selectedTickets.length}
      eventName={eventName}
      firstName={firstName}
      lastName={lastName}
      email={email}
    />
  ) : (
    <div className="flex flex-col justify-center items-center">
      <p className="text-center text-gray-500">{t('transfer.assurerMessage')}</p>
      <p className="text-red-500 text-center">{t('transfer.chaqueBilletMessage')}</p>
      <div className="flex flex-row justify-center items-center w-full mb-0">
        <div className="flex-grow mb-0">
          <h3>{t('transfer.detailDuReceveur')}</h3>
          <div className="flex w-full flex-row items-center">
            <PersonImage width="10%" height="10%" />
            <div className="ml-2 flex flex-col">
              <h4 className="mb-0 mt-0">
                {firstName} {lastName}
              </h4>
              <h5 className="mb-0 mt-0 text-gray-500">{email}</h5>
            </div>
          </div>
        </div>
        <EditButton handleClick={EditForm} />
      </div>
      <div className="flex flex-row justify-center items-center w-full mb-0">
        <div className="flex-grow mb-0">
          <h3>
            {t('transfer.billetTransfere')} ({selectedTickets.length})
          </h3>
        </div>
        <EditButton handleClick={editTicketSelection} />
      </div>

      {/* Event Modal */}
      {selectedTickets.map((ticket, index) => (
        <ModalTicket key={index} category={ticket.category} id={ticket.id} />
      ))}
      <div className="flex flex-row w-full items-center justify-start mt-5">
        <input
          id="default-checkbox"
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
          value=""
          className="w-4 h-4 text-purple-600 bg-custom border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600"
        />
        <label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
          <p className="font-bold m-0">
            {t('transfer.firstPolitiqueMessage')}{' '}
            <span className="text-purple-600">{t('transfer.secondPolitiqueMessage')}</span>{' '}
            {t('transfer.thirdPolitiqueMessage')}{' '}
            <span className="text-purple-600">{t('transfer.forthPolitiqueMessage')}</span>
          </p>
        </label>
      </div>
      <div className="flex flex-row w-full justify-center items-center mt-5 flex-grow space-x-4">
        <DialogClose asChild>
          <Button
            variant="contained"
            onClick={handleCancelTicketSelection}
            sx={{
              borderRadius: 3,
              height: 48,
              px: 4,
              width: { xs: '100%', md: '100%' },
              '&:hover': {
                backgroundColor: '#6b7280', // Change background color on hover
                color: 'white' // Change text color on hover
              }
            }}
            className="bg-gray-500 dark:bg-gray-800"
          >
            <Typography variant="lg" fontWeight={700} className="leading-10">
              {t('transfer.annulerUserFormButton')}
            </Typography>
          </Button>
        </DialogClose>
        <Button
          onClick={printInformations}
          disabled={!isChecked}
          variant="contained"
          sx={{
            borderRadius: 3,
            height: 48,
            px: 4,
            background: 'linear-gradient(90deg, #27D4FF 0%, #584CF4 100%)',
            '&:hover': {
              background: 'linear-gradient(90deg, #27D4FF 0%, #584CF4 100%)'
            },
            width: { xs: '100%', md: '100%' }
          }}
        >
          {transferingTicket ? (
            <CircularProgress />
          ) : (
            <Typography variant="lg" fontWeight={700} className="leading-10">
              {t('transfer.transfererButton')}
            </Typography>
          )}
        </Button>
      </div>
    </div>
  );
}

export default ModalBody;
