import { useMediaQuery } from '@mui/material';
import { Tilt } from 'react-tilt';

interface ResponsiveTiltProps {
  children?: React.ReactNode;
}

const ResponsiveTilt = (props: ResponsiveTiltProps) => {
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  const height = isDesktop ? '100vh' : 'auto';

  return (
    <div className={`w-full h-${height}`}>
      {isDesktop ? <Tilt options={{ scale: 1, perspective: 1800, max: 12 }}>{props.children}</Tilt> : props.children}
    </div>
  );
};

export default ResponsiveTilt;
