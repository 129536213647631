import { Button, CircularProgress, Typography } from '@mui/material';
import ClockImage from '../ClockImage';
import ticketApi from '@/services/api/ticket';
import { useNavigate } from 'react-router-dom';
import { DrawerClose } from '@/components/ui/drawer';
import intransferImage from '../../../assets/images/icons/intransfer.png';
import alreadyTransfredImage from '../../../assets/images/icons/alreadytransfered.png';
import CancelVerified from '@/pages/CancelVerified';
import { useState } from 'react';
import CantGetTicket from '../CantGetTicket';
import { t } from 'i18next';
interface TicketInTransferProps {
  setOpen: () => void;
  email: string;
  eventId: string;
  uuid: string;
  isDrawer: boolean;
}

function TicketInTransfer({ setOpen, email, eventId, uuid, isDrawer }: TicketInTransferProps) {
  const [cancelSuccess, setCancelSuccess] = useState(false);
  const [exitTransfer, { isLoading: exitTransferring, isSuccess: isSuccess, isError }] =
    ticketApi.endpoints.exitTicketTransfer.useMutation();
  const navigate = useNavigate();
  const exitTran = async () => {
    const uuids = [uuid];
    const exitTransferBody = {
      ticketUuids: uuids,
      eventUuid: eventId // Array of ticket UUIDs to transfer
    };
    console.log(exitTransferBody);

    const reponse = await exitTransfer(exitTransferBody).unwrap();
    if (reponse.reponse == 'Transfer successfully exited.') {
      // navigate(`/cancel-verified`);
      setCancelSuccess(true);
    }
  };
  return !cancelSuccess ? (
    isError ? (
      <CantGetTicket setOpen={setOpen} isDrawer={isDrawer} setCancelSuccess={() => setCancelSuccess(false)} />
    ) : (
      <div className="flex flex-col   justify-center items-center">
        <div className="flex flex-col w-[350px] justify-center items-center">
          <div className="flex items-center justify-center mt-5">
            <img src={intransferImage} width="50%" height="50%" />
          </div>

          <h3 className="text-yellow-400 text-center p-2">{t('transfer.transferEnCours')}</h3>

          <p className="text-center">
            {t('transfer.ceBilletTransferer')} <span className="font-bold">{email}</span>
            <br /> {t('transfer.maisPas')}
          </p>

          <div className="flex flex-row items-center mt-10 justify-center">
            {isDrawer ? (
              <DrawerClose asChild>
                <Button
                  className=" bg-gray-500 dark:bg-gray-800 "
                  onClick={setOpen}
                  variant="contained"
                  sx={{
                    borderRadius: 3,
                    height: 48,
                    px: 4,
                    '&:hover': {
                      backgroundColor: '#6b7280', // Change background color on hover
                      color: 'white' // Change text color on hover
                    }
                  }}
                >
                  <Typography variant="lg" fontWeight={700} className="leading-10 text-sm">
                    {t('transfer.retourButton')}
                  </Typography>
                </Button>
              </DrawerClose>
            ) : (
              <Button
                className=" bg-gray-500 dark:bg-gray-800"
                onClick={setOpen}
                variant="contained"
                sx={{
                  borderRadius: 3,
                  height: 48,
                  px: 4,
                  width: { xs: '100%', md: 'auto' },
                  '&:hover': {
                    backgroundColor: '#6b7280', // Change background color on hover
                    color: 'white' // Change text color on hover
                  }
                }}
              >
                <Typography variant="lg" fontWeight={700} className="leading-10 text-sm">
                  {t('transfer.retourButton')}
                </Typography>
              </Button>
            )}

            {/* <Button onClick={exitTran} className="bg-blue-500 text-white  px-4 py-2 rounded-full">
        Annuler la transfert
        {exitTransferring && <CircularProgress size={20} />}
      </Button> */}
            <Button
              className="ml-2"
              onClick={exitTran}
              variant="contained"
              sx={{
                borderRadius: 3,
                height: 48,
                px: 4,
                background: 'linear-gradient(90deg, #27D4FF 0%, #584CF4 100%)',
                '&:hover': {
                  background: 'linear-gradient(90deg, #27D4FF 0%, #584CF4 100%)'
                }
              }}
            >
              <Typography variant="lg" fontWeight={700} className="leading-10 text-sm">
                {t('transfer.annulerLeTransfert')}
              </Typography>
            </Button>
            {exitTransferring && <CircularProgress size={20} />}
          </div>
        </div>
      </div>
    )
  ) : (
    <CancelVerified isDrawer={isDrawer} setOpen={setOpen} setCancelSuccess={() => setCancelSuccess(false)} />
  );
}
export default TicketInTransfer;
