import { Box, Tab, Tabs } from '@mui/material';
import TickieAppBar from '../../components/AppBar';
import HomeEventList from './components/HomeEventList';
import Tags from './components/Tags';
import TabPanel from '../../components/TabPanel';
import { useState } from 'react';
import EventPoster from '../CustomHome/components/EventPoster';

function Home() {
  const [tab, setTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <>
      <TickieAppBar />

      {/* <Box alignItems="center" justifyContent="center" width="100%" display="flex">
          <Tabs value={tab} onChange={handleTabChange}>
            <Tab label={'Events'} />
            <Tab label={'Collections'} />
          </Tabs>
        </Box> */}
      <TabPanel value={tab} index={0}>
        {/* <Tags /> */}
        <EventPoster />
        <Box sx={{ mx: { xs: 3, md: 6 }, mt: -6 }}>
          <HomeEventList isEvent />
        </Box>
        <Box height={60} />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        {/* <Tags /> */}
        <HomeEventList />
        <Box height={60} />
      </TabPanel>
    </>
  );
}

export default Home;
