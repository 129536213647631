import {
  Avatar,
  Box,
  ButtonBase,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography
} from '@mui/material';
import { Stack } from '@mui/system';
import { t } from 'i18next';
import DescriptionIconDecoration from '../../components/DescriptionIconDecoration';
import userApi, { User } from '../../services/api/user';
import greyScale from '../../themes/default/colors/greyscale';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SendIcon from '@mui/icons-material/Send';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import TabPanel from '../../components/TabPanel';
import { useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InfoIcon from '@mui/icons-material/Info';
import SettingsIcon from '@mui/icons-material/Settings';
import DesktopDrawer from '../../components/DesktopDrawer';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import Login from '../Login';
// import { useWeb3Auth } from '../../services/web3/web3auth';
import UnhandledError from '../../components/UnhandledError';
import SeasonCard from './components/SeasonCard';
import SearchIcon from '@mui/icons-material/Search';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import RewardCard from './components/RewardCard';
import { shortenPublicKey } from '../../utils/strings';
import seasonApi from '../../services/api/season';
import ticketApi from '../../services/api/ticket';
import TicketCollectionCard from '../../components/TicketCollectionCard';
import { useTheme } from '@/components/theme-provider';
import TickieAppBar from '@/components/AppBar';
import CustomAppBarGaya from '../CustomHomeGaya/components/CustomAppBar';
import CustomAppBarNantes from '../CustomHomeNantes/components/CustomAppBar';
import CustomAppBar from '@/components/CustomAppBar';
import { getWebsiteConfig } from '../whiteLabel/themeFactory';
import CustomAppBarNancy from '../CustomHomeNancy/components/CustomAppBar';

function Profile() {
  const { data: user, isLoading, isError } = userApi.endpoints.getUser.useQuery();
  const { data: eventTickets } = ticketApi.endpoints.getTicketsEvent.useQuery({ used: true });
  const { data: seasons } = seasonApi.endpoints.getUserSeasons.useQuery();
  const { isAuth } = useAppSelector(state => state.authSlice);
  const navigate = useNavigate();
  const { theme } = useTheme();
  const [tab, setTab] = useState(0);
  // const { balance, address, wallet } = useWeb3Auth();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const seasonBgImages = [
    'card-red.png',
    'card-yellow.png',
    'card-blue.png',
    'card-green.png',
    'card-blue2.png',
    'card-orange.png',
    'card-purple.png',
    'card-red2.png'
  ];

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const copyWalletAddress = (user: User) => {
    navigator.clipboard.writeText(`${user?.publicKey}`);
    setTooltipOpen(true);
    setTimeout(() => {
      setTooltipOpen(false);
    }, 1750);
  };

  if (!isAuth) {
    return <Login />;
  }

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={`calc(100vh - 200px)`}>
        <CircularProgress />
      </Box>
    );
  }

  if (isError || !user) {
    return <UnhandledError />;
  }

  // const season1: Season = {
  //   id: 1,
  //   uuid: '',
  //   description: '',
  //   events: [],
  //   name: 'Collectible',
  //   numberOfEvents: 0,
  //   organizationUuid: ''
  // };
  // const season2: Season = {
  //   id: 2,
  //   uuid: '',
  //   description: 'Blagnac',
  //   events: [],
  //   name: 'Abonnement',
  //   numberOfEvents: 0,
  //   organizationUuid: ''
  // };

  const collectibles = () => {
    return (
      <Grid container spacing={2} pl={2} mt={2} minHeight="50vh">
        {/* <Stack direction="row" justifyContent="center" alignItems="center" spacing={1} mb={2} width="100%">
          <Stack
            direction="row"
            width="100%"
            alignItems="center"
            bgcolor={'rgba(158, 158, 158, 0.20)'}
            borderRadius={4}
            py={2}
            px={2}>
            <SearchIcon />
            <Typography ml={1} variant="md" fontWeight={600} color={greyScale[500]}>
              Rechercher une collection
            </Typography>
          </Stack>
        </Stack> */}

        {seasons &&
          seasons?.map((season, index: number) => (
            <SeasonCard
              key={index}
              bgImage={seasonBgImages[index % seasonBgImages.length]}
              season={season}
              tickets={user?.tickets}
            />
          ))}

        {/* <Stack direction="row" justifyContent="center" alignItems="center" spacing={1} width="100%">
          <SeasonCard isCollectible bgImage="card-blue2.png" season={season1} tickets={[]} />
          <SeasonCard isCollectible bgImage="card-purple.png" season={season1} tickets={[]} />
        </Stack> */}
      </Grid>
    );
  };

  const myTicketsCollection = () => {
    const ticketCount = eventTickets?.reduce((acc, curr) => acc + curr.tickets.length, 0);

    return (
      <Grid container spacing={2} pl={2} mt={2} minHeight="50vh">
        <Typography variant="h5" fontWeight={600} textAlign="left" mb={3} ml={1}>
          Mes billets <span style={{ color: greyScale[500], fontWeight: 500 }}>({ticketCount})</span>
        </Typography>
        {/* <Stack direction="row" justifyContent="center" alignItems="center" spacing={1} mb={2} width="100%">
          <Stack
            direction="row"
            width="100%"
            alignItems="center"
            bgcolor={'rgba(158, 158, 158, 0.20)'}
            borderRadius={4}
            py={2}
            px={2}>
            <SearchIcon />
            <Typography ml={1} variant="md" fontWeight={600} color={greyScale[500]}>
              Rechercher une événement
            </Typography>
          </Stack>
        </Stack> */}
        <Grid container spacing={2} justifyContent="center">
          {eventTickets &&
            eventTickets?.map((ev, index: number) =>
              ev.tickets.map(t => (
                <Grid item xs={6} sm={6} md={4} lg={3}>
                  <TicketCollectionCard event={ev} ticket={t} />
                </Grid>
              ))
            )}

          {/* <Stack direction="row" justifyContent="center" alignItems="center" spacing={1} width="100%">
          <SeasonCard isCollectible bgImage="card-blue2.png" season={season1} tickets={[]} />
          <SeasonCard isCollectible bgImage="card-purple.png" season={season1} tickets={[]} />
        </Stack> */}
        </Grid>
      </Grid>
    );
  };

  const rewards = () => {
    return (
      <Grid container>
        <Typography variant="h6" fontWeight={600} textAlign="left" mt={3} mb={3}>
          Récompenses <span style={{ color: greyScale[500], fontWeight: 500 }}>(2)</span>
        </Typography>
        {/* <Stack direction="row" justifyContent="center" alignItems="center" spacing={1} width="100%">
          <Stack direction="row" width="100%" bgcolor={greyScale[900]} borderRadius={100} p={0.5} pl={1}>
            <SearchIcon />
            <Typography ml={1} variant="md" fontWeight={600} color={greyScale[700]}>
              Filtre par match, récompense
            </Typography>
          </Stack>

          <Stack
            border={`1px solid ${greyScale[700]}`}
            borderRadius={100}
            height={30}
            width={30}
            alignItems="center"
            justifyContent="center">
            <BookmarkIcon fontSize="small" />
          </Stack>
        </Stack> */}
        <RewardCard
          cardImage="images/dax.png"
          reward="Une boisson offerte à la buvette"
          title="Blagnac - DAX"
        ></RewardCard>
        <RewardCard
          cardImage="images/csbj.png"
          reward="Une boisson offerte à la buvette"
          title="Blagnac - CSBJ"
        ></RewardCard>
      </Grid>
    );
  };

  const crypto = () => {
    return (
      <Grid container minHeight="80vh">
        <Stack direction="column" spacing={2} width="100%">
          <Box pt={1} pb={1}>
            <Divider />
          </Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center" pl={2}>
            <Typography variant="xl" fontWeight={700}>
              {t('profile.balance')}
            </Typography>
            <Stack direction="row" alignItems="center">
              <Typography variant="lg" fontWeight={700} color={greyScale[700]} pr={1}>
                {/* {balance?.toString() ?? ''}
                {`${Intl.NumberFormat('fr-FR', {
                  style: 'currency',
                  currency: 'EUR'
                }).format(balance as any)}`} */}
              </Typography>
              <img alt="matic" src="/images/chains/chiliz.png" height={20} />
              <Box width={8} />
              <InfoIcon sx={{ color: greyScale[700] }} />
            </Stack>
          </Stack>
          <Tooltip
            open={!tooltipOpen ? undefined : tooltipOpen}
            title={!tooltipOpen ? t('profile.copyText') : t('profile.copied')}
            placement="bottom-end"
          >
            <ButtonBase sx={{ justifyContent: 'space-between', pt: 1, pb: 1 }} onClick={() => copyWalletAddress(user)}>
              <>
                <Typography variant="lg" fontWeight={600} color="primary" pl={2}>
                  {t('profile.address')}
                </Typography>
                <Stack direction="row">
                  <Typography variant="md" fontWeight={600} pr={1}>
                    {`${shortenPublicKey(user.publicKey ?? '')}`}
                  </Typography>
                  <ContentCopyIcon fontSize="small" />
                </Stack>
              </>
            </ButtonBase>
          </Tooltip>
          <Stack direction="row" justifyContent="space-evenly" pt={1}>
            <Stack direction="column" spacing={1}>
              <ButtonBase>
                <DescriptionIconDecoration title={t('profile.creditSentence') ?? ''}>
                  <ShoppingCartIcon />
                </DescriptionIconDecoration>
              </ButtonBase>

              <Typography>{t('profile.credit')}</Typography>
            </Stack>
            <Divider orientation="vertical" />
            <Stack direction="column" spacing={1}>
              <ButtonBase>
                <DescriptionIconDecoration title={t('profile.recieveSentence') ?? ''}>
                  <MoveToInboxIcon />
                </DescriptionIconDecoration>
              </ButtonBase>
              <Typography>{t('profile.recieve')}</Typography>
            </Stack>
            <Divider orientation="vertical" />
            <Stack direction="column" spacing={1}>
              <ButtonBase>
                <DescriptionIconDecoration title={t('profile.sendSentence') ?? ''}>
                  <SendIcon />
                </DescriptionIconDecoration>
              </ButtonBase>
              <Typography>{t('profile.send')}</Typography>
            </Stack>
          </Stack>
        </Stack>
      </Grid>
    );
  };

  const appId = getWebsiteConfig().id;

  const appBar = () => {
    if (appId == 'TICKIE') {
      return <TickieAppBar isProfile />;
    } else if (appId == 'GAYA') {
      return <CustomAppBarGaya />;
    } else if (appId === 'BLAGNAC') {
      return <CustomAppBar />;
    } else if (appId === 'GRIZZLYS') {
      return <CustomAppBar />;
    } else if (appId === 'SAINTES') {
      return <CustomAppBar />;
    } else if (appId === 'NANTES') {
      return <CustomAppBarNantes />;
    } else if (appId === 'NANCY') {
      return <CustomAppBarNancy />;
    } else {
      return <TickieAppBar />;
    }
  };
  return (
    <>
      {appBar()}
      {/* <TickieAppBar isProfile /> */}
      {appId == 'TICKIE' && <div className="-mb-5" />}
      <div className={`${theme === 'light' ? '' : 'reward-page-bg'}`}>
        {/* {appBar()} */}
        <Stack direction="row" spacing={2} justifyContent="start" alignItems="start" p={3}>
          <Stack justifyContent="center" alignItems="center">
            <Avatar src={user?.avatar} sx={{ height: 32, width: 32 }} />
          </Stack>
          <Typography variant="h6" align="center">{`${user?.firstName} ${user?.lastName}`}</Typography>
        </Stack>
        <Box p={3}>
          <Divider />
        </Box>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" alignItems="center" px={3} py={1}>
            {[
              { image: 'bronz', quantity: user?.tickets?.filter(ticket => ticket.rarity === 'BRONZE')?.length ?? 0 },
              { image: 'silver', quantity: user?.tickets?.filter(ticket => ticket.rarity === 'SILVER')?.length ?? 0 },
              { image: 'gold', quantity: user?.tickets?.filter(ticket => ticket.rarity === 'GOLD')?.length ?? 0 },
              { image: 'diamond', quantity: user?.tickets?.filter(ticket => ticket.rarity === 'DIAMOND')?.length ?? 0 },
              { image: 'unique', quantity: user?.tickets?.filter(ticket => ticket.rarity === 'UNIQUE')?.length ?? 0 }
            ].map((rarity, index) => (
              <Stack direction="row" alignItems="center" spacing={1} mr={1} key={index}>
                <img alt="rarity" src={`/images/rarities/${rarity.image}.png`} height={20} />
                <Typography variant="h6">{rarity.quantity}</Typography>
              </Stack>
            ))}
          </Stack>

          <Box className="number-of-collectibles" px={1.5} py={0.5} mr={2} display="flex" alignItems="center">
            <img alt="a" src={`/images/rarities/purple.png`} height={20} /> &nbsp;&nbsp;{' '}
            <Typography>{user?.tickets?.length ?? 0}</Typography>
          </Box>
        </Stack>

        <Box sx={{ ml: { xs: 3, md: 6 }, mr: { xs: 3, md: 6 }, mt: 1 }}>
          <Box alignItems="center" justifyContent="center" width="100%" display="flex">
            <Tabs value={tab} onChange={handleTabChange}>
              <Tab label={t('profile.collection')} />
              <Tab label={'Mes billets'} />
              <Tab label={t('profile.rewards')} />
              <Tab label="Portefeuille" />
            </Tabs>
          </Box>
          <TabPanel value={tab} index={0}>
            {collectibles()}
          </TabPanel>
          <TabPanel value={tab} index={1}>
            {myTicketsCollection()}
          </TabPanel>
          <TabPanel value={tab} index={2}>
            {import.meta.env.VITE_NODE_ENV != 'production' ? rewards() : <Box height="80vh" />}
          </TabPanel>

          <TabPanel value={tab} index={3}>
            {crypto()}
          </TabPanel>
          <Box height={64} />
        </Box>
      </div>
    </>
  );
}

export default Profile;
