import { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import paymentApi, { PurchaseType } from '../../services/api/payment';
import { Box, Card, CircularProgress, IconButton, Stack, Typography, useMediaQuery } from '@mui/material';
import { t } from 'i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import userApi from '../../services/api/user';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import ProceedPaymentCard from './components/ProceedPaymentCard';
import UserInfos from './components/UserInfos';
import CheckoutForm from './components/CheckoutForm';
import Footer from '../../components/Footer';
import OrganizationEventsCard from '../../components/OrganizationEventsCard';
import eventApi from '../../services/api/event';
import * as Yup from 'yup';
import { Formik } from 'formik';
import UnhandledError from '../../components/UnhandledError';
import Login from '../Login';
import InvitForm from './components/Invite';
import invitationApi from '../../services/api/invitation';
import shoppingCartSlice from '../../services/shoppingCart';
import purchaseIntentApi from '../../services/api/purchase-intent';
import EventCheckoutCard from '@/components/EventCheckoutCard';

export interface IFormData {
  firstName: string;
  lastName: string;
  email: string;
}

const Checkout = () => {
  /**
   * Router
   */
  const { eventId } = useParams();
  const navigate = useNavigate();
  let [urlParams, setUrlParams] = useSearchParams();
  const promoCodeFromUrl = urlParams.get('promoCode');
  const inviteFromUrl = urlParams.get('invite');
  const purchaseIntentFromUrl = urlParams.get('purchaseIntent');
  const isRewardFromUrl = urlParams.get('isReward');
  const { data: event, isLoading, isError } = eventApi.endpoints.getEventDetails.useQuery(eventId ?? '');
  const [createPaymentIntent, { data: paymentIntent }] = paymentApi.endpoints.createPaymentIntent.useMutation();
  /**
   * local state
   */
  const [showPromoModal, setShowPromoModal] = useState(false);
  const [stripeObject, setStripeObject] = useState<Stripe | null>(null);
  /**
   * redux
   */
  const dispatch = useAppDispatch();
  const { isAuth } = useAppSelector(state => state.authSlice);
  const { tickets: selectedTickets, finalPrice, srcPrice, promo } = useAppSelector(app => app.shoppingCartSlice);
  const { darkTheme } = useAppSelector(state => state.themeSlice);
  /**
   *  theme
   */
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  /**
   * API calls
   */
  const { data: user, isLoading: userLoading } = userApi.endpoints.getUser.useQuery();
  const { data: invitation } = invitationApi.endpoints.getInvitation.useQuery(inviteFromUrl!, {
    skip: !inviteFromUrl
  });

  const { data: purchaseIntent } = purchaseIntentApi.endpoints.getPurchaseIntent.useQuery(purchaseIntentFromUrl!, {
    skip: !purchaseIntentFromUrl
  });
  useEffect(() => {
    if (invitation) {
      dispatch(shoppingCartSlice.actions.resetTickets());
      for (let i = 0; i < invitation.quantity!; i++) {
        dispatch(
          shoppingCartSlice.actions.addTicket({
            ticketCategory: { ...invitation.ticketCategory, price: 0 },
            eventId: eventId as string,
            type: 'event'
          })
        );
      }
    }
  }, [invitation]);

  useEffect(() => {
    if (purchaseIntentFromUrl) {
      dispatch(shoppingCartSlice.actions.resetTickets());
      console.log(purchaseIntent);
      if (!purchaseIntent?.ticketCategories) {
        return;
      }
      for (const e of purchaseIntent?.ticketCategories) {
        for (let i = 0; i < e.quantity!; i++) {
          dispatch(
            shoppingCartSlice.actions.addTicket({
              ticketCategory: { ...e.ticketCategory },
              eventId: eventId as string,
              type: 'event'
            })
          );
        }
      }
    }
  }, [purchaseIntent]);

  useEffect(() => {
    if (selectedTickets.length == 0 && !inviteFromUrl && !purchaseIntentFromUrl) {
      navigate(`/reservation/${eventId}${promoCodeFromUrl ? `?promoCode=${promoCodeFromUrl}` : ''}`);
    }
  }, []);

  useEffect(() => {
    if (finalPrice > 0) {
      const fetchStripeObject = async () => {
        // If there is no accountId, do not run the loadStripe function.
        if (event?.organization?.stripeAccountId) {
          const res = await loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY, {
            stripeAccount: event?.organization?.stripeAccountId
          });
          // When we have got the Stripe object, pass it into our useState.
          setStripeObject(res);
        }
      };
      fetchStripeObject();
    }
  }, [event, finalPrice]);

  useEffect(() => {
    if (user && event && finalPrice > 0) {
      createPaymentIntent({
        amount: finalPrice,
        currency: 'eur',
        metadata: {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          eventUuid: eventId as string,
          places: selectedTickets.map(cat => {
            return { categoryUuid: cat.categoryUuid, quantity: cat.quantity };
          }),
          promoCode: selectedTickets.find(e => e.promo)?.promo?.promoCode ?? undefined,
          finalPrice: finalPrice,
          srcPrice: srcPrice,
          status: 'COMPLETE',
          userUuid: user.uuid,
          purchaseType: PurchaseType.Ticket,
          subscription: undefined,
          purchaseIntentUuid: purchaseIntentFromUrl ? purchaseIntentFromUrl : undefined
        }
      }).unwrap();
    }
  }, [user, event, finalPrice]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={`calc(100vh)`}>
        <CircularProgress />
      </Box>
    );
  }

  if (isError || !event) {
    return <UnhandledError />;
  }

  function appBar() {
    return (
      <Stack direction="row" alignItems="center" mt={2} mb={3} spacing={1}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4">{t('checkout.recap')}</Typography>
      </Stack>
    );
  }

  const checkoutForm = () => {
    if (!isAuth && isDesktop && !eventId) {
      return null;
    }
    if (paymentIntent && paymentIntent?.client_secret && finalPrice > 0) {
      return (
        <>
          <Elements
            options={{
              clientSecret: paymentIntent?.client_secret,
              appearance: { theme: darkTheme ? 'night' : 'flat', labels: 'floating' }
            }}
            stripe={stripeObject}
          >
            <CheckoutForm paymentIntent={paymentIntent} eventId={eventId ?? ''} />
          </Elements>
        </>
      );
    } else if (finalPrice <= 0 && eventId) {
      return <InvitForm eventId={eventId} />;
    }
  };

  return (
    <Box>
      {event && (
        <div
          className="-z-20 top-0 left-0 absolute w-full h-full max-h-[600px] bg-contain md:bg-cover backdrop-blur-5xl bg-no-repeat"
          style={{
            backgroundImage: `url(${event.cover})`,
            backgroundRepeat: 'no-repeat'
          }}
        >
          <div className="-z-20 top-0 left-0 absolute w-full h-full backdrop-blur-3xl bg-gradient-to-t  from-white/[1] dark:from-dark1/[1] from-0% via-white/50 dark:via-black/30 to-blue-900/50 bg-no-repeat ">
            <div className="-z-10 bottom-0 left-0 absolute w-full h-96  bg-cover bg-gradient-to-t from-white/[1] dark:from-dark1/[1] dark:to-black/1 bg-no-repeat " />
          </div>
        </div>
      )}
      <Box minHeight="calc(100vh - 180px)">
        <header>{appBar()}</header>
        <Formik
          enableReinitialize={true}
          initialValues={{
            firstName: user?.firstName ?? '',
            lastName: user?.lastName ?? '',
            email: user?.email
              ? user.email
              : user && user.tickets.length > 0
              ? user.tickets[user.tickets.length - 1].email
              : ''
          }}
          validationSchema={Yup.object({
            firstName: Yup.string().required(t('formErrors.firstNameEmpty') ?? ''),
            lastName: Yup.string().required(t('formErrors.lastNameEmpty') ?? ''),
            email: Yup.string()
              .email(t('formErrors.mailNotValid') ?? '')
              .required(t('formErrors.mailNotValid') ?? '')
          })}
          onSubmit={async values => {
            try {
            } catch (error) {}
          }}
        >
          {userLoading ? (
            <Stack direction="row" justifyContent="center" alignItems="center" height={`calc(100vh)`}>
              <CircularProgress></CircularProgress>
            </Stack>
          ) : (
            <Box m={2}>
              <>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:container">
                  <div className="md:col-span-3">
                    <EventCheckoutCard event={event} />
                  </div>
                  <div className="md:col-span-2">
                    {isAuth ? null : (
                      <Card className="checkout-card flex flex-col w-full justify-center items-center h-full">
                        <Login
                          isReservation
                          redirectLink={`${import.meta.env.VITE_APP_BASE_URL}/reservation/${eventId}`}
                        />
                      </Card>
                    )}
                    {isAuth && <UserInfos user={user} userLoading={userLoading} />}
                    {checkoutForm()}
                  </div>
                  <div className="col-span-1">
                    <ProceedPaymentCard event={event} isEventAvailable={false} goToCheckout={() => null} />
                  </div>
                </div>
              </>
            </Box>
          )}
        </Formik>
      </Box>
      <div className="h-24" />
      <Footer />
    </Box>
  );
};

export default Checkout;
