import { X } from 'lucide-react';
import React, { ReactNode } from 'react';
interface TransferDetailModalProps {
  open: boolean;
  onClose: () => void;
  children?: ReactNode;
}
function TransferDetailModal({ open, onClose, children }: TransferDetailModalProps) {
  return (
    <>
      <div
        onClick={onClose}
        className={`fixed inset-0 flex justify-center items-center m-5 transition-colors ${
          open ? 'visible bg-black/70 z-50' : 'invisible'
        }`}
      >
        <div
          onClick={e => e.stopPropagation()}
          className={`bg-gray-100 dark:bg-[#181A20] no-scrollbar rounded-xl shadow p-6 transition-all ${
            open ? 'scale-100 opacity-100' : 'scale-125 opacity-0'
          }`}
          style={{ maxHeight: '80vh', overflowY: 'auto' }} // Set max height and enable scrolling
        >
          <button
            onClick={onClose}
            className="absolute top-2 right-2 p-1  border-none rounded-lg dark:text-gray-400 bg-gray-100 hover:bg-gray-50 dark:bg-[#181A20] dark:hover:bg-[#181A20] dark:hover:text-white"
          >
            <X />
          </button>
          <div className="no-scrollbar">{children}</div>
        </div>
      </div>
    </>
  );
}

export default TransferDetailModal;
