import { Box } from '@mui/material';

const BackgroundGradient = () => {
  return (
    <Box
      sx={{
        backgroundImage: `url('https://opensea.io/static/images/home-gradient-dark.png')`,
        backgroundSize: 'cover',
        position: 'absolute',
        width: '100%',
        height: '100%',
        opacity: 0.5,
        zIndex: -1
      }}
    />
  );
};

export default BackgroundGradient;
