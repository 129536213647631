import { Column } from '@/components/ui/column';
import { Row } from '@/components/ui/row';
import palette from '@/themes/default/colors/palette';
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';

const InsuranceCard = () => {
  return (
    <Column className={`checkout-card p-4 w-full`}>
      <Row className="justify-between">
        <Typography variant="h5">Assurez vos billets</Typography>
        <Typography variant="h6"> 0,50€ / billet</Typography>
      </Row>
      <Typography variant="caption">Nos billets sont non échangeables, non remboursables</Typography>
      <Row className="h-5" />
      <Box
        sx={{ borderRadius: '8px', padding: '16px' }}
        className="border-solid border-1 border-neutral-200 dark:border-dark3 "
      >
        <Typography variant="lg">
          Avec notre <strong>assurance remboursement</strong>, vous êtes remboursés avec ou sans justificatif,{' '}
          <strong>même en cas de COVID.</strong>
        </Typography>
        <ul className="list-image-[url(/images/done.png)] ...">
          <li>Annulation en ligne 24h/24h + remboursement sous 48h</li>
          <li>Vos billets sont remboursés à 100% avec justificatif ou à 80% sans justificatif</li>
        </ul>
        <FormControl>
          <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
            <FormControlLabel value="OUI" control={<Radio />} label="OUI, j’assure mes billets" />
            <FormControlLabel value="NON" control={<Radio />} label="NON, je n’ai pas besoin d’assurance" />
          </RadioGroup>
        </FormControl>
      </Box>
    </Column>
  );
};
export default InsuranceCard;
