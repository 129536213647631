/* eslint-disable camelcase */
import baseApi from './base';
import { Address, AddressDetails, Event, Organization } from './event';

/**
 * Model Ticket
 *
 */
export type Ticket = {
  id: number;
  uuid: string;
  qrCode: string;
  nftId: number;
  fullName: string;
  firstName: string;
  lastName: string;
  email: string;
  status: string;
  isOpened: boolean;
  type: string;
  isScanned: boolean;
  rewardScanned: boolean;
  srcPrice: number;
  finalPrice: number;
  discount: number;
  extReference: string;
  currencyCode: string;
  rarity: string;
  reward: string;
  tokenUri: string;
  updatedAt: Date;
  createdAt: Date;
  deletedAt: Date | null;
  eventId: number;
  userId: number;
  ticketCategoryId: number;
  organizationId: number;
  ticketReservationId: number;
  ticketCategory: TicketCategory;
  event: Event;
};

/**
 * Model TicketCategory
 *
 */
export type TicketCategory = {
  id: number;
  uuid: string;
  name: string | null;
  price: number | null;
  maxPerPerson: number | null;
  minPerPerson: number | null;
  quantityAvailable: number | null;
  quantitySold: number | null;
  startSaleDate: Date | null;
  endSaleDate: Date | null;
  range: number | null;
  status: string;
  ticketCategoryId: number | null;
  eventId: number;
  organizationId: number;
};

export interface Seat {
  key: string;
  value: string;
}

export interface TicketEvent {
  uuid: string;
  title: string;
  beginAt: string;
  endAt: string;
  cover: string;
  tickets: Ticket[];
  address: Address;
}

export interface TicketEventDetails {
  event: Event;
  tickets: Ticket[];
}
interface TicketTransferStatusResponse {
  status: string;
  receiverEmail: string;
}

export enum TicketStatus {
  FREE = 'FREE',
  PENDING = 'PENDING',
  TO_BE_PAID = 'TO_BE_PAID',
  ACQUIRED = 'ACQUIRED',
  CANCELLED = 'CANCELLED',
  CHECKED_IN = 'CHECKED_IN',
  EXPIRED = 'EXPIRED',
  INVALIDATED = 'INVALIDATED',
  RELEASED = 'RELEASED',
  PRE_RESERVED = 'PRE_RESERVED'
}

export interface RevealTicketsForm {
  status: TicketStatus;
}

export type TicketReservation = {
  id: number;
  uuid: string;
  validity: Date;
  status: TicketStatus;
  firstName: string;
  lastName: string;
  email: string;
  userLanguage: string | null;
  srcPrice: number;
  finalPrice: number;
  currencyCode: string | null;
  userId: number;
  promoCodeDiscountId: number | null;
  eventId: number;
  event: Event;
};
interface PassUrlResponse {
  saveUrl: string;
}
interface ExitTransferBody {
  ticketUuids: string[];
  eventUuid: string;
}
interface TransferBody {
  firstName: string;
  lastName: string;
  email: string;
  ticketUuids: string[];
  eventUuid: string;
}
interface TransferResponse {
  response: string;
}
interface ExitTransferResponse {
  reponse: string;
}
interface ApproveTransferResponse {
  reponse: string;
}
interface RefuserTransferResponse {
  response: string;
}
interface ApproveTransferBody {
  transferUid: string;
}
interface RefuseTransferBody {
  transferUuid: string;
}
interface AddToWalletForm {
  ticketUuid: string;
}
interface TicketStatusBody {
  ticketUuid: string;
  eventUuid: string;
}
interface TicketWithEvent {
  id: number;
  uuid: string;
  qrCode: string | null;
  nftId: number | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  status: string;
  isOpened: boolean;
  isScanned: boolean;
  rewardScanned: boolean;
  srcPrice: number;
  finalPrice: number;
  discount: number | null;
  extReference: string | null;
  rarity: string;
  reward: string;
  txHash: string;
  tokenUri: string;
  updatedAt: string;
  createdAt: string;
  deletedAt: string | null;
  eventId: number;
  userId: number | null;
  ticketCategoryId: number | null;
  ticketReservationId: number;
  type: string;
  ticketCategory: TicketCategory;
  organizationId: number | null;
  purchaseIntentId: number | null;
  transferId: number | null;
  event: Event;
}
interface Transfer {
  id: number;
  uuid: string;
  senderId: number;
  receiverFirstName: string;
  receiverLastName: string;
  receiverPhone: string | null;
  receiverEmail: string;
  status: string;
  updatedAt: string;
  createdAt: string;
  transfredAt: string | null;
  tickets: TicketWithEvent[];
}

interface ResponseTransferedInformation {
  transfer: Transfer;
}

interface TicketWaitingReponse {
  transfer: Transfer[];
}

interface GetTicketsEventParams {
  used?: boolean;
  toBeUsed?: boolean;
}

const ticketApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getTicketWaiting: builder.query<TicketWaitingReponse, void>({
      query: () => ({
        url: `/transfer/available-tickets`,
        method: 'GET'
      })
    }),
    getTransferedTicketInformation: builder.query<ResponseTransferedInformation, string>({
      query: eventUuid => ({
        url: `/transfer/transfer-info/${eventUuid}`,
        method: 'GET'
      })
    }),
    getTicketDetails: builder.query<TicketEventDetails, string>({
      query: eventUuid => ({
        url: `/tickets/events/${eventUuid}`,
        method: 'GET'
      }),
      providesTags: (result, error, arg) => [{ type: 'ticket-details', id: arg }]
    }),
    getTicketTransferStatus: builder.query<TicketTransferStatusResponse, TicketStatusBody>({
      query: ({ ticketUuid }) => ({
        url: `/transfer/ticket-status/${ticketUuid}`,
        method: 'GET'
      }),
      providesTags: (result, error, arg) => [{ type: 'ticket-details', id: arg.eventUuid }]
    }),
    getTicketsEvent: builder.query<TicketEvent[], GetTicketsEventParams>({
      query: params => ({
        url: `/tickets`,
        method: 'GET',
        params: params
      }),
      providesTags: ['tickets']
    }),
    refuserTransfer: builder.mutation<RefuserTransferResponse, RefuseTransferBody>({
      query: body => ({
        url: `/transfer/decline-transfer`,
        method: 'DELETE',
        body
      })
    }),
    approveTransfer: builder.mutation<ApproveTransferResponse, ApproveTransferBody>({
      query: body => ({
        url: `/transfer/approve-transfer`,
        method: 'POST',
        body
      })
    }),
    getTicketByUuid: builder.query<Ticket, string>({
      query: ticketUuid => ({
        url: `/tickets/${ticketUuid}`,
        method: 'GET'
      })
    }),
    ticketTransfer: builder.mutation<TransferResponse, TransferBody>({
      query: body => ({
        url: `/transfer/transfer-ownership`,
        method: 'POST',
        body
      }),
      invalidatesTags: (result, error, body) => [{ type: 'ticket-details', id: body.eventUuid }]
    }),
    exitTicketTransfer: builder.mutation<ExitTransferResponse, ExitTransferBody>({
      query: ({ ticketUuids }) => ({
        url: `/transfer/exit-transfer`,
        method: 'POST',
        body: { ticketUuids }
      }),
      invalidatesTags: (result, error, body) => [{ type: 'ticket-details', id: body.eventUuid }]
    }),

    getCollectibles: builder.query<
      (Ticket & {
        event: {
          uuid: string;
          beginAt: Date;
          web3Address: string;
          title: string;
        };
      })[],
      void
    >({
      query: () => ({
        url: `/tickets/collectibles`,
        method: 'GET'
      })
    }),
    open: builder.mutation<any, string>({
      query: ticketUuid => ({
        url: `/tickets/${ticketUuid}/open`,
        method: 'PATCH'
      })
    }),
    findPendingReservation: builder.query<TicketReservation[], void>({
      query: () => ({
        url: `/reservations/pending`,
        method: 'GET'
      })
    }),
    addToWallet: builder.mutation<string, AddToWalletForm>({
      query: body => ({
        url: `/tickets/generate-pass`,
        method: 'POST',
        body
      })
    }),
    addToGoogleWallet: builder.mutation<PassUrlResponse, AddToWalletForm>({
      query: body => ({
        url: `/tickets/generate-google-pass`,
        method: 'POST',
        body
      })
    })
  })
});

export default ticketApi;
