import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
// import { i18nextPlugin } from 'translation-check'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  fr: {
    translation: {
      home: {
        hello: 'Bonjour👋',
        onDisplay: "À l'affiche 🚀",
        seeAll: 'Voir tout',
        whatEventAreYouLookinFor: 'Rechercher un évènement',
        soon: 'Bientôt',
        darkMode: 'Mode sombre',
        lightMode: 'Mode claire',
        noEvent: 'Aucun Événement'
      },
      profile: {
        profile: 'Profil',
        myProfile: 'Mon profil',
        balance: 'Solde du portefeuille',
        address: 'ID du portefeuille',
        credit: 'Créditer',
        creditSentence:
          'Recharge ton portefeuille en toute sécurité pour transférer de l’argent ou payer plus facilement et rapidement',
        recieve: 'Recevoir',
        recieveSentence: 'Reçois des fonds depuis un autre portefeuille, en euro ou en cryptomonnaie',
        send: 'Envoyer',
        sendSentence:
          'Transfère des euros ou de la cryptomonnaie vers un autre portefeuille ou vers ton compte bancaire',
        collection: 'Collection',
        rewards: 'Récompenses',
        copyText: "Copier l'adresse",
        copied: 'Copié ✅'
      },
      navigationBar: {
        home: 'Home',
        marketplace: 'Marketplace',
        tickets: 'Billets',
        messages: 'Messages',
        profile: 'Profil',
        signIn: 'Connexion',
        signUp: 'Inscription'
      },
      event: {
        addToCalendar: 'Ajouter à mon calendrier',
        itinerary: 'Itinéraire',
        seeOnMap: "Voir l'emplacement sur la carte",
        itenerary: 'Itinéraire',
        priceOfTicketDepends: 'Le prix du billet dépend du forfait',
        eventIsFinished: 'Cet événement est terminé',
        description: 'Description',
        tickieTakaInfo:
          'Achetez et découvrez votre billet sur Tickie pour avoir la chance de gagner des récompenses exclusives 🤩',
        place: 'Emplacement',
        order: 'Réserver',
        share: 'Partager'
      },
      subscription: {
        addToCalendar: 'Ajouter à mon calendrier',
        seeOnMap: "Voir l'emplacement sur la carte",
        itenerary: 'Itinéraire',
        priceOfSubscriptionDepends: "Le prix de l'abonnement dépend du forfait",
        subscriptionNotAvailible: "Cet abonnement n'est pas disponible",
        description: 'Description',
        place: 'Emplacement',
        subscribe: "S'abonner",
        share: 'Partager'
      },
      reservation: {
        reserveAplace: 'Réserver une place',
        left_one: 'restant',
        left_other: 'restants',
        notAvailable: 'Épuisé',
        lookOnSecondaryMarket: 'Chercher sur le marché secondaire',
        pleaseSelectATicket: 'Veuillez sélectionner vos places avant de continuer',
        noTicketsAvailable: 'Aucun billet disponible à la vente',
        IhavePromoCode: "J'ai un code promo",
        removePromoCode: 'Retirer le code promo',
        promoCode: 'Code promo',
        promoCodeError: "Le code entré n'est pas valide",
        continue: 'Continuer'
      },
      transfer: {
        processPeutPrendre: 'CE PROCESSUS PEUT PRENDRE QUELQUES INSTANTS',
        billetEnAttente: 'BILLETS EN ATTENTE',
        aucunBilletDisponible: "Aucun billet n'est disponible.",
        vousAvez: 'Vous avez reçu',
        billetsPour: 'billets pour :',
        refuserBillet: 'Refuser',
        accepterBillet: 'Accepter',
        transferButton: 'Transférer',
        sellButton: 'Vendre',
        dialogDrawerTitle: 'Transférer mes billets',
        userFormMessageFirst: 'Renseignez les informations de la personne à qui',
        userFormMessageSecond: 'vous souhaitez transférer vos billets.',
        lastNameTextField: 'Nom',
        firstNameTextField: 'Prénom',
        emailTextField: 'Email',
        lastNameMessage: 'Veuillez entrer un nom.',
        firstNameMessage: 'Veuillez entrer un prénom.',
        emailMessage: 'Veuillez entrer une adresse e-mail valide.',
        annulerUserFormButton: 'Annuler',
        suivantUserFormButton: 'Suivant',
        ticketSelectionMessage:
          'Sélectionnez les billets que vous voulez transférer. ⚠️ Vous n’y aurez plus accès une fois que cette personne aura accepté le transfert.',
        BilletsSelectionnesMessage: 'billets sélectionnés',
        BilletTicketBody: 'BILLET',
        categorieTicketBody: 'CATÉGORIE',
        typeTicketBody: 'TYPE',
        assurerMessage: 'Assurez vous que toutes les informations soient correctes avant de les transférer.',
        chaqueBilletMessage: '🚨 Chaque billet n’est transférable qu’une seule fois',
        detailDuReceveur: 'Détail du receveur',
        billetTransfere: 'Billets transférés',
        firstPolitiqueMessage: "J'accepte notre",
        secondPolitiqueMessage: 'Politique de Confidentialité',
        thirdPolitiqueMessage: 'ainsi que nos',
        forthPolitiqueMessage: 'Termes et Conditions.',
        transfererButton: 'Transférer',
        billetsTransferers: 'BILLETS TRANSFÉRÉS',
        billetspour: 'Billets pour',
        emailEnvoyer: 'Un email vient d’être envoyé à',
        recuperBillets: 'pour qu’il puisse récupérer ses billets.',
        ensuite: 'Et ensuite?',
        desque: 'Dès que',
        ensuiteParagraph:
          'aura accepté ses billets, ils disparaitront de votre portefeuille et ne seront plus valides pour vous. Si vous changez d’avis, vous pouvez toujours annuler le transfert, tant qu’ils n’ont pas été acceptés.',
        continuerButton: 'Continuer',
        transferEnCours: 'Transfert en cours',
        ceBilletTransferer: 'Ce billet a été transféré à',
        maisPas: 'mais n’a pas encore été accepté.',
        retourButton: 'Retour',
        laDemande: "La demande d'annulation du transfert a été effectuée avec succès.",
        annulerLeTransfert: 'Annuler le transfert',
        billetDejaTransferer: 'Billet déjà transféré',
        pourPlusDeSecuriter:
          'Pour plus de sécurité, ce billet n’est transférable qu’une seule fois et a malheureusement déjà été transféré. Vous ne pouvez plus plus le transférer.',
        approveTransfer: 'Approuver le transfert',
        celaPeutPrendre: 'Cela peut prendre quelques instants.',
        renseignezVosInformation: 'Renseignez vos informations pour récupérer vos billets.',
        approuverButton: 'Approuver',
        expiditeurAnnuleTransfer: "L'expéditeur a annulé le transfert",
        expediteurAnnulerMessage: "L'expéditeur a annulé le transfert des billets.",
        pourPlusSecurité: 'Pour plus de sécurité, le billet n’est transférable',
        quUneSeule: 'qu’une seule fois et a malheureusement déjà été transféré.',
        vousNePouvez: 'Vous ne pouvez plus le transférer.',
        fermerButton: 'Fermer'
      },
      organizationDetails: {
        events: 'événements',
        eventsC: 'Événements',
        continue: 'Continuer',
        about: 'À propos',
        organization: 'Organisateur'
      },
      checkout: {
        recap: 'Récapitulatif',
        name: 'Nom',
        firstName: 'Prénom',
        lastName: 'Nom',
        phone: 'Téléphone',
        mail: 'Email',
        seat_one: 'Place',
        seat_other: 'Places',
        subscription_one: 'Abonnement',
        subscription_other: 'Abonnements',
        fees: 'Frais',
        total: 'Total',
        pay: 'Payer',
        retry: 'Réessayer',
        changeCard: 'Changer de carte',
        cancel: 'Annuler',
        ouchFailed: 'Oops, échoué !',
        acceptTerms: 'J’accepte la <0>Politique de Confidentialité</0> ainsi que <1>les Termes et Conditions</1>',
        getMyInvit: 'Récupérer mon invitation',
        getMyCollectible: 'Récupérer ma récompense',
        getMyTicket: 'Récupérer mon billet',
        getMySubscription: 'Récupérer mon abonnement'
      },
      paymentSuccess: {
        congratulations: 'Félicitations !',
        congratulationsMessage: 'Vous avez passé avec succès une commande pour <0>{{eventName}}</0>.',
        ticketSentence1: 'Vos billets NFT sont stockés en toute sécurité sur votre portefeuille numérique Tickie',
        ticketSentence2: '👉 Vous ne les recevrez donc pas par mail',
        discoverTicket: 'Découvrir mes billets'
      },
      myTickets: {
        showMyTickets: 'Afficher mes billets',
        myTickets: 'Mes billets',
        continue: 'Continuer',
        toCome: 'À venir',
        pending: 'En attente',
        toSell: 'En vente',
        discoverTicket: 'Découvrir mon billet',
        discoverTicketSentence: 'Découvrez vite votre billet pour voir la récompense qui se cache derrère !',
        needHelp: 'Besoin d’aide ?',
        needHelpSentence: 'Consultez notre FAQ ou contactez nous',
        heplCenter: 'Centre d’aide',
        activity: 'Activité',
        activitySentence: 'Consultez l’historique de votre billet',
        sell: 'Vendre',
        scan: 'Scanner',
        transfer: 'Transférer',
        goBack: 'Retour',
        ticketPending: 'Votre billet est en cours de traitement',
        isAlreadyScanned: 'Ce billet a déjà été scanné.',
        congratulations: 'Félicitations !',
        congratulationsSentence: 'Vous avez obtenu un billet <0>{{rarity}}</0>',
        congratulationsRewardSentence: 'Vos récompenses :',
        lostTickieTaka: 'Pas de chance !',
        lostTickieTakaSentence:
          "Dommage vous n'êtes pas tombé sur un billet gagnant. Retentez votre chance la prochaine fois",
        lostTickieTakaZ5Sentence:
          "Dommage vous n'êtes pas tombé sur billet rare. Retentez votre chance la prochaine fois",
        scanned: 'Scanné',
        noTicketFound: 'Aucun billet trouvé'
      },
      changeLanguageModal: {
        chooseLanguage: 'Choisissez une langue'
      },
      login: {
        welcom: 'Bienvenue',
        continueWithFacebook: 'Continuer avec Facebook',
        continueWithGoogle: 'Continuer avec Google',
        continueWithApple: 'Continuer avec Apple',
        continueWithEmail: 'Continuer avec un email',
        or: 'ou',
        continueWithPhoneNumber: 'Continuer avec un numéro de téléphone',
        connectOrRegisterToReserve: 'Connectez-vous ou inscrivez-vous pour continuer',
        continue: 'Continuer',
        rememberMe: 'Se souvenir de moi',
        conexion: 'Connexion',
        phoneNumber: 'Numéro de téléphone',
        conexionOrRegister: 'Connexion ou inscription',
        orContinueWith: 'ou continuer avec',
        enterMail: 'Entrez votre email',
        enterTheCodeSentTo: 'Entrez le code envoyé à <0>{{phoneNumber}}</0>',
        enterTheCodeSentToMail: 'Entrez le code envoyé à <0>{{email}}</0>',
        checkYourPhoneNumber: 'Vérification de votre numéro',
        checkYourEmail: 'Vérification de votre email',
        check: 'Vérifier',
        search: 'Rechercher',
        youAlreadyHaveAnAccount:
          'Vous avez déjà créé un compte en utilisant <0>{{provider}}</0>. Veuillez vous connecter.'
      },
      completeProfile: {
        completeProfile: 'Complétez votre profil',
        firstName: 'Prénom',
        lastName: 'Nom',
        birthdate: 'Date de naissance',
        skip: 'Passer',
        continue: 'Continuer'
      },
      editProfile: {
        title: 'Éditer mon profil',
        firstName: 'Prénom',
        lastName: 'Nom',
        email: 'Mail',
        phone: 'Téléphone',
        birthdate: 'Date de naissance',
        save: 'Sauvegarder'
      },
      notifications: {
        noMessages: 'Aucun message',
        noMessagesText: "Vous n'avez pas de messages pour le moment, revenez plus tard."
      },
      marketplace: {
        marketplace: 'Marketplace',
        marketPlaceCommingSoon: 'Bientôt disponible',
        marketPlaceCommingSoonText: 'La marketplace arrive bientôt.'
      },
      firebaseAuthErrors: {
        unexpected: 'une erreur inattendue est survenue. Veuillez rafraîchir la page.',
        'auth/invalid-phone-number': "Le numéro de téléphone que vous avez fourni n'est pas valide.",
        'auth/invalid-verification-code': 'Le code de vérification est incorrecte.',
        'auth/popup-closed-by-user': 'Vous avez fermé la pop-up de connexion.',
        'auth/account-exists-with-different-credential':
          'Vous avez déja un compte enregistré avec cette adresse mail. Veuillez utiliser la même méthode de connexion que vous aviez utilisé lors de votre inscription.',
        'auth/too-many-requests': 'Nombre de tentatives excédés, veuillez réessayer dans quelques minutes.'
      },
      settings: {
        settings: 'Paramètres',
        profile: 'Profil',
        notifications: 'Notifications',
        paymentMethods: 'Moyens de paiement',
        linkedAccounts: 'Comptes liés',
        ticketIssue: 'Problèmes de billets',
        security: 'Sécurité',
        language: 'Langue',
        darkMode: 'Mode sombre',
        helpCenter: "Centre d'aide",
        inviteFriends: 'inviter des amis',
        rateUs: 'Notez-nous',
        scan: 'Scanner un ticket',
        blockchain: 'Blockchain',
        getPrivateKey: 'Obtenir ma clé privée',
        logout: 'Déconnexion'
      },
      rewards: {
        rewards: 'Récompenses',
        level: 'Niveau {{level}}',
        xpRemainSentence: '<0>{{xpRemain}}</0> Xp pour atteindre le niveau suivant',
        rankigSentence: '{{ranking}}ème du leaderboard',
        lastRewards: 'Dernières récompenses',
        claim: 'Réclamer'
      },
      scan: {
        scanAticket: 'Scanner un Ticket',
        scan: 'Scanner'
      },
      walletErrors: {
        checkIfConnectedToWallet: 'Vérifiez que vous êtes bien connecté à votre portefeuille',
        notOwnerOfThisTicket: "Vous n'êtes pas le propriérte de ce billet"
      },
      formErrors: {
        mailNotValid: 'Veuillez entrer une adresse mail valide 🖖',
        firstNameEmpty: 'Veuillez entrer votre nom 🙏',
        lastNameEmpty: 'Veuillez entrer votre prénom 🙏'
      },
      footer: {
        privacyPolicy: 'Politique de confidentialité',
        saleConditions: 'Conditions générales de vente',
        contactUs: 'Nous contacter',
        faq: 'FAQ'
      },
      unhandledErrors: {
        reload: 'Rafraîchir la page',
        returnHome: "Retourner à l'accueil"
      }
    }
  },
  en: {
    translation: {
      home: {
        hello: 'Hello👋',
        onDisplay: 'Popular 🚀',
        seeAll: 'See All',
        whatEventAreYouLookinFor: 'Search for an event',
        soon: 'Coming Soon',
        darkMode: 'Dark Mode',
        lightMode: 'Light Mode',
        noEvent: 'No events'
      },
      profile: {
        profile: 'Profile',
        myProfile: 'My Profile',
        balance: 'Wallet Balance',
        address: 'Wallet ID',
        credit: 'Credit',
        creditSentence: 'Safely recharge your wallet to transfer money or make payments more easily and quickly',
        recieve: 'Receive',
        recieveSentence: 'Receive funds from another wallet, in euros or cryptocurrency',
        send: 'Send',
        sendSentence: 'Transfer euros or cryptocurrency to another wallet or to your bank account',
        collection: 'Collection',
        rewards: 'Rewards',
        copyText: 'Copy Address',
        copied: 'Copied ✅'
      },
      navigationBar: {
        home: 'Home',
        marketplace: 'Marketplace',
        tickets: 'Tickets',
        messages: 'Messages',
        profile: 'Profile',
        signIn: 'Sign In',
        signUp: 'Sign Up'
      },
      event: {
        addToCalendar: 'Add to Calendar',
        seeOnMap: 'See Location on Map',
        itenerary: 'Itinerary',
        priceOfTicketDepends: 'Ticket price depends on the package',
        eventIsFinished: 'This event has ended',
        description: 'Description',
        tickieTakaInfo: 'Buy and discover your ticket on Tickie to have a chance to win exclusive rewards 🤩',
        place: 'Location',
        order: 'Buy ticket',
        share: 'Share'
      },
      transfer: {
        processPeutPrendre: 'THIS PROCESS MAY TAKE A FEW MOMENTS',
        billetEnAttente: 'PENDING TICKETS',
        aucunBilletDisponible: 'No tickets are available.',
        vousAvez: 'You received',
        billetsPour: 'tickets for :',
        refuserBillet: 'Refuse',
        accepterBillet: 'Accept',
        transferButton: 'Transfer',
        sellButton: 'Sell',
        dialogDrawerTitle: 'Transfer my tickets',
        userFormMessageFirst: 'Fill in the information of the person to whom',
        userFormMessageSecond: 'you want to transfer your tickets.',
        lastNameTextField: 'Last Name',
        firstNameTextField: 'First Name',
        emailTextField: 'Email',
        lastNameMessage: 'Please enter a last name.',
        firstNameMessage: 'Please enter a first name.',
        emailMessage: 'Please enter a valid email address.',
        annulerUserFormButton: 'Cancel',
        suivantUserFormButton: 'Next',
        ticketSelectionMessage:
          'Select the tickets you want to transfer. ⚠️ You will no longer have access to it once this person accepts the transfer.',
        BilletsSelectionnesMessage: 'selected tickets',
        BilletTicketBody: 'TICKET',
        categorieTicketBody: 'CATEGORY',
        typeTicketBody: 'TYPE',
        assurerMessage: 'Make sure all information is correct before uploading it.',
        chaqueBilletMessage: '🚨 Each ticket is only transferable once',
        detailDuReceveur: 'Receiver detail',
        billetTransfere: 'Transferred tickets',
        firstPolitiqueMessage: 'I accept our',
        secondPolitiqueMessage: 'Privacy Policy',
        thirdPolitiqueMessage: 'as well as our',
        forthPolitiqueMessage: 'Terms and conditions.',
        transfererButton: 'Transfer',
        billetsTransferers: 'TRANSFERRED TICKETS',
        billetspour: 'Tickets for',
        emailEnvoyer: 'An email has just been sent to',
        recuperBillets: 'so he can get his tickets.',
        ensuite: 'After that?',
        desque: 'As soon as',
        ensuiteParagraph:
          "has accepted your tickets, they will disappear from your wallet and will no longer be valid for you. If you change your mind, you can always cancel the transfer, as long as they haven't been accepted.",
        continuerButton: 'Continue',
        transferEnCours: 'Transfer in progress',
        ceBilletTransferer: 'This ticket has been transferred to',
        maisPas: 'but has not yet been accepted.',
        retourButton: 'Back',
        annulerLeTransfert: 'Cancel transfer',
        billetDejaTransferer: 'Ticket already transferred',
        laDemande: 'The request to cancel the transfer was completed successfully.',
        pourPlusDeSecuriter:
          "For added security, this ticket is only transferable once and has unfortunately already been transferred. You can't transfer it anymore.",
        approveTransfer: 'Approve transfer',
        celaPeutPrendre: 'This may take a few moments.',
        renseignezVosInformation: 'Fill in your information to collect your tickets.',
        approuverButton: 'Approve',
        expiditeurAnnuleTransfer: 'The sender canceled the transfer',
        expediteurAnnulerMessage: 'The sender canceled the transfer of the tickets.',
        pourPlusSecurité: 'For added security, the ticket is not transferable',
        quUneSeule: 'only once and has unfortunately already been transferred.',
        vousNePouvez: 'You can no longer transfer it.',
        fermerButton: 'Close'
      },
      reservation: {
        reserveAplace: 'Choose a ticket',
        left_one: 'left',
        left_other: 'left',
        notAvailable: 'Sold Out',
        lookOnSecondaryMarket: 'Search on the secondary market',
        pleaseSelectATicket: 'Please select your seats before proceeding',
        noTicketsAvailable: 'No tickets left',
        IhavePromoCode: 'I have a promo code',
        promoCode: 'Promo Code',
        promoCodeError: 'The entered code is not valid',
        continue: 'Continue'
      },
      organizationDetails: {
        events: 'events',
        eventsC: 'Events',
        continue: 'Continue',
        about: 'About',
        organization: 'Organizer'
      },
      checkout: {
        recap: 'Summary',
        name: 'Name',
        firstName: 'First Name',
        lastName: 'Last Name',
        phone: 'Phone',
        mail: 'Email',
        seat_one: 'Seat',
        seat_other: 'Seats',
        fees: 'Fees',
        total: 'Total',
        pay: 'Pay',
        retry: 'Retry',
        changeCard: 'Change Card',
        cancel: 'Cancel',
        ouchFailed: 'Oops, Failed!',
        acceptTerms: 'I accept the <0>Privacy Policy</0> and <1>Terms and Conditions</1>',
        getMyInvit: 'Get My Invitation',
        getMyCollectible: 'Get My Rewards'
      },
      paymentSuccess: {
        congratulations: 'Congratulations!',
        congratulationsMessage: 'You have successfully placed an order for <0>{{eventName}}</0>.',
        ticketSentence1: 'Your NFT tickets are securely stored in your Tickie digital wallet',
        ticketSentence2: '👉 You will not receive them by email',
        discoverTicket: 'Discover My Tickets'
      },
      myTickets: {
        showMyTickets: 'Show My Tickets',
        myTickets: 'My Tickets',
        pending: 'Pending',
        continue: 'Continue',
        toCome: 'Upcoming',
        toSell: 'For Sale',
        discoverTicket: 'Discover My Ticket',
        discoverTicketSentence: 'Quickly discover your ticket to see the hidden reward!',
        needHelp: 'Need Help?',
        needHelpSentence: 'Check out our FAQ or contact us',
        heplCenter: 'Help Center',
        activity: 'Activity',
        activitySentence: 'View the history of your ticket',
        sell: 'Sell',
        scan: 'Scan',
        transfer: 'Transfer',
        goBack: 'Go Back',
        ticketPending: 'Your ticket is being processed',
        isAlreadyScanned: 'This ticket has already been scanned.',
        congratulations: 'Congratulations!',
        congratulationsSentence: 'You have obtained a <0>{{rarity}}</0> ticket',
        congratulationsRewardSentence: 'Your rewards:',
        lostTickieTaka: 'No Luck!',
        lostTickieTakaSentence: 'Too bad, you did not get a winning ticket. Try again next time.',
        lostTickieTakaZ5Sentence: 'Too bad, you did not get a rare ticket. Try again next time.'
      },
      changeLanguageModal: {
        chooseLanguage: 'Choose a Language'
      },
      login: {
        welcom: 'Welcome',
        continueWithFacebook: 'Continue with Facebook',
        continueWithGoogle: 'Continue with Google',
        continueWithApple: 'Continue with Apple',
        continueWithEmail: 'Continue with Email',
        or: 'or',
        continueWithPhoneNumber: 'Continue with Phone Number',
        connectOrRegisterToReserve: 'Connect or register to continue',
        continue: 'Continue',
        rememberMe: 'Remember Me',
        conexion: 'Log In',
        phoneNumber: 'Phone Number',
        conexionOrRegister: 'Log In or Register',
        orContinueWith: 'or continue with',
        enterMail: 'Enter your email',
        enterTheCodeSentTo: 'Enter the code sent to <0>{{phoneNumber}}</0>',
        enterTheCodeSentToMail: 'Enter the code sent to <0>{{email}}</0>',
        checkYourPhoneNumber: 'Verifying your phone number',
        checkYourEmail: 'Verifying your email',
        check: 'Verify',
        search: 'Search',
        youAlreadyHaveAnAccount:
          'You already have an account using <0>{{provider}}</0>. Please log in through this provider.'
      },
      completeProfile: {
        completeProfile: 'Complete Your Profile',
        firstName: 'First Name',
        lastName: 'Last Name',
        birthdate: 'Birthdate',
        skip: 'Skip',
        continue: 'Continue'
      },
      editProfile: {
        title: 'Edit My Profile',
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        phone: 'Phone',
        birthdate: 'Birthdate',
        save: 'Save'
      },
      notifications: {
        noMessages: 'No Messages',
        noMessagesText: "You don't have any messages at the moment, come back later."
      },
      marketplace: {
        marketplace: 'Marketplace',
        marketPlaceCommingSoon: 'Coming Soon',
        marketPlaceCommingSoonText: 'The marketplace is coming soon.'
      },
      firebaseAuthErrors: {
        unexpected: 'An unexpected error occurred. Please refresh the page.',
        'auth/invalid-phone-number': 'The phone number you provided is not valid.',
        'auth/invalid-verification-code': 'The verification code is incorrect.',
        'auth/popup-closed-by-user': 'You closed the login popup.',
        'auth/account-exists-with-different-credential':
          'You already have an account registered with this email address. Please use the same login method you used during registration.',
        'auth/too-many-requests': 'Too many attempts. Please try again in a few minutes.'
      },
      settings: {
        settings: 'Settings',
        profile: 'Profile',
        notifications: 'Notifications',
        paymentMethods: 'Payment Methods',
        linkedAccounts: 'Linked Accounts',
        ticketIssue: 'Ticket Issues',
        security: 'Security',
        language: 'Language',
        darkMode: 'Dark Mode',
        helpCenter: 'Help Center',
        inviteFriends: 'Invite Friends',
        rateUs: 'Rate Us',
        scan: 'Scan Ticket',
        blockchain: 'Blockchain',
        getPrivateKey: 'Get My Private Key',
        logout: 'Logout'
      },
      scan: {
        scanAticket: 'Scan a Ticket',
        scan: 'Scan'
      },
      walletErrors: {
        checkIfConnectedToWallet: 'Please check if you are connected to your wallet',
        notOwnerOfThisTicket: 'You are not the owner of this ticket'
      },
      formErrors: {
        mailNotValid: 'Please enter a valid email address 🖖',
        firstNameEmpty: 'Please enter your first name 🙏',
        lastNameEmpty: 'Please enter your last name 🙏'
      },
      footer: {
        privacyPolicy: 'Privacy Policy',
        saleConditions: 'Terms of Sale',
        contactUs: 'Contact Us',
        faq: 'FAQ'
      },
      unhandledErrors: {
        reload: 'Reload Page',
        returnHome: 'Return to Home'
      }
    }
  }
};

export const languageList = [
  { language: 'Français', country: 'France', code: 'fr' },
  { language: 'English', country: 'Anglais', code: 'en' }
];

i18n
  .use(detector)
  // .use(i18nextPlugin)
  .use(initReactI18next)
  .init({
    resources,
    debug: true,
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
