import { isAndroid, isIOS, isMacOs } from 'react-device-detect';
import ticketApi, { Ticket } from '../../../../services/api/ticket';
import { CircularProgress } from '@mui/material';

interface AddToWalletButtonProps {
  type: 'apple' | 'google';
  ticket: Ticket;
}

const AddToWalletButton = (props: AddToWalletButtonProps) => {
  const { type } = props;
  const [addToWallet, { isLoading: isAddingToWallet }] = ticketApi.endpoints.addToWallet.useMutation();

  const downloadPass = async () => {
    const passUrl = await addToWallet({ ticketUuid: props.ticket.uuid }).unwrap();
    const link = document.createElement('a');
    link.href = passUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // if (!isIOS && !isMacOs && type === 'apple') return null;
  // if (!isAndroid && type === 'google') return null;

  return (
    <div
      className="text-white w-full h-12 rounded-xl py-1 font-bold bg-black flex items-center justify-center space-x-2 hover:bg-slate-600 cursor-pointer"
      onClick={() => downloadPass()}
    >
      <div className="flex items-center justify-center">
        <img className=" w-[30px] h-[30px] sm:w-[37px] sm:h-[37px]" src={`/images/wallets/${type}.svg`} />{' '}
      </div>
      <div>
        {' '}
        {isAddingToWallet ? (
          <CircularProgress />
        ) : (
          <div className="leading-6 text-xs sm:text-[14px] mr-1 leading-tight">
            <span className="font-normal sm:text-[12px] ">Ajouter à</span>
            <br />
            {type === 'apple' ? 'Cartes Apple' : 'Google Wallet'}
          </div>
        )}
      </div>
    </div>
  );
};

export default AddToWalletButton;
