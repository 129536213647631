import { createTheme, PaletteColor, PaletteColorOptions, PaletteOptions } from '@mui/material';
import greyScale from './colors/greyscale';
import palette from './colors/palette';
import primary from './colors/primary';
import transparent from './colors/transparent';
import secondary from './colors/secondary';
import { TypographyStyleOptions } from '@mui/material/styles/createTypography';

declare module '@mui/material/styles' {
  interface Palette {
    appBar: PaletteColor;
  }
  interface PaletteOptions {
    appBar: PaletteColorOptions;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    square: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    ContainedSecondary: true;
    socialLogin: true;
    gradient: true;
    gradientTicket: true;
    ticketText: true;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    xl: TypographyStyleOptions;
    lg: TypographyStyleOptions;
    md: TypographyStyleOptions;
    sm: TypographyStyleOptions;
    xs: TypographyStyleOptions;
  }
  interface TypographyVariantsOptions {
    xl?: TypographyStyleOptions;
    lg?: TypographyStyleOptions;
    md?: TypographyStyleOptions;
    sm?: TypographyStyleOptions;
    xs?: TypographyStyleOptions;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    xl: true;
    lg: true;
    md: true;
    sm: true;
    xs: true;
    subtitle1: false;
    subtitle2: false;
    body1: false;
    body2: false;
  }
}

declare module '@mui/material/Card' {
  interface CardPropsVariantOverrides {
    contained: true;
  }
}

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    contained: true;
  }
}
// declare module '@mui/material/styles' {
//   interface TypeBackground {
//     purple: string;
//     blue: string;
//     green: string;
//     orange: string;
//     pink: string;
//     yellow: string;
//   }
// }

const ligthPalette: PaletteOptions = {
  primary: {
    main: primary[500],

    100: primary[100],

    200: primary[200],

    300: primary[300],

    400: primary[400],

    500: primary[500]
  },
  secondary: {
    main: secondary[500],

    100: secondary[100],

    200: secondary[200],

    300: secondary[300],

    400: secondary[400],

    500: secondary[500]
  },
  text: {
    primary: greyScale[900]
  },
  /**
   * Alert & Status
   */
  success: {
    main: palette.success
  },
  info: {
    main: palette.info
  },
  warning: {
    main: palette.warning
  },
  error: {
    main: palette.error
  },
  divider: greyScale[200],
  appBar: { main: '#171413' }

  // background: {
  //   purple: '#F6F5FF',

  //   blue: '#EEF4FF',

  //   green: '#F2FFFC',

  //   orange: '#FFF8ED',

  //   pink: '#FFF5F5',

  //   yellow: '#FFFEE0'
  // },
};

const darkPalette: PaletteOptions = {
  mode: 'dark',
  primary: {
    main: primary[500],

    100: primary[100],

    200: primary[200],

    300: primary[300],

    400: primary[400],

    500: primary[500]
  },
  secondary: {
    main: secondary[500],

    100: secondary[100],

    200: secondary[200],

    300: secondary[300],

    400: secondary[400],

    500: secondary[500]
  },
  text: {
    primary: '#FFFFFF'
  },
  /**
   * Alert & Status
   */
  success: {
    main: palette.success
  },
  info: {
    main: palette.info
  },
  warning: {
    main: palette.warning
  },
  error: {
    main: palette.error
  },
  divider: palette.dark3,
  appBar: { main: '#171413' },

  background: {
    default: palette.dark1,
    paper: palette.dark1
    //   purple: '#F6F5FF',

    //   blue: '#EEF4FF',

    //   green: '#F2FFFC',

    //   orange: '#FFF8ED',

    //   pink: '#FFF5F5',

    //   yellow: '#FFFEE0'
  }
};

const theme = (isDark: boolean) =>
  createTheme({
    palette: isDark ? darkPalette : ligthPalette,
    typography: {
      button: {
        textTransform: 'none',
        fontSize: '1rem',
        fontWeight: 600,
        letterSpacing: '0.2px',
        fontFamily: ['Urbanist'].join(','),
        lineHeight: 1.4
      },
      fontFamily: ['Urbanist'].join(','),
      h1: {
        fontSize: '3rem',
        fontWeight: 700,
        '@media (min-width:600px)': {
          fontSize: '5rem'
        }
      },
      h2: {
        fontSize: '3rem',
        fontWeight: 700,
        '@media (min-width:600px)': {
          fontSize: '4rem'
        }
      },
      h3: {
        fontSize: '2rem',
        fontWeight: 700,
        '@media (min-width:600px)': {
          fontSize: '3rem'
        }
      },
      h4: {
        fontSize: '1.5rem',
        fontWeight: 700,
        '@media (min-width:600px)': {
          fontSize: '2rem'
        }
      },
      h5: {
        fontSize: '1.3rem',
        fontWeight: 700,
        '@media (min-width:600px)': {
          fontSize: '1.6rem'
        }
      },
      h6: {
        fontSize: '1.125rem',
        fontWeight: 700,
        '@media (min-width:600px)': {
          fontSize: '1,375rem'
        }
      },
      xl: {
        fontSize: '1.125rem',
        letterSpacing: '0.2px',
        fontFamily: ['Urbanist'].join(','),
        lineHeight: 1.4,
        '@media (min-width:600px)': {
          fontSize: '1,375rem'
        }
      },
      lg: {
        fontSize: '1rem',
        letterSpacing: '0.2px',
        fontFamily: ['Urbanist'].join(','),
        lineHeight: 1.4,
        '@media (min-width:600px)': {
          fontSize: '1.125rem'
        }
      },
      md: {
        fontSize: '0.9rem',
        letterSpacing: 0.2,
        fontFamily: ['Urbanist'].join(','),
        lineHeight: 1.4,
        '@media (min-width:600px)': {
          fontSize: '1rem'
        }
      },
      sm: {
        fontSize: '0.8rem',
        letterSpacing: 0.2,
        fontFamily: ['Urbanist'].join(','),
        lineHeight: 1.4,
        '@media (min-width:600px)': {
          fontSize: '0.9rem'
        }
      },
      xs: {
        fontSize: '0.6rem',
        letterSpacing: 0.2,
        fontFamily: ['Urbanist'].join(','),
        lineHeight: 1.4,
        '@media (min-width:600px)': {
          fontSize: '0.7rem'
        }
      }
    },
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&:-webkit-autofill': {
              transitionDelay: '9999s',
              transitionProperty: 'background-color, color'
            }
          }
        }
      },
      MuiButton: {
        variants: [
          {
            props: { variant: 'ContainedSecondary' },
            style: {
              borderRadius: 200,
              height: 58,
              color: palette.primary,
              backgroundColor: palette.buttonSecondary
            }
          },
          {
            props: { variant: 'gradient' },
            style: {
              backgroundColor: `#928073`,
              color: '#FFF',
              transition: '0.5s',
              borderRadius: 16,
              fontWeight: 700,
              backgroundSize: '200% auto',
              '&:hover': {
                backgroundPosition: 'right center',
                textDecoration: 'none',
                backgroundColor: `#928073`
              }
            }
          },
          {
            props: { variant: 'gradientTicket' },
            style: {
              backgroundImage: `linear-gradient(to right top, #27D4FF 0%, #584CF4  51%, #584CF4  100%)`,
              transition: '0.5s',
              borderRadius: 100,
              color: 'white',
              fontWeight: 700,
              backgroundSize: '200% auto',
              '&:hover': {
                backgroundPosition: 'right top',
                color: '#fff',
                textDecoration: 'none'
              }
            }
          },
          {
            props: { variant: 'ticketText' },
            style: {
              backgroundImage: `linear-gradient(to right, #25254a 0%, #25254a  30%, #25254a  100%)`,
              transition: '0.5s',
              borderRadius: 100,
              border: '1px solid #5449e7',
              color: 'white',
              fontWeight: 700,
              backgroundSize: '200% auto',
              '&:hover': {
                backgroundPosition: 'right center',
                color: '#fff',
                textDecoration: 'none'
              }
            }
          },
          {
            props: { variant: 'socialLogin' },
            style: {
              borderRadius: 16,
              height: 58,
              backgroundColor: isDark ? palette.dark2 : '#FFFFFF',
              color: isDark ? 'white' : greyScale[900],
              border: isDark ? '1px solid #35383F' : `1px solid ${greyScale[200]}`
            }
          }
        ],
        styleOverrides: {
          root: {},
          sizeSmall: {
            height: 32,
            fontSize: 14
          },
          contained: {
            borderRadius: 200,
            height: 58
          },
          text: {}
        }
      },
      MuiCard: {
        variants: [
          {
            props: { variant: 'contained' },
            style: {
              backgroundColor: '#181A20'
            }
          }
        ],
        styleOverrides: {
          root: {
            boxShadow: '0px 4px 60px rgba(4, 6, 15, 0.05)',
            borderRadius: 16,
            backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.00), rgba(255, 255, 255, 0.00))'
          }
        }
      },
      // MuiDivider: {
      //   defaultProps: { color: greyScale[200] },
      //   styleOverrides: {
      //     root: {
      //       borderBottomWidth: 1,
      //     }
      //   }
      // },
      MuiBottomNavigationAction: {
        defaultProps: { color: greyScale[200] },
        styleOverrides: {
          root: {
            borderBottomWidth: 2,
            borderColor: greyScale[200],
            '& .Mui-selected': {
              color: primary[500]
            }
          },
          label: {
            fontSize: 10,
            color: greyScale[500],
            lineHeight: '12px'
          }
        }
      },
      MuiSelect: {
        defaultProps: { notched: false },
        styleOverrides: {
          outlined: {
            backgroundColor: greyScale[50],
            borderRadius: 16
          },
          select: {
            borderColor: 'red',
            border: 'none'
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            backgroundColor: isDark ? palette.dark2 : greyScale[50],
            borderRadius: 16,
            '&:hover': {
              backgroundColor: transparent.purple,
              '& .MuiOutlinedInput-notchedOutline': {
                border: `1px solid ${palette.primary}`,
                borderWidth: 1.5
              }
            },
            '&.Mui-error': {
              border: `1.5px solid ${palette.error}`
            }
          },

          notchedOutline: {
            border: 'none'
          }
        }
      },
      MuiInput: {
        styleOverrides: {
          underline: {
            '&:before': {
              borderBottom: `1px solid ${greyScale[800]}`
            }
          }
        }
      },
      MuiLink: {
        styleOverrides: {
          root: { textDecoration: 'none', color: isDark ? 'white' : greyScale[700] }
        }
      },
      MuiChip: {
        variants: [
          {
            props: { variant: 'square' },
            style: {
              height: 24,
              fontSize: '0.75rem',
              color: palette.primary,
              borderRadius: 6,
              border: `1px solid ${palette.primary}`,
              backgroundColor: 'transparent'
            }
          }
        ],

        styleOverrides: {
          root: {
            boxShadow: '0px 4px 60px rgba(4, 6, 15, 0.05)',
            borderRadius: 40
          }
        }
      }
    }
  });

export default theme;
