/* eslint-disable camelcase */

import baseApi from './base';
import { Collectible, HomeEvent } from './event';
import { Ticket } from './ticket';

export interface User {
  id: string;
  uuid: string;
  fullname: string;
  firstName: string;
  lastName: string;
  avatar: string;
  phone: string;
  gender: Gender;
  email: string;
  balance: number;
  publicKey: string;
  secret: string;
  collectibles: Collectible[];
  tickets: Ticket[];
}

export interface OrganizationDetails {
  id: string;
  fullname: string;
  avatar: string;
  type: string;
  isFavorite: boolean;
  eventCount: number;
  events: HomeEvent[];
}

export interface CompleteProfileForm {
  firstName: string;
  lastName: string;
  gender: Gender | undefined;
}
export interface SetPublicKeyForm {
  publicKey: string;
  idToken: string;
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE'
}

export interface UserResponse {
  firstName: string;
  lastName: string;
}

const userApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getUser: builder.query<User, void>({
      query: () => ({
        url: `/users/user-details`,
        method: 'GET'
      }),
      providesTags: ['user-details']
    }),
    completeProfile: builder.mutation<UserResponse, CompleteProfileForm>({
      query: credentials => ({
        url: '/users/complete-profile',
        method: 'PATCH',
        body: credentials
      })
    }),
    updateProfile: builder.mutation<UserResponse, CompleteProfileForm>({
      query: credentials => ({
        url: '/users/profile',
        method: 'PATCH',
        body: credentials
      })
    }),
    setPublicKey: builder.mutation<UserResponse, SetPublicKeyForm>({
      query: body => ({
        url: '/users/set-public-key',
        method: 'PATCH',
        body
      })
    })
  })
});

export default userApi;
