import SubscriptionList from './components/SubscriptionList';
import TabPanel from '../../components/TabPanel';
import { useState } from 'react';
import CustomAppBar from '../../components/CustomAppBar';
import eventApi, { HomeEvent } from '../../services/api/event';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import Countdown from 'react-countdown';
import { capitalize } from '../../utils/strings';
import { getWebsiteConfig } from '../whiteLabel/themeFactory';
import TicketIcon from '../../assets/images/icons/ticket';
//
function SubscriptionHome() {
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(true);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };
  const data = eventApi.endpoints.getEvents.useQuery('currentOrgaUuid()', {
    selectFromResult: ({ data }) => {
      if (data && loading)
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      else if (data === null) {
        setLoading(false);
      }
      return {
        events: getNearestEvent(data)
      };
    }
  });
  const { events } = data;
  function getNearestEvent(e: HomeEvent[] | undefined) {
    if (e) {
      const now = new Date();

      // Filter out past events and sort the upcoming ones by beginAt
      const sortedEvents = e
        .filter(
          event =>
            new Date(event.beginAt) > now &&
            event.type == 'EVENT' &&
            ((event as any)?.orgaId === 1 || (event as any)?.orgaId === 3)
        )
        .sort((a, b) => new Date(a.beginAt).getTime() - new Date(b.beginAt).getTime());

      // Return the first event from the sorted list or null if there are no upcoming events
      return sortedEvents.length ? sortedEvents[0] : null;
    } else {
      return null;
    }
  }

  const navigate = useNavigate();

  const renderer = ({ hours, days, minutes, seconds, completed }: any) => {
    return (
      <div className="bg-black opacity-70	 z-10 py-9 h-[55px] w-[250px] mb-4 flex justify-evenly mt-5">
        <div className="flex flex-col justify-center items-center w-[4px]">
          <div className="font-bold text-2xl z-10"> {days}</div>
          <div className="text-bold text-gray-400 text-md  z-10">J</div>
        </div>
        <div className="flex flex-col justify-center items-center w-[4px]">
          <div className="font-bold text-2xl z-10"> {hours}</div>
          <div className="text-bold text-gray-400 text-md z-10">H</div>
        </div>
        <div className="flex flex-col justify-center items-center w-[4px] ">
          <div className="font-bold text-2xl z-10"> {minutes}</div>
          <div className="text-bold text-gray-400 z-10 text-md">M</div>
        </div>
        <div className="flex flex-col justify-center items-center w-[4px]">
          <div className="font-bold text-2xl z-10"> {seconds}</div>
          <div className="text-bold text-gray-400 z-10 text-md">S</div>
        </div>
      </div>
    );
  };

  const homeBanner = getWebsiteConfig().banner;
  const partners = getWebsiteConfig().partners;

  return (
    <>
      <CustomAppBar />
      <div className="w-full flex flex-col	min-h-[460px] justify-center items-center bg-gray-300 relative ">
        <img src={homeBanner} alt="bg" className="absolute w-full h-full -z-1  object-cover" />
        <h2 className="mb-1 z-10">À l’affiche</h2>
        {!loading ? (
          <>
            {events ? (
              <>
                <h1 className="mt-2 z-10 text-5xl font-bold text-center">{events?.title}</h1>
                <div className="flex  flex-col sm:flex-row">
                  <div className="flex flex-col justify-center items-center mr-4 md:w-[400px] mb-10">
                    <div className="text-red-500 text-xl  z-10 ">{events?.subTitle}</div>
                    <div className="text-xl font-bold  mt-3 z-10">
                      {events && `${capitalize(format(new Date(events?.beginAt), 'eeee dd MMMM'))}`}
                    </div>
                    <div className="text-xl  font-bold mb-3 z-10">
                      {' '}
                      {events && `${capitalize(format(new Date(events?.beginAt), "HH'h'mm"))}`}
                    </div>
                    <div className="text-gray-200 z-10 text-xl  text-bold">{events?.address?.name}</div>
                  </div>
                  <div className="flex flex-col justify-center items-center mr-4">
                    <Countdown date={new Date(events?.beginAt)} renderer={renderer} />

                    <div
                      onClick={() => navigate(`/event/${events?.uuid}`)}
                      className="w-[250px] h-[50px] flex justify-center rounded-md items-center bg-red-600  cursor-pointer z-10 mb-10  text-white  hover:bg-red-600/90 focus:ring-4 focus:outline-none focus:ring-red-600/50 font-bold  text-md  px-5 py-2.5 text-center   dark:focus:ring-red-600/55  "
                    >
                      Choisir ma place
                      <TicketIcon size="17" className="ml-5 w-[17px]" />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="text-2xl font-bold md:text-5xl z-20 text-center mt-5">
                  Il n’y a aucun événement en ligne, <br />
                  revenez plus tard 👋{' '}
                </div>
              </>
            )}
          </>
        ) : (
          <div className="z-50"></div>
        )}
      </div>

      <div className="w-full h-full ">
        <div className="mx-0">
          <h1 className="text-2xl mt-10 md:text-4xl ">Les abonnements </h1>

          <TabPanel value={tab} index={0}>
            <SubscriptionList isEvent />
          </TabPanel>
          <br />
          <br />
          <br />
        </div>

        <div className="w-full bg-white text-center text-[#000033]">
          <br />
          <br />
          <div className="font-bold text-2xl mt-50">Ils nous font confiance</div>
          <div className="font-bold text-3xl sm:text-4xl mx-4 md:text-5xl mt-2 ">Nos partenaires officiels</div>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-8 gap-4 md:gap-2 lg:gap-4  mx-8 my-20">
            {/* <div className="grid grid-cols-8 md:grid-cols-4 lg:grid-cols-8 gap-4 md:gap-2 lg:gap-4 mx-8 my-20"> */}
            {partners?.map((p, index) => (
              <div key={index} className="flex justify-center items-center h-30">
                <img src={p.img} alt={`Logo ${index}`} className="max-h-16 md:max-h-20 lg:max-h-30 m-8" />
              </div>
            ))}
          </div>
          <br />
          <br />
        </div>
      </div>
    </>
  );
}

export default SubscriptionHome;
