/* eslint-disable camelcase */

import baseApi from './base';
import { PromoCodeResponse } from './promo-code';
import { TicketCategory } from './ticket-category';
import { User } from './user';

export type PurchaseIntent = {
  id: number;
  uuid: string;
  status: string;
  firstName: string;
  lastName: string;
  email: string;
  srcPrice: number;
  finalPrice: number;
  currencyCode: string | null;
  expiresAt: Date | null;
  updatedAt: Date;
  createdAt: Date;
  deletedAt: Date | null;
  userId: number | null;
  promoCodeDiscountId: number | null;
  eventId: number;
  reservationId: number | null;
  organizationId: number;
  user: User;
  promoCodeDiscount: PromoCodeResponse;
  ticketCategories: TicketCategoriesOnPurchaseIntent[];
  event: Event;
  //   reservation: Reservation | null;
};

type TicketCategoriesOnPurchaseIntent = {
  purchaseIntentId: number;
  ticketCategoryId: number;
  quantity: number;
  ticketCategory: TicketCategory;
  purchaseIntent: PurchaseIntent;
};

export type PlacesReservation = {
  quantity: number;
  categoryUuid: string;
};

export enum PurchaseIntentStatus {
  TO_SEND = 'PENDING',
  SENT = 'COMPLETE'
}

const purchaseIntentApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getPurchaseIntent: builder.query<PurchaseIntent, string>({
      query: invitationUuid => ({
        url: `/purchase-intents/${invitationUuid}`,
        method: 'GET'
      })
    })
  })
});

export default purchaseIntentApi;
