import { Alert, Box, Button, IconButton, Snackbar, Typography, useMediaQuery } from '@mui/material';
import { Stack } from '@mui/system';
import { t } from 'i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import eventApi from '../../services/api/event';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import shadows from '../../themes/default/shadows';
import DesktopDrawer from '../../components/DesktopDrawer';
import Footer from '../../components/Footer';
import promoCodeApi from '../../services/api/promo-code';
import PromoCodeModal from './components/PromoCodeModal';
import shoppingCartSlice from '../../services/shoppingCart';
import { isAfter, isBefore } from 'date-fns';
import ProceedPaymentCard from '../Checkout/components/ProceedPaymentCard';
import SelectTicketsCard from './components/SelectTicketsCard';
import { ArrowRight } from 'lucide-react';
import AppleIcon from '@/assets/images/icons/apple';
import InsuranceCard from './components/InsuranceCard';
import palette from '@/themes/default/colors/palette';

function Reservation() {
  const { eventId } = useParams();
  let [urlParams, setUrlParams] = useSearchParams();
  const promoCodeFromUrl = urlParams.get('promoCode');

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [openErrorSnackbar, setErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPromoModal, setShowPromoModal] = useState(false);
  const { tickets, finalPrice, srcPrice, promo } = useAppSelector(state => state.shoppingCartSlice);
  const [canUsePromoCode] = promoCodeApi.endpoints.canUsePromoCode.useLazyQuery();

  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  const continueButtonStyle = isDesktop ? { mb: 10 } : { position: 'fixed', bottom: 0, left: 0, right: 0, mb: 3 };

  const { data: event, isLoading } = eventApi.endpoints.getEventDetails.useQuery(eventId ?? '');

  const getPromoCode = async () => {
    if (promoCodeFromUrl && eventId) {
      try {
        const data = await canUsePromoCode({ eventUuid: eventId!, code: promoCodeFromUrl! }).unwrap();
        dispatch(shoppingCartSlice.actions.initializePromoCode({ promo: data }));
      } catch (error) {}
    }
  };

  useEffect(() => {
    getPromoCode();
  }, [promoCodeFromUrl]);

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorSnackbar(false);
  };

  const checkTickets = async () => {
    if (tickets.length <= 0) {
      setErrorMessage(t('reservation.pleaseSelectATicket') ?? '');
      setErrorSnackbar(true);
      return false;
    }
    return true;
  };

  const goToCheckout = async () => {
    if (await checkTickets()) {
      navigate(`/checkout/${eventId}${promoCodeFromUrl ? `?promoCode=${promoCodeFromUrl}` : ''}`);
    }
  };

  const isEventAvailable = () => {
    if (!event) {
      return false;
    } else if (event?.saleBeginAt && isBefore(new Date(), new Date(event?.saleBeginAt))) {
      return false;
    } else if (event.saleEndAt && isBefore(new Date(event.saleEndAt), new Date())) {
      return false;
    } else if (isAfter(new Date(), new Date(event!.endAt))) {
      return false;
    } else if (!event.isAvailable) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      {event && (
        <div
          className="-z-20 top-0 left-0 absolute w-full h-full max-h-[600px] bg-contain md:bg-cover backdrop-blur-5xl bg-no-repeat"
          style={{
            backgroundImage: `url(${event.cover})`,
            backgroundRepeat: 'no-repeat'
          }}
        >
          <div className="-z-20 top-0 left-0 absolute w-full h-full backdrop-blur-3xl bg-gradient-to-t  from-white/[1] dark:from-dark1/[1] from-0% via-white/50 dark:via-black/30 to-blue-900/50 bg-no-repeat ">
            <div className="-z-10 bottom-0 left-0 absolute w-full h-96  bg-cover bg-gradient-to-t from-white/[1] dark:from-dark1/[1] dark:to-black/1 bg-no-repeat " />
          </div>
        </div>
      )}
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: '100%', minHeight: 60, justifyContent: 'center', alignItems: 'center' }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <PromoCodeModal showPromoModal={showPromoModal} setShowPromoModal={setShowPromoModal} />
      <Box minHeight="100vh">
        <Stack direction="row" justifyContent="space-between" alignItems="center" mt={2} ml={1} mb={1} spacing={1}>
          <Stack direction="row" alignItems="center">
            <IconButton onClick={() => navigate(`/event/${eventId}`)}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h4">{t('reservation.reserveAplace')}</Typography>
          </Stack>
          <Box pr={2}>
            <DesktopDrawer />
          </Box>
        </Stack>
        <Stack direction="column" justifyContent="center" alignItems="center" className="md:container mt-16">
          {!event || isLoading ? null : (
            <div className="flex p-6 w-full justify-center items-center ">
              <div className="grid grid-cols-1 gap-8 justify-center md:grid-cols-3">
                <div className="md:col-span-2 h-full">
                  <SelectTicketsCard event={event} />
                </div>
                <div className="flex justify-center w-full ">
                  <ProceedPaymentCard
                    event={event}
                    goToCheckout={goToCheckout}
                    isEventAvailable={isEventAvailable()}
                    showButtons
                  />
                </div>
                <div className="md:col-span-2">{event.settings?.useInsurances && <InsuranceCard />}</div>
              </div>
              <Box sx={{ height: { xs: 120, md: 32 } }} />
              <Box
                zIndex={1000}
                className="fixed bottom-0 left-0 right-0 mb-6 md:bg-none dark:bg-dark4 bg-slate-50 md:hidden"
              >
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  spacing={1}
                  pb={1}
                  my={-1}
                  px={2}
                  pt={1}
                  mb={-3}
                  className="md:bg-none dark:bg-dark4 bg-slate-50"
                >
                  {import.meta.env.VITE_NODE_ENV !== 'production' && (
                    <Button
                      variant="contained"
                      fullWidth
                      disabled={!isEventAvailable}
                      onClick={goToCheckout}
                      sx={{
                        backgroundColor: 'black',
                        height: 58,
                        boxShadow: shadows.primaryButton,
                        width: { xs: '100%', md: '100%' },
                        border: '1px solid black'
                      }}
                      className="border-1 border-dark3 rounded-2xl "
                    >
                      <div className="flex justify-center items-center">
                        {isEventAvailable() ? '' : t('reservation.noTicketsAvailable')}&nbsp;{' '}
                        <AppleIcon className="text-white h-6 w-6" />{' '}
                        <span className="ml-1 mt-[2px] font-extrabold">Achat express</span>
                      </div>
                    </Button>
                  )}
                  <Button
                    variant="gradient"
                    fullWidth
                    disabled={!isEventAvailable}
                    onClick={goToCheckout}
                    sx={{
                      height: 58,
                      boxShadow: shadows.primaryButton,
                      width: { xs: '100%', md: '100%' }
                    }}
                  >
                    {isEventAvailable() ? 'Paiement' : t('reservation.noTicketsAvailable')}&nbsp;
                    <ArrowRight className="h-5" />
                  </Button>
                </Stack>
              </Box>
            </div>
          )}
        </Stack>
      </Box>
      <Footer />
    </>
  );
}

export default Reservation;
