// TabBar.tsx

import React from 'react';

interface TabBarProps {
  activeTab: 'mesBillets' | 'enTransfers';
  onTabChange: (tab: 'mesBillets' | 'enTransfers') => void;
}

const TabBar: React.FC<TabBarProps> = ({ activeTab, onTabChange }) => {
  return (
    <div className="flex justify-center mr-0 ml-0 flex-grow mt-2">
      <h3
        className={`tab-item mr-4 cursor-pointer transition duration-300 ${
          activeTab === 'mesBillets' ? 'text-white border-b-2 border-white' : 'text-gray-500 border-b-2 border-gray-300'
        }`}
        onClick={() => onTabChange('mesBillets')}
      >
        Mes billets
      </h3>
      <h3
        className={`tab-item cursor-pointer transition duration-300 ${
          activeTab === 'enTransfers'
            ? 'text-white border-b-2 border-white'
            : 'text-gray-500 border-b-2 border-gray-300'
        }`}
        onClick={() => onTabChange('enTransfers')}
      >
        En transfers
      </h3>
    </div>
  );
};

export default TabBar;
