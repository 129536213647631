import { Alert, Box, Button, Divider, IconButton, Snackbar, Typography, useMediaQuery } from '@mui/material';
import { Stack } from '@mui/system';
import { t } from 'i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import staduim from '../../assets/images/stade-blagnac.jpeg';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import shadows from '../../themes/default/shadows';
import DesktopDrawer from '../../components/DesktopDrawer';
import Footer from '../../components/Footer';
import promoCodeApi from '../../services/api/promo-code';
import shoppingCartSlice from '../../services/shoppingCart';
import IhavePromoCodeButton from '../Checkout/components/IhavePromoCodeButton';
import broderRadius from '../../themes/default/borders/border-rounded';
import { isAfter } from 'date-fns';
import PromoCodeModal from '../Reservation/components/PromoCodeModal';
import TicketCategoryComponent from '../Reservation/components/TicketCategoryComponent';
import subscriptionApi from '../../services/api/subscription';

function Reservation() {
  const { subscriptionUuid } = useParams();
  let [urlParams, setUrlParams] = useSearchParams();
  const promoCodeFromUrl = urlParams.get('promoCode');

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [openErrorSnackbar, setErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPromoModal, setShowPromoModal] = useState(false);
  const { tickets, finalPrice, srcPrice, promo } = useAppSelector(state => state.shoppingCartSlice);
  const [canUsePromoCode] = promoCodeApi.endpoints.canUsePromoCode.useLazyQuery();

  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  const continueButtonStyle = isDesktop ? { mb: 10 } : { position: 'fixed', bottom: 0, left: 0, right: 0, mb: 3 };

  const {
    data: subscription,
    isLoading,
    isError
  } = subscriptionApi.endpoints.getSubscriptionDetails.useQuery(subscriptionUuid ?? '');

  const getPromoCode = async () => {
    if (promoCodeFromUrl && subscriptionUuid) {
      try {
        const data = await canUsePromoCode({ eventUuid: subscriptionUuid!, code: promoCodeFromUrl! }).unwrap();
        dispatch(shoppingCartSlice.actions.initializePromoCode({ promo: data }));
      } catch (error) {}
    }
  };

  useEffect(() => {
    getPromoCode();
  }, [promoCodeFromUrl]);

  const handleCloseSnackbar = (e?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorSnackbar(false);
  };

  const checkTickets = async () => {
    if (tickets.length <= 0) {
      setErrorMessage(t('reservation.pleaseSelectATicket') ?? '');
      setErrorSnackbar(true);
      return false;
    }
    return true;
  };

  const goToCheckout = async () => {
    if (await checkTickets()) {
      navigate(`/subscription-checkout/${subscriptionUuid}${promoCodeFromUrl ? `?promoCode=${promoCodeFromUrl}` : ''}`);
    }
  };

  const isEventAvailable = () => {
    if (!subscription) {
      return false;
    } else if (isAfter(new Date(), new Date(subscription.endDate))) {
      return false;
    } else if (!subscription.isAvailible) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      {subscription && (
        <div
          className="-z-20 top-0 left-0 absolute w-full h-full max-h-[600px] bg-contain md:bg-cover backdrop-blur-5xl bg-no-repeat"
          style={{
            backgroundImage: `url(${subscription.cover})`,
            backgroundRepeat: 'no-repeat'
          }}
        >
          <div className="-z-20 top-0 left-0 absolute w-full h-full backdrop-blur-3xl bg-gradient-to-t  from-dark1/[1] from-0% via-black/30 to-blue-900 bg-no-repeat ">
            <div
              className={`-z-20 bottom-0 left-0 absolute w-full h-96  bg-cover bg-gradient-to-t  from-dark1/[1]  to-black/1 bg-no-repeat `}
            />
          </div>
        </div>
      )}
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: '100%', minHeight: 60, justifyContent: 'center', alignItems: 'center' }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <PromoCodeModal showPromoModal={showPromoModal} setShowPromoModal={setShowPromoModal} />
      <Box minHeight="100vh">
        <header>
          <Stack direction="row" justifyContent="space-between" alignItems="center" mt={2} ml={1} mb={1} spacing={1}>
            <Stack direction="row" alignItems="center">
              <IconButton onClick={() => navigate(`/subscription/${subscriptionUuid}`)}>
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h4">{t('reservation.reserveAplace')}</Typography>
            </Stack>
            <Box pr={2}>
              <DesktopDrawer />
            </Box>
          </Stack>
        </header>
        <Stack direction="column" justifyContent="center" alignItems="center">
          <Stack direction="column" justifyContent="center" alignItems="center" sx={{ width: { xs: '100%', md: 900 } }}>
            <Box justifyContent="center" alignItems="center" sx={{ width: { xs: '100%', md: 900 } }}>
              <Stack direction="row" justifyContent="center" alignItems="center" mt={10}></Stack>
              {!subscription || isLoading ? null : (
                <Box m={3}>
                  <Box height={24} />
                  {subscription?.tarifs && subscription?.tarifs?.length > 0 ? (
                    subscription?.tarifs?.map((category, i) => (
                      <Box
                        key={category.name}
                        sx={{
                          '&:hover:not(.Mui-disabled)': {
                            cursor: 'pointer'
                          }
                        }}
                      >
                        <TicketCategoryComponent
                          category={category}
                          eventId={subscriptionUuid ?? ''}
                          isAvailable={subscription.isAvailable}
                          type="subscription"
                        />
                        {subscription?.tarifs?.length === i + 1 ? null : (
                          <Box mt={1} mb={1}>
                            <Divider />
                          </Box>
                        )}
                      </Box>
                    ))
                  ) : (
                    <Typography variant="h4" textAlign="center">
                      {/* TODO put a better message */}
                      {t('reservation.noTicketsAvailable')}
                    </Typography>
                  )}
                  <Box height={32} />
                  {/* TODO add promo code for subscription */}
                  {/* <IhavePromoCodeButton setShowPromoModal={setShowPromoModal} /> */}
                  <Box sx={{ height: { xs: 120, md: 32 } }} />
                  <Box m={3} sx={continueButtonStyle} zIndex={1000}>
                    <Stack direction="column" justifyContent="center" alignItems="center" width="100%" spacing={1}>
                      <Button
                        variant="gradient"
                        fullWidth
                        disabled={!isEventAvailable()}
                        onClick={goToCheckout}
                        sx={{
                          height: 58,
                          boxShadow: shadows.primaryButton,
                          width: { xs: '100%', md: 200 }
                        }}
                      >
                        {isEventAvailable() ? t('reservation.continue') : t('reservation.noTicketsAvailable')}{' '}
                        &nbsp;-&nbsp;
                        {`${Intl.NumberFormat('fr-FR', {
                          style: 'currency',
                          currency: 'EUR'
                        }).format(finalPrice)}`}
                      </Button>
                    </Stack>
                  </Box>
                </Box>
              )}
            </Box>
          </Stack>
        </Stack>
      </Box>
      <Footer />
    </>
  );
}

export default Reservation;
