import { Button, Typography } from '@mui/material';
import alreadyTransfredImage from '../../../assets/images/icons/alreadytransfered.png';
// import { useNavigate } from "react-router-dom";
// const navigate = useNavigate();
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { t } from 'i18next';
interface CantGetTicketProps {
  isDrawer: boolean;
  setOpen: () => void;
  setCancelSuccess: () => void;
}
function CantGetTicket({ isDrawer, setOpen, setCancelSuccess }: CantGetTicketProps) {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col justify-center items-center w-[350px] p-10">
      <div className="flex flex-col p-3  items-center justify-center">
        <img src={alreadyTransfredImage} width="100px" height="100px" />

        <h2 className="text-[#F75555] sm:text-xs md:text-base lg:text-base xl:text-base text-center w-full mt-2 mb-2">
          {t('transfer.billetDejaTransferer')}
        </h2>
        <p className="text-center sm:text-xs md:text-base lg:text-base xl:text-base w-[300px]">
          {t('transfer.pourPlusDeSecuriter')}
        </p>
        <div className="flex w-full flex-row items-center justify-center">
          {!isDrawer && (
            <Button
              className="mt-3 w-full"
              onClick={() => {
                setOpen();
                setCancelSuccess();
              }}
              variant="contained"
              sx={{
                borderRadius: 3,
                height: 48,
                px: 4,
                background: 'linear-gradient(90deg, #27D4FF 0%, #584CF4 100%)',
                '&:hover': {
                  background: 'linear-gradient(90deg, #27D4FF 0%, #584CF4 100%)'
                },
                width: { xs: '100%', md: 'auto' }
              }}
            >
              <Typography variant="lg" fontWeight={700} className="leading-10">
                {t('transfer.retourButton')}
              </Typography>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
export default CantGetTicket;
