import { Button } from '@mui/material';

import CancelImage from '../CancelImage';
interface TicketInTransferProps {
  setOpen: () => void;
}

function AlreadyTransfered({ setOpen }: TicketInTransferProps) {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="h-20 w-20 bg-red-500 rounded-full flex items-center justify-center mt-5">
        <CancelImage width="50%" height="50%" />
      </div>
      <h3 className="text-red-500 text-center p-2">Billet déjà transféré</h3>
      <p className="text-center">
        Pour plus de sécurité, ce billet n’est transférable
        <br />
        qu’une seule fois et a malheureusement déjà été
        <br />
        transféré. Vous ne pouvez plus plus le transférer.
      </p>
      <div className="flex flex-row items-center justify-center mt-5">
        <Button className="bg-gray-800  text-white px-8 py-2 mr-2 rounded-full" onClick={setOpen}>
          Retour
        </Button>
      </div>
    </div>
  );
}
export default AlreadyTransfered;
