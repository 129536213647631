import { Typography, Card, Stack, Divider, Box, useMediaQuery } from '@mui/material';
import { t } from 'i18next';
import { useAppSelector } from '../../../../../redux/hooks';
import greyScale from '../../../../../themes/default/colors/greyscale';
import { Subscription } from '../../../../../services/api/subscription';

interface SubscriptionBasketCardProps {
  subscription: Subscription;
}

const SubscriptionBasketCard = (props: SubscriptionBasketCardProps) => {
  const { tickets } = useAppSelector(app => app.shoppingCartSlice);
  const { subscription } = props;
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  return (
    <Card variant="contained" sx={{ p: 3, mb: 3 }}>
      {isDesktop ? (
        <>
          <Stack direction="row" spacing={1}>
            <Box pb={0} height={100}>
              <img
                height={100}
                src={subscription?.cover!}
                alt="event cover"
                style={{ borderRadius: 20, objectFit: 'contain' }}
              />
            </Box>
            <Box>
              <Stack direction="column" spacing={1}>
                <Typography variant="h4">{subscription.name}</Typography>
              </Stack>
            </Box>
          </Stack>
          <Box mt={2} mb={2}>
            <Divider />
          </Box>
        </>
      ) : null}
      {tickets?.map(tarif => (
        <div key={tarif.categoryUuid}>
          <Stack direction="row" justifyContent="space-between" mb={1}>
            <Typography variant="md" color={greyScale[500]}>{`${tarif.quantity} ${t('checkout.subscription', {
              count: tarif.quantity
            })}  ${tarif.categoryName == null ? ` ` : `${tarif.categoryName}`}
            `}</Typography>
            <Typography variant="lg" color={greyScale[600]} fontWeight={600}>{`${Intl.NumberFormat('fr-FR', {
              style: 'currency',
              currency: 'EUR'
            }).format(tarif.finalPrice * tarif.quantity)}`}</Typography>
          </Stack>
        </div>
      ))}{' '}
      <Divider />
      <Stack direction="row" justifyContent="space-between" mt={2}>
        <Typography variant="md" color={greyScale[500]}>
          {t('checkout.total')}
        </Typography>
        <Typography variant="lg" color={greyScale[600]} fontWeight={600}>{`${Intl.NumberFormat('fr-FR', {
          style: 'currency',
          currency: 'EUR'
        }).format(tickets.reduce((a, b) => a + b.finalPrice * b.quantity, 0))}`}</Typography>
      </Stack>
    </Card>
  );
};

export default SubscriptionBasketCard;
