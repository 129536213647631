import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from '@mui/material';

interface HorizontaleCardProps {
  title: string;
  text1: string;
  buttonText: string;
  text2: string;
  image: string;
  link: string;
  index: number;
}

const HorizontaleCard: React.FC<HorizontaleCardProps> = ({ title, link, text1, text2, image, index, buttonText }) => {
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.up('sm'));
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  return (
    <Box
      width={'100%'}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          marginRight: isSmall ? '20px' : '0px',
          borderRadius: '35px',
          border: '1px solid #2A2A2A',
          paddingY: '20px',
          flexDirection: 'row',
          height: '500px'
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden'
            // marginRight: '20px'
          }}
        >
          <Box
            width="95%"
            height={'100%'}
            sx={{
              borderRadius: '35px 35px 35px 35px ',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundImage: `url(${image})`,
              backgroundSize: 'cover'
            }}
          ></Box>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'start',
            padding: isSmall ? '20px' : '5px',
            flexDirection: 'column'
          }}
        >
          <Typography
            className="custom-font-text"
            variant="xl"
            sx={{
              fontSize: '31px ',
              color: '#777'
            }}
          >
            0{index}
          </Typography>
          <Typography
            className="custom-font-text"
            variant="xl"
            sx={{
              fontSize: isDesktop ? '30px' : '20px',
              color: 'white',
              textTransform: 'uppercase',
              fontFamily: 'helvetica ',
              whiteSpace: 'pre-line'
            }}
          >
            {title}{' '}
          </Typography>
          <Typography
            variant="xl"
            sx={{
              fontFamily: 'helvetica ',

              fontSize: isSmall ? '21px ' : '18px',
              color: '#777'
            }}
          >
            {text1} <br />
            <br /> {text2}{' '}
          </Typography>
          <br />
          {/* <Box
            className="custom-font-text"
            sx={{
              background: '#212121',
              border: '2px solid #454545',
              borderRadius: '30px',
              fontWeight: '800',
              color: '#FFF',
              width: '298px',
              height: '55px',
              minWidth: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            {buttonText}
          </Box> */}
        </Box>
      </Box>
    </Box>
  );
};

export default HorizontaleCard;
