import VerifyCircleImage from '../VerifyCircleImage';
import InfoImage from '../InfoImage';
import { Button, Typography } from '@mui/material';
import { DialogClose } from '@/components/ui/dialog';
import { t } from 'i18next';
// nbBillet={selectedTickets.length} eventName={eventName} firstName={firstName} lastName={lastName} email={email}
interface VerifiedTransferProps {
  resetTransferEnCours: () => void;
  nbBillet: number;
  eventName: string;
  firstName: string;
  lastName: string;
  email: string;
}
function VerifiedTransferModalBody({
  nbBillet,
  eventName,
  firstName,
  lastName,
  email,
  resetTransferEnCours
}: VerifiedTransferProps) {
  return (
    <div className="flex flex-col items-center">
      <VerifyCircleImage width="70px" height="70px" />
      <h2 className="text-center w-full mt-2 mb-2">{t('transfer.billetsTransferers')}</h2>
      <p className="text-gray-600 text-center mb-1 mt-2">
        {nbBillet} {t('transfer.billetspour')}
      </p>
      <h3 className="text-center mt-0  mb-2">{eventName}</h3>
      <p className="text-gray-600 text-center mt-2 mb-2">
        {t('transfer.emailEnvoyer')} <span className="dark:text-white text-black">{email}</span>
        <br /> {t('transfer.recuperBillets')}
      </p>
      <div className="rounded w-5/6 dark:bg-custom bg-gray-200 mt-2 flex flex-col m-2">
        <div className="flex flex-row items-center p-3">
          <InfoImage width="25px" height="25px" />
          <h3 className="text-gray-400 mt-0 ml-2 mb-0  sm:text-base md:text-base lg:text-base xl:text-base">
            {t('transfer.ensuite')}
          </h3>
        </div>
        <p className="text-gray-400 rounded-3xl ml-3 mr-2 mt-0 text-justify sm:text-xs md:text-base lg:text-base xl:text-base">
          {t('transfer.desque')} {firstName} {t('transfer.ensuiteParagraph')}
        </p>
        <div className="flex-grow"></div> {/* Add this line */}
      </div>
      <div className="flex flex-row w-full mt-3 items-center justify-center">
        <DialogClose asChild>
          <Button
            onClick={resetTransferEnCours}
            variant="contained"
            sx={{
              borderRadius: 3,
              height: 48,
              px: 4,
              background: 'linear-gradient(90deg, #27D4FF 0%, #584CF4 100%)',
              '&:hover': {
                background: 'linear-gradient(90deg, #27D4FF 0%, #584CF4 100%)'
              }
            }}
            className="w-5/6"
          >
            <Typography variant="lg" fontWeight={700} className="leading-10">
              {t('transfer.continuerButton')}
            </Typography>
          </Button>
        </DialogClose>
      </div>
    </div>
  );
}

export default VerifiedTransferModalBody;
