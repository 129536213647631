import { ReactElement, useState } from 'react';
import {
  Avatar,
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Paper,
  Slide,
  useScrollTrigger
} from '@mui/material';
import { t } from 'i18next';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Link, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import userApi from '../../services/api/user';
import ticketApi from '../../services/api/ticket';

interface HideOnScrollProps {
  children: ReactElement;
}

const HideOnScroll = (props: HideOnScrollProps) => {
  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction="up" in={!trigger}>
      {props.children}
    </Slide>
  );
};

const TickieBottomNavigation = () => {
  const { isAuth } = useAppSelector(state => state.authSlice);
  const { data: user } = userApi.endpoints.getUser.useQuery();
  const { data: tickets } = ticketApi.endpoints.getTicketsEvent.useQuery({ toBeUsed: true });
  const location = useLocation();
  const initialtab = () => {
    if (location.pathname.includes('/home')) {
      return 0;
    }
    if (location.pathname.includes('/marketplace')) {
      return 1;
    }
    if (location.pathname.includes('/my-tickets')) {
      return 2;
    }
    if (location.pathname.includes('/messages')) {
      return 3;
    }
    if (location.pathname.includes('/profile')) {
      return 4;
    }
  };
  const [value, setValue] = useState(initialtab());

  return (
    <HideOnScroll>
      <Paper
        sx={{ zIndex: 1000, position: 'fixed', bottom: 0, left: 0, right: 0, display: { md: 'none' } }}
        elevation={3}
      >
        <Box
          sx={{
            backgroundImage: `url('https://opensea.io/static/images/home-gradient-dark.png')`,
            backgroundSize: 'cover',
            position: 'absolute',
            width: '100%',
            height: '100%',
            opacity: 0.15
          }}
        ></Box>
        {isAuth ? (
          <>
            <BottomNavigation
              showLabels
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
            >
              <BottomNavigationAction
                component={Link}
                to="/home"
                label={t('navigationBar.home')}
                icon={<HomeOutlinedIcon />}
              />

              <BottomNavigationAction
                component={Link}
                to="/my-tickets"
                label={t('navigationBar.tickets')}
                icon={
                  <Badge badgeContent={tickets?.reduce((a, b) => a + b.tickets.length, 0) ?? 0} color="primary">
                    <ConfirmationNumberOutlinedIcon />
                  </Badge>
                }
              />

              <BottomNavigationAction
                component={Link}
                to="/profile"
                label={t('navigationBar.profile')}
                icon={
                  isAuth && user ? (
                    <Avatar src={user?.avatar} sx={{ width: 24, height: 24 }} />
                  ) : (
                    <PersonOutlineOutlinedIcon />
                  )
                }
              />
            </BottomNavigation>
          </>
        ) : (
          <>
            <BottomNavigation
              showLabels
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
            >
              <BottomNavigationAction
                component={Link}
                to="/home"
                label={t('navigationBar.home')}
                icon={<HomeOutlinedIcon />}
              />

              <BottomNavigationAction
                component={Link}
                to="/login"
                label={t('navigationBar.tickets')}
                icon={<ConfirmationNumberOutlinedIcon />}
              />
              <BottomNavigationAction
                component={Link}
                to="/login"
                label={t('navigationBar.signIn')}
                icon={
                  isAuth && user ? (
                    <Avatar src={user?.avatar} sx={{ width: 24, height: 24 }} />
                  ) : (
                    <PersonOutlineOutlinedIcon />
                  )
                }
              />
            </BottomNavigation>
          </>
        )}
      </Paper>
    </HideOnScroll>
  );
};

export default TickieBottomNavigation;
