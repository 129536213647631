import { Modal, Typography, Box, ButtonBase } from '@mui/material';
import { Stack } from '@mui/system';
import i18next, { t } from 'i18next';
import { languageList } from '../../i18n';
import primary from '../../themes/default/colors/primary';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 4,
  p: 3,
  width: { xs: '66%', sm: '50%', lg: '40%', xl: '30%' }
};

interface PaymentErrorModalProps {
  changeLanguageModalOpen: boolean;
  handleCloseChangeLanguageModal: () => void;
}

const ChangeLanguageModal = (props: PaymentErrorModalProps) => {
  const changeLanguage = (code: string) => {
    i18next.changeLanguage(code, (err, t) => {
      if (err) return console.log('something went wrong loading', err);
      window.location.reload();
    });
  };
  return (
    <Modal open={props.changeLanguageModalOpen} onClose={props.handleCloseChangeLanguageModal}>
      <Box sx={style}>
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={2} mb={3}>
          <Typography variant="h5" textAlign="center">
            {t('changeLanguageModal.chooseLanguage')}
          </Typography>

          {languageList.map(l => (
            <ButtonBase
              key={l.code}
              sx={{
                border: i18next.resolvedLanguage === l.code ? `1px solid ${primary[500]}` : 'none',
                width: 200,
                borderRadius: 2,
                p: 1.5
              }}
              onClick={() => changeLanguage(l.code)}
            >
              <Stack direction="column">
                <Typography variant="lg" fontWeight={700}>
                  {l.language}
                </Typography>
                <Typography variant="lg">{l.country}</Typography>
              </Stack>
            </ButtonBase>
          ))}
        </Stack>
      </Box>
    </Modal>
  );
};

export default ChangeLanguageModal;
