import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';

const rowVariants = cva('flex', {
  variants: {
    variant: {
      default: ''
    }
  },
  defaultVariants: {
    variant: 'default'
  }
});

const Row = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof rowVariants>>(
  ({ className, variant, ...props }, ref) => (
    <div ref={ref} className={cn(rowVariants({ variant }), className)} {...props} />
  )
);
Row.displayName = 'Row';

export { Row, rowVariants };
