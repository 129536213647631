import { Card, Stack, TextField, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { t, use } from 'i18next';
import { IFormData } from '../..';
import { User } from '../../../../services/api/user';
import greyScale from '../../../../themes/default/colors/greyscale';
import { useSearchParams } from 'react-router-dom';
import invitationApi from '../../../../services/api/invitation';
import { useEffect } from 'react';
import purchaseIntentApi from '../../../../services/api/purchase-intent';
import { Mail, Person } from '@mui/icons-material';

export interface UserInfosProps {
  user: User | undefined;
  userLoading: boolean;
}

const UserInfos = (props: UserInfosProps) => {
  const { values, handleChange, touched, errors, setFieldValue } = useFormikContext<IFormData>();
  // get url params bellow
  let [urlParams, setUrlParams] = useSearchParams();
  const invite = urlParams.get('invite');
  const purchaseIntentFromUrl = urlParams.get('purchaseIntent');
  const { data: invitation } = invitationApi.endpoints.getInvitation.useQuery(invite!, { skip: !invite });
  const { data: purchaseIntent } = purchaseIntentApi.endpoints.getPurchaseIntent.useQuery(purchaseIntentFromUrl!, {
    skip: !purchaseIntentFromUrl
  });
  useEffect(() => {
    if (invitation) {
      setFieldValue('firstName', invitation.firstName);
      setFieldValue('lastName', invitation.lastName);
      setFieldValue('email', invitation.email);
    }
  }, [invitation]);

  useEffect(() => {
    if (purchaseIntent) {
      setFieldValue('firstName', purchaseIntent.firstName);
      setFieldValue('lastName', purchaseIntent.lastName);
      setFieldValue('email', purchaseIntent.email);
    }
  }, [purchaseIntent]);

  return (
    <Card variant="contained" sx={{ p: 3, mb: 3 }} className="checkout-card">
      {
        <>
          <p className="text-xl font-bold my-0 mb-6">Informations sur la réservation</p>
          <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
            <Typography variant="md" color={greyScale[700]} minWidth={100}>
              {t('checkout.lastName')}
            </Typography>
            <TextField
              name="lastName"
              variant="outlined"
              value={values.lastName}
              onChange={handleChange}
              placeholder={t('checkout.lastName') ?? ''}
              fullWidth
              error={touched.lastName && Boolean(errors.lastName)}
              helperText={touched.lastName && errors.lastName}
              InputProps={{
                className: 'h-12 bg-neutral-200 dark:bg-black',
                startAdornment: <Person className="h-[20px] mr-1"></Person>
              }}
            />
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
            <Typography variant="md" color={greyScale[700]} minWidth={100}>
              {t('checkout.firstName')}
            </Typography>
            <TextField
              name="firstName"
              variant="outlined"
              value={values.firstName}
              onChange={handleChange}
              placeholder={t('checkout.firstName') ?? ''}
              fullWidth
              error={touched.firstName && Boolean(errors.firstName)}
              helperText={touched.firstName && errors.firstName}
              InputProps={{
                className: 'h-12 bg-neutral-200 dark:bg-black',
                startAdornment: <Person className="h-[20px] mr-1"></Person>
              }}
            />
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="md" color={greyScale[700]} minWidth={100}>
              {t('checkout.mail')}
            </Typography>
            <TextField
              name="email"
              variant="outlined"
              value={values.email}
              onChange={handleChange}
              placeholder={t('checkout.mail') ?? ''}
              fullWidth
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              InputProps={{
                className: 'h-12 bg-neutral-200 dark:bg-black',
                startAdornment: <Mail className="h-[18px] mr-1"></Mail>
              }}
            />
          </Stack>
        </>
      }
    </Card>
  );
};

export default UserInfos;
