import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { Stack } from '@mui/system';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { HomeEvent } from '../../services/api/event';
import format from 'date-fns/format';
import { capitalize } from '../../utils/strings';
import { CalendarMonth } from '@mui/icons-material';

interface EventCheckoutCardProps {
  event: HomeEvent;
}

const EventCheckoutCard = (props: EventCheckoutCardProps) => {
  const { event } = props;
  return (
    <div className="p-3">
      <Stack direction="row">
        <Box pr={2} alignItems="center" display="flex">
          <img
            src={event.cover}
            alt="event cover"
            style={{ objectFit: 'cover' }}
            className="max-w-[120px] max-h-[120px] min-h-0 min-w-0 w-full aspect-video rounded-xl"
          />
        </Box>
        <Stack direction="column" spacing={0.5} justifyContent="center">
          <Typography variant="h5" textOverflow="ellipsis" noWrap>
            {event.title}
          </Typography>
          <Stack direction="row" ml={-1} alignItems="end">
            <div className=" space-x-1 flex ">
              <CalendarMonth sx={{ width: 18, height: 18 }} />
              <Typography variant="md" color="text.secondary" fontWeight={600} textOverflow="ellipsis" noWrap>
                {`${capitalize(format(new Date(event?.beginAt!), "eee dd MMMM - HH'h'mm"))}`}
              </Typography>
            </div>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            {event.address == null ? (
              <Box height={8} />
            ) : (
              <div className="space-x-1 flex ">
                <LocationOnIcon sx={{ width: 18, height: 18 }} />
                <Typography variant="md" color="text.secondary" textOverflow="ellipsis" noWrap>
                  {event.address.name} {`, `} {event.address.city}
                </Typography>
              </div>
            )}
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};

export default EventCheckoutCard;
