import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import { Stack } from '@mui/system';
import { Box, ButtonBase, useMediaQuery } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import hamburger from '/hamburger.svg';
import x from '/x.png';
import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { JSX } from 'react/jsx-runtime';
import { getWebsiteConfig } from '@/pages/whiteLabel/themeFactory';
import DesktopDrawer from '@/components/DesktopDrawer';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  justifyContent: 'space-between',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(0)
}));

const CustomAppBar = () => {
  const [showMenuBar, setShowMenuBar] = useState(false);
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  const theme = useTheme(); // Access the theme object
  const navigate = useNavigate();
  const blagnacLogo = getWebsiteConfig()?.logo;

  const renderKeyValuePairs = () => {
    const keyValuePairs: JSX.Element[] = [];
    const data = getWebsiteConfig()?.headerLinks; // Specify the type
    data?.forEach(key => {
      keyValuePairs.push(
        <Link key={key.name} to={key.url} className=" text-black cursor-pointer font-extrabold hover:text-[#928073]">
          {key.name}
        </Link>
      );
    });

    return keyValuePairs;
  };
  return (
    <>
      <CustomSideAppBar showMenuBar={showMenuBar} />
      <Box
        sx={{
          flexGrow: 1,
          backgroundColor: theme.palette.appBar?.main
        }}
      >
        {!isDesktop && (
          <img
            src={!showMenuBar ? hamburger : x}
            onClick={() => {
              setShowMenuBar(!showMenuBar);
            }}
            alt="hamburger"
            className="absolute z-50 right-10 top-7 w-[25px] object-scale-down"
            style={{ width: showMenuBar ? '29px' : '25px', position: showMenuBar ? 'fixed' : 'absolute' }}
          />
        )}{' '}
        <AppBar
          position="static"
          elevation={0}
          sx={{
            bgcolor: '#f4f4f4',
            // background: `url(images/texture.png) repeat 4px 4px,linear-gradient(90deg, #6d1952 35.8%, #44163d 98.93%)`,
            pb: 1
          }}
        >
          <StyledToolbar>
            <Box display="flex" justifyContent={'center'} width={!isDesktop ? '100%' : 'unset'}>
              <ButtonBase onClick={() => navigate('/home')}>
                <div className="w-full flex items-center justify-center ">
                  <img
                    src={blagnacLogo}
                    className="flex justify-center items-center w-[56px] h-[56px] md:w-[60px] md:h-[60px]"
                  />
                </div>
              </ButtonBase>
            </Box>
            {isDesktop && (
              <Box display="flex" alignItems="center" justifyContent="space-between" width="40%">
                {renderKeyValuePairs()}
              </Box>
            )}
            <Box display="flex" alignItems="center" justifyContent="center">
              <Stack direction="row" spacing={2}>
                <DesktopDrawer />
              </Stack>
            </Box>
          </StyledToolbar>
        </AppBar>
      </Box>
    </>
  );
};

interface CustomSideAppBarProps {
  showMenuBar: boolean;
}
const CustomSideAppBar: React.FC<CustomSideAppBarProps> = ({ showMenuBar }) => {
  const renderKeyValuePairs = () => {
    const keyValuePairs: JSX.Element[] = [];
    const data = getWebsiteConfig()?.headerLinks; // Specify the type
    data?.forEach(key => {
      keyValuePairs.push(
        <Link
          key={key.name}
          to={key.url}
          className="cursor-pointer text-white font-semibold hover:text-blue-500 text-2xl border-b border-gray-300 py-5"
        >
          {key.name}
        </Link>
      );
    });

    return keyValuePairs;
  };

  return (
    <div
      className={`w-full z-40 h-full'
      }  fixed top-0 right-0 left-0 bottom-0 bg-black`}
      style={{ transition: '.6s ease-in-out', transform: showMenuBar ? 'translateX(0%)' : 'translateX(100%)' }}
    >
      <div className="flex flex-col w-full justify-center items-center h-full -mt-20">{renderKeyValuePairs()}</div>
    </div>
  );
};
export default CustomAppBar;
