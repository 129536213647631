import { Outlet } from 'react-router-dom';
import TickieBottomNavigation from './components/BottomNavigation';
import { getWebsiteConfig } from './pages/whiteLabel/themeFactory';

const RootWithoutFooter = () => {
  const appId = getWebsiteConfig().id;
  return (
    <>
      <Outlet />
      {appId !== 'NANCY' && <TickieBottomNavigation />}
    </>
  );
};

export default RootWithoutFooter;
