import React from 'react';
import { useTheme } from '@mui/material/styles';
interface EditButtonProps {
  handleClick: () => void;
}

const EditButton: React.FC<EditButtonProps> = ({ handleClick }) => {
  const theme = useTheme();
  return (
    <button onClick={handleClick} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
      <svg
        fill={theme.palette.mode === 'dark' ? '#ffffff' : '#000000'}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 528.899 528.899"
        xmlSpace="preserve"
        stroke={theme.palette.mode === 'dark' ? '#ffffff' : '#000000'}
        width="15" // Set width and height for the SVG
        height="15"
      >
        <path d="M328.883,89.125l107.59,107.589l-272.34,272.34L56.604,361.465L328.883,89.125z M518.113,63.177l-47.981-47.981 c-18.543-18.543-48.653-18.543-67.259,0l-45.961,45.961l107.59,107.59l53.611-53.611 C532.495,100.753,532.495,77.559,518.113,63.177z M0.3,512.69c-1.958,8.812,5.998,16.708,14.811,14.565l119.891-29.069 L27.473,390.597L0.3,512.69z"></path>
      </svg>
    </button>
  );
};

export default EditButton;
