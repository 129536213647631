import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { t } from 'i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import greyScale from '../../themes/default/colors/greyscale';
import { Mail } from 'lucide-react';
import { Person } from '@mui/icons-material';
import { useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';

function TicketTransfer() {
  const [params] = useSearchParams();
  const { eventId } = useParams();
  const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: params.get('lastName') || '',
      lastName: params.get('firstName') || '',
      email: params.get('firstName') || ''
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('Veulliez enter un prénom'),
      lastName: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email').required('Required')
    }),
    onSubmit: async values => {
      try {
        navigate(
          `/tickets-selection/${eventId}?firstName=${values.firstName}&lastName=${values.lastName}&email=${values.email}`
        );
      } catch (error) {}
    }
  });

  const appBar = () => (
    <Stack direction="row" zIndex="tooltip" position="absolute" left={0} top={0} m={1} alignItems="center">
      <div onClick={() => navigate(`/ticket-details/${eventId}`)}>
        <IconButton>
          <ArrowBackIcon color="primary" />
        </IconButton>
      </div>
      <Typography variant="h6" textAlign="center">
        Transférer mes billets
      </Typography>
    </Stack>
  );

  return (
    <div className="flex flex-col items-center  p-10">
      {appBar()}
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col justify-center">
          <Typography variant="xl" textAlign="center" color={greyScale[500]} mt={12} p={1}>
            Renseignez les informations de la personne à qui vous souhaitez transférer vos billets.
          </Typography>
          <div className="flex flex-col w-full p-4 space-y-2">
            <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1} spacing={2}>
              <TextField
                id="firstName"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                variant="outlined"
                placeholder={t('checkout.lastName') ?? ''}
                fullWidth
                helperText={formik.touched.lastName && formik.errors.lastName}
                InputProps={{
                  className: 'h-12 bg-gray-800 ',
                  startAdornment: <Person className="h-[20px] mr-1"></Person>
                }}
              />
              <TextField
                id="firstName"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                variant="outlined"
                placeholder={t('checkout.firstName') ?? ''}
                fullWidth
                helperText={formik.touched.firstName && formik.errors.firstName}
                InputProps={{
                  className: 'h-12 bg-gray-800',
                  startAdornment: <Person className="h-[20px] mr-1"></Person>
                }}
              />
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <TextField
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                variant="outlined"
                placeholder={t('checkout.mail') ?? ''}
                fullWidth
                helperText={formik.touched.email && formik.errors.email}
                InputProps={{
                  className: 'h-12 bg-gray-800',
                  startAdornment: <Mail className="h-[18px] mr-1"></Mail>
                }}
              />
            </Stack>
            <div className="mt-10">
              <Stack direction="row" spacing={3} justifyContent="center" mx={2} my={1.5}>
                <Button
                  type="button"
                  onClick={() => navigate(`/ticket-details/${eventId}`)}
                  variant="contained"
                  sx={{ borderRadius: 3, height: 48, px: 4, width: { xs: '100%', md: 'auto' } }}
                  className="bg-[#181632] text-lg font-bold leading-10"
                >
                  Annuler
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={!formik.isValid}
                  sx={{ borderRadius: 3, height: 48, px: 4, width: { xs: '100%', md: 'auto' } }}
                  className="text-lg font-bold leading-10"
                >
                  Suivant
                </Button>
              </Stack>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default TicketTransfer;
