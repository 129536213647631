import { Badge, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import noReveal from '../../../../assets/images/card/no-reveal.png';
import { format } from 'date-fns';
import { PitchNft } from '../../../../utils/seasonUtils';

interface NftSeasonCardProps {
  pitchNft: PitchNft;
}

const NftSeasonCard = (props: NftSeasonCardProps) => {
  const { pitchNft } = props;
  const { quantity, beginAt, isOpened, tokenUri, isLocked } = pitchNft;
  const [tokenData, setTokenData] = useState<any | null>(null);
  useEffect(() => {
    if (tokenUri == null) return;
    fetch(tokenUri)
      .then(response => response.json())
      .then(data => {
        return setTokenData(data);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {}, []);

  const selectImage = () => {
    if (isLocked) return '/locked.png';
    if (quantity) {
      if (isOpened) {
        if (tokenData?.image) return tokenData?.image;
        else return `/place-holder.png`;
      } else {
        return noReveal;
      }
    } else {
      return `/place-holder.png`;
    }
  };
  return (
    <Stack
      direction="column"
      borderRadius={2}
      p={1}
      justifyContent="center"
      alignItems="center"
      className="transform transition duration-500 hover:scale-110 scale-100 min-w-[70px]"
    >
      <Badge badgeContent={quantity > 1 ? `x${quantity}` : null} color="warning">
        <img src={selectImage()} alt="Remy Sharp" height={100} style={{ borderRadius: 8 }} />
      </Badge>
      <Typography
        variant="caption"
        fontWeight={700}
        pt={0.1}
        sx={{ color: !beginAt ? 'transparent' : 'rgba(255,255,255,0.75)' }}
      >
        {beginAt ? format(new Date(beginAt), 'dd/MM') : '-'}
      </Typography>
    </Stack>
  );
};

export default NftSeasonCard;
