import HomeEventList from './components/HomeEventList';
import eventApi, { HomeEvent } from '../../services/api/event';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import Countdown from 'react-countdown';
import { capitalize } from '../../utils/strings';
import { getWebsiteConfig } from '../whiteLabel/themeFactory';
import HomeCountDownRenderer from '../../components/HomeCountownRender';
import { Box, CircularProgress, Tab, Tabs, Typography } from '@mui/material';
import { useState } from 'react';
import SubscriptionList from '../SubscriptionHome/components/SubscriptionList';
import TicketIcon from '../../assets/images/icons/ticket';
import CustomAppBar from './components/CustomAppBar';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function CustomHomeBlackVision() {
  const navigate = useNavigate();
  const homeBanner = getWebsiteConfig().banner;
  const partners = getWebsiteConfig().partners;
  const orgaUuids = getWebsiteConfig().orgaUuids;
  const filterOrgaEvents = (e: HomeEvent) => orgaUuids.includes(e.orgaId);

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  // TODO filter les events au niveau du backend
  const data = eventApi.endpoints.getEvents.useQuery('', {
    selectFromResult: ({ data }: any) => {
      return {
        events: getNearestEvent(data)
      };
    }
  });
  const { isLoading } = eventApi.endpoints.getEvents.useQuery('');

  const { events } = data;
  function getNearestEvent(e: HomeEvent[] | undefined) {
    if (e) {
      const now = new Date();
      // Filter out past events and sort the upcoming ones by beginAt
      const sortedEvents = e
        .filter(
          event =>
            (new Date(event.beginAt) > now || (new Date(event.beginAt) < now && now < new Date(event.endAt))) &&
            event.type == 'EVENT' &&
            filterOrgaEvents(event)
        )
        .sort((a, b) => new Date(a.beginAt).getTime() - new Date(b.beginAt).getTime());
      // Return the first event from the sorted list or null if there are no upcoming events
      return sortedEvents.length ? sortedEvents[0] : null;
    } else {
      return null;
    }
  }

  return (
    <>
      <CustomAppBar />
      <div className="w-full flex flex-col	min-h-[460px] justify-center items-center bg-gray-300 relative ">
        <img src={homeBanner} alt="bg" className="absolute w-full h-full -z-1  object-cover brightness-50	" />
        <h2 className="mb-1 z-10 text-white">À l’affiche</h2>
        {!isLoading ? (
          <>
            {events ? (
              <>
                <h1 className="text-white   mt-2 z-10 text-5xl font-bold text-center">{events?.title}</h1>
                <div className="flex  flex-col sm:flex-row">
                  <div className="flex flex-col justify-center items-center mr-4 md:w-[400px] mb-10">
                    <div className="text-red-500 text-xl z-10 text-center px-2">{events?.subTitle}</div>
                    <div className="text-xl font-bold  mt-3 z-10 text-white  ">
                      {events && `${capitalize(format(new Date(events?.beginAt), 'eeee dd MMMM'))}`}
                    </div>
                    <div className="text-xl  font-bold mb-3 z-10 text-white ">
                      {' '}
                      {events && `${capitalize(format(new Date(events?.beginAt), "HH'h'mm"))}`}
                    </div>
                    <div className="text-gray-200 z-10 text-xl  text-bold">{events?.address?.name}</div>
                  </div>
                  <div className="flex flex-col justify-center items-center mr-4 text-white  ">
                    <Countdown date={new Date(events?.beginAt)} renderer={HomeCountDownRenderer} />
                    <div
                      onClick={() => navigate(`/event/${events?.uuid}`)}
                      className="w-[250px] h-[50px] flex justify-center rounded-md items-center   cursor-pointer z-10 mb-10  text-white bg-[#507251]  hover:bg-[#507251]/90 focus:ring-4 focus:outline-none focus:ring-red-600/50 font-bold  text-md  px-5 py-2.5 text-center   dark:focus:ring-red-600/55  "
                    >
                      Choisir ma place
                      <TicketIcon size="17" className="ml-5 w-[17px]" />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="text-white text-2xl font-bold md:text-5xl z-20 text-center mt-5">
                  Il n’y a aucun événement en ligne, <br />
                  revenez plus tard 👋{' '}
                </div>
              </>
            )}
          </>
        ) : (
          <CircularProgress sx={{ color: '#fff' }} size={80} />
        )}
      </div>
      <div className="w-full h-full ">
        <div className="mx-0">
          <CustomTabPanel value={value} index={0}>
            <HomeEventList isEvent />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <SubscriptionList isEvent />
          </CustomTabPanel>
          <br />
          <br />
          <br />
        </div>
        {/* <PartnersWall /> */}
      </div>
    </>
  );
}

export default CustomHomeBlackVision;
