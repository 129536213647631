import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box, CardActionArea } from '@mui/material';
import { Stack } from '@mui/system';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useNavigate } from 'react-router-dom';
import { HomeEvent } from '../../services/api/event';
import format from 'date-fns/format';
import { capitalize } from '../../utils/strings';
import { Subscription } from '../../services/api/subscription';

interface OrganizationEventsCardProps {
  subscription: Subscription;
  showFavorite?: boolean | undefined;
}

const OrganizationSubscriptionCard = (props: OrganizationEventsCardProps) => {
  const { subscription } = props;
  const navigate = useNavigate();
  return (
    <Card sx={{ mb: 2 }} onClick={() => navigate(`/subscription/${subscription.uuid}`)}>
      <CardActionArea>
        <CardContent>
          <Stack direction="row">
            <Box pr={2}>
              <img
                width={120}
                height={120}
                src={subscription?.cover ?? ''}
                alt="event cover"
                style={{ borderRadius: 20, objectFit: 'cover' }}
              />
            </Box>
            <Stack direction="column" spacing={1} mt={2} width="100%">
              <Typography variant="h5">{subscription.name}</Typography>
              {/* <Typography variant="md" color="primary" fontWeight={600}>
                {`${capitalize(format(new Date(event?.beginAt!), "eee dd MMMM - HH'h'mm"))}`}
              </Typography> */}
              {/* <Stack direction="row" justifyContent="space-between">
                {event.address == null ? (
                  <Box height={8} />
                ) : (
                  <Stack direction="row" ml={-1} alignItems="end">
                    <LocationOnIcon sx={{ width: 20, height: 20 }} />
                    <Typography variant="md" color="text.secondary" textOverflow="ellipsis" noWrap>
                      {event.address.name} {`, `} {event.address.city}
                    </Typography>
                  </Stack>
                )}
                {props.showFavorite ? <FavoriteBorderIcon /> : null}
              </Stack> */}
            </Stack>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default OrganizationSubscriptionCard;
