import { t } from 'i18next';

function ModalTicket({ id, category }: { id: number; category: string }) {
  return (
    <div className="rounded bg-gray-300 dark:bg-custom w-full mt-2">
      <div className="flex flex-row w-full">
        <div className="flex flex-col mt-0 mb-0 flex-grow">
          <div className="p-3 flex-grow">
            <h3 className="mt-0 mb-0">
              {t('transfer.BilletTicketBody')} #{id}
            </h3>
            <h4 className="mt-0 mb-0 text-[#2e2e2e]  dark:text-[#E0E0E0]">{category}</h4>
          </div>
        </div>
        {/* <div className="flex flex-col mt-0 mb-0 flex-grow">
          <div className="p-3 flex-grow ">
            <h3 className="mt-0 mb-0 text-right">{category}</h3>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default ModalTicket;
