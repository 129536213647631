import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import DesktopDrawer from '../../../../components/DesktopDrawer';
import NancyDesktopDrawer from '../DesktopDrawer';

interface HeaderProps {
  logo: string; // Assuming logo is a URL
}

const Header: React.FC<HeaderProps> = ({ logo }) => {
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  return (
    <>
      <Box
        width={'100%'}
        bgcolor={'black'}
        height={isDesktop ? 95 : 85}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          px: isDesktop ? ' 100px' : '8px'
        }}
      >
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          height={'100%'}
          width={'100%'}
        >
          <img height={'60%'} src={logo} alt="logo" />
          <div></div>
          <NancyDesktopDrawer />
        </Box>
      </Box>
    </>
  );
};

export default Header;
