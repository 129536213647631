import { Stack, IconButton, Typography, Box, TextField, useMediaQuery, Button, CircularProgress } from '@mui/material';
import { t } from 'i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import greyScale from '../../themes/default/colors/greyscale';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import shadows from '../../themes/default/shadows';
import { useEffect, useState } from 'react';
import primary from '../../themes/default/colors/primary';
import userApi, { Gender } from '../../services/api/user';
import { useAppDispatch } from '../../redux/hooks';

const isDefiend = (prop: any) => {
  if (prop == '' || prop == undefined || prop == null) {
    return undefined;
  } else {
    return prop;
  }
};

const CompleteProfile = () => {
  const navigate = useNavigate();
  const { transferId } = useParams();
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  const [completeProfile, { isLoading, isError }] = userApi.endpoints.completeProfile.useMutation();
  const { data: user } = userApi.endpoints.getUser.useQuery();
  const dispatch = useAppDispatch();
  // const { data: user } = userApi.endpoints.getUser.useQuery();
  const [gender, setGender] = useState<Gender | undefined>();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? ''
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('Required'),
      lastName: Yup.string().required('Required')
    }),
    onSubmit: async values => {
      try {
        await completeProfile({ ...values, gender }).unwrap();
        dispatch(userApi.util.invalidateTags(['user-details']));
        if (transferId) {
          navigate(`/approve-transfer/${transferId}`);
        } else {
          navigate('/home');
        }
      } catch (error) {}
    }
  });

  useEffect(() => {
    if (user) {
      setGender(user.gender);
    }
  }, [user]);

  return (
    <>
      <header>
        <Stack direction="row" alignItems="center" mt={2} ml={1} mb={1} spacing={1}>
          <Stack direction="row" alignItems="center">
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h4">{t('completeProfile.completeProfile')}</Typography>
          </Stack>
        </Stack>
      </header>
      <form onSubmit={formik.handleSubmit}>
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={3} p={3}>
          {user && isDefiend(user?.avatar) && <img src={user?.avatar} referrerPolicy="no-referrer"></img>}
          <TextField
            id="lastName"
            name="lastName"
            autoComplete="family-name"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            placeholder={t('completeProfile.lastName') ?? ''}
            sx={{ maxWidth: 300 }}
            fullWidth
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          />
          <TextField
            id="firstName"
            name="firstName"
            autoComplete="given-name"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            placeholder={t('completeProfile.firstName') ?? ''}
            sx={{ maxWidth: 300 }}
            fullWidth
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          />
          <Stack direction="row" justifyContent="center" spacing={2} sx={{ maxWidth: 300 }}>
            <IconButton
              sx={{
                border: `1px solid ${greyScale[200]}`,
                height: 50,
                width: 50,
                backgroundColor: gender == Gender.MALE ? primary[200] : 'black',
                '&:hover': {
                  backgroundColor: primary[200]
                }
              }}
              onClick={() => setGender(Gender.MALE)}
            >
              🙋
            </IconButton>

            <IconButton
              sx={{
                border: `1px solid ${greyScale[200]}`,
                height: 50,
                width: 50,
                backgroundColor: gender == Gender.FEMALE ? primary[200] : 'black',
                '&:hover': {
                  backgroundColor: primary[200]
                }
              }}
              onClick={() => setGender(Gender.FEMALE)}
            >
              🙋‍♀️
            </IconButton>
          </Stack>
          <Box
            sx={{ position: isDesktop ? 'relative' : 'fixed', bottom: 0, left: 0, right: 0, p: 3 }}
            justifyContent="center"
            alignItems="center"
          >
            <Stack direction="column" spacing={1.5}>
              <Button
                variant="contained"
                type="submit"
                fullWidth
                sx={{ boxShadow: shadows.primaryButton, maxWidth: { xs: '100%', md: 300 }, width: { md: 300 } }}
              >
                {isLoading ? <CircularProgress sx={{ color: 'white' }} /> : t('completeProfile.continue')}
              </Button>
              <Button variant="text" onClick={() => navigate('/home')} sx={{ fontSize: '0.8rem' }}>
                {t('completeProfile.skip')}
              </Button>
            </Stack>
          </Box>
        </Stack>
      </form>
    </>
  );
};

export default CompleteProfile;
