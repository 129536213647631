import {
  alpha,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  IconButton,
  Typography,
  useMediaQuery
} from '@mui/material';
import { Stack } from '@mui/system';
import { t } from 'i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useRef, useState } from 'react';
// import 'add-to-calendar-button/assets/css/atcb.css';
import ShareModal from './components/ShareModal';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import shadows from '../../themes/default/shadows';
import DescriptionIconDecoration from '../../components/DescriptionIconDecoration';
import greyScale from '../../themes/default/colors/greyscale';
import DesktopAppBar from './components/DesktopAppBar';
import primary from '../../themes/default/colors/primary';
import { useAppSelector } from '../../redux/hooks';
import palette from '../../themes/default/colors/palette';
import UnhandledError from '../../components/UnhandledError';
import CustomFooter from '../../components/CustomFooter';
import subscriptionApi from '../../services/api/subscription';
import LoginModalPhone from './components/LoginModalPhone';

function SubscriptionDetails() {
  const { subscriptionUuid } = useParams();
  const {
    data: subscription,
    isLoading,
    isError
  } = subscriptionApi.endpoints.getSubscriptionDetails.useQuery(subscriptionUuid ?? '');
  const { isAuth } = useAppSelector(state => state.authSlice);
  const { darkTheme } = useAppSelector(state => state.themeSlice);
  const navigate = useNavigate();
  const mapRef = useRef<null | HTMLDivElement>(null);
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  const scrollToMap = () => mapRef?.current?.scrollIntoView();
  const subtitleColor = darkTheme ? greyScale[300] : greyScale[900];
  let [urlParams, setUrlParams] = useSearchParams();
  const subscriptionInvite = urlParams.get('subscriptionInvite');

  const [openLoginModal, setOpenLoginModal] = useState(false);
  const handleOpenLoginModal = () => setOpenLoginModal(true);
  const handleCloseLoginModal = () => setOpenLoginModal(false);

  const redirecTo = () => {
    // if (isAuth) {
    navigate(
      `/subscription-reservation/${subscription?.uuid}${
        subscriptionInvite ? `?subscriptionInvite=${subscriptionInvite}` : ''
      }`
    );
    // } else {
    //   // handleOpenLoginModal();
    //   navigate(
    //     `/login?redirect=/subscription-reservation/${subscription?.uuid}${
    //       invitationCode ? `?subscriptionInvite=${invitationCode}` : ''
    //     }`
    //   );
    // }
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={`calc(100vh)`}>
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return <UnhandledError />;
  }

  function AppBar() {
    return (
      <Stack direction="row" justifyContent="space-between" zIndex="toolpit" sx={{ display: { md: 'none' } }}>
        <Link to="/home">
          <IconButton sx={{ bgcolor: alpha(greyScale[900], 0.25), m: 1 }}>
            <ArrowBackIcon sx={{ color: 'white' }} />
          </IconButton>
        </Link>
        <Box display="flex">
          <ShareModal url={`${import.meta.env.VITE_APP_BASE_URL}/event/${subscriptionUuid}`} />
        </Box>
      </Stack>
    );
  }

  const cover = () => {
    return (
      <Box display="flex" my={3}>
        <Box sx={{ width: { xs: '100%', lg: 1200, md: 900, aspectRatio: '5/2' } }}>
          <img
            src={subscription?.cover!}
            width="100%"
            style={{
              borderRadius: 4,
              objectFit: 'cover',
              aspectRatio: isDesktop ? '5/2' : '5/3'
            }}
          />
        </Box>
      </Box>
    );
  };

  const mobileReservationButton = () => {
    return (
      <Box sx={{ display: isDesktop ? 'none' : '', position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 200 }}>
        <Box m={3}>
          <Button
            variant={!true ? 'contained' : 'gradient'}
            fullWidth
            disabled={!true}
            onClick={redirecTo}
            sx={{ boxShadow: shadows.primaryButton, height: 58, fontSize: 18 }}
          >
            {t('subscription.subscribe')}
          </Button>
        </Box>
      </Box>
    );
  };

  const desktopReservationCard = () => {
    return (
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <Card
          sx={{
            minWidth: 200,
            border: `1px solid ${darkTheme ? '#000000' : greyScale[300]}`,
            bgcolor: darkTheme ? palette.dark2 : alpha(primary[100], 0.25),
            boxShadow: shadows.primaryButton,
            position: 'sticky',
            borderRadius: 4,
            top: 24
          }}
        >
          <Stack direction="row" m={2}>
            <DescriptionIconDecoration>
              <ConfirmationNumberIcon />
            </DescriptionIconDecoration>
            <Stack direction="column" pl={2} spacing={0.5}>
              {true ? (
                <Typography variant="xl" fontWeight={700}>{`${Intl.NumberFormat('fr-FR', {
                  style: 'currency',
                  currency: 'EUR'
                }).format(subscription?.minPrice ?? 0)} - ${Intl.NumberFormat('fr-FR', {
                  style: 'currency',
                  currency: 'EUR'
                }).format(subscription?.maxPrice ?? 0)}`}</Typography>
              ) : (
                <Stack direction="column" justifyContent="center" height="100%">
                  <Typography variant="xl" fontWeight={700} alignItems="center" justifyContent="end">
                    {t('subscription.subscriptionNotAvailible')}
                  </Typography>
                </Stack>
              )}
              {true && <Typography variant="md">{t('subscription.priceOfSubscriptionDepends')}</Typography>}
            </Stack>
          </Stack>
          <Box m={3}>
            <Button
              variant={!true ? 'contained' : 'gradient'}
              fullWidth
              disabled={!true}
              onClick={redirecTo}
              sx={{
                boxShadow: shadows.primaryButton,
                height: 58,
                fontSize: 18,
                borderRadius: 2
              }}
            >
              {t('subscription.subscribe')}
            </Button>
          </Box>
        </Card>
      </Box>
    );
  };

  return (
    <>
      {subscription ? (
        <div>
          <header>
            <DesktopAppBar eventId={subscriptionUuid ?? ''} />
            {AppBar()}
          </header>
          <LoginModalPhone handleCloseLoginModal={handleCloseLoginModal} openLoginModal={openLoginModal} />
          <Stack direction="column" justifyContent="center" alignItems="center">
            <Stack direction="column" m={3} mt={0} sx={{ width: { lg: 1000 }, mt: { xs: -3 } }}>
              {cover()}
              <Stack direction={isDesktop ? 'row' : 'column'} justifyContent="space-between">
                <Box width="100%" sx={{ pr: { md: 3 } }}>
                  <Typography variant="h3" fontWeight={700} mb={2}>
                    {subscription?.name}
                  </Typography>
                  <Box mt={2} mb={2} mr={2}>
                    <Divider sx={{ display: { md: 'none' } }} />
                  </Box>

                  <Stack direction="row" mb={2} sx={{ display: { md: 'none' } }}>
                    <DescriptionIconDecoration>
                      <ConfirmationNumberIcon />
                    </DescriptionIconDecoration>
                    <Stack direction="column" pl={2} spacing={0.5}>
                      {true ? (
                        <>
                          <Typography variant="xl" fontWeight={700}>{`${Intl.NumberFormat('fr-FR', {
                            style: 'currency',
                            currency: 'EUR'
                          }).format(subscription?.minPrice ?? 0)} - ${Intl.NumberFormat('fr-FR', {
                            style: 'currency',
                            currency: 'EUR'
                          }).format(subscription?.maxPrice ?? 0)}`}</Typography>
                          <Typography variant="md" color={subtitleColor}>
                            {t('subscription.priceOfSubscriptionDepends')}
                          </Typography>
                        </>
                      ) : (
                        <Stack direction="column" justifyContent="center" height="100%">
                          <Typography variant="xl" fontWeight={700} alignItems="center" justifyContent="end">
                            {t('subscription.subscriptionNotAvailible')}
                          </Typography>
                        </Stack>
                      )}
                    </Stack>
                  </Stack>
                  <Box height={24} />
                  <>
                    <Box height={16} />
                    <Typography variant="lg" color={subtitleColor}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: subscription?.description ?? ''
                        }}
                      />
                    </Typography>
                  </>
                  <Box mt={3} mb={3}>
                    <Divider />
                  </Box>
                </Box>
                {desktopReservationCard()}
              </Stack>
            </Stack>
          </Stack>
          <Box height={64} />
          {mobileReservationButton()}
          <CustomFooter />
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
}

export default SubscriptionDetails;
