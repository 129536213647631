import { Button, Typography } from '@mui/material';
import VerifyCircleImage from '../TicketsSelection/VerifyCircleImage';
import InfoImage from '../TicketsSelection/InfoImage';
import { DrawerClose } from '@/components/ui/drawer';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { t } from 'i18next';
interface CancelVerifiedProps {
  isDrawer: boolean;
  setOpen: () => void;
  setCancelSuccess: () => void;
}
function CancelVerified({ isDrawer, setOpen, setCancelSuccess }: CancelVerifiedProps) {
  const navigate = useNavigate();
  return (
    <div className="flex justify-center items-center">
      <div className="flex flex-col justify-center items-center w-[350px] p-10">
        <VerifyCircleImage width="70px" height="70px" />
        <h2 className="text-center mt-2 mb-2">{t('transfer.annulerLeTransfert')}</h2>

        <h3 className="text-center mt-2 mb-2">{t('transfer.laDemande')}</h3>
        <div className="w-full">
          {!isDrawer && (
            <Button
              className="mt-3 w-full"
              variant="contained"
              onClick={() => {
                setOpen();
                setCancelSuccess();
              }}
              sx={{ borderRadius: 3, height: 48, px: 4, width: { xs: '100%', md: 'auto' } }}
            >
              <Typography variant="lg" fontWeight={700} className="leading-10">
                {t('transfer.continuerButton')}
              </Typography>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
export default CancelVerified;
