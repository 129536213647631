import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TicketCategory } from './api/ticket-category';
import { PromoCodeResponse } from './api/promo-code';
import { computeApplyPromoCode, computeDiscount } from '../utils/computeDiscount';

export interface TicketInShoppingCart {
  eventId: string;
  categoryName: string;
  categoryId: number;
  categoryUuid: string;
  quantity: number;
  promo?: PromoCodeResponse;
  finalPrice: number;
  srcPrice: number;
  type: 'event' | 'subscription';
}

export interface SubscriptionsInShoppingCart {
  subscriptionUuid: string;
  tarifName: string;
  tarifUuid: string;
  quantity: number;
  price: number;
}

export interface ShoppingCart {
  tickets: TicketInShoppingCart[];
  finalPrice: number;
  srcPrice: number;
  promo?: PromoCodeResponse[];
  /// Subscriptions
}

const initialState: ShoppingCart = {
  finalPrice: 0,
  srcPrice: 0,
  tickets: []
};

const shoppingCartSlice = createSlice({
  name: 'shoppingCartSlice',
  initialState,
  reducers: {
    addTicket: (
      state,
      action: PayloadAction<{
        ticketCategory: TicketCategory;
        eventId: string;
        promo?: PromoCodeResponse;
        type: 'event' | 'subscription';
      }>
    ) => {
      const ticketCat = action.payload.ticketCategory;
      const eventId = action.payload.eventId;
      const promo = action.payload.promo;
      const index = state.tickets.findIndex(t => t.eventId === eventId && t.categoryUuid === ticketCat.uuid);
      if (index == -1) {
        state.tickets.push({
          categoryId: ticketCat.id,
          categoryUuid: ticketCat.uuid,
          categoryName: ticketCat.name,
          srcPrice: ticketCat.price,
          finalPrice: computeDiscount(ticketCat.price, promo),
          promo: promo,
          eventId: eventId,
          quantity: 1,
          type: action.payload.type
        });
        state.finalPrice = state.finalPrice + computeDiscount(ticketCat.price, promo);
        state.srcPrice = state.srcPrice + ticketCat.price;
      } else {
        state.tickets[index].quantity = state.tickets[index].quantity + 1;
        state.finalPrice = state.finalPrice + computeDiscount(ticketCat.price, promo);
        state.srcPrice = state.srcPrice + ticketCat.price;
      }
    },
    removeTicket: (state, action: PayloadAction<{ ticketCategory: TicketCategory; eventId: string }>) => {
      const ticketCat = action.payload.ticketCategory;
      const eventId = action.payload.eventId;
      const index = state.tickets.findIndex(t => t.eventId === eventId && t.categoryUuid === ticketCat.uuid);
      if (index === -1) {
        return;
      }
      if (state.tickets[index].quantity === 1) {
        state.finalPrice = state.finalPrice - state.tickets[index].finalPrice;
        state.srcPrice = state.srcPrice - state.tickets[index].srcPrice;
        state.tickets = state.tickets.filter(t => t.categoryUuid !== ticketCat.uuid);
      } else {
        state.finalPrice = state.finalPrice - state.tickets[index].finalPrice;
        state.srcPrice = state.srcPrice - state.tickets[index].srcPrice;
        state.tickets[index].quantity = state.tickets[index].quantity - 1;
      }
    },
    initializePromoCode: (state, action: PayloadAction<{ promo: PromoCodeResponse[] }>) => {
      const promo = action.payload.promo;
      state.promo = promo;
    },
    changePromo: (state, action: PayloadAction<{ promo: PromoCodeResponse[] }>) => {
      const promo = action.payload.promo;
      state.promo = promo;
      state.tickets = state.tickets.map(t => {
        const p = promo.find(e => e.ticketCategories.find(e => e.ticketCategoryId === t.categoryId));
        console.log(p);
        const canApplyPromoCode = computeApplyPromoCode(t.categoryId, p);
        const finalPrice = canApplyPromoCode ? computeDiscount(t.srcPrice, p) : t.srcPrice;
        return { ...t, promo: canApplyPromoCode ? p : undefined, finalPrice };
      });

      state.finalPrice = state.tickets.reduce((acc, t) => acc + t.finalPrice * t.quantity, 0);
      state.srcPrice = state.tickets.reduce((acc, t) => acc + t.srcPrice * t.quantity, 0);
    },
    removePromo: state => {
      state.promo = undefined;
      state.tickets = state.tickets.map(t => {
        return { ...t, promo: undefined, finalPrice: t.srcPrice };
      });
      state.finalPrice = state.tickets.reduce((acc, t) => acc + t.finalPrice * t.quantity, 0);
      state.srcPrice = state.tickets.reduce((acc, t) => acc + t.srcPrice * t.quantity, 0);
    },
    resetTickets: state => {
      state.tickets = [];
    }
  }
});

export default shoppingCartSlice;
