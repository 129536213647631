import React from 'react';
import { useTheme } from '@mui/material/styles';

interface CustomSVGProps {
  width: string;
  height: string;
}

const SelectImage: React.FC<CustomSVGProps> = ({ width, height }) => {
  const theme = useTheme();

  return (
    <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <path
          fill={theme.palette.mode === 'dark' ? '#ffffff' : '#000000'} // Conditionally set fill color based on theme mode
          d="M77.248 415.04a64 64 0 0 1 90.496 0l226.304 226.304L846.528 188.8a64 64 0 1 1 90.56 90.496l-543.04 543.04-316.8-316.8a64 64 0 0 1 0-90.496z"
        ></path>
      </g>
    </svg>
  );
};

export default SelectImage;
