import { BaseQueryFn, FetchArgs, FetchBaseQueryError, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import type { AxiosRequestConfig, AxiosError } from 'axios';

const baseUrl = import.meta.env.VITE_API_BASE_URL;

export interface SignInResponse {
  accessToken: string;
  needToCompleteProfile: boolean;
  secret: string;
}

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      body?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
      headers?: AxiosRequestConfig['headers'];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, body: data, params, headers }) => {
    try {
      const user = JSON.parse(localStorage.getItem('user') ?? '{"accessToken":null }') as SignInResponse;
      const result = await axios({
        url: baseUrl + url,
        method,
        data,
        params,
        headers: { ...headers, authorization: `Bearer ${user.accessToken}` }
      });
      return { data: result.data?.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      // if (err.response?.status === 401) {
      //   localStorage.clear();
      //   window.location.reload();
      // }
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message
        }
      };
    }
  };

const baseApi = createApi({
  baseQuery: axiosBaseQuery({
    baseUrl
  }),
  reducerPath: 'baseApi',
  tagTypes: [
    'user-details',
    'ticket-details',
    'tickets',
    'events',
    'claim-event',
    'subscriptions',
    'user-subscriptions',
    'event-invitations',
    'seasons',
    'event-purchase-intents',
    'claimable-collectibles'
  ],
  endpoints: build => ({})
});

export default baseApi;
