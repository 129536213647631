import { createSlice } from '@reduxjs/toolkit';

export interface ThemeSliceProps {
  darkTheme: boolean;
}

const initialState: ThemeSliceProps = {
  // default to dark theme
  darkTheme:
    localStorage.getItem('vite-ui-theme') === 'dark' || localStorage.getItem('vite-ui-theme') == null ? true : false
};
export const themeSlice = createSlice({
  name: 'themeSlice',
  initialState,
  reducers: {
    toggleTheme: state => {
      state.darkTheme = !state.darkTheme;
    }
  }
});

export default themeSlice;
