import { useNavigate } from 'react-router-dom';
import { Subscription } from '../../services/api/subscription';
import { Box, Card, CardActionArea, CardContent, Stack, Typography } from '@mui/material';
import TicketIcon from '../../assets/images/icons/ticket';
import { getWebsiteConfig } from '@/pages/whiteLabel/themeFactory';

interface OnDispayEventCardProps {
  subscription: Subscription;
}

const SubscriptionCard = (props: OnDispayEventCardProps) => {
  const { subscription } = props;
  const navigate = useNavigate();

  const defaultImg =
    'https://img.rawpixel.com/s3fs-private/rawpixel_images/website_content/px1331795-image-kwvw0m03.jpg?w=800&dpr=1&fit=default&crop=default&q=65&vib=3&con=3&usm=15&bg=F4F4F3&ixlib=js-2.2.1&s=b9b9885327b681c7c6eb3a0b77fd09ab';

  const websiteId = getWebsiteConfig()?.id;
  return (
    <Card
      onClick={() => navigate(`/subscription/${subscription.uuid}`)}
      className="group checkout-card"
      sx={{
        boxShadow: '0px 1px 1px 0px rgba(255, 255, 255, 0.20), 0px 0px 15px 0px rgba(255, 255, 255, 0.20)',
        maxWidth: 300,
        borderRadius: 1,
        bgcolor: '#000',
        mb: 6
      }}
    >
      <CardActionArea>
        <Stack direction="column" height="100%">
          <Box pb={0} height={180} overflow="hidden">
            <img
              width="100%"
              src={subscription.cover || defaultImg}
              alt="event cover"
              className="transform transition duration-500 group-hover:scale-110"
              style={{ borderTopRightRadius: 8, borderTopLeftRadius: 8, objectFit: 'cover', aspectRatio: '5/3' }}
            />
          </Box>
          <CardContent>
            <Stack direction="column" justifyContent="space-between" alignItems="center" height={200}>
              <Stack direction="column">
                <Typography
                  variant={subscription?.name?.length > 30 ? 'md' : 'xl'}
                  maxWidth="100%"
                  fontWeight={800}
                  textAlign="center"
                >
                  {subscription?.name?.toUpperCase()}
                </Typography>
              </Stack>
              <div
                onMouseDown={e => e.stopPropagation()}
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  navigate(`/subscription/${subscription.uuid}`);
                }}
                className={`text-white w-full h-[54px] font-bold flex justify-center rounded-md items-center ${
                  websiteId !== 'GRIZZLYS'
                    ? websiteId === 'NANTES'
                      ? 'text-[#6d1952] bg-[#f5dc47]  hover:bg-[#f5dc47]/90'
                      : 'bg-red-600 '
                    : ' bg-[#D6BF91] '
                }font-bold cursor-pointer z-50`}
              >
                S'abonner
                <TicketIcon size="17" className="ml-5 w-[17px]" />
              </div>
              {/* <div className=" w-full h-[54px] flex justify-center rounded-md items-center font-bold cursor-pointer bg-gradient-to-r from-[#909090] via-[#909090]  to-[#DBDBDB]">
                  Vente fermée
                  <Lock sx={{ width: 16, height: 16, ml: 1 }} />
                </div> */}
            </Stack>
          </CardContent>
        </Stack>
      </CardActionArea>
    </Card>
  );
};

export default SubscriptionCard;
