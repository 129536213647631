import React from 'react';
import { Box, Typography, useMediaQuery, Theme, CircularProgress } from '@mui/material';
import bg from '/marque-blanche/hero.png';
import Countdown from 'react-countdown';
import '/fonts/SpaceGrotesk-VariableFont_wght.ttf';
import { useNavigate } from 'react-router-dom';
import ticketApi from '@/services/api/ticket';

interface HeroProps {
  title: string;
  subtitle: string;
  image: string;
  showMintButton: boolean;
  onClickMintButton: any;
  mintLoading: boolean;
  eventId: string;
}

const Hero: React.FC<HeroProps> = ({
  title,
  subtitle,
  image,
  onClickMintButton,
  showMintButton,
  mintLoading,
  eventId
}) => {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const navigate = useNavigate();
  const { data: tickets } = ticketApi.endpoints.getTicketDetails.useQuery(eventId);

  const renderer = ({ hours, days, minutes, seconds, completed }: any) => {
    return (
      <div
        className="z-10 py-9 h-[55px] sm:w-[360px] w-[270px] mb-4 flex justify-evenly mt-5 items-start font-sans"
        style={{
          fontFamily: 'Helvetica'
        }}
      >
        <div className="flex flex-col justify-center items-center w-[4px]">
          <div className="font-bold sm:text-3xl text-2xl z-10 flex">
            {days <= 9 ? '0' : ''}
            {days}{' '}
          </div>
          <div className="font-bold text-[#D0121C] sm:text-sm text-xs z-10">JOURS</div>
        </div>
        <div className="text-2xl font-bold">:</div>
        <div className="flex flex-col justify-center items-center w-[4px]">
          <div className="font-bold sm:text-3xl text-2xl z-10">
            {' '}
            {hours <= 9 ? '0' : ''}
            {hours}
          </div>
          <div className="font-bold text-[#D0121C] sm:text-sm  text-xs z-10">HEURES</div>
        </div>
        <div className="text-2xl font-bold">:</div>
        <div className="flex flex-col justify-center items-center w-[4px] ">
          <div className="font-bold sm:text-3xl text-2xl z-10">
            {' '}
            {minutes <= 9 ? '0' : ''}
            {minutes}
          </div>
          <div className="font-bold text-[#D0121C] z-10 sm:text-sm text-xs">MINUTES</div>
        </div>
        <div className="text-2xl font-bold">:</div>
        <div className="flex flex-col justify-center items-center w-[4px]">
          <div className="font-bold sm:text-3xl text-2xl z-10">
            {' '}
            {seconds <= 9 ? '0' : ''}
            {seconds}
          </div>
          <div className="font-bold text-[#D0121C] z-10 sm:text-sm text-xs">SECONDES</div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Box
        width={'100%'}
        sx={{
          height: isDesktop ? 'calc(100vh - 132px)' : 'unset',
          backgroundImage: `url(https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/nancy/images/HERO.png)`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'bottom',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            px: isDesktop ? '100px' : '10px',
            display: 'flex',
            flexDirection: isDesktop ? 'row' : 'column'
          }}
        >
          <Box
            width={'100%'}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Box>
              <Countdown date={new Date('2024-05-04T14:00:00.000Z')} renderer={renderer} />
              <br />

              <Typography
                className="custom-font-text"
                sx={{
                  fontWeight: 900,
                  color: '#fff',
                  fontSize: isDesktop ? '35px' : '25px',
                  textTransform: 'uppercase',
                  fontFamily: 'Space Grotesk',
                  fontStyle: 'normal'
                }}
              >
                {title}{' '}
              </Typography>
              <br />
              <Typography
                sx={{
                  fontSize: 15,
                  color: '#fff',
                  fontStyle: 'normal',
                  fontFamily: 'Helvetica ! important'
                }}
              >
                Réclamez gratuitement votre billet souvenir NFT et tentez de gagner des récompenses exclusives 🎁
              </Typography>
              <br />
              <br />
              {tickets && tickets?.tickets?.length > 0 ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: !isSmall ? 'center' : ''
                  }}
                >
                  <Box
                    className="custom-font-text"
                    height={50}
                    width={!isDesktop ? '90%' : 250}
                    onClick={() => {
                      navigate(`/ticket-details/${eventId}`);
                    }}
                    sx={{
                      backgroundColor: '#D0121C',
                      color: '#fff',
                      borderRadius: '8px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: isDesktop ? '20px' : '0px',
                      fontWeight: 'bold',
                      cursor: 'pointer',
                      fontSize: isSmall ? 17 : 16
                    }}
                  >
                    Voir mes billets
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: !isSmall ? 'center' : ''
                  }}
                >
                  {showMintButton && (
                    <Box
                      className="custom-font-text"
                      height={50}
                      width={!isDesktop ? '90%' : 250}
                      onClick={() => {
                        onClickMintButton();
                      }}
                      sx={{
                        backgroundColor: '#D0121C',
                        color: '#fff',
                        borderRadius: '8px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: isDesktop ? '20px' : '0px',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        fontSize: isSmall ? 17 : 16
                      }}
                    >
                      {mintLoading ? <CircularProgress sx={{ color: '#FFF' }} /> : 'OBTENIR MAINTEANT'}
                    </Box>
                  )}
                  {isDesktop && (
                    <Box
                      className="custom-font-text"
                      height={50}
                      width={!isSmall ? 140 : 210}
                      onClick={() => {
                        window.open(
                          'https://asnlbillets.net/(S(opp0kukzc440ufqkldbftk4h))/Pages/PageSpectacles.aspx',
                          '_blank'
                        );
                      }}
                      sx={{
                        backgroundColor: 'transparent',
                        border: '1px solid #D0121C',
                        borderRadius: '8px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        fontSize: isSmall ? 16 : 12
                      }}
                    >
                      ACHETER UN BILLET
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: isDesktop ? 'unset' : '60px'
            }}
          >
            <div className="flex justify-center items-center">
              <img src={image} alt="LeftImage" className="w-3/5 max-w-lg" />
            </div>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Hero;
