import React from 'react';
import { Box, Stack, useMediaQuery } from '@mui/material';
import { SocialFooterIcon } from './SocialFooterIcon';

interface HeaderProps {
  logo: string;
}

const Footer: React.FC<HeaderProps> = ({ logo }) => {
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  const icons = [
    { url: 'https://www.instagram.com/asnancylorraine/', img: '/socialMedias/instagram.png' },
    { url: 'https://twitter.com/asnlofficiel', img: '/socialMedias/twitter.png' }
  ];

  return (
    <>
      <Box
        width={'100%'}
        bgcolor={'black'}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          px: isDesktop ? ' 100px' : '8px'
        }}
      >
        <br />
        <br />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: isDesktop ? 'row' : 'column'
          }}
          height={'100%'}
          width={'100%'}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <img height={'50px'} src={logo} alt="logo" />
            <img height={'50%'} src={'/poweredBy.png'} alt="poweredBy" />
          </Stack>
          <Stack direction="row" alignItems="center" my={3}>
            {icons.map((icon, index) => (
              <SocialFooterIcon key={index} url={icon.url} img={icon.img} />
            ))}
          </Stack>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: !isDesktop ? 'column' : 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderTop: `1px solid ${'gray'}`,
            color: 'rgba(173, 173, 173, 0.85)'
          }}
          height={'100%'}
          width={'100%'}
        >
          <Box sx={{ cursor: 'pointer', my: '30px' }}>Tickie © 2024</Box>
          <Box sx={{ cursor: 'pointer', mb: !isDesktop ? '28px' : 'unset' }}>Français (FR)</Box>
          <Box
            sx={{
              display: 'flex'
            }}
          >
            <Box sx={{ mx: isDesktop ? 5 : 1, cursor: 'pointer', textAlign: 'center' }}>Terms Of Service</Box>
            <Box sx={{ mx: isDesktop ? 5 : 1, cursor: 'pointer', textAlign: 'center' }}> Privacy Policy</Box>
            <Box sx={{ mx: isDesktop ? 5 : 1, cursor: 'pointer', textAlign: 'center' }}>Cookie Policy</Box>
          </Box>
          <br />
          <br />
          <br />
          <br />
        </Box>
      </Box>
    </>
  );
};

export default Footer;
