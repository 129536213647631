import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import { Stack } from '@mui/system';
import { Avatar, Box, ButtonBase, IconButton, Slide, Typography, useScrollTrigger } from '@mui/material';
import userApi from '../../services/api/user';
import DesktopDrawer from '../DesktopDrawer';
import logoTextWhite from '../../assets/images/logo/logo-white-big.png';
import { Link, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SettingsIcon from '@mui/icons-material/Settings';
import { getWebsiteConfig } from '@/pages/whiteLabel/themeFactory';
import { useState } from 'react';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(2)
}));

interface HideOnScrollProps {
  children: React.ReactElement;
}

function HideOnScroll(props: HideOnScrollProps) {
  const { children } = props;
  const trigger = useScrollTrigger({
    target: undefined
  });
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

interface TickieAppBarProps {
  canGoBack?: boolean;
  isTransparent?: boolean;
  isProfile?: boolean;
}

const TickieAppBar = ({ canGoBack, isTransparent, isProfile }: TickieAppBarProps) => {
  const [showMenuBar, setShowMenuBar] = useState(false);
  const navigate = useNavigate();
  const logo = getWebsiteConfig()?.logo;

  const data = [
    { name: 'Accueil', url: '/' },
    // { name: 'Events', url: '/events' },
    { name: 'Mes billets', url: '/my-tickets' },
    { name: 'Mon profil', url: '/profile' },
    { name: 'Publier mon événement', url: 'https://www.tickie.io/' }
  ];

  const renderKeyValuePairs = () => {
    const keyValuePairs: JSX.Element[] = [];

    data?.forEach(key => {
      keyValuePairs.push(
        <div key={key.name} className="md:h-full">
          <Link key={key.name} to={key.url} className="text-white cursor-pointer font-bold hover:text-blue-500 h-full">
            {key.name}
          </Link>
        </div>
      );
    });

    return keyValuePairs;
  };

  return (
    <>
      <SideAppBar showMenuBar={showMenuBar}>
        {data?.map(key => (
          <Link
            key={key.name}
            to={key.url}
            className="cursor-pointer text-white font-semibold hover:text-blue-500 text-2xl border-b border-gray-300 py-5"
          >
            {key.name}
          </Link>
        ))}
      </SideAppBar>
      <Box sx={{ flexGrow: 1, mb: 12 }}>
        <HideOnScroll>
          <AppBar elevation={0}>
            <StyledToolbar
              sx={{ alignItems: 'center', p: 2 }}
              className={`${!isProfile && ' justify-center '} md:justify-between`}
            >
              {canGoBack ? (
                <Link to="/">
                  <IconButton>
                    <ArrowBackIcon />
                  </IconButton>
                </Link>
              ) : (
                <Box display="flex">
                  <Stack direction="row">
                    <ButtonBase onClick={() => navigate('/home')}>
                      <Box>
                        <img src={logo} height={42} />
                      </Box>
                    </ButtonBase>
                  </Stack>
                </Box>
              )}
              {/* {isDesktop && (
              <Box display="flex" alignItems="center" ml={10} width="50%">
                <SearchBar showFilterIcon isInAppBar />
              </Box>
            )} */}{' '}
              <div className="hidden md:flex items-end justify-center space-x-12 h-full ">{renderKeyValuePairs()}</div>
              <Stack direction="row" spacing={2}>
                {isProfile && (
                  <IconButton onClick={() => navigate('/settings')}>
                    <SettingsIcon />
                  </IconButton>
                )}
                <div className="md:w-0 w-11" />
                <DesktopDrawer />
              </Stack>
              <img
                src={!showMenuBar ? '/hamburger.svg' : '/x.png'}
                onClick={() => {
                  setShowMenuBar(!showMenuBar);
                }}
                alt="hamburger"
                className="md:hidden absolute z-50 right-10 top-[30px] w-[25px] object-scale-down"
                style={{
                  width: showMenuBar ? '38px' : '25px',
                  marginTop: showMenuBar ? '-10px' : '0px',
                  position: showMenuBar ? 'fixed' : 'absolute'
                }}
              />
            </StyledToolbar>
            {/* {!isDesktop && (
              <Box sx={{ ml: { xs: 3, md: 6 }, mr: { xs: 3, md: 6 } }}>
                <SearchBar showFilterIcon />
              </Box>
            )} */}
          </AppBar>
        </HideOnScroll>
      </Box>
    </>
  );
};

interface SideAppBarProps {
  showMenuBar: boolean;
  children: any;
}
const SideAppBar: React.FC<SideAppBarProps> = ({ showMenuBar, children }) => {
  return (
    <div
      className={`w-full z-[1000] h-full'
      }  fixed top-0 right-0 left-0 bottom-0 bg-black`}
      style={{ transition: '.6s ease-in-out', transform: showMenuBar ? 'translateX(0%)' : 'translateX(100%)' }}
    >
      <div className="flex flex-col w-full justify-center items-center h-full -mt-20">{children}</div>
    </div>
  );
};
export default TickieAppBar;
