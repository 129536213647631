import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { Box, Button, CardActionArea, useMediaQuery } from '@mui/material';
import { Stack } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import format from 'date-fns/format';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { TicketEvent } from '../../services/api/ticket';
import { capitalize } from '../../utils/strings';
import Countdown from 'react-countdown';
import ticket from '../../assets/images/svgs/icon.svg';
import scan from '../../assets/images/svgs/scan.svg';
import position from '../../assets/images/svgs/position.svg';
import calendar from '../../assets/images/svgs/calendar.svg';
import { isAfter, isBefore } from 'date-fns';
import { t } from 'i18next';

interface UpcomingEventsCardProps {
  event: TicketEvent;
}

const UpcomingEventsCard = (props: UpcomingEventsCardProps) => {
  const { event } = props;
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  const defaultImg =
    'https://img.rawpixel.com/s3fs-private/rawpixel_images/website_content/px1331795-image-kwvw0m03.jpg?w=800&dpr=1&fit=default&crop=default&q=65&vib=3&con=3&usm=15&bg=F4F4F3&ixlib=js-2.2.1&s=b9b9885327b681c7c6eb3a0b77fd09ab';
  const navigate = useNavigate();

  const renderer = ({ hours, days, minutes, seconds, completed }: any) => {
    if (!completed)
      return (
        <div className=" z-10 py-6 h-[25px] w-[335px] mb-2 flex justify-evenly mt-0">
          <div className="flex flex-col justify-center items-center w-[4px]">
            <div className="font-bold text-lg z-10"> {days}</div>
            <div className=" text-gray-400 text-[10px]  z-10">JOURS</div>
          </div>
          <div className="flex flex-col justify-center items-center w-[4px]">
            <div className="font-bold text-lg z-10"> {hours}</div>
            <div className=" text-gray-400 text-[10px] z-10">HEURES</div>
          </div>
          <div className="flex flex-col justify-center items-center w-[4px] ">
            <div className="font-bold text-lg z-10"> {minutes}</div>
            <div className=" text-gray-400 z-10 text-[10px]">MINUTES</div>
          </div>
          <div className="flex flex-col justify-center items-center w-[4px]">
            <div className="font-bold text-lg z-10"> {seconds}</div>
            <div className=" text-gray-400 z-10 text-[10px]">SECONDES</div>
          </div>
        </div>
      );
    else
      return (
        <Box height={42}></Box>
        // <div className=" z-10 py-6 h-[25px] w-[335px] mb-2 flex justify-evenly mt-0 text-red-500 text-[15px]">
        //   Terminé
        // </div>
      );
  };

  const isEventRunning = isAfter(new Date(), new Date(event.beginAt)) && isBefore(new Date(), new Date(event.endAt));

  return (
    <Card sx={{ mx: { xs: 1, md: 5 }, mb: 2, width: { xs: '100%', sm: 340 } }} className="checkout-card group">
      <CardActionArea onClick={() => navigate(`/ticket-details/${event.uuid}`)}>
        <Stack direction="column" onClick={() => navigate(`/ticket-details/${event.uuid}`)}>
          <Box width={'100%'} height={'190px'} position="relative" overflow="hidden">
            <Box
              position="absolute"
              width={48}
              height={48}
              sx={{
                backgroundColor: 'white',
                color: 'black',
                borderRadius: '7px',
                top: 15,
                left: 15,
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)' // Add shadow here
              }}
            >
              <Stack
                direction="column"
                width={'100%'}
                textAlign={'center'}
                justifyContent={'center'}
                display={'flex'}
                alignItems={'center'}
              >
                <Typography variant="sm" fontWeight={700} mt={0.4} color="black" pb={0.2}>
                  {`${capitalize(format(new Date(event?.beginAt!), 'MMM'))}`}
                </Typography>
                <Box display={'flex'} alignItems={'baseline'}>
                  <Typography fontSize={22} mt={-1} fontWeight={800} color="black">
                    {`${capitalize(format(new Date(event?.beginAt!), 'dd'))}`}
                  </Typography>
                </Box>
              </Stack>
            </Box>
            <Box
              position="absolute"
              width={48}
              height={48}
              sx={{
                backgroundColor: 'white',
                color: 'black',
                borderRadius: '7px',
                top: 15,
                right: 15,
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.30)' // Add shadow here
              }}
            >
              <Stack
                direction="column"
                width={'100%'}
                textAlign={'center'}
                justifyContent={'center'}
                display={'flex'}
                alignItems={'center'}
              >
                <ConfirmationNumberIcon
                  sx={{
                    color: 'black',
                    width: 16
                  }}
                />
                <Box display={'flex'} mt={-0.5} alignItems={'baseline'}>
                  <Typography variant="xs" fontWeight={700} color="black" pb={0.2}>
                    x
                  </Typography>
                  <Typography variant="xl" fontWeight={800} color="black" pb={0.2}>
                    {event.tickets.length}
                  </Typography>
                </Box>
              </Stack>
            </Box>
            <img
              width={'100%'}
              height={'100%'}
              src={event?.cover || defaultImg}
              alt="event cover"
              style={{ objectFit: 'cover' }}
              className="transform transition duration-500 group-hover:scale-110 "
            />
          </Box>
        </Stack>
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'center'
          }}
          // height={235}
          direction="column"
          spacing={1}
          mt={0}
          width="100%"
        >
          <Stack direction="row" alignItems="end">
            <Typography
              mt={2}
              variant={event?.title.length > 30 ? 'md' : 'xl'}
              maxWidth="100%"
              fontWeight={800}
              textAlign="center"
            >
              {event.title?.toLocaleUpperCase()}&nbsp;
            </Typography>
            {/* <Typography variant="xl">{`#${nftId}`}</Typography> */}
          </Stack>
          <Box width={'100%'} px={2} display={'flex'} flexDirection={'column'} justifyContent={'start'}>
            <Box display={'flex'} height={'30px'} alignItems={'center'}>
              <Box mr={1} onClick={() => navigate(`/ticket-details/${event.uuid}`)}>
                <img src={position} alt="" />
              </Box>
              <Typography variant="md" fontWeight={600} textOverflow="ellipsis" noWrap>
                {event?.address?.name} {`, `} {event?.address?.city}
              </Typography>
            </Box>
            <Box display={'flex'} height={'30px'} alignItems={'center'}>
              <Box mr={1}>
                <img src={calendar} alt="calendar" />
              </Box>
              <Typography
                variant="md"
                color="white"
                fontWeight={600}
                textOverflow="ellipsis"
                noWrap
                sx={{ color: isEventRunning ? '#43d8fe' : '' }}
              >
                {isEventRunning ? "L'événement est en cours" : 'Début de l’événement dans'}
              </Typography>
            </Box>
          </Box>
          <Countdown date={new Date(event?.beginAt)} renderer={renderer} />
          <Box display={'flex'} width="100%" justifyContent={'space-evenly'} mt={'-5px'}>
            {event.tickets.some(e => !e.isOpened) ? (
              <>
                <Button
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    navigate(`/ticket-details/${event.uuid}`);
                  }}
                  variant="gradientTicket"
                  sx={{
                    width: '80%',
                    height: '40px'
                  }}
                >
                  <img src={ticket} alt="ticket" />

                  <Typography variant="md" color="white" textOverflow="ellipsis" noWrap>
                    {t('paymentSuccess.discoverTicket')}
                  </Typography>
                </Button>
              </>
            ) : (
              <Stack direction="row" alignItems="center" justifyContent="center" width="100%" spacing={1.5} mx={2}>
                <Button
                  onClick={() => navigate(`/ticket-details/${event.uuid}`)}
                  variant="gradientTicket"
                  sx={{
                    height: 40,
                    width: '100%'
                  }}
                >
                  <img src={ticket} alt="" />
                  <Typography variant="xs" color="white" textOverflow="ellipsis" noWrap>
                    {t('myTickets.myTickets')}
                  </Typography>
                </Button>
                <Button
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    navigate(`/ticket/event/${event.uuid}`);
                  }}
                  variant="ticketText"
                  sx={{
                    height: 40,
                    width: '100%'
                  }}
                >
                  <img src={scan} alt="scan" />
                  <Typography variant="xs" color="white" textOverflow="ellipsis" noWrap>
                    {t('myTickets.scan')}{' '}
                  </Typography>
                </Button>
              </Stack>
            )}
          </Box>
          <br />
        </Stack>
      </CardActionArea>
    </Card>
  );
};

export default UpcomingEventsCard;
