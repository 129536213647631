import { useNavigate } from 'react-router-dom';
import { HomeEvent } from '../../services/api/event';
import format from 'date-fns/format';
import { capitalize } from '../../utils/strings';
import { Box, Card, CardActionArea, CardContent, Stack, Typography } from '@mui/material';
import CustomOnDispayEventCardButton from '../CustomOnDispayEventCardButton';

export interface OnDispayEventCardProps {
  event: HomeEvent;
  isEvent?: boolean;
}

const CustomOnDispayEventCard = (props: OnDispayEventCardProps) => {
  const { event } = props;
  const navigate = useNavigate();

  const defaultImg =
    'https://img.rawpixel.com/s3fs-private/rawpixel_images/website_content/px1331795-image-kwvw0m03.jpg?w=800&dpr=1&fit=default&crop=default&q=65&vib=3&con=3&usm=15&bg=F4F4F3&ixlib=js-2.2.1&s=b9b9885327b681c7c6eb3a0b77fd09ab';

  return (
    <div>
      <Card
        onClick={() => navigate(props.isEvent ? `/event/${event.uuid}` : `/claim/${event.uuid}`)}
        className="checkout-card group shadow-xl"
        sx={{
          boxShadow: '0px 1px 1px 0px rgba(255, 255, 255, 0.20), 0px 0px 15px 0px rgba(255, 255, 255, 0.20)',
          maxWidth: 300,
          borderRadius: 1,
          // bgcolor: '#000',
          mb: 6
        }}
      >
        <CardActionArea>
          <Stack direction="column" height="100%">
            <Box pb={0} height={180} overflow="hidden">
              <img
                width="100%"
                src={event.cover || defaultImg}
                alt="event cover"
                className="transform transition duration-500 group-hover:scale-110"
                style={{ borderTopRightRadius: 8, borderTopLeftRadius: 8, objectFit: 'cover', aspectRatio: '5/3' }}
              />
            </Box>
            <CardContent>
              <Stack direction="column" justifyContent="space-between" alignItems="center" height={200}>
                <Stack direction="column">
                  <Typography
                    variant={event?.title.length > 30 ? 'md' : 'xl'}
                    maxWidth="100%"
                    fontWeight={800}
                    textAlign="center"
                  >
                    {event.title?.toUpperCase()}
                  </Typography>
                  <Typography
                    variant="xs"
                    noWrap
                    fontWeight={700}
                    color="#E10B17"
                    textAlign="center"
                    height={20}
                    mb={0.5}
                  >
                    {event.subTitle?.toUpperCase()}
                  </Typography>
                  <Typography variant="md" fontWeight={600} textAlign="center">
                    {`${capitalize(format(new Date(event?.beginAt!), 'dd MMMM YYY - HH:mm'))}`}
                  </Typography>
                  {event.address == null ? (
                    <Box height={30} />
                  ) : (
                    <Typography
                      my={1}
                      variant="lg"
                      color="#897E72"
                      noWrap
                      fontWeight={400}
                      width="95%"
                      textOverflow="ellipsis"
                      overflow="hidden"
                      textAlign="center"
                    >
                      {event.address.name?.toUpperCase()}
                    </Typography>
                  )}
                </Stack>
                <CustomOnDispayEventCardButton {...props} />
              </Stack>
            </CardContent>
          </Stack>
        </CardActionArea>
      </Card>
    </div>
  );
};

export default CustomOnDispayEventCard;
