const primary = {
  100: '#EEEDFE',

  200: '#BCB7FB',

  300: '#9B94F8',

  400: '#7970F6',

  500: '#e52321'
};

export default primary;
