import { Button, Stack } from '@mui/material';
import { DialogClose } from '@radix-ui/react-dialog';
import { t } from 'i18next';

interface TicketsSelectionButtonsProps {
  handleConfirmSelection: () => void;
  handleCancelTicketSelection: () => void;
  selectedTickets: number;
}
function TicketsSelectionButtons({
  handleConfirmSelection,
  handleCancelTicketSelection,
  selectedTickets
}: TicketsSelectionButtonsProps) {
  return (
    <>
      <div className="h-[50px]"></div>
      <Stack direction="row" spacing={3} justifyContent="center">
        <DialogClose asChild>
          <Button
            type="button"
            onClick={handleCancelTicketSelection}
            variant="contained"
            sx={{
              borderRadius: 3,
              height: 48,
              px: 4,
              width: {
                xs: '100%',
                md: '100%',
                '&:hover': {
                  backgroundColor: '#6b7280', // Change background color on hover
                  color: 'white' // Change text color on hover
                }
              }
            }}
            className="bg-gray-500 dark:bg-gray-800 text-lg font-bold leading-10"
          >
            {t('transfer.annulerUserFormButton')}
          </Button>
        </DialogClose>
        <Button
          type="submit"
          variant="contained"
          disabled={selectedTickets === 0}
          onClick={handleConfirmSelection}
          sx={{
            borderRadius: 3,
            height: 48,
            px: 4,
            background: 'linear-gradient(90deg, #27D4FF 0%, #584CF4 100%)',
            '&:hover': {
              background: 'linear-gradient(90deg, #27D4FF 0%, #584CF4 100%)'
            },
            width: { xs: '100%', md: '100%' }
          }}
          className="text-lg font-bold leading-10"
        >
          {t('transfer.suivantUserFormButton')}
        </Button>
      </Stack>
      <div className="h-[10px]"></div>
    </>
  );
}

export default TicketsSelectionButtons;
