import Header from '../components/Header';
import Hero from '../../CustomHomeNancy/components/Hero';
import Main from '../components/Main';
import Faq from '../components/Faq';
import { Alert, Box, Snackbar, ThemeProvider } from '@mui/material';
import data from './asNancy.json';
import Footer from '../components/Footer';
import { useAppSelector } from '../../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import userApi from '../../../services/api/user';
import LoginModal from '../components/LoginModal';
import { getClubThemeFromHostname } from '../../whiteLabel/themeFactory';
import invitationApi from '@/services/api/invitation';
import Rewards from '../components/Rewards';

function AsNancyHome() {
  const eventId =
    import.meta.env.VITE_NODE_ENV === 'production'
      ? 'e63dd483-0bc9-4d8a-a37f-3965fcdb3482'
      : '72d2cd89-b0c0-45fc-b487-ecc80f1d86c5';
  //
  const { isAuth } = useAppSelector(state => state.authSlice);
  const navigate = useNavigate();
  const [claimCollectorTicket, { isLoading: isLoadingClaimCollectible }] =
    invitationApi.endpoints.claimCollectorTicket.useMutation();
  const [openErrorSnackbar, setErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { data: user } = userApi.endpoints.getUser.useQueryState();

  const [openLoginModal, setOpenLoginModal] = useState(false);
  const handleOpenLoginModal = () => setOpenLoginModal(true);
  const handleCloseLoginModal = () => setOpenLoginModal(false);

  const handleSubmit = async () => {
    try {
      await claimCollectorTicket(eventId).unwrap();
      navigate(`/ticket-details/${eventId}`);
    } catch (error: any) {
      setErrorMessage(error.data.message);
      setErrorSnackbar(true);
    }
  };

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorSnackbar(false);
  };

  return (
    <Box>
      <ThemeProvider theme={getClubThemeFromHostname(false)}>
        <LoginModal handleCloseLoginModal={handleCloseLoginModal} openLoginModal={openLoginModal} eventId={eventId} />
      </ThemeProvider>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: '100%', minHeight: 60, justifyContent: 'center', alignItems: 'center' }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <Header logo={data.logo} />
      <Hero
        eventId={eventId}
        title={data.title}
        subtitle={data.subtitle}
        image={data.image}
        mintLoading={isLoadingClaimCollectible}
        onClickMintButton={() => {
          if (!isAuth) {
            handleOpenLoginModal();
          } else {
            handleSubmit();
          }
        }}
        showMintButton={true}
      />
      <Rewards
        rewards={data.rewards}
        legend={data.legend}
        onClickMintButton={() => {
          if (!isAuth) {
            handleOpenLoginModal();
          } else {
            handleSubmit();
          }
        }}
      />
      <Main
        steps={data.steps}
        onLogin={() => {
          if (!isAuth) {
            handleOpenLoginModal();
          }
        }}
        onClickMintButton={() => {
          if (!isAuth) {
            handleOpenLoginModal();
          } else {
            handleSubmit();
          }
        }}
      />
      <Faq faq={data.faq} />
      <Footer logo={data.logo} />
    </Box>
  );
}

export default AsNancyHome;
