interface EventProps {
  coverImage: string;
  eventName: string;
}
function ModalEvent({ coverImage, eventName }: EventProps) {
  return (
    <div className="flex flex-row justify-center items-center w-full mt-0 mb-0">
      <div className="flex-grow justify-start mt-0 mb-0">
        <img src={coverImage} alt="Your Image" className="rounded-lg w-20 h-auto shadow-lg" />
      </div>
      <div className="flex-grow">
        <h3>{eventName}</h3>
      </div>
    </div>
  );
}

export default ModalEvent;
