import { useNavigate } from 'react-router-dom';
import format from 'date-fns/format';
import { capitalize } from '../../utils/strings';
import { Box, Card, CardActionArea, Chip, Stack, Typography } from '@mui/material';
import { Ticket, TicketEvent } from '../../services/api/ticket';
import useTokenUri from '../../hooks/useTokenUri';
import noReveal from '../../assets/images/card/no-reveal.png';
import palette from '../../themes/default/colors/palette';
import { CalendarMonth } from '@mui/icons-material';
import rarityTextStyle from '../../utils/rarityTextColor';
import NftRarityName from '../../pages/EventDetails/components/NftRarityName';
import classnames from 'classnames';

export interface TicketCollectionCardProps {
  event: TicketEvent;
  ticket: Ticket;
}

const TicketCollectionCard = (props: TicketCollectionCardProps) => {
  const { event } = props;
  const navigate = useNavigate();
  const { nftImage } = useTokenUri({ ticket: props.ticket });

  const rarityColor = rarityTextStyle(props.ticket.rarity).color;
  return (
    <div>
      <Card
        onClick={() => navigate(`/ticket-details/${props.event.uuid}`)}
        className="group checkout-card"
        sx={{
          boxShadow: '0px 1px 1px 0px rgba(255, 255, 255, 0.10), 0px 0px 15px 0px rgba(255, 255, 255, 0.10)',
          borderRadius: 3,
          bgcolor: '#000'
        }}
      >
        <CardActionArea>
          <Stack direction="column" height="100%">
            <Box p={2} height={230} overflow="hidden" position="relative" className="bg-black">
              <img
                width="100%"
                height="100%"
                src={nftImage || noReveal}
                alt="event cover"
                className="transform transition duration-500 group-hover:scale-110 "
                style={{
                  borderTopRightRadius: 8,
                  borderTopLeftRadius: 8,
                  objectFit: 'contain',
                  aspectRatio: '5/3',
                  position: 'relative'
                }}
              />{' '}
              <Chip
                label={`#${props.ticket.nftId}`}
                size="small"
                sx={{
                  position: 'absolute',
                  bottom: 8,
                  left: 8,
                  fontWeight: 600,
                  color: props.ticket.isOpened ? rarityColor : '#FFF'
                }}
              />
            </Box>
            <Stack direction="column" justifyContent="space-between" alignItems="left" height={70} px={2} pt={1}>
              <Stack direction="column">
                <Typography
                  variant={event?.title.length > 18 ? 'md' : 'xl'}
                  maxWidth="100%"
                  fontWeight={800}
                  noWrap
                  overflow="ellipsis"
                  textAlign="left"
                >
                  {event.title?.toUpperCase()}
                </Typography>
                <Stack direction="row" alignItems="center" spacing={0.5} ml={-0.25}>
                  <CalendarMonth
                    sx={{ height: 16, width: 16, color: props.ticket.isOpened ? rarityColor : undefined }}
                  />
                  <Typography
                    variant="md"
                    fontWeight={600}
                    pt={0.25}
                    textAlign="left"
                    sx={{ color: props.ticket.isOpened ? rarityColor : undefined }}
                  >
                    {`${capitalize(format(new Date(event?.beginAt!), 'dd MMMM YYY'))}`}
                  </Typography>{' '}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </CardActionArea>
      </Card>
    </div>
  );
};

export default TicketCollectionCard;
