import { IconButton, Typography, Box, Button, CircularProgress } from '@mui/material';
import { Stack } from '@mui/system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import TicketBody from './TicketBody';
import TransferDetailModal from './TransferDetailModal';
import EventBody from './EventBody';
import { useState } from 'react';
import VerifiedTransferModal from './VerifiedTransferModal';
import eventApi from '@/services/api/event';
import ticketApi from '@/services/api/ticket';
import ModalBody from './ModalBody';
import EditButton from './EditButton';
import PersonImage from './PersonImage';
import TabBar from './TabBar';
import { Transition } from 'react-transition-group';
function TicketsSelection() {
  const [open, setOpen] = useState(false);
  const [params, setParams] = useSearchParams();
  const { eventId } = useParams();
  const [selectedTickets, setSelectedTickets] = useState<{ id: number; uuid: string }[]>([]);
  //
  const [activeTab, setActiveTab] = useState<'mesBillets' | 'enTransfers'>('mesBillets');

  const [exitTransfer, { isLoading: exitTransferring, isSuccess: isSuccess }] =
    ticketApi.endpoints.exitTicketTransfer.useMutation();
  const exitTran = async () => {
    const uuids = selectedTickets.map(ticket => ticket.uuid);
    const exitTransferBody = {
      ticketUuids: uuids,
      eventUuid: eventId! // Array of ticket UUIDs to transfer
    };
    console.log(exitTransfer);

    const reponse = await exitTransfer(exitTransferBody).unwrap();
    if (reponse) {
      navigate(`/cancel-verified`);
    }
  };

  const handleTabChange = (tab: 'mesBillets' | 'enTransfers') => {
    setActiveTab(tab);
  };

  const handleTicketSelect = (ticketId: number, ticketUuid: string) => {
    setSelectedTickets(prevState => {
      // Check if ticket is already selected
      const index = prevState.findIndex(ticket => ticket.id === ticketId);
      if (index !== -1) {
        // Deselect ticket if already selected
        return prevState.filter(ticket => ticket.id !== ticketId);
      } else {
        // Select ticket if not selected
        return [...prevState, { id: ticketId, uuid: ticketUuid }];
      }
    });
  };

  //const { data: event } = eventApi.endpoints.getEventDetails.useQuery(eventId ?? '');
  const {
    data: event,
    isLoading,
    isError
  } = ticketApi.endpoints.getTicketDetails.useQuery(eventId!, { skip: !eventId });
  const firstName = params.get('firstName');
  const lastName = params.get('lastName');
  const email = params.get('email');
  console.log(event?.tickets);
  const navigate = useNavigate();
  return (
    <div className="flex flex-col flex-grow">
      <Stack direction="row" zIndex="tooltip" position="absolute" left={0} top={0} m={1} alignItems="center">
        <div onClick={() => navigate(`/transfer-details/${eventId}`)}>
          <IconButton>
            <ArrowBackIcon color="primary" />
          </IconButton>
        </div>
        <Typography variant="h6" textAlign="center">
          Transférer mes billets
        </Typography>
      </Stack>
      <div className="flex flex-col w-full justify-center items-center">
        <div className="flex flex-col justify-center mt-20 mb-20 md:w-2/3">
          {event?.event.cover ? (
            <EventBody coverImage={event?.event.cover} eventName={event?.event.title} />
          ) : (
            <CircularProgress />
          )}
          <div className="p-5">
            <div className="flex flex-row justify-center items-center w-full">
              <div className="flex-grow">
                <h3>Détail du receveur</h3>
              </div>
              <EditButton
                handleClick={() =>
                  navigate(`/transfer-details/${eventId}?firstName=${firstName}&lastName=${lastName}&email=${email}`)
                }
              />
            </div>
            <div className="flex flex-row justify-center items-center w-full">
              <PersonImage width="50px" height="50px" />
              <div className="flex flex-col flex-grow justify-start ml-3">
                <h3 className="mt-0 mb-0">
                  {firstName} {lastName}
                </h3>
                <p className="mt-0 mb-0 text-gray-500">{email}</p>
              </div>
            </div>
          </div>
          <div className="container w-full mx-auto">
            <TabBar activeTab={activeTab} onTabChange={handleTabChange} />
            {activeTab === 'mesBillets' &&
              event?.tickets.map(
                ticket =>
                  ticket.status !== 'IN_TRANSFER' &&
                  ticket.status !== 'TRANSFER_APPROVED' && (
                    <TicketBody
                      isVisible={activeTab === 'mesBillets'}
                      key={ticket.id}
                      tokenUri={ticket.tokenUri}
                      ticketId={ticket.id}
                      ticketUuid={ticket.uuid}
                      status={ticket.status}
                      onSelect={handleTicketSelect}
                    />
                  )
              )}
            {activeTab === 'enTransfers' &&
              event?.tickets.map(
                ticket =>
                  ticket.status == 'IN_TRANSFER' && (
                    <TicketBody
                      isVisible={activeTab === 'enTransfers'}
                      key={ticket.id}
                      tokenUri={ticket.tokenUri}
                      ticketId={ticket.id}
                      ticketUuid={ticket.uuid}
                      status={ticket.status}
                      onSelect={handleTicketSelect}
                    />
                  )
              )}
          </div>
          <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: 'transparent' }}>
            <Stack direction="row" spacing={3} justifyContent="center" mx={2} my={1.5}>
              <Button
                onClick={() => /*navigate(`/ticket-details/${eventId}`)*/ console.log(selectedTickets)}
                variant="contained"
                sx={{ borderRadius: 3, height: 48, px: 4, width: { xs: '100%', md: 'auto' } }}
                className="bg-[#181632]"
              >
                <Typography variant="lg" fontWeight={700} className="leading-10">
                  Annuler
                </Typography>
              </Button>
              <Button
                onClick={() => setOpen(true)}
                variant="contained"
                sx={{ borderRadius: 3, height: 48, px: 4, width: { xs: '100%', md: 'auto' } }}
              >
                <Typography variant="lg" fontWeight={700} className="leading-10">
                  Suivant
                </Typography>
              </Button>
            </Stack>
          </Box>
          <TransferDetailModal open={open} onClose={() => setOpen(false)}>
            {event && eventId && activeTab === 'mesBillets' && (
              <ModalBody
                onClose={() => setOpen(false)}
                selectedTickets={selectedTickets}
                coverImage={event.event.cover}
                eventName={event.event.title}
                eventUuid={eventId}
                firstName={firstName ?? ''}
                lastName={lastName ?? ''}
                email={email ?? ''}
              />
            )}
            {activeTab === 'enTransfers' && (
              <div className="flex flex-col items-center justify-center">
                <h3>Êtes-vous certain de vouloir annuler le transfert ?</h3>
                <Button
                  onClick={exitTran}
                  variant="contained"
                  sx={{ borderRadius: 3, height: 48, px: 4, width: { xs: '100%', md: 'auto' } }}
                  className="text-lg font-bold leading-10"
                >
                  Confirmer
                </Button>
                {exitTransferring && <CircularProgress />}
              </div>
            )}
            {/* <VerifiedTransferModal /> */}
          </TransferDetailModal>
        </div>
      </div>
    </div>
  );
}

export default TicketsSelection;
