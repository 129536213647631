import { Box, Typography, useMediaQuery } from '@mui/material';
import bg from '/BookADemo.png';
import '/fonts/SpaceGrotesk-VariableFont_wght.ttf';

const Contact = () => {
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.up('sm'));

  return (
    <Box
      className="custom-font-text"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        px: isDesktop ? ' 100px' : '10px',
        fontFamily: 'Space Grotesk',
        fontStyle: 'normal',
        marginTop: '150px'
      }}
    >
      <Box
        width={'100%'}
        sx={{
          backgroundImage: `url(${bg})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderRadius: '24px',
          padding: isDesktop ? ' 52px' : '18px',
          textAlign: 'center'
        }}
      >
        <Typography
          variant="h4"
          className="custom-font-text"
          sx={{
            marginTop: isDesktop ? 'unset' : '15px',
            fontSize: isDesktop ? 'unset' : '25px'
          }}
        >
          BESOIN DE FAIRE PASSER VOS ÉVÉNEMENTS À UN NIVEAU SUPÉRIEUR ?
        </Typography>
        <br />

        <Typography
          variant={'h6'}
          className="custom-font-text"
          sx={{
            fontFamily: 'Helvetica ! important',
            fontSize: isDesktop ? 'unset' : '12px'
          }}
        >
          Tirez parti des nouvelles technologies pour proposer à vos clients une expérience innovante et sur mesure en
          fonction de vos besoins
        </Typography>
        <br />
        <br />
        <Box
          sx={{
            display: 'flex',
            justifyContent: !isSmall ? 'center' : 'center',
            width: '100%'
          }}
        >
          <Box
            className="custom-font-text"
            height={60}
            width={!isSmall ? 170 : 260}
            onClick={() => window.open('https://tally.so/r/3laxBV', '_blank')}
            sx={{
              backgroundColor: '#D0121C',
              color: '#fff',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: '20px',
              fontWeight: 'bold',
              cursor: 'pointer',
              fontSize: isSmall ? 17 : '16px',
              background: 'linear-gradient(46deg, #FC3D7D -16.49%, #FFCCB3 74.95%)',
              border: '2px solid var(rgba(255, 255, 255, 0.47))'
            }}
          >
            PARLONS-EN
          </Box>
          <Box
            className="custom-font-text"
            height={60}
            width={!isSmall ? 170 : 260}
            onClick={() => window.open('https://www.tickie.io/fr', '_blank')}
            sx={{
              backgroundColor: 'transparent',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontWeight: 'bold',
              cursor: 'pointer',
              fontSize: isSmall ? 17 : '16px',
              border: '2px solid  rgba(255, 255, 255, 0.47)',
              background: 'linear-gradient(46deg, rgba(0, 144, 255, 0.30) -16.49%, rgba(98, 67, 255, 0.30) 74.95%)'
            }}
          >
            NOS SOLUTIONS{' '}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Contact;
