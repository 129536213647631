/* eslint-disable camelcase */

import baseApi from './base';
import { TicketCategory } from './ticket-category';

export type PromoCodeParams = {
  eventUuid: string;
  code: string;
  ticketCategoriesUuids?: string[];
};

export enum PromoCodeType {
  PERCENTAGE_OFF,
  FIXED_AMOUNT_OFF
}

export type Invitation = {
  id: number;
  uuid: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phone: string | null;
  code: string | null;
  quantity: number | null;
  roles: 'ROLE_DEV' | 'ROLE_ADMIN' | 'ROLE_MANAGER' | 'ROLE_USER';
  type: 'EVENT' | 'SUBSCRIPTION' | 'ORGANIZATION';
  requestType: 'EMAIL' | 'PHONE' | 'CODE';
  updatedAt: Date;
  createdAt: Date;
  deletedAt: Date | null;
  status: 'TO_SEND' | 'SENT' | 'EXPIRED' | 'CLAIMED' | 'REJECTED';
  sendAt: Date | null;
  expiredAt: Date | null;
  organizationId: number;
  assignedById: number;
  assignedToId: number | null;
  eventId: number | null;
  ticketCategoryId: number | null;
  subscriptionId: number | null;
  ticketCategory: TicketCategory;
};

const invitationApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getInvitation: builder.query<Invitation, string>({
      query: uuid => ({
        method: 'GET',
        url: `/invitations/${uuid}`
      })
    }),
    acceptInvitation: builder.mutation<Invitation, string>({
      query: uuid => ({
        method: 'POST',
        url: `/invitations/${uuid}/accept`
      })
    }),
    claimCollectorTicket: builder.mutation<Invitation, string>({
      query: uuid => ({
        method: 'POST',
        url: `/invitations/${uuid}/claim-collectible`
      })
    }),
    getClaimableCollectibles: builder.query<Invitation, string>({
      query: eventUuid => ({
        method: 'GET',
        url: `/invitations/claimable-collectibles/${eventUuid}`
      }),
      providesTags: ['claimable-collectibles']
    })
  })
});

export default invitationApi;
