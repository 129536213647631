import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import { Subscription, UserSubscription } from '../../services/api/subscription';
import { Box, Button, Card, CardActionArea, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { capitalize } from '../../utils/strings';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import greyScale from '../../themes/default/colors/greyscale';

interface UserSubscriptionCardProps {
  userSubscription: UserSubscription;
}

const UserSubscriptionCard = (props: UserSubscriptionCardProps) => {
  const { userSubscription } = props;
  const { darkTheme } = useAppSelector(state => state.themeSlice);
  const navigate = useNavigate();

  return (
    <>
      <Card sx={{ mx: { xs: 1, md: 5 }, mb: 2, width: { xs: '100%', sm: 340 } }} className="checkout-card group">
        <CardActionArea onClick={() => navigate(`/ticket/subscription/${userSubscription.uuid}`)}>
          <Stack direction="column" onClick={() => navigate(`/ticket/subscription/${userSubscription.uuid}`)}>
            <Box width={'100%'} height={'190px'} position="relative" overflow="hidden">
              <Box
                position="absolute"
                width={48}
                height={48}
                sx={{
                  backgroundColor: 'white',
                  color: 'black',
                  borderRadius: '7px',
                  top: 15,
                  left: 15,
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)' // Add shadow here
                }}
              >
                <Stack
                  direction="column"
                  width={'100%'}
                  textAlign={'center'}
                  justifyContent={'center'}
                  display={'flex'}
                  alignItems={'center'}
                >
                  <Typography variant="sm" fontWeight={700} mt={0.4} color="black" pb={0.2}>
                    {userSubscription?.subscription?.startDate &&
                      `${capitalize(format(new Date(userSubscription?.subscription?.startDate), 'MMM'))}`}
                  </Typography>
                  <Box display={'flex'} alignItems={'baseline'}>
                    <Typography fontSize={22} mt={-1} fontWeight={800} color="black">
                      {userSubscription?.subscription?.endDate &&
                        `${capitalize(format(new Date(userSubscription?.subscription?.endDate), 'dd'))}`}
                    </Typography>
                  </Box>
                </Stack>
              </Box>

              <img
                width={'100%'}
                height={'100%'}
                src={userSubscription?.subscription?.cover || ''}
                alt="event cover"
                style={{ objectFit: 'cover' }}
                className="transform transition duration-500 group-hover:scale-110 "
              />
            </Box>
          </Stack>
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
            // height={235}
            direction="column"
            spacing={1}
            minHeight={236}
            mt={0}
            width="100%"
          >
            <div>
              <Typography variant="h6" mt={2} textAlign="center" color={greyScale[500]}>
                Abonnement
              </Typography>
              <Stack direction="row" textAlign="center">
                <Typography variant="h5" mt={2}>
                  {userSubscription?.subscription.name}&nbsp;
                </Typography>
                {/* <Typography variant="xl">{`#${nftId}`}</Typography> */}
              </Stack>
            </div>

            <Box display={'flex'} width="100%" justifyContent={'space-evenly'} mt={'-5px'} pb={2}>
              <Button
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  navigate(`/ticket/subscription/${userSubscription?.uuid}`);
                }}
                variant="ticketText"
                sx={{
                  width: '118px',
                  height: '40px'
                }}
              >
                {/* <img src={scan} alt="scan" /> */}
                <Typography variant="xs" color="white" textOverflow="ellipsis" noWrap>
                  Scanner{' '}
                </Typography>
              </Button>
            </Box>
          </Stack>
        </CardActionArea>
      </Card>
    </>
  );
};

export default UserSubscriptionCard;
