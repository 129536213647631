import HomeEventList from './components/HomeEventList';
import CustomAppBar from '../../components/CustomAppBar';
import { useNavigate } from 'react-router-dom';
import { getWebsiteConfig } from '../whiteLabel/themeFactory';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useState } from 'react';
import SubscriptionList from '../SubscriptionHome/components/SubscriptionList';
import EventPoster from './components/EventPoster';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

function CustomHome() {
  const partners = getWebsiteConfig().partners;

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const PartnersWall = () => {
    return (
      <div className="w-full bg-white text-center text-[#000033]">
        <br />
        <br />
        <div className="font-bold text-2xl mt-50">Ils nous font confiance</div>
        <div className="font-bold text-3xl sm:text-4xl mx-4 md:text-5xl mt-2 ">Nos partenaires officiels</div>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-8 gap-4 md:gap-2 lg:gap-4  mx-8 my-20">
          {partners?.map((p, index) => (
            <div key={index} className="flex justify-center items-center h-30">
              <img src={p.img} alt={`Logo ${index}`} className="max-h-16 md:max-h-20 lg:max-h-30 m-8" />
            </div>
          ))}
        </div>
        <br />
        <br />
      </div>
    );
  };

  return (
    <>
      <CustomAppBar />
      <EventPoster isCustomHome />
      <div className="w-full h-full ">
        <div className="mx-0">
          <Box
            alignItems="center"
            justifyContent="center"
            width="100%"
            display="flex"
            mb={3}
            sx={{ borderBottom: 1, borderColor: 'divider' }}
          >
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Les prochains matchs" {...a11yProps(0)} sx={{ height: 64 }} />
              <Tab label="Abonnements" {...a11yProps(1)} />
            </Tabs>
          </Box>
          {/* <h1 className="text-2xl mt-10 md:text-4xl ">Les prochains matchs 🏟️</h1> */}
          <CustomTabPanel value={value} index={0}>
            <HomeEventList isEvent />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <SubscriptionList isEvent />
          </CustomTabPanel>
          <br />
          <br />
          <br />
        </div>
        <PartnersWall />
      </div>
    </>
  );
}

export default CustomHome;
