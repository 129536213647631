import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { Ticket, TicketEventDetails } from '../../../services/api/ticket';
import palette from '../../../themes/default/colors/palette';
import DescriptionIconDecoration from '../../../components/DescriptionIconDecoration';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { format } from 'date-fns';
import AddToCalendarButton from '../../EventDetails/components/AddToCalendarButton';
import RoomIcon from '@mui/icons-material/Room';
import TurnSlightRightIcon from '@mui/icons-material/TurnSlightRight';
import { useEffect, useState } from 'react';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import NftRarityName from '../../EventDetails/components/NftRarityName';
import rarityTextStyle from '../../../utils/rarityTextColor';
import { t } from 'i18next';
import CropFreeIcon from '@mui/icons-material/CropFree';
import { capitalize } from '../../../utils/strings';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import Markdown from 'react-markdown';

interface DetailsCardProps {
  tickets: TicketEventDetails;
  setAnimationOpen: any;
  revealNft: any;
  activeSlide: number;
}

function DetailsCard({ tickets, setAnimationOpen, revealNft, activeSlide }: DetailsCardProps) {
  return (
    <Stack
      direction="column"
      mt={0}
      m={3}
      width="100%"
      borderRadius={2}
      sx={{
        maxWidth: {
          md: 450
        },
        p: 2
      }}
      className="ticket-infos-card"
    >
      <Stack direction="row" mt={1}>
        <DescriptionIconDecoration>
          <CalendarMonthIcon />
        </DescriptionIconDecoration>
        <Stack direction="column" pl={2} spacing={0.5}>
          <Typography variant="xl" fontWeight={700}>{`${capitalize(
            format(new Date(tickets.event.beginAt), 'eeee dd MMMM yyyy')
          )}`}</Typography>
          <Typography variant="md">{`${format(new Date(tickets.event.beginAt), "HH'h'mm")} - ${format(
            new Date(tickets.event.endAt),
            "HH'h'mm (OOOO)"
          )}`}</Typography>
          <AddToCalendarButton
            title={tickets.event.title}
            beginAt={tickets.event.beginAt}
            endAt={tickets.event.endAt}
          />
        </Stack>
      </Stack>

      <Box m={2} mx={-2}>
        <Divider />
      </Box>

      <Stack direction="row">
        <DescriptionIconDecoration>
          <RoomIcon />
        </DescriptionIconDecoration>
        <Stack direction="column" pl={2} spacing={0.5}>
          <Typography
            variant="xl"
            fontWeight={700}
          >{`${tickets.event.address?.name}, ${tickets.event?.address?.city}`}</Typography>
          <Typography variant="md">{`${tickets.event?.address?.street}, ${tickets.event?.address?.zipCode}, ${tickets.event?.address?.city}`}</Typography>
          <Button
            variant="outlined"
            size="small"
            startIcon={<TurnSlightRightIcon />}
            onClick={() =>
              window.open(
                `https://www.google.com/maps?daddr=${tickets.event?.address?.lat},${tickets.event?.address?.lng}&mode=driving`
              )
            }
            sx={{ justifyContent: 'left', pr: 3 }}
            className="whitespace-nowrap flex justify-start pl-3 text-neutral-200 rounded-full w-min"
          >
            {t('event.itenerary')}
          </Button>
        </Stack>
      </Stack>

      <Box m={2} mx={-2}>
        <Divider />
      </Box>

      <RewardSection
        ticket={tickets.tickets[activeSlide]}
        openTicket={() => {
          setAnimationOpen(true);
          revealNft(tickets.tickets[activeSlide].uuid, tickets.event.uuid);
        }}
      />

      <Box m={2} mx={-2}>
        <Divider />
      </Box>

      <Stack direction="row">
        <DescriptionIconDecoration>
          <CropFreeIcon />
        </DescriptionIconDecoration>
        <Stack direction="column" pl={2} spacing={0.5}>
          <Typography variant="xl" fontWeight={700}>
            Scanné : {tickets.tickets[activeSlide].isScanned ? 'OUI' : 'NON'}
          </Typography>
          <Typography variant="md">
            Récompense récupérée : {tickets.tickets[activeSlide].rewardScanned ? 'OUI' : 'NON'}
          </Typography>
        </Stack>
      </Stack>

      <Box m={2} mx={-2}>
        <Divider />
      </Box>
      <Stack direction="row">
        <DescriptionIconDecoration>
          <HelpCenterIcon />
        </DescriptionIconDecoration>
        <Stack direction="column" pl={2} spacing={0.5}>
          <Typography variant="xl" fontWeight={700}>
            {t('myTickets.needHelp')}
          </Typography>
          <Typography variant="md">{t('myTickets.needHelpSentence')}</Typography>
          <Box>
            <Button
              variant="text"
              size="small" // startIcon={<HelpCenterIcon />}
              href="https://tickieapp.notion.site/FAQ-3a1ebb1e6b47402a9b5dc9751191b17a"
            >
              {t('myTickets.heplCenter')}
            </Button>
          </Box>
        </Stack>
      </Stack>
      <Box height={24} />
    </Stack>
  );
}

interface RewardSectionProps {
  ticket: Ticket;
  openTicket: () => void;
}

const RewardSection = (props: RewardSectionProps) => {
  const { openTicket, ticket } = props;

  const [nftImage, setNftImage] = useState<string>();
  const [nftMeataData, setNftMeataData] = useState<any>();
  const [reward, setReward] = useState<string>();

  const getNfts = async () => {
    const tokenUri = props.ticket.tokenUri;
    if (tokenUri) {
      const res = await fetch(tokenUri);
      const metadata = await res.json();
      setNftImage(metadata.image);
      setNftMeataData(metadata);
      const rarity = metadata?.attributes?.find((e: any) => e.trait_type === 'rarity')?.value.toString() ?? '';
      const reward = metadata?.attributes?.find((e: any) => e.trait_type === 'rewards')?.value.toString() ?? '';
      setReward(reward);
    }
  };

  useEffect(() => {
    if (props.ticket.isOpened) {
      getNfts();
    }
  }, [props.ticket]);

  return (
    <Stack direction="row">
      <DescriptionIconDecoration>
        <ConfirmationNumberIcon />
      </DescriptionIconDecoration>
      <Stack direction="column" pl={2} spacing={0.5}>
        <Typography variant="xl" fontWeight={700}>
          Tickie Taka
        </Typography>

        {ticket.isOpened ? (
          <>
            <NftRarityName rarity={ticket.rarity} />
            {ticket.rarity !== 'COMMON' && (
              <Typography variant="md" color={rarityTextStyle(ticket.rarity)?.color}>
                <Markdown>{reward}</Markdown>
              </Typography>
            )}
          </>
        ) : (
          // <Typography variant="md">Votre récompense : </Typography>
          <>
            <Typography variant="md">{t('myTickets.discoverTicketSentence')}</Typography>
            <Box>
              <Button
                variant="gradient"
                size="small"
                startIcon={<ConfirmationNumberIcon />}
                onClick={() => {
                  props.openTicket();
                  // setAnimationOpen(true);
                  // revealNft(tickets.tickets[activeSlide].uuid, tickets.event.uuid);
                }}
              >
                {t('myTickets.discoverTicket')}
              </Button>
            </Box>
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default DetailsCard;
