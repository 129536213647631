import { Stack, Typography, useMediaQuery } from '@mui/material';
import palette from '../../../themes/default/colors/palette';
import greyScale from '../../../themes/default/colors/greyscale';
import { TicketEventDetails } from '../../../services/api/ticket';

interface CategoryCardProps {
  tickets: TicketEventDetails;
  activeSlide: number;
}

const CategoryCard = ({ tickets, activeSlide }: CategoryCardProps) => {
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      p={2}
      m={3}
      mt={0}
      mb={0}
      width="100%"
      sx={{ maxWidth: { md: 450 }, p: 2 }}
      className="ticket-infos-card"
    >
      <Stack direction="row" justifyContent="space-around" width="100%">
        <Stack direction="column">
          <Typography variant="md" color={greyScale[500]} textAlign={'center'}>
            Catégorie
          </Typography>
          <Typography
            variant={
              tickets.tickets[activeSlide]?.ticketCategory?.name &&
              (tickets.tickets[activeSlide]?.ticketCategory?.name ?? 'BILLET').length > 15
                ? 'lg'
                : 'xl'
            }
            fontWeight={700}
            maxWidth={150}
            textAlign="center"
          >
            {tickets.tickets[activeSlide]?.ticketCategory?.name ?? 'BILLET'}
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="md" color={greyScale[500]} textAlign={'center'}>
            Tarif
          </Typography>
          <Typography
            variant={
              tickets.tickets[activeSlide]?.ticketCategory?.name &&
              (tickets.tickets[activeSlide]?.ticketCategory?.name ?? 'BILLET').length > 15
                ? 'lg'
                : 'xl'
            }
            fontWeight={700}
            textAlign={'center'}
            maxWidth={150}
          >
            Tarif Normal
          </Typography>
        </Stack>
      </Stack>
      {false ? (
        <Stack direction="row" justifyContent="space-around" width="100%" pt={2}>
          <Stack>
            <Typography variant="sm" color="gray" textAlign={'center'}>
              Tarif
            </Typography>
            <Typography variant="xl" textAlign={'center'} fontWeight={700}>
              H{' '}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="sm" color="gray" textAlign={'center'}>
              Rang
            </Typography>
            <Typography variant="xl" textAlign={'center'} fontWeight={700}>
              4{' '}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="sm" color="gray" textAlign={'center'}>
              Place
            </Typography>
            <Typography variant="xl" textAlign={'center'} fontWeight={700}>
              12{' '}
            </Typography>
          </Stack>
        </Stack>
      ) : null}
    </Stack>
  );
};

export default CategoryCard;
