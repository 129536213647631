import React, { useState, useEffect } from 'react';
import * as Progress from '@radix-ui/react-progress';

interface ProgressBarProps {
  progress: number;
}
const ProgressBar = ({ progress }: ProgressBarProps) => {
  return (
    <Progress.Root
      className="relative overflow-hidden bg-gray-500 dark:bg-gray-800 rounded-full w-4/5 h-[5px]"
      style={{
        // Fix overflow clipping in Safari
        // https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0
        transform: 'translateZ(0)'
      }}
      value={progress}
    >
      <Progress.Indicator
        className="bg-[#27D4FF] w-full h-full transition-transform duration-[660ms] ease-[cubic-bezier(0.65, 0, 0.35, 1)]"
        style={{ transform: `translateX(-${100 - progress}%)` }}
      />
    </Progress.Root>
  );
};

export default ProgressBar;
