import { CountdownRenderProps } from 'react-countdown';

const HomeCountDownRenderer = ({ hours, days, minutes, seconds, completed }: CountdownRenderProps) => {
  return (
    <div className="z-10 py-9 h-[55px] w-[250px] mb-4 flex justify-evenly mt-5 rounded-md bg-black/[0.5] ">
      <div className="flex flex-col justify-center items-center w-[4px]">
        <div className="font-bold text-2xl z-10"> {days}</div>
        <div className="text-bold text-gray-400 text-md  z-10">J</div>
      </div>
      <div className="flex flex-col justify-center items-center w-[4px]">
        <div className="font-bold text-2xl z-10"> {hours}</div>
        <div className="text-bold text-gray-400 text-md z-10">H</div>
      </div>
      <div className="flex flex-col justify-center items-center w-[4px] ">
        <div className="font-bold text-2xl z-10"> {minutes}</div>
        <div className="text-bold text-gray-400 z-10 text-md">M</div>
      </div>
      <div className="flex flex-col justify-center items-center w-[4px]">
        <div className="font-bold text-2xl z-10"> {seconds}</div>
        <div className="text-bold text-gray-400 z-10 text-md">S</div>
      </div>
    </div>
  );
};

export default HomeCountDownRenderer;
