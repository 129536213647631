import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import { Stack } from '@mui/system';
import { Box, ButtonBase } from '@mui/material';
import DesktopDrawer from '../../../../components/DesktopDrawer';
import SearchBar from '../../../../components/SearchBar';
import ShareModal from '../ShareModal';
import LogoButton from '../../../../components/LogoButton';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import logoTextWhite from '../../../../assets/images/logo/logo-white-big.png';
import { getWebsiteConfig } from '@/pages/whiteLabel/themeFactory';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(2),
  // Override media queries injected by theme.mixins.toolbar
  '@media all': {
    // minHeight: 128,
  }
}));

interface DesktopAppBarProps {
  eventId: string;
}

const DesktopAppBar = (props: DesktopAppBarProps) => {
  const navigate = useNavigate();

  const logo = getWebsiteConfig()?.logo;

  const renderKeyValuePairs = () => {
    const keyValuePairs: JSX.Element[] = [];
    const data = [
      { name: 'Accueil', url: '/' },
      // { name: 'Events', url: '/events' },
      { name: 'Mes billets', url: '/my-tickets' }
    ];
    data?.forEach(key => {
      keyValuePairs.push(
        <div key={key.name} className="h-full">
          <Link key={key.name} to={key.url} className="text-white cursor-pointer font-bold hover:text-blue-500 h-full">
            {key.name}
          </Link>
        </div>
      );
    });

    return keyValuePairs;
  };

  return (
    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'block' } }}>
      <AppBar
        position="static"
        color="transparent"
        elevation={0}
        sx={{ mt: -2, mb: 0, alignItems: 'center', justifyContent: 'center' }}
      >
        <Box width="100%" maxWidth={1100}>
          <StyledToolbar sx={{ alignItems: 'center', p: 4 }}>
            <Box display="flex">
              <Stack direction="row">
                <ButtonBase onClick={() => navigate('/home')}>
                  <Box>
                    <img src={logo} height={42} />
                  </Box>
                </ButtonBase>
              </Stack>
            </Box>
            <div className="hidden md:flex items-end justify-center space-x-12 h-full ">{renderKeyValuePairs()}</div>
            <Stack direction="row" mt={1}>
              <Box display="flex">
                <ShareModal url={`${import.meta.env.VITE_APP_BASE_URL}/event/${props.eventId}`} isDesktop />
              </Box>
              <DesktopDrawer />
            </Stack>
          </StyledToolbar>
        </Box>
      </AppBar>
    </Box>
  );
};

export default DesktopAppBar;
