import React, { useState, useEffect } from 'react';
import SelectImage from '../SelectImage';
import ExitButtonImage from '../ExitButtonImage';
import { AnimatePresence, motion } from 'framer-motion';

interface TicketProps {
  tokenUri: string;
  ticketId: number;
  ticketUuid: string;
  status: string;
  isVisible: boolean;
  onSelect: (ticketId: number, ticketUuid: string) => void;
}

function TicketBody({ tokenUri, ticketId, ticketUuid, status, onSelect, isVisible }: TicketProps) {
  const [metadata, setMetadata] = useState<any>(null);
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const handleTicketClick = () => {
    setIsSelected(!isSelected);
    onSelect(ticketId, ticketUuid);
  };

  useEffect(() => {
    const fetchMetadata = async () => {
      try {
        const res = await fetch(tokenUri);
        const metadata = await res.json();
        setMetadata(metadata);
      } catch (error) {
        console.error('Error fetching metadata:', error);
      }
    };

    fetchMetadata();
  }, [tokenUri]);

  return (
    <AnimatePresence>
      {isVisible && ( // Use isVisible to conditionally render the component
        <motion.div // Wrap your component with motion.div
          key="ticketBody"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
          className={`bg-gray-800 rounded-lg flex mt-5 transition-all duration-300 cursor-pointer ${
            isSelected ? 'bg-purple-600' : ''
          }`}
          onClick={handleTicketClick}
        >
          <div className="flex-grow items-center w-30 sm:w-40 md:w-48 lg:w-30 p-5 h-auto relative">
            <div className="w-8 h-8 sm:w-8 sm:h-8 md:w-8 md:h-8 lg:w-10 lg:h-10 bg-black rounded-full flex justify-center items-center absolute inset-0 mx-auto top-1/2 transform -translate-y-1/2">
              {isSelected && status !== 'IN_TRANSFER' && <SelectImage width="60%" height="60%" />}
              {isSelected && status === 'IN_TRANSFER' && <ExitButtonImage width="60%" height="60%" />}
            </div>
          </div>
          <div className="flex flex-col items-center relative">
            <div className="transform rotate-180 top-0 absolute">
              <div className="w-2 h-5 bg-black rounded-t-full"></div>
            </div>
            <div className="transform bottom-0 absolute">
              <div className="w-2 h-5 bg-black rounded-t-full"></div>
            </div>
          </div>

          <div className="flex  flex-row flex-grow items-center">
            {metadata && metadata.image && (
              <img
                src={metadata.image}
                alt="Placeholder Image"
                className="h-auto shadow-lg p-2"
                style={{ objectFit: 'fill', width: '25%' }}
              />
            )}
            <div className="flex flex-col flex-grow p-2">
              <div className="flex flex-row flex-grow items-center mt-0 mb-0">
                <div className="flex-grow">
                  <h4 className="text-sm md:text-base lg:text-lg whitespace-nowrap overflow-hidden overflow-ellipsis mt-0 mb-0">
                    Place Sèche
                  </h4>
                </div>
                <div className="flex-grow flex justify-end items-center mt-0 mb-0">
                  <h4 className="text-sm md:text-base lg:text-lg text-right  mt-0 mb-0">#{ticketId}</h4>
                </div>
              </div>
              <h4 className="mt-0 mb-0 text-sm md:text-base lg:text-lg text-gray-300 whitespace-nowrap overflow-hidden overflow-ellipsis">
                Plein tarif
              </h4>
              <div className="flex flex-row items-center">
                <div className="flex flex-col flex-grow items-center">
                  <h4 className="m-3 text-sm md:text-base lg:text-lg text-gray-500 whitespace-nowrap overflow-hidden overflow-ellipsis">
                    Placement Libre
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default TicketBody;
