import { Stack, IconButton, Typography, Box, TextField, useMediaQuery, Button, CircularProgress } from '@mui/material';
import { t } from 'i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import greyScale from '../../../../themes/default/colors/greyscale';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import shadows from '../../../../themes/default/shadows';
import { useEffect, useState } from 'react';
import primary from '../../../../themes/default/colors/primary';
import userApi, { Gender } from '../../../../services/api/user';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import RPI from 'react-phone-input-2';
import palette from '../../../../themes/default/colors/palette';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const PhoneInput = RPI.default ? RPI.default : RPI;
const isDefiend = (prop: any) => {
  if (prop == '' || prop == undefined || prop == null) {
    return undefined;
  } else {
    return prop;
  }
};

const EditProfile = () => {
  const navigate = useNavigate();
  const { darkTheme } = useAppSelector(state => state.themeSlice);
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  const [updateProfile, { isLoading, isError }] = userApi.endpoints.updateProfile.useMutation();

  const { data: user } = userApi.endpoints.getUser.useQuery();
  const dispatch = useAppDispatch();
  // const { data: user } = userApi.endpoints.getUser.useQuery();
  const [gender, setGender] = useState<Gender | undefined>();

  useEffect(() => {
    if (user) {
      setGender(user.gender);
    }
  }, [user]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      email: user?.email,
      phone: user?.phone
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('Required'),
      lastName: Yup.string().required('Required')
    }),
    onSubmit: async values => {
      try {
        await updateProfile({ ...values, gender }).unwrap();
        dispatch(userApi.util.invalidateTags(['user-details']));
        navigate('/settings');
      } catch (error) {}
    }
  });

  return (
    <>
      <header>
        <Stack direction="row" alignItems="center" mt={2} ml={1} mb={1} spacing={1}>
          <Stack direction="row" alignItems="center">
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h4">{t('editProfile.title')}</Typography>
          </Stack>
        </Stack>
      </header>
      <form onSubmit={formik.handleSubmit}>
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={3} p={3}>
          {user && isDefiend(user?.avatar) && <img src={user?.avatar} referrerPolicy="no-referrer"></img>}
          <TextField
            name="lastName"
            label={t('editProfile.lastName')}
            value={formik.values.lastName}
            onChange={formik.handleChange}
            sx={{ maxWidth: 300 }}
            fullWidth
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          />
          <TextField
            name="firstName"
            label={t('editProfile.firstName')}
            value={formik.values.firstName}
            onChange={formik.handleChange}
            sx={{ maxWidth: 300 }}
            fullWidth
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          />
          <TextField
            disabled
            name="email"
            label={t('editProfile.email')}
            value={formik.values.email}
            onChange={formik.handleChange}
            sx={{ maxWidth: 300, opacity: 0.4 }}
            fullWidth
            error={formik.touched.email && Boolean(formik.errors.email)}
          />
          <Stack direction="row" justifyContent="center">
            <PhoneInput
              value={formik.values.phone}
              disabled
              inputProps={{
                id: 'phoneNumber',
                name: 'phoneNumber'
              }}
              country="fr"
              masks={{
                fr: '..........'
              }}
              countryCodeEditable={false}
              enableSearch
              copyNumbersOnly={false}
              showDropdown={false}
              searchPlaceholder={t('login.search')}
              searchStyle={{
                backgroundColor: '#FFF',
                margin: '0',
                height: 38,
                width: '90%',
                borderRadius: 100
              }}
              dropdownStyle={{ backgroundColor: darkTheme ? palette.dark2 : '#FFF' }}
              inputStyle={{
                height: 60,
                width: 300,
                border: 'none',
                backgroundColor: darkTheme ? palette.dark2 : greyScale[50],
                borderRadius: 16,
                fontFamily: 'Urbanist',
                fontSize: '1.115rem',
                fontWeight: 600,
                opacity: 0.4,
                color: darkTheme ? '#FFFFFF' : greyScale[900]
              }}
              specialLabel=""
            />
          </Stack>
          <Stack direction="row" justifyContent="center" spacing={2} sx={{ maxWidth: 300 }}>
            <IconButton
              sx={{
                border: `1px solid ${greyScale[200]}`,
                height: 50,
                width: 50,
                backgroundColor: gender == 'MALE' ? primary[200] : 'black',
                '&:hover': {
                  backgroundColor: primary[200]
                }
              }}
              onClick={() => setGender(Gender.MALE)}
            >
              🙋
            </IconButton>

            <IconButton
              sx={{
                border: `1px solid ${greyScale[200]}`,
                height: 50,
                width: 50,
                backgroundColor: gender == Gender.FEMALE ? primary[200] : 'black',
                '&:hover': {
                  backgroundColor: primary[200]
                }
              }}
              onClick={() => setGender(Gender.FEMALE)}
            >
              🙋‍♀️
            </IconButton>
          </Stack>
          <Box
            sx={{ position: isDesktop ? 'relative' : 'fixed', bottom: 0, left: 0, right: 0, p: 3 }}
            justifyContent="center"
            alignItems="center"
          >
            <Stack direction="column" spacing={1.5} justifyContent="center" alignItems="center">
              <Button
                variant="contained"
                type="submit"
                fullWidth
                sx={{ boxShadow: shadows.primaryButton, maxWidth: { xs: '100%', sm: 300 }, width: { md: 300 } }}
              >
                {isLoading ? <CircularProgress sx={{ color: 'white' }} /> : t('editProfile.save')}
              </Button>
            </Stack>
          </Box>
        </Stack>
      </form>
    </>
  );
};

export default EditProfile;
