interface SocialLinks {
  facebook?: string;
  twitter?: string;
  instagram?: string;
  linkedin?: string;
}

export type ClientIdentifier =
  | 'TICKIE'
  | 'LESDETERMINES'
  | 'BLAGNAC'
  | 'NICE'
  | 'DECATHLON'
  | 'SPORSORA'
  | 'SAINTES'
  | 'GRIZZLYS'
  | 'NANTES'
  | 'GAYA'
  | 'NANCY'
  | 'BLACKVISION';

export interface WhiteLabelConfigEntry {
  id: ClientIdentifier;
  orgaUuids: number[];
  name: string;
  title: string;
  type: 'marketplace' | 'ticketing' | 'collectibles';
  favicon: string;
  description: string;
  url: string;
  hostname: string;
  logo: string;
  banner: string;
  social: SocialLinks;
  partners?: { name: string; url: string; img: string }[];
  headerLinks?: { name: string; url: string }[];
}

export type WhiteLabelConfigType = {
  [hostname: string]: WhiteLabelConfigEntry;
};

const whiteLabelConfig: WhiteLabelConfigType = {
  'app.tickie.io': {
    id: 'TICKIE',
    orgaUuids: [1, 2],
    name: 'Tickie',
    title: 'Tickie',
    type: 'marketplace',
    favicon: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/default/favicon/favicon.ico',
    description: 'Tickie',
    url: 'https://app.tickie.io',
    hostname: 'app.tickie.io',
    logo: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/default/logo/tickie.png',
    banner: '',
    social: {
      twitter: 'https://x.com/tickie_io',
      instagram: 'https://www.instagram.com/tickie.io/',
      linkedin: 'https://www.linkedin.com/company/tickie/'
    }
  },
  'collectibles.lesdetermines.tickie.io': {
    id: 'LESDETERMINES',
    orgaUuids: [],
    name: 'Les Déterminés',
    title: 'DTR CUP 2023 | powered by Tickie',
    type: 'collectibles',
    favicon: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/les-determines/favicon/favicon.ico',
    description:
      'Les Déterminés est une association qui accompagne les jeunes des quartiers populaires dans la création de leur entreprise.',
    url: 'https://collectibles.lesdetermines.tickie.io',
    hostname: 'collectibles.lesdetermines.tickie.io',
    logo: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/les-determines/logo/logo.png',
    banner: 'https://lesdetermines.tickie.io/assets/images/background.jpg',
    headerLinks: [],
    social: {
      facebook: 'https://www.facebook.com/lesdetermines',
      twitter: 'https://twitter.com/lesdetermines',
      instagram: 'https://www.instagram.com/lesdetermines/',
      linkedin: 'https://www.linkedin.com/company/les-d%C3%A9termin%C3%A9s/'
    }
  },
  'collectibles.nice.tickie.io': {
    id: 'NICE',
    orgaUuids: [],
    name: 'OGC Nice',
    title: 'OGC Nice | powered by Tickie',
    type: 'collectibles',
    favicon: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/ogc-nice/favicon/favicon.ico',
    description: '',
    url: 'https://lcollectibles.nice.tickie.io',
    hostname: 'collectibles.nice.tickie.io',
    logo: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/ogc-nice/logo/logo.png',
    banner: 'https://lesdetermines.tickie.io/assets/images/background.jpg',
    headerLinks: [],
    social: {
      facebook: 'https://www.facebook.com/lesdetermines',
      twitter: 'https://twitter.com/lesdetermines',
      instagram: 'https://www.instagram.com/lesdetermines/',
      linkedin: 'https://www.linkedin.com/company/les-d%C3%A9termin%C3%A9s/'
    }
  },
  'billetterie-blagnac.tickie.io': {
    id: 'BLAGNAC',
    orgaUuids: import.meta.env.VITE_NODE_ENV == 'production' ? [1, 3] : [1, 2, 3],
    name: 'BLAGNAC RUGBY',
    title: 'Billetterie officielle | Blagnac rugby',
    type: 'ticketing',
    favicon: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/blagnac/favicon/favicon.png',
    description:
      'Billet officiel du club de rugby de Blagnac. Achetez vos billets pour les matchs du club de rugby de Blagnac.',
    url: 'https://billetterie-blagnac.tickie.io',
    hostname: 'billetterie-blagnac.tickie.io',
    logo: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/blagnac/logo/logo.webp',
    banner: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/blagnac/banner/home-banner.webp',
    social: {
      facebook: 'https://www.facebook.com/BlagnacRugby/',
      twitter: 'https://twitter.com/BlagnacRugby',
      instagram: 'https://www.instagram.com/blagnac_rugby/',
      linkedin: 'https://www.linkedin.com/company/blagnac-rugby/?viewAsMember=true'
    },
    headerLinks: [
      { name: 'Site Officiel', url: 'https://www.blagnac-rugby.fr/' },
      { name: 'Mes billets', url: '/my-tickets' },
      { name: 'Boutique', url: 'https://www.blagnac-rugby.fr/boutique' },
      { name: 'Business', url: 'https://www.blagnac-rugby.fr/blagnac-rugby-event' }
    ],
    partners: [
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/blagnac/partners/tickie.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/blagnac/partners/logo1.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/blagnac/partners/logo2.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/blagnac/partners/logo5.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/blagnac/partners/logo6.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/blagnac/partners/logo7.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/blagnac/partners/logo8.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/blagnac/partners/logo9.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/blagnac/partners/logo10.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/blagnac/partners/logo11.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/blagnac/partners/logo13.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/blagnac/partners/logo14.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/blagnac/partners/logo15.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/blagnac/partners/logo16.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/blagnac/partners/logo17.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/blagnac/partners/logo18.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/blagnac/partners/logo19.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/blagnac/partners/logo20.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/blagnac/partners/logo21.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/blagnac/partners/logo22.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/blagnac/partners/logo23.png'
      }
    ]
  },
  'decathlon.tickie.io': {
    id: 'DECATHLON',
    orgaUuids: [4],
    name: 'DECATHLON',
    title: 'Billetterie officielle | DECATHLON',
    type: 'ticketing',
    favicon: 'https://www.decathlon.fr/render/favicon-16x16.5b96aec8a3db881722a5.png',
    description: '',
    url: 'https://decathlon.tickie.io',
    hostname: 'decathlon.tickie.io',
    logo: 'https://www.1min30.com/wp-content/uploads/2018/02/Embl%C3%A8me-D%C3%A9cathlon.jpg',
    banner:
      'https://tickie-dev-2.s3.eu-west-3.amazonaws.com/subscription/6943c6e6-92c4-4ecd-b50f-6c80181cfe6a/e9c75fa5-ded1-42c5-83d7-f0814ef4c47c/subscription_1698683644141.webp',
    social: {
      facebook: 'https://www.facebook.com/BlagnacRugby/',
      twitter: 'https://twitter.com/BlagnacRugby',
      instagram: 'https://www.instagram.com/blagnac_rugby/',
      linkedin: 'https://www.linkedin.com/company/blagnac-rugby/?viewAsMember=true'
    },
    headerLinks: [
      { name: 'Site Officiel', url: 'https://www.blagnac-rugby.fr/' },
      { name: 'Mes billets', url: '/my-tickets' },
      { name: 'Boutique', url: 'https://www.blagnac-rugby.fr/boutique' },
      { name: 'Business', url: 'https://www.blagnac-rugby.fr/blagnac-rugby-event' }
    ],
    partners: []
  },
  'sporsora.tickie.io': {
    id: 'SPORSORA',
    orgaUuids: [],
    name: 'SPORSORA',
    title: 'SPORSORA | powered by Tickie',
    type: 'collectibles',
    favicon: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/sporsora/favicon/favicon.ico',
    description: '',
    url: 'https://collectibles.nice.tickie.io',
    hostname: 'collectibles.nice.tickie.io',
    logo: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/sporsora/logo/logo.png',
    banner: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/sporsora/banner/banner.png',
    headerLinks: [],
    social: {
      facebook: 'https://www.facebook.com/lesdetermines',
      twitter: 'https://twitter.com/lesdetermines',
      instagram: 'https://www.instagram.com/lesdetermines/',
      linkedin: 'https://www.linkedin.com/company/les-d%C3%A9termin%C3%A9s/'
    }
  },
  'us-saintes-handball.tickie.io': {
    id: 'SAINTES',
    orgaUuids: import.meta.env.VITE_NODE_ENV == 'production' ? [14] : [1, 2, 3],
    name: 'US SAINTES HANDBALL',
    title: 'Billetterie officielle | US SAINTES HANDBALL',
    type: 'ticketing',
    favicon: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/saintes/favico/favicon.png',
    description:
      'Billet officiel du club de us saintes handball. Achetez vos billets pour les matchs du club de us saintes handball.',
    url: 'https://us-saintes-handball.tickie.io',
    hostname: 'us-saintes-handball.tickie.io',
    logo: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/saintes/logo/logo.png',
    banner: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/saintes/banner/banner.jpg',
    social: {
      facebook: 'https://us-saintes-handball.fr/contact/',
      twitter: 'https://us-saintes-handball.fr/contact/',
      instagram: 'https://us-saintes-handball.fr/contact/',
      linkedin: ''
    },
    headerLinks: [
      { name: 'Site Officiel', url: 'https://us-saintes-handball.fr/' },
      { name: 'Mes billets', url: '/my-tickets' },
      { name: 'Boutique', url: 'https://us-saintes-handball.fr/boutique/' },
      { name: 'Contact', url: 'https://us-saintes-handball.fr/contact/' }
    ],
    partners: [
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/saintes/partners/logo1.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/saintes/partners/logo2.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/saintes/partners/logo5.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/saintes/partners/logo6.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/saintes/partners/logo7.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/saintes/partners/logo9.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/saintes/partners/logo10.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/saintes/partners/logo11.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/saintes/partners/logo13.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/saintes/partners/logo14.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/saintes/partners/logo15.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/saintes/partners/logo16.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/saintes/partners/logo17.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/saintes/partners/logo18.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/saintes/partners/logo19.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/saintes/partners/logo20.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/saintes/partners/logo21.png'
      },

      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/saintes/partners/logo23.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/saintes/partners/logo24.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/saintes/partners/logo25.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/saintes/partners/logo26.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/saintes/partners/logo27.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/saintes/partners/logo28.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/saintes/partners/logo29.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/saintes/partners/logo31.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/saintes/partners/logo32.png'
      },
      {
        name: '',
        url: '',
        img: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/saintes/partners/logo33.png'
      }
    ]
  },
  'grizzlys-catalans.tickie.io': {
    id: 'GRIZZLYS',
    orgaUuids: import.meta.env.VITE_NODE_ENV == 'production' ? [15] : [1, 2, 3],
    name: 'GRIZZLYS CATALANS',
    title: 'Billetterie officielle | GRIZZLYS CATALANS',
    type: 'ticketing',
    favicon: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/grizzlys/favico/favicon.ico',
    description:
      'Billet officiel du club des grizzlys catalans. Achetez vos billets pour les matchs du club des grizzlys catalans.',
    url: 'https://grizzlys-catalans.tickie.io',
    hostname: 'grizzlys-catalans.tickie.io',
    logo: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/grizzlys/logo/logo.png',
    banner: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/grizzlys/banner/banner.webp',
    social: {
      facebook: 'https://www.facebook.com/LesGrizzlysCatalans/',
      twitter: 'https://twitter.com/GrizzlysCatalan',
      instagram: 'https://www.instagram.com/grizzlys_catalans/?hl=fr',
      linkedin: 'https://www.linkedin.com/in/grizzlys-catalans-75b29a1a0/'
    },
    headerLinks: [
      { name: 'Site Officiel', url: 'https://grizzlys-catalans.fr/' },
      { name: 'Mes billets', url: '/my-tickets' },
      { name: 'Boutique', url: 'https://shop.grizzlys-catalans.fr/' },
      { name: 'Contact', url: 'https://grizzlys-catalans.fr/contacts-2/' }
    ],
    partners: []
  },
  'hbcnantes.tickie.io': {
    id: 'NANTES',
    orgaUuids: import.meta.env.VITE_NODE_ENV == 'production' ? [15] : [1, 2, 3],
    name: 'HBC NANTES',
    title: 'Billetterie officielle | HBC NANTES',
    type: 'ticketing',
    favicon: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/nantes/favico/favico.ico',
    description: 'Billet officiel du club de HBC NANTES. Achetez vos billets pour les matchs du club de HBC NANTES',
    url: 'https://grizzlys-catalans.tickie.io',
    hostname: 'grizzlys-catalans.tickie.io',
    logo: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/nantes/logo/logo.png',
    banner: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/nantes/banner/banner.jpeg',
    social: {
      facebook: 'https://www.facebook.com/LesGrizzlysCatalans/',
      twitter: 'https://twitter.com/GrizzlysCatalan',
      instagram: 'https://www.instagram.com/grizzlys_catalans/?hl=fr',
      linkedin: 'https://www.linkedin.com/in/grizzlys-catalans-75b29a1a0/'
    },
    headerLinks: [
      { name: 'Site Officiel', url: 'https://grizzlys-catalans.fr/' },
      { name: 'Mes billets', url: '/my-tickets' },
      { name: 'Boutique', url: 'https://shop.grizzlys-catalans.fr/' },
      { name: 'Contact', url: 'https://grizzlys-catalans.fr/contacts-2/' }
    ],
    partners: []
  },
  'gaya-rixheim.tickie.io': {
    id: 'GAYA',
    orgaUuids: import.meta.env.VITE_NODE_ENV == 'production' ? [17] : [1, 2, 3],
    name: 'Gaya Rixheim',
    title: 'Billetterie officielle | Gaya Rixheim',
    type: 'ticketing',
    favicon: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/gaya/favico/favicon.ico',
    description: 'Billet officiel de Gaya Rixheim. Achetez vos billets pour les spectacles de Gaya Rixheim',
    url: 'https://gaya-rixheim.tickie.io',
    hostname: 'gaya-rixheim.tickie.io',
    logo: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/gaya/logo/logo.png',
    banner: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/gaya/banner/banner.jpeg',
    social: {
      instagram: 'https://www.instagram.com/gaya_rixheim'
    },
    headerLinks: [
      { name: 'Accueil', url: '/' },
      { name: 'Mes billets', url: '/my-tickets' },
      { name: 'Mon profil', url: '/profile' },
      { name: 'Contact', url: 'https://www.instagram.com/gaya_rixheim' }
    ],
    partners: []
  },
  'asnl.tickie.io': {
    id: 'NANCY',
    orgaUuids: import.meta.env.VITE_NODE_ENV == 'production' ? [17] : [1, 2, 3],
    name: 'AS Nancy Lorraine',
    title: 'Fan expérience | AS Nancy Lorraine',
    type: 'collectibles',
    favicon: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/nancy/favico/favicon.ico',
    description: 'Billet officiel de AS Nancy Lorraine. Achetez vos billets pour les spectacles de AS Nancy Lorraine',
    url: 'https://asnl.tickie.io',
    hostname: 'asnl.tickie.io',
    logo: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/nancy/logo/logo.png',
    banner: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/nancy/banner/banner.jpeg',
    social: {
      instagram: 'https://www.instagram.com/nancy'
    },
    headerLinks: [
      { name: 'Site Officiel', url: 'https://www.asnl.net/' },
      { name: 'Mes billets', url: '/my-tickets' },
      { name: 'Profil', url: '/profile' }
    ],
    partners: []
  },
  'black-vision.tickie.io': {
    id: 'BLACKVISION',
    orgaUuids: import.meta.env.VITE_NODE_ENV == 'production' ? [20] : [1, 2, 3],
    name: 'Black Vision',
    title: 'Billetterie officielle | Black Vision',
    type: 'ticketing',
    favicon: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/black-vision/favico/favicon.ico',
    description: 'Billet officiel de Black Vision. Achetez vos billets pour les spectacles de Black Vision',
    url: 'https://gaya-rixheim.tickie.io',
    hostname: 'gaya-rixheim.tickie.io',
    logo: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/black-vision/logo/logo.webp',
    banner: 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/black-vision/banner/banner.webp',
    social: {
      instagram: 'https://www.instagram.com/black_vision'
    },
    headerLinks: [
      { name: 'Accueil', url: '/' },
      { name: 'Mes billets', url: '/my-tickets' },
      { name: 'Mon profil', url: '/profile' },
      { name: 'Contact', url: 'https://www.instagram.com/black_vision' }
    ],
    partners: []
  }
};

export default whiteLabelConfig;
