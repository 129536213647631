import { Box, ButtonBase } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getWebsiteConfig } from '../../pages/whiteLabel/themeFactory';

const LogoButton = () => {
  const navigate = useNavigate();
  const logo = getWebsiteConfig()?.logo;
  return (
    <ButtonBase onClick={() => navigate('/home')}>
      <Box p={2}>
        <img src={logo} width={55} />
      </Box>
    </ButtonBase>
  );
};

export default LogoButton;
