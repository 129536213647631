import { Button, IconButton, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { t } from 'i18next';
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { CHAIN_CONFIG_TYPE } from '../../../../config/chainConfig';
import { decryptPrivateKey } from '@/services/web3/crypto';
import userApi from '@/services/api/user';

function BlockchainSettings() {
  const { data: user } = userApi.endpoints.getUser.useQuery();
  const [privateKey, setPrivateKey] = useState('');

  const getPrivateKey = async () => {
    if (user != null) {
      const encryptedPrivateKey = localStorage.getItem('encryptedPrivateKey') ?? '';
      const privateKey = decryptPrivateKey(encryptedPrivateKey, user.secret);
      setPrivateKey(privateKey);
    }
  };
  const chain: CHAIN_CONFIG_TYPE = import.meta.env.VITE_NODE_ENV === 'production' ? 'polygonMainnet' : 'polygonTestnet';

  const navigate = useNavigate();
  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} m={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4">{t('settings.settings')}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <IconButton>
            <SettingsIcon />
          </IconButton>
        </Stack>
      </Stack>
      <Stack direction="column" spacing={1} p={3}>
        {/* {Object.entries(CHAIN_CONFIG[chain as CHAIN_CONFIG_TYPE]).map(([key, value]) => (
          <Stack direction="row" spacing={1} alignItems="center" key={key}>
            <Typography variant="xl" fontWeight={600}>
              {`${key} :`}
            </Typography>
            <Typography variant="h6" flexWrap="wrap">{`  ${value}`}</Typography>
          </Stack>
        ))} */}
      </Stack>
      <Stack direction="row" spacing={1} justifyContent="center" p={3}>
        <Button
          variant="contained"
          color="error"
          onClick={async () => {
            await getPrivateKey();
          }}
        >
          {t('settings.getPrivateKey')}
        </Button>
      </Stack>
      <Stack direction="row" spacing={1} alignItems="center" p={3} width="100%">
        <Typography variant="xl" fontWeight={600} maxWidth="100%" sx={{ wordWrap: 'break-word' }}>
          {privateKey}
        </Typography>
      </Stack>
    </>
  );
}

export default BlockchainSettings;
