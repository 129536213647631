import { t } from 'i18next';
import {
  Button,
  Stack,
  Box,
  Typography,
  Checkbox,
  Alert,
  Snackbar,
  IconButton,
  useMediaQuery,
  CircularProgress,
  TextField,
  Modal
} from '@mui/material';
import { useState } from 'react';

import logo from '../../../../assets/images/logo/logo-picto.png';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-phone-input-2/lib/material.css';
import { getIdToken, signInWithCustomToken } from 'firebase/auth';
import { PinInput } from 'react-input-pin-code';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Trans } from 'react-i18next';
import 'react-phone-number-input/style.css';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import authApi, { SignInType } from '../../../../services/api/auth';
// import { useWeb3Auth } from '../../../../services/web3/web3auth';
import { auth } from '../../../../services/firebase';
import greyScale from '../../../../themes/default/colors/greyscale';
import shadows from '../../../../themes/default/shadows';
import CompleteProfile from '../../../CompleteProfile';
import CompleteProfileModal from '../CompleteProfileModal';
import palette from '../../../../themes/default/colors/palette';
import { set } from 'date-fns';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 8,
  p: 3,
  backgroundColor: '#101417',
  width: { xs: '90%', sm: '60%', lg: '40%', xl: '30%' }
};

interface LoginModalProps {
  openLoginModal: boolean;
  handleCloseLoginModal: () => void;
}

enum CurrentPage {
  enterMail,
  enterMailOtp,
  completeProfile
}

function LoginModal(props: LoginModalProps) {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { darkTheme } = useAppSelector(state => state.themeSlice);

  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  ///
  const [email, setEmail] = useState<string>('');
  const [openErrorSnackbar, setErrorSnackbar] = useState(false);
  const [pinCode, setPinCode] = useState(['', '', '', '', '', '']);
  const [currentPage, setCurrentPage] = useState<CurrentPage>(CurrentPage.enterMail);
  const [isCheckingAuthCredentials, setCheckingAuthCredentials] = useState(false);
  const [isCheckingPinCode, setCheckingPinCode] = useState(false);
  const [isCheckingWeb3, setIsCheckingWeb3] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [authRequest] = authApi.endpoints.signIn.useMutation();
  const [mailAuthRequest] = authApi.endpoints.signInWithEmail.useMutation();
  const [verifyMailOtpRequest] = authApi.endpoints.verifyMailOtp.useMutation();

  // const { login } = useWeb3Auth();

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorSnackbar(false);
  };

  const redirectTo = (needToCompleteProfile: boolean) => {
    if (eventId) {
      return setCurrentPage(CurrentPage.completeProfile);
    } else if (needToCompleteProfile) {
      return setCurrentPage(CurrentPage.completeProfile);
    } else {
      return setCurrentPage(CurrentPage.completeProfile);
    }
  };

  const cancelOtpVerification = (currentPage: CurrentPage) => {
    setPinCode(['', '', '', '', '', '']);
    setCheckingPinCode(false);
    setCurrentPage(currentPage);
    setErrorSnackbar(false);
  };

  const sendEmailCode = async () => {
    if (email === '') return;
    await mailAuthRequest({ email: email?.toLowerCase() }).unwrap;
    try {
      setCheckingAuthCredentials(true);
      setCurrentPage(CurrentPage.enterMailOtp);
      setCheckingAuthCredentials(false);
    } catch (error) {
      setCheckingAuthCredentials(false);
      setErrorMessage((error as any)?.toString());
      setErrorSnackbar(true);
      console.log(error);
    }
  };

  const verifyMailOtp = async (otp: string) => {
    if (otp.length === 6 && !isCheckingPinCode) {
      try {
        setCheckingPinCode(true);
        const { customIdToken } = await verifyMailOtpRequest({ email: email?.toLowerCase(), otp }).unwrap();
        // isReservation ? null : dispatch(baseApi.util.resetApiState());
        const { user } = await signInWithCustomToken(auth, customIdToken);
        const idToken = await getIdToken(user, true);
        const { needToCompleteProfile, secret } = await authRequest({
          idToken,
          type: SignInType.EMAIL
        }).unwrap();
        setIsCheckingWeb3(true);
        setCheckingPinCode(false);
        // await login(await getIdToken(user, true), secret);
        redirectTo(needToCompleteProfile);
        setCheckingPinCode(false);
        setIsCheckingWeb3(false);
      } catch (error) {
        setCheckingPinCode(false);
        setIsCheckingWeb3(false);
        setCheckingAuthCredentials(false);
        setErrorMessage((error as any)?.toString());
        setErrorSnackbar(true);
        console.log(error);
      }
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  if (currentPage == CurrentPage.enterMail) {
    {
      return (
        <>
          <Modal open={props.openLoginModal} onClose={props.handleCloseLoginModal}>
            <Box sx={style}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Snackbar
                  open={openErrorSnackbar}
                  autoHideDuration={6000}
                  onClose={handleCloseSnackbar}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                  <Alert
                    onClose={handleCloseSnackbar}
                    severity="error"
                    sx={{ width: '100%', minHeight: 60, justifyContent: 'center', alignItems: 'center' }}
                  >
                    {errorMessage}
                  </Alert>
                </Snackbar>

                <Stack direction="column" alignItems="center" spacing={6} width="100%" maxWidth={350}>
                  <Box width={150} height={150} pl={3} sx={{ display: 'none' }}>
                    <img src={logo} width="100%" height="100%" />
                  </Box>

                  <Typography variant="h5" textAlign="center">
                    {t('login.enterMail')}
                  </Typography>

                  <Stack direction="column" spacing={3} width="100%">
                    <TextField
                      name="email"
                      autoComplete="email"
                      value={email}
                      type="email"
                      onChange={handleEmailChange}
                      placeholder={t('checkout.mail') ?? ''}
                      fullWidth
                      sx={{ mb: 1 }}
                      // error={touched.email && Boolean(errors.email)}
                      // helperText={touched.email && errors.email}
                    />
                    {/* <Stack direction="row" justifyContent="center" alignItems="center" spacing={0.5} display={'flex'}>
                      <Checkbox checked={checked} onChange={handleChange} />
                      <Typography variant="xl" color={greyScale[700]} fontWeight={600}>
                        {t('login.rememberMe')}
                      </Typography>
                    </Stack> */}
                    <Button fullWidth variant="contained" onClick={sendEmailCode}>
                      {isCheckingAuthCredentials ? <CircularProgress sx={{ color: '#FFFFFF' }} /> : t('login.continue')}
                    </Button>
                  </Stack>
                </Stack>

                <div id="recaptcha-container"></div>
              </Box>
            </Box>
          </Modal>
        </>
      );
    }
  }

  if (currentPage == CurrentPage.enterMailOtp) {
    return (
      <>
        <Modal open={props.openLoginModal}>
          <Box sx={style}>
            <Snackbar
              open={openErrorSnackbar}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <Alert
                onClose={handleCloseSnackbar}
                severity="error"
                sx={{ width: '100%', minHeight: 60, justifyContent: 'center', alignItems: 'center' }}
              >
                {errorMessage}
              </Alert>
            </Snackbar>
            <Stack direction="row" alignItems="center" justifyContent={'start'} spacing={1} p={1}>
              <IconButton onClick={() => cancelOtpVerification(CurrentPage.enterMail)}>
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h4">{t('login.checkYourEmail')}</Typography>
            </Stack>
            <Box height={64} />
            <Stack direction="column" alignItems="center" m={3} spacing={4}>
              <Typography variant="xl" textAlign="center">
                <Trans i18nKey="login.enterTheCodeSentToMail" values={{ email }} components={[<b />]} />
              </Typography>
              <PinInput
                values={pinCode}
                autoFocus
                onChange={(value, index, values) => {
                  setPinCode(values);
                  if (currentPage == CurrentPage.enterMailOtp) {
                    verifyMailOtp(values.join(''));
                  }
                }}
                placeholder=""
                type="number"
                autoComplete="one-time-code"
                inputStyle={{
                  height: isDesktop ? 64 : 50,
                  width: isDesktop ? 64 : 50,
                  marginRight: 8,
                  borderColor: 'transparent',
                  backgroundColor: darkTheme ? greyScale[900] : greyScale[200],
                  color: darkTheme ? 'white' : greyScale[900]
                }}
                showState={false}
              />
              <Box p={3} mb={6} zIndex={1000} width="100%">
                <Stack direction="column" justifyContent="center" alignItems="center" width="100%">
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      if (currentPage == CurrentPage.enterMailOtp) {
                        verifyMailOtp(pinCode.join(''));
                      }
                    }}
                    sx={{ boxShadow: shadows.primaryButton, width: { xs: '100%', md: 200 } }}
                  >
                    {isCheckingPinCode || isCheckingWeb3 ? (
                      <CircularProgress sx={{ color: '#FFFFFF' }} />
                    ) : (
                      t('reservation.continue')
                    )}
                  </Button>
                </Stack>
              </Box>
            </Stack>
          </Box>
        </Modal>
      </>
    );
  }

  if (currentPage == CurrentPage.completeProfile) {
    return (
      <>
        <Modal open={props.openLoginModal}>
          <Box sx={style}>
            <Snackbar
              open={openErrorSnackbar}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <Alert
                onClose={handleCloseSnackbar}
                severity="error"
                sx={{ width: '100%', minHeight: 60, justifyContent: 'center', alignItems: 'center' }}
              >
                {errorMessage}
              </Alert>
            </Snackbar>
            <CompleteProfileModal
              onClose={() => {
                props.handleCloseLoginModal();
              }}
            />
          </Box>
        </Modal>
      </>
    );
  } else {
    return <></>;
  }
}

export default LoginModal;
