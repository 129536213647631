import SelectImage from '../SelectImage';
import ExitButtonImage from '../ExitButtonImage';
import { AnimatePresence, motion } from 'framer-motion';
import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { t } from 'i18next';

interface TicketProps {
  category: string;
  firstName: string;
  lastName: string;
  selectedTickets: { id: number; uuid: string }[];
  tokenUri: string;
  ticketId: number;
  ticketUuid: string;
  status: string;
  type: string;
  onSelect: (ticketId: number, ticketUuid: string, category: string) => void;
}

function TicketBody({
  tokenUri,
  ticketId,
  ticketUuid,
  status,
  onSelect,
  selectedTickets,
  firstName,
  lastName,
  category,
  type
}: TicketProps) {
  const [metadata, setMetadata] = useState<any>(null);
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [isClicked, setIsClicked] = useState<boolean>(false);

  const handleTicketClick = () => {
    setIsClicked(true);
    setIsSelected(!isSelected);
    onSelect(ticketId, ticketUuid, category);
  };

  useEffect(() => {
    // Check if selectedTickets is not empty and if ticketId exists in selectedTickets
    const isSelectedTicket = selectedTickets.length > 0 && selectedTickets.some(ticket => ticket.id === ticketId);
    setIsSelected(isSelectedTicket);
  }, [selectedTickets, ticketId]);

  useEffect(() => {
    const fetchMetadata = async () => {
      try {
        const res = await fetch(tokenUri);
        const metadata = await res.json();
        setMetadata(metadata);
      } catch (error) {
        console.error('Error fetching metadata:', error);
      }
    };

    fetchMetadata();
  }, [tokenUri]);
  const ticketSeperator = () => {
    return (
      <>
        <div className="bg-blue-500 absolute flex flex-col h-full"></div>
      </>
    );
  };
  return (
    <AnimatePresence>
      <div
        className={`rounded-lg flex flex-row justify-center items-center mt-3 p-1 transition-all duration-300 cursor-pointer ${
          isSelected ? 'bg-gradient-to-br from-[#27D4FF] to-[#584CF4]' : ''
        }`}
      >
        <motion.div
          key="ticketBody"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
          className={`bg-gray-300 dark:bg-gray-800 rounded-lg flex transition-all duration-300 cursor-pointer `}
          onClick={handleTicketClick}
          // style={{
          //   border: isSelected ? '10px solid #0099FF' : 'none',
          //   borderRadius: '5px',
          //   transition: 'border-color 0.3s ease-in-out'
          // }}
        >
          <div className="flex-grow items-center w-30 sm:w-40 md:w-48 lg:w-30 p-10 h-auto relative">
            <div className="w-8 h-8 sm:w-8 sm:h-8 md:w-8 md:h-8 lg:w-10 lg:h-10 bg-[#ffffff] dark:bg-[#181A20] rounded-full flex justify-center items-center absolute inset-0 mx-auto top-1/2 transform -translate-y-1/2">
              {isSelected && status !== 'IN_TRANSFER' && <SelectImage width="60%" height="60%" />}
            </div>
          </div>
          <div className="flex flex-col items-center relative mr-8">
            <div className="transform -top-[5%] absolute">
              <div className={`w-[20px] h-[20px] bg-[#ffffff] dark:bg-[#181A20] rounded-full`}></div>
            </div>
            <div className="relative h-full mt-4">
              {/* {[...Array(11)].map((_, index) => (
                <div key={index} className={`transform`}>
                  <div className={`w-[2px] h-[5px] bg-[#ffffff] dark:bg-[#181A20]`}></div>
                  <div className={`w-[2px] h-[5px] bg-gray-300 dark:bg-gray-800`}></div>
                </div>
              ))} */}
              <div className=" top-0  transform -translate-y-0   border-t-0 border-b-0 border-l-0 border-r-1 border-dashed border-[#ffffff] h-full w-0  dark:border-[#181A20]" />
            </div>
            <div className="transform -bottom-[5%] absolute">
              <div className={`w-[20px] h-[20px] bg-[#ffffff] dark:bg-[#181A20] rounded-full`}></div>
            </div>
          </div>

          <div className="flex mt-2 mb-2 flex-row h-full  items-center justify-center">
            {metadata && metadata.image && (
              <img
                src={metadata.image}
                // src='https://tickie-dev-2.s3.amazonaws.com/event/d85ea2eb-4297-4ac1-8932-02e328a22911/nft/1.webp'
                alt="Placeholder Image"
                className="h-auto shadow-lg object-cover w-1/4" // or object-contain
              />
            )}
            <div className="flex flex-col flex-grow ml-4 p-2">
              <div className="flex flex-row flex-grow items-center mt-0 mb-0">
                <div className="flex-grow">
                  <h4 className="text-sm md:text-base lg:text-lg whitespace-nowrap overflow-hidden overflow-ellipsis mt-0 mb-0 font-bold">
                    {t('transfer.BilletTicketBody')} #{ticketId}
                  </h4>
                </div>
              </div>
              <h5 className="mt-0 mb-0 text-sm md:text-base lg:text-lg text-[#9E9E9E] whitespace-nowrap overflow-hidden overflow-ellipsis">
                {firstName} {lastName}
              </h5>
              <div className="flex flex-row items-center mb-2">
                <div className="flex flex-col flex-grow">
                  <h5 className="mt-2 mb-0 mr-1  text-xs md:text-sm lg:text-base text-[#9E9E9E] whitespace-nowrap overflow-hidden overflow-ellipsis">
                    {t('transfer.categorieTicketBody')}
                  </h5>
                  <h4 className="mt-0 mb-0 mr-1  text-xs md:text-sm lg:text-base whitespace-nowrap overflow-hidden overflow-ellipsis">
                    {category}
                  </h4>
                </div>
                <div className="flex flex-col flex-grow">
                  <h5 className="mt-2 mb-0 mr-3 ml-1 text-xs md:text-sm lg:text-base text-[#9E9E9E] whitespace-nowrap overflow-hidden overflow-ellipsis">
                    {t('transfer.typeTicketBody')}
                  </h5>
                  <h4 className="mt-0 mb-0 mr-3 ml-1 text-xs md:text-sm lg:text-base whitespace-nowrap overflow-hidden overflow-ellipsis">
                    {type}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </AnimatePresence>
  );
}

export default TicketBody;
