import { Event } from '../services/api/event';
import { Season } from '../services/api/season';
import { Ticket } from '../services/api/ticket';

export interface PitchNft {
  event: Event | null;
  eventUuid: string;
  quantity: number;
  beginAt: string | null;
  isOpened: boolean;
  tokenUri: string;
  rarity: string | boolean;
  isLocked: boolean;
}
const rarityValues: { [key: string]: number } = {
  common: 0,
  bronze: 1,
  silver: 2,
  gold: 3,
  diamond: 4,
  unique: 5
};

// Function to compare two rarities
function isRarityGreaterThan(rarity1: string | undefined, rarity2: string | undefined) {
  if (!rarity1 || !rarity2) return false;
  return rarityValues[rarity1] > rarityValues[rarity2];
}

export const filterEvents = (season: Season, tickets: Ticket[]): PitchNft[] => {
  let nfts: PitchNft[] = [];
  const numberOfEvents = season.numberOfEvents;
  season.events.forEach(event => {
    const founTickets = tickets.filter(ticket => ticket.event.id === event.id);

    if (founTickets.length > 0) {
      founTickets.forEach((ticket, index) => {
        if (nfts.find(nft => nft.eventUuid === event.uuid)) {
          const nft = nfts.find(nft => nft.eventUuid === event.uuid);
          if (nft) {
            nft.quantity = nft.quantity + 1;
          }
        } else {
          nfts.push({
            event: event,
            eventUuid: event.uuid,
            quantity: 1,
            beginAt: event.beginAt,
            isOpened: ticket.isOpened,
            tokenUri: ticket.tokenUri,
            rarity:
              founTickets.length > 1 && index > 1
                ? isRarityGreaterThan(ticket?.rarity?.toLowerCase(), founTickets[index - 1]?.rarity?.toLowerCase())
                : ticket?.rarity?.toLowerCase(),
            isLocked: false
          });
        }
      });
    } else {
      nfts.push({
        event: event,
        eventUuid: event.uuid,
        quantity: 0,
        beginAt: event.beginAt,
        isOpened: false,
        tokenUri: '',
        rarity: '',
        isLocked: false
      });
    }
  });
  const nftsWithCommingEvent = [
    ...nfts,
    ...Array.from(
      { length: numberOfEvents - nfts.length },
      (): PitchNft => ({
        event: null,
        eventUuid: '',
        quantity: 0,
        beginAt: null,
        isOpened: false,
        tokenUri: '',
        rarity: '',
        isLocked: true
      })
    )
  ];

  console.log('nftsWithCommingEvent', nftsWithCommingEvent);

  return nftsWithCommingEvent;
};
