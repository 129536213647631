import { Box, IconButton, Stack, Typography, useMediaQuery } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import greyScale from '../../../themes/default/colors/greyscale';
import { useAnimation } from 'framer-motion';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import Card from './card';
import userApi from '../../../services/api/user';
import { capitalize } from '../../../utils/strings';
import { format } from 'date-fns';
import seasonApi, { Season } from '../../../services/api/season';
import { PitchNft, filterEvents } from '../../../utils/seasonUtils';

const scrollToMiddle = () => {
  const middleOfPage = window.innerHeight * 45;
  window.scrollTo({
    top: middleOfPage
  });
};

const isFirstCard = (shift: number, index: number): boolean => shift + index === 0;
const isCardRendered = (shift: number, index: number): boolean =>
  !(shift < 0 && shift + index + 1 < 0) && !(5 - index <= shift);

const textVariants = {
  appear: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      duration: 0.5,
      ease: 'easeInOut'
    }
  },
  disappear: {
    opacity: 0,
    y: 50,
    scale: 1,
    transition: {
      duration: 0.5,
      ease: 'easeInOut'
    }
  }
};

const Pitch: React.FC = () => {
  const { seasonUuid } = useParams();
  const { data: user, isLoading, isError } = userApi.endpoints.getUser.useQuery();
  const { data: userSeason } = seasonApi.endpoints.getSeason.useQuery(seasonUuid!, { skip: !seasonUuid });
  const [cardsArray, setCardsArray] = useState<PitchNft[]>([]);
  const [season, setSeason] = useState<Season>();

  const navigate = useNavigate();
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  const [shift, setShift] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [length, setLength] = useState(0);
  const controls = useAnimation();
  const controls2 = useAnimation();
  let lastAnimateTime = 0;

  const animationConfig = useMemo(() => {
    return {
      animationSpeed: 0.4,
      scrollTimeout: 360,
      scrollStep: window.devicePixelRatio >= 3 ? 20 : 45,
      numberOfDisplayedCardsInTopEdge: 0,
      numberOfDisplayedCardsInBottomEdge: 0,

      positionArray: [
        { x: '59vw', y: '60vh', scale: 1, rotate: 10 },
        { x: 'calc(-25vw - 35%)', y: isDesktop ? 'calc(50vh - 200px)' : 'calc(50vh - 155px)', scale: 1, rotate: -8 },
        { x: 'calc(25vw - 50%)', y: isDesktop ? 'calc(50vh - 320px)' : 'calc(50vh - 320px)', scale: 0.75, rotate: 10 },
        { x: 'calc(-25vw - 55%)', y: isDesktop ? 'calc(50vh - 630px)' : 'calc(50vh - 430px)', scale: 0.7, rotate: -16 },
        { x: 'calc(25vw  - 50%)', y: isDesktop ? 'calc(50vh - 750px)' : 'calc(50vh - 550px)', scale: 0.62, rotate: 5 },
        { x: '-90vw', y: '-35vh', scale: 0.3, rotate: -10 }
      ]
    };
  }, [isDesktop]);

  const getMaxShift = useCallback(
    (prev: number): number => {
      return Math.max(prev - 1, animationConfig.numberOfDisplayedCardsInBottomEdge - 6);
    },
    [length]
  );

  const getMinShift = (prev: number): number => {
    return Math.min(prev + 1, animationConfig.numberOfDisplayedCardsInTopEdge);
  };

  const effectRan = useRef(false);

  useEffect(() => {
    document.body.classList.add('no-scroll');
    let lastScrollY = window.scrollY;
    const handleScroll = async () => {
      let currentScrollY = window.scrollY;
      const currentTime = Date.now();
      if (currentTime - lastAnimateTime > animationConfig.scrollTimeout) {
        if (currentScrollY > lastScrollY + animationConfig.scrollStep) {
          setShift(prev => getMinShift(prev));
          lastAnimateTime = currentTime;
          controls2.start('hide');
        } else if (currentScrollY < lastScrollY - animationConfig.scrollStep) {
          setShift(prev => getMaxShift(prev));
          controls2.start('hide');
          lastAnimateTime = currentTime;
        }
      }

      lastScrollY = currentScrollY;
    };
    if (effectRan.current) {
      scrollToMiddle();
      setTimeout(() => {
        setLoading(false);

        // Check if there are no scroll event listeners before adding one
        if (!hasScrollEventListener) {
          window.addEventListener('scroll', handleScroll);
          hasScrollEventListener = true;
        }
      }, 1000);
    }
    return () => {
      effectRan.current = true;
      window.removeEventListener('scroll', handleScroll);
      document.body.classList.remove('no-scroll');
    };
  }, [getMaxShift]);

  // Add a flag to track whether the scroll listener has been added
  let hasScrollEventListener = false;

  useEffect(() => {
    if (userSeason) {
      setSeason(userSeason);
      const arr = filterEvents(userSeason, user?.tickets || []);
      setCardsArray(arr);
      if (arr.length != 0) setLength(arr.length);
    }
  }, [userSeason]);

  const uniqueTickets = cardsArray.filter(c => c.quantity > 0)?.length ?? 0;

  return (
    <div className="no-scroll">
      <Box position="relative">
        <Box>
          <Box>
            <Box position="fixed" width="100vw" zIndex={100} mt={3} p={isDesktop ? 3 : 1}>
              <Stack direction="column" width="100%" className="card-gradient" borderRadius={4}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={1}
                  p={isDesktop ? 2 : 1}
                  mb={0}
                >
                  <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                    <IconButton onClick={() => navigate(-1)}>
                      <ArrowBackIcon className="text-white" />
                    </IconButton>
                    <Typography variant="h4" className="text-white">
                      {' '}
                      {season?.name}
                    </Typography>
                  </Stack>
                  {/* <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={0.5}>
                    <Star color="primary" />
                    <Typography variant="md" color="primary">
                      {season?.name}
                    </Typography>
                  </Stack> */}
                </Stack>
                {/* TODO delete if not validated  */}
                {/* <Stack
                  direction="row"
                  px={2}
                  alignItems="center"
                  justifyContent="space-between"
                  onClick={() => navigate('/rewards')}>
                  <Stack direction="column" p={1}>
                    <Typography variant="caption">Mes quetes</Typography>
                    <Stack direction="row">
                      <CheckCircleIcon color="primary" />
                      <CheckCircleIcon color="primary" />
                      <RadioButtonUncheckedIcon color="primary" />
                      <RadioButtonUncheckedIcon color="primary" />
                      <RadioButtonUncheckedIcon color="primary" />
                    </Stack>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Stack
                      border={`1px solid ${greyScale[800]}`}
                      borderRadius={100}
                      height={40}
                      width={40}
                      alignItems="center"
                      justifyContent="center">
                      <EmojiEventsIcon sx={{ color: '#27D4FF' }} />
                    </Stack>
                    <Stack
                      border={`1px solid ${greyScale[800]}`}
                      borderRadius={100}
                      height={40}
                      width={40}
                      alignItems="center"
                      justifyContent="center">
                      <CardGiftcardIcon sx={{ color: '#27D4FF' }} />
                    </Stack>
                  </Stack>
                </Stack> */}
              </Stack>
            </Box>

            <Box position="fixed" width="100vw" zIndex={1000} bottom={0}>
              <Stack direction={isDesktop ? 'column' : 'row'} borderRadius={4} m={3} alignItems="center">
                <Typography
                  variant="caption"
                  noWrap
                  textAlign="center"
                  width={isDesktop ? '180px' : 90}
                  fontSize={isDesktop ? '20px' : '13px'}
                  className="text-white"
                >
                  {uniqueTickets}/
                  {
                    //@ts-ignore
                    season ? season?.numberOfEvents ?? 0 : 0
                  }{' '}
                  Événements
                </Typography>
                <Box position="relative" width="89%" height={8}>
                  <Box bgcolor={greyScale[700]} borderRadius={100} width="100%" height={8}></Box>
                  <Box
                    position="absolute"
                    width={(uniqueTickets / (season?.numberOfEvents ?? 1)) * 100 + '%'}
                    height={8}
                    top={0}
                    left={0}
                    className="gradient-bar"
                  ></Box>
                </Box>
              </Stack>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box height="20000vh" pt={20} zIndex={20} overflow={'hidden'}>
                {cardsArray && cardsArray.length > 0 && (
                  <>
                    {cardsArray?.map((item, index) => {
                      return (
                        <div onClick={() => setShift(-index)} key={index}>
                          <Card
                            item={item}
                            key={index}
                            index={index + 1}
                            positionArray={animationConfig.positionArray}
                            controls={controls}
                            shift={shift}
                            animationSpeed={animationConfig.animationSpeed}
                          />
                          {isCardRendered(shift, index) && (
                            <Box
                              position="fixed"
                              display="flex"
                              alignItems="end"
                              sx={{
                                transform: 'translate(-50%, 0%)'
                              }}
                              mt={3}
                              width="100vw"
                              bottom={35}
                              zIndex={1000}
                            >
                              <motion.div
                                initial={{
                                  opacity: 0, // When shift changes, this will make the text disappear
                                  y: 50,
                                  scale: 1
                                }}
                                animate={isFirstCard(index, shift) ? 'appear' : 'disappear'}
                                variants={textVariants}
                                className="w-full"
                              >
                                <Box
                                  display="flex"
                                  alignItems="end"
                                  // ml={5}
                                  px={isDesktop ? 12 : 0}
                                  justifyContent={isDesktop ? 'space-between' : ''}
                                >
                                  <Stack direction="column" borderRadius={4} m={3} sx={{ margin: '0 0 18px 18px' }}>
                                    <Typography
                                      variant={item.isLocked ? 'h3' : isDesktop ? 'h2' : 'h1'}
                                      className="text-white"
                                    >
                                      {item.isLocked ? 'Événement non disponible' : item?.event?.title}
                                    </Typography>
                                  </Stack>
                                  <Stack direction="column" borderRadius={4} m={4}>
                                    <Typography variant="md">
                                      {item.isLocked ? '' : `${capitalize(format(new Date(item?.beginAt!), 'dd/MM '))}`}{' '}
                                    </Typography>
                                  </Stack>
                                </Box>
                              </motion.div>
                            </Box>
                          )}
                        </div>
                      );
                    })}
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="pitch-gradient1"></Box>
        <Box className="pitch-gradient2"></Box>
      </Box>
    </div>
  );
};
export default Pitch;
