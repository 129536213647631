import { useParams } from 'react-router-dom';
import ShowQrCodeLayout from '../components/SowQrCodeLayout';
import UnhandledError from '../../../components/UnhandledError';
import { CircularProgress, Stack } from '@mui/material';
import subscriptionApi from '../../../services/api/subscription';

const ShowSubscriptionQrCodes = () => {
  const { subscriptionUuid } = useParams();

  const {
    data: userSubscription,
    isLoading: isLoading,
    isError: isError
  } = subscriptionApi.endpoints.getUserSubscriptionDetails.useQuery(subscriptionUuid!, {
    skip: !subscriptionUuid,
    refetchOnMountOrArgChange: true
  });

  if (isError) {
    return <UnhandledError />;
  }

  return (
    <div>
      {isLoading || !userSubscription ? (
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} m={2}>
          <CircularProgress />
        </Stack>
      ) : (
        <ShowQrCodeLayout userSubscription={userSubscription} scanType="SUBSCRIPTION" />
      )}
    </div>
  );
};

export default ShowSubscriptionQrCodes;
