import { IconButton } from '@mui/material';
import React, { useState, ReactNode } from 'react';
import { TiChevronLeftOutline, TiChevronRightOutline } from 'react-icons/ti';
import { FaCircleChevronLeft, FaCircleChevronRight } from 'react-icons/fa6';
import RightChevron from '../RightChevron';
import LeftChevron from '../LeftChevron';
interface CarouselProps {
  children: ReactNode;
  setActiveS: (index: number) => void;
  active: number;
}

const CustomCarousel: React.FC<CarouselProps> = ({ children, setActiveS, active }) => {
  // const [active, setActive] = useState<number>(0);
  setActiveS(active);
  const count: number = React.Children.count(children);

  return (
    <div className="relative w-full h-96  perspective-500 transform-style-preserve">
      {React.Children.map(children, (child, i) => (
        <div
          className="card-container absolute w-full h-full transition-all ease-out"
          style={{
            transform: `rotateY(calc(${active - i} * 50deg)) scaleY(calc(1 + ${Math.abs(
              active - i
            )} * -0.4)) translateZ(calc(${Math.abs(active - i)} * -30rem)) translateX(calc(${Math.sign(
              active - i
            )} * -5rem))`,
            filter: `blur(calc(${Math.abs(active - i)} * 1rem))`,
            pointerEvents: active === i ? 'auto' : 'none',
            opacity: Math.abs(active - i) >= 3 ? '0' : '1',
            display: Math.abs(active - i) > 3 ? 'none' : 'block'
          }}
        >
          {child}
        </div>
      ))}
    </div>
  );
};

export default CustomCarousel;
