const primary = {
  100: '#ffe2b4',

  200: '#ffd084',

  300: '#ffbc53',

  400: '#ffad2f',

  500: '#ea580b'
};

export default primary;
