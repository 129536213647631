import { Button, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { t } from 'i18next';
import { Mail } from 'lucide-react';
import { Person } from '@mui/icons-material';
import { FormikValues, useFormik } from 'formik';
import { DialogClose } from '@/components/ui/dialog';

interface UserFormProps {
  formik?: FormikValues;
  annuler: () => void;
  handleIncrementProgress: () => void;
}

function UserForm({ formik, annuler, handleIncrementProgress }: UserFormProps) {
  return (
    <div className="flex flex-col items-center p-10">
      <form onSubmit={formik!.handleSubmit}>
        <div className="flex flex-col justify-center">
          <div className="flex flex-col w-full p-4 space-y-2">
            <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1} spacing={2}>
              <TextField
                id="lastName"
                name="lastName"
                value={formik!.values.lastName}
                onChange={formik!.handleChange}
                variant="outlined"
                placeholder={t('transfer.lastNameTextField') ?? ''}
                fullWidth
                helperText={formik!.touched.lastName && formik!.errors.lastName ? String(formik!.errors.lastName) : ''}
                InputProps={{
                  className: 'h-12 bg-gray-300 dark:bg-gray-800',
                  startAdornment: <Person className="h-[20px] mr-1" />
                }}
              />
              <TextField
                id="firstName"
                name="firstName"
                value={formik!.values.firstName}
                onChange={formik!.handleChange}
                variant="outlined"
                placeholder={t('transfer.firstNameTextField') ?? ''}
                fullWidth
                helperText={
                  formik!.touched.firstName && formik!.errors.firstName ? String(formik!.errors.firstName) : ''
                }
                InputProps={{
                  className: 'h-12 bg-gray-300 dark:bg-gray-800',
                  startAdornment: <Person className="h-[20px] mr-1" />
                }}
              />
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <TextField
                id="email"
                name="email"
                value={formik!.values.email}
                onChange={formik!.handleChange}
                variant="outlined"
                placeholder={t('transfer.emailTextField') ?? ''}
                fullWidth
                helperText={formik!.touched.email && formik!.errors.email ? String(formik!.errors.email) : ''}
                InputProps={{
                  className: 'h-12 bg-gray-300 dark:bg-gray-800',
                  startAdornment: <Mail className="h-[18px] mr-1" />
                }}
              />
            </Stack>
            <div className="h-[50px]"></div>
            <Stack direction="row" spacing={3} justifyContent="center">
              <DialogClose asChild>
                <Button
                  type="button"
                  variant="contained"
                  onClick={annuler}
                  sx={{
                    borderRadius: 3,
                    height: 48,
                    px: 4,
                    width: { xs: '100%', md: '100%' },
                    '&:hover': {
                      backgroundColor: '#6b7280', // Change background color on hover
                      color: 'white' // Change text color on hover
                    }
                  }}
                  className="bg-gray-500 dark:bg-gray-800 text-lg font-bold leading-10"
                >
                  {t('transfer.annulerUserFormButton')}
                </Button>
              </DialogClose>
              <Button
                type="submit"
                onClick={() => {
                  if (
                    formik?.isValid &&
                    formik!.values.email !== '' &&
                    formik!.values.firstName !== '' &&
                    formik!.values.lastName !== ''
                  ) {
                    handleIncrementProgress();
                  }
                }}
                variant="contained"
                sx={{
                  borderRadius: 3,
                  height: 48,
                  background: 'linear-gradient(90deg, #27D4FF 0%, #584CF4 100%)',
                  '&:hover': {
                    background: 'linear-gradient(90deg, #27D4FF 0%, #584CF4 100%)'
                  },
                  px: 4,
                  width: { xs: '100%', md: '100%' }
                }}
                className="text-lg font-bold leading-10"
              >
                {t('transfer.suivantUserFormButton')}
              </Button>
            </Stack>
          </div>
        </div>
      </form>
    </div>
  );
}

export default UserForm;
