import { Button, Typography } from '@mui/material';
import VerifyCircleImage from '../TicketsSelection/VerifyCircleImage';
import InfoImage from '../TicketsSelection/InfoImage';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

function TransferVerified() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const firstName = params.get('firstName');
  const lastName = params.get('lastName');
  const email = params.get('email');
  const nbtickets = params.get('nbtickets');
  const eventName = params.get('eventName');
  return (
    <div className="flex justify-center items-center h-screen">
      <div className="flex flex-col justify-center items-center md:w-[30%] p-10">
        <VerifyCircleImage width="70px" height="70px" />
        <h2 className="text-center mt-2 mb-2">BILLETS TRANSFÉRÉS</h2>
        <p className="text-gray-500 text-center mt-2 mb-2">{nbtickets} Billets pour</p>
        <h3 className="text-center mt-2 mb-2">{eventName}</h3>
        <p className="text-gray-600 text-center mt-2 mb-2">
          Ont été transféré à{' '}
          <span className="font-bold">
            {firstName} {lastName}
          </span>{' '}
          avec succès.
        </p>
        <p className="text-gray-600 text-center mt-2 mb-2">
          Un email vient d’être envoyé à <span className="font-bold">{email} </span>
          pour qu’il puisse récupérer ses billets.
        </p>
        <div className="rounded bg-custom w-full mt-2">
          <div className="flex flex-col m-2">
            <div className="flex flex-row">
              <InfoImage width="25px" height="25px" />
              <h3 className="text-gray-400 mt-0 ml-2">Et ensuite?</h3>
            </div>
            <p className="text-gray-400 mt-2 mb-2">
              Dès que {firstName} aura accepté ses billets, ils disparaitront de votre portefeuille et ne seront plus
              valides pour vous. Si vous changez d’avis, vous pouvez toujours annuler le transfert, tant qu’ils n’ont
              pas été acceptés.
            </p>
          </div>
        </div>
        <div className="w-full">
          <Button
            className="mt-3 w-full"
            onClick={() => navigate('/my-tickets')}
            variant="contained"
            sx={{ borderRadius: 3, height: 48, px: 4, width: { xs: '100%', md: 'auto' } }}
          >
            <Typography variant="lg" fontWeight={700} className="leading-10">
              Continuer
            </Typography>
          </Button>
        </div>
      </div>
    </div>
  );
}
export default TransferVerified;
