import Reservation from './pages/Reservation';
import Checkout from './pages/Checkout';
import { createBrowserRouter, Outlet, RouterProvider, ScrollRestoration } from 'react-router-dom';
import EventDetails from './pages/EventDetails';
import Home from './pages/Home';
import PaymentSuccess from './pages/PaymentSucces';
import MyTickets from './pages/MyTickets';
import TicketDetails from './pages/TicketDetails';
import Root from './Root';
import Login from './pages/Login';
import PhoneLogin from './pages/Login';
import CompleteProfile from './pages/CompleteProfile';
import Profile from './pages/Profile';
import Notifications from './pages/Notifications';
import Marketplace from './pages/Marketplace';
import Settings from './pages/Settings';
import OrganizationDetails from './pages/OrganizationDetails';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { useEffect } from 'react';
import userApi from './services/api/user';
// import { useWeb3Auth } from './services/web3/web3auth';
import BlockchainSettings from './pages/Settings/components/Blockchain';
import EditProfile from './pages/Settings/components/EditProfile';
import { Badge, styled, Typography } from '@mui/material';
import palette from './themes/default/colors/palette';
import { auth } from './services/firebase';
import { getIdToken } from '@firebase/auth';
import Pitch from './pages/Profile/Pitch';
import Claim from './pages/Claim';
import RewardsPage from './pages/Rewards';
import CustomHome from './pages/CustomHome';
import SubscriptionDetails from './pages/SubscriptionDetails';
import SubscriptionHome from './pages/SubscriptionHome';
import SubscriptionReservation from './pages/SubscriptionReservation';
import SubscriptionCheckout from './pages/SubscriptionCheckout';
import SubscriptionPaymentSuccess from './pages/SubscriptionPaymentSucces';
import RootWithoutFooter from './RootWithoutFooter';
import { getWebsiteConfig } from './pages/whiteLabel/themeFactory';
import SwitchApp from './pages/Settings/components/SwitchApp';
import ShowEventQrCodes from './pages/ShowQrCodes/ShowEventQrCodes';
import ShowReservationQrCodes from './pages/ShowQrCodes/ShowReservationQrCodes';
import ShowSubscriptionQrCodes from './pages/ShowQrCodes/ShowSubscriptionQrCodes';
import CustomHomeSainte from './pages/CustomHomeSainte';
import CustomHomeGrizzlys from './pages/CustomHomeGrizzlys';
import LesDeterminesHome from './pages/CustomHome/lesDetermines';
import SporsoraHome from './pages/CustomHome/Sporsora';
import NiceHome from './pages/CustomHome/Nice';
import TicketTransfer from './pages/TicketTransfer';
import TicketsSelection from './pages/TicketsSelection';
import TransferVerified from './pages/TransferVerified';
import ApproveTransfer from './pages/ApproveTransfer';
import CancelVerified from './pages/CancelVerified';
import CustomHomeNantes from './pages/CustomHomeNantes';
import CustomHomeGaya from './pages/CustomHomeGaya';
import CantGetTicket from './pages/TicketDetails/CantGetTicket';
import CustomHomeNancy from './pages/CustomHomeNancy';
import AsNancyHome from './pages/CustomHome/AsNancy';
import CustomHomeBlackVision from './pages/CustomHomeBlackVision';

const CustomBadge = styled(Badge)(({ theme }) => ({
  background: palette.error,
  color: '#fff',
  position: 'fixed',
  right: -10,
  top: -40,
  zIndex: 1,
  transform: 'rotate(-45deg)',
  width: 56,
  height: 110,
  textAlign: 'center',
  padding: '40px 2px',
  boxShadow: theme.shadows[1]
}));

const collectibleHome = () => {
  const id = getWebsiteConfig().id;
  if (id === 'LESDETERMINES') {
    return <LesDeterminesHome />;
  }
  if (id === 'NANCY') {
    return <AsNancyHome />;
  } else if (id === 'NICE') {
    return <NiceHome />;
  } else if (id === 'SPORSORA') {
    return <SporsoraHome />;
  } else {
    return <Home />;
  }
};

const ticketingHome = () => {
  const id = getWebsiteConfig().id;

  if (id === 'BLAGNAC' || id === 'DECATHLON') {
    return <CustomHome />;
  } else if (id === 'SAINTES') {
    return <CustomHomeSainte />;
  } else if (id === 'GRIZZLYS') {
    return <CustomHomeGrizzlys />;
  } else if (id === 'NANTES') {
    return <CustomHomeNantes />;
  } else if (id === 'GAYA') {
    return <CustomHomeGaya />;
  } else if (id === 'NANCY') {
    return <CustomHomeNancy />;
  } else if (id === 'BLACKVISION') {
    return <CustomHomeBlackVision />;
  } else {
    return <Home />;
  }
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootRouteComponent />,
    children: [
      {
        path: '/login',
        element: <Login />
      },
      {
        path: '/login-with-phone',
        element: <PhoneLogin />
      },
      {
        path: '/complete-profile',
        element: <CompleteProfile />
      },
      {
        path: '/',
        element: <Root />,
        children: [
          {
            path: '',
            element: ticketingHome()
          },
          {
            path: 'home',
            element: ticketingHome()
          },
          {
            path: '/marketplace',
            element: <Marketplace />
          },
          {
            path: '/my-tickets',
            element: <MyTickets />
          },
          {
            path: '/messages',
            element: <Notifications />
          },
          {
            path: '/profile',
            element: <Profile />,
            children: []
          }
        ]
      },
      {
        path: '/event/:eventId',
        element: <EventDetails />
      },
      {
        path: '/subscription/:subscriptionUuid',
        element: <SubscriptionDetails />
      },
      {
        path: '/subscriptions',
        element: <SubscriptionHome />
      },
      {
        path: '/subscription-reservation/:subscriptionUuid',
        element: <SubscriptionReservation />
      },
      {
        path: '/subscription-checkout/:subscriptionUuid',
        element: <SubscriptionCheckout />
      },
      {
        path: '/subscription-payment-success/:subscriptionUuid',
        element: <SubscriptionPaymentSuccess />
      },
      {
        path: '/claim/:eventId',
        element: <Claim />
      },
      {
        path: '/organization/:organizationId',
        element: <OrganizationDetails />
      },
      {
        path: '/reservation/:eventId',
        element: <Reservation />
      },
      {
        path: '/checkout/:eventId',
        element: <Checkout />
      },
      {
        path: '/payment-success/:eventId',
        element: <PaymentSuccess />
      },
      {
        path: '/ticket-details/:eventId',
        element: <TicketDetails />
      },
      {
        path: '/transfer-details/:eventId',
        element: <TicketTransfer />
      },
      {
        path: '/tickets-selection/:eventId',
        element: <TicketsSelection />
      },
      {
        path: '/settings',
        element: <Settings />
      },
      {
        path: '/approve-transfer/:transferId',
        element: <ApproveTransfer />
      },
      {
        path: '/transfer-verified',
        element: <TransferVerified />
      },
      {
        path: '/login/:transferId',
        element: <Login />
      },
      {
        path: '/blockchain-settings',
        element: <BlockchainSettings />
      },
      {
        path: '/edit-profile',
        element: <EditProfile />
      },
      import.meta.env.VITE_NODE_ENV === 'development' || import.meta.env.VITE_NODE_ENV === 'staging'
        ? {
            path: '/switch-app',
            element: <SwitchApp />
          }
        : { path: '', element: <></> },
      {
        path: '/pitch/:seasonUuid',
        element: <Pitch />,
        children: []
      },
      {
        path: '/rewards',
        element: <RewardsPage />,
        children: []
      },
      {
        path: '/ticket/event/:eventUuid',
        element: <ShowEventQrCodes />,
        children: []
      },
      {
        path: '/ticket/reservation/:eventUuid/:reservationUuid',
        element: <ShowReservationQrCodes />,
        children: []
      },
      {
        path: '/ticket/subscription/:subscriptionUuid',
        element: <ShowSubscriptionQrCodes />,
        children: []
      },
      //TODO add 404 page for all routes that are not defined
      {
        path: '*',
        element: <Home />
      }
    ]
  }
]);

const collectibleRouter = createBrowserRouter([
  {
    path: '/',
    element: <RootRouteComponent />,
    children: [
      {
        path: '/login',
        element: <Login />
      },
      {
        path: '/login-with-phone',
        element: <PhoneLogin />
      },
      {
        path: '/complete-profile',
        element: <CompleteProfile />
      },
      {
        path: '/',
        element: <RootWithoutFooter />,
        children: [
          {
            path: '',
            element: collectibleHome()
          },
          {
            path: 'home',
            element: collectibleHome()
          },
          {
            path: '/my-tickets',
            element: <MyTickets />
          },
          {
            path: '/profile',
            element: <Profile />,
            children: []
          }
        ]
      },
      {
        path: '/event/:eventId',
        element: <EventDetails />
      },
      {
        path: '/claim/:eventId',
        element: <Claim />
      },
      {
        path: '/organization/:organizationId',
        element: <OrganizationDetails />
      },
      {
        path: '/reservation/:eventId',
        element: <Reservation />
      },
      {
        path: '/checkout/:eventId',
        element: <Checkout />
      },
      {
        path: '/payment-success/:eventId',
        element: <PaymentSuccess />
      },
      {
        path: '/ticket-details/:eventId',
        element: <TicketDetails />
      },
      {
        path: '/transfer-details/:eventId',
        element: <TicketTransfer />
      },
      {
        path: '/approve-transfer/:transferId',
        element: <ApproveTransfer />
      },
      {
        path: '/transfer-verified',
        element: <TransferVerified />
      },
      {
        path: '/login/:transferId',
        element: <Login />
      },
      {
        path: '/ticket/event/:eventUuid',
        element: <ShowEventQrCodes />,
        children: []
      },
      {
        path: '/ticket/reservation/:eventUuid/:reservationUuid',
        element: <ShowReservationQrCodes />,
        children: []
      },
      {
        path: '/ticket/subscription/:subscriptionUuid',
        element: <ShowSubscriptionQrCodes />,
        children: []
      },
      {
        path: '/settings',
        element: <Settings />
      },
      {
        path: '/blockchain-settings',
        element: <BlockchainSettings />
      },
      {
        path: '/edit-profile',
        element: <EditProfile />
      },
      import.meta.env.VITE_NODE_ENV === 'development' || import.meta.env.VITE_NODE_ENV === 'staging'
        ? {
            path: '/switch-app',
            element: <SwitchApp />
          }
        : { path: '', element: <></> },
      {
        path: '/pitch/:seasonUuid',
        element: <Pitch />,
        children: []
      },
      {
        path: '/rewards',
        element: <RewardsPage />,
        children: []
      }
    ]
  }
]);

const getRouter = () => {
  const type = getWebsiteConfig().type;

  if (type === 'marketplace') {
    return router;
  } else if (type === 'collectibles') {
    return collectibleRouter;
  } else if (type === 'ticketing') {
    return router;
  } else {
    return router;
  }
};

function RootRouteComponent() {
  return (
    <div>
      {import.meta.env.VITE_NODE_ENV != 'development' ? null : (
        <CustomBadge>
          <Typography fontWeight={600}>dev</Typography>
        </CustomBadge>
      )}

      <ScrollRestoration />
      <Outlet />
    </div>
  );
}

function AppRouter() {
  const { isAuth } = useAppSelector(state => state.authSlice);
  // const { address, wallet } = useWeb3Auth();
  const dispatch = useAppDispatch();
  const [setPublicKey] = userApi.endpoints.setPublicKey.useMutation();
  const { data: user } = userApi.endpoints.getUser.useQuery();

  // const initUserPublicKey = async () => {
  //   try {
  //     const { currentUser } = auth;
  //     if (currentUser) {
  //       const idToken = await getIdToken(currentUser, true);
  //       await setPublicKey({ idToken, publicKey: address });
  //       dispatch(userApi.util.invalidateTags(['user-details']));
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     throw error;
  //   }
  // };

  // useEffect(() => {
  //   if (isAuth === true && wallet && user) {
  //     if (user.publicKey == '' || user.publicKey == null || user.publicKey == undefined) {
  //       auth.onAuthStateChanged(authUser => {
  //         if (authUser) {
  //           initUserPublicKey();
  //         } else {
  //           return;
  //         }
  //       });
  //     }
  //   }
  // }, [user, isAuth, wallet]);

  return <RouterProvider router={getRouter()} />;
}

export default AppRouter;
