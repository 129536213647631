import { ContentCutOutlined, Mail, Person } from '@mui/icons-material';
import { Box, Button, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { t } from 'i18next';
import greyScale from '../../themes/default/colors/greyscale';
import ticketApi from '@/services/api/ticket';
import { useAppSelector } from '@/redux/hooks';
import image from './Checkbox.png';
import Login from '../Login';
import LocationImage from './LocationImage';
import CalendarImage from './CalendarImage';
import ModalTicket from '../TicketDetails/ModalTicket';
import IfLoggedIn from './IfLoggedIn';
function ApproveTransfer() {
  const { isAuth } = useAppSelector(state => state.authSlice);
  // if (!isAuth) {
  //   return <Login />;
  // }

  // console.log(transfered);
  // console.log(transferId);
  // const [userData, setUserData] = useState({
  //   lastName: '',
  //   firstName: '',
  //   email: ''
  // });
  // const [isValidEmail, setIsValidEmail] = useState(true);

  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;
  //   setUserData({ ...userData, [name]: value });
  // };

  // const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { value } = e.target;
  //   setUserData({ ...userData, email: value });
  //   setIsValidEmail(validateEmail(value));
  // };

  // const validateEmail = (email: string) => {
  //   // Basic email validation
  //   return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  // };

  // const isFormValid = () => {
  //   return (
  //     userData.lastName.trim() !== '' &&
  //     userData.firstName.trim() !== '' &&
  //     userData.email.trim() !== '' &&
  //     isValidEmail
  //   );
  // };

  return !isAuth ? <Login /> : <IfLoggedIn />;
}

export default ApproveTransfer;
