import { Button } from '@mui/material';
import { DialogClose } from '@/components/ui/dialog';
import { t } from 'i18next';

function BilletsTransferer() {
  return (
    <div className="flex flex-col w-full items-center justify-center">
      <img src="/group.png" className="w-32 h-32" alt="Group" />
      <p className="text-center mb-4">
        {t('transfer.pourPlusSecurité')}
        <br />
        {t('transfer.quUneSeule')}
        <br />
        {t('transfer.vousNePouvez')}
      </p>
      <DialogClose asChild>
        <Button
          type="submit"
          variant="contained"
          sx={{ borderRadius: 3, height: 48, px: 4, width: '70%' }}
          className="text-lg font-bold leading-10"
        >
          {t('transfer.fermerButton')}
        </Button>
      </DialogClose>
    </div>
  );
}

export default BilletsTransferer;
