import { useState } from 'react';
import { TicketCategory } from '../../../../services/api/ticket-category';
import { Stack, Typography, IconButton, alpha, Collapse, Box, Popover } from '@mui/material';
import { t } from 'i18next';
import palette from '../../../../themes/default/colors/palette';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import TicketRank from '../TicketRank';
import { Info } from '@mui/icons-material';

interface TicketCategoryProps {
  category: TicketCategory;
  eventId: string;
  isAvailable: boolean;
  type: 'subscription' | 'event';
}

const TicketCategoryComponent = (props: TicketCategoryProps) => {
  const [expanded, setExpanded] = useState(true);
  const [showDescription, setShowDescription] = useState(false);
  const hasSubCategories = props.category.subTicketCategory.length > 0;

  const handleClick = () => {
    setShowDescription(!showDescription);
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        onClick={props.isAvailable ? () => setExpanded(!expanded) : () => null}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="h6" sx={{ opacity: props.isAvailable ? 1 : 0.4 }}>
            {props.category.name}
          </Typography>
          {props.category.description && (
            <IconButton onClick={handleClick}>
              <Info />
            </IconButton>
          )}
        </Stack>
        {hasSubCategories && (
          <>
            {expanded ? (
              <IconButton onClick={() => setExpanded(false)}>
                <ArrowDropDownIcon />
              </IconButton>
            ) : !props.isAvailable ? (
              <Box bgcolor={alpha(palette.error, 0.12)} borderRadius={1} height={24} pr={2} pl={2}>
                <Typography variant="xs" color="error.main" fontWeight={700}>
                  {t('reservation.notAvailable')}
                </Typography>
              </Box>
            ) : (
              <IconButton onClick={() => setExpanded(true)}>
                <ArrowRightIcon />
              </IconButton>
            )}
          </>
        )}
      </Stack>
      {hasSubCategories ? (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Stack direction="column" spacing={1} mt={1.5}>
            {props.category.subTicketCategory.map(sub => (
              <TicketRank
                key={sub.name}
                subTicketCategory={sub}
                eventId={props.eventId}
                categoryName={props.category.name}
                isAvailable={props.isAvailable}
                hasSubCategories={true}
                type={props.type}
                showDescription={showDescription}
              />
            ))}
          </Stack>
        </Collapse>
      ) : (
        <TicketRank
          key={props.category.name}
          subTicketCategory={props.category}
          eventId={props.eventId}
          categoryName={props.category.name}
          isAvailable={props.isAvailable}
          hasSubCategories={false}
          type={props.type}
          showDescription={showDescription}
        />
      )}
    </>
  );
};

export default TicketCategoryComponent;
