const primary = {
  100: '#EEEDFE',

  200: '#BCB7FB',

  300: '#9B94F8',

  400: '#7970F6',

  500: '#584CF4'
};

export default primary;
