import { Typography, Card, Stack, Divider, Box, useMediaQuery } from '@mui/material';
import { format, sub } from 'date-fns';
import { t } from 'i18next';
import { useAppSelector } from '../../../../redux/hooks';
import { HomeEvent } from '../../../../services/api/event';
import greyScale from '../../../../themes/default/colors/greyscale';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { capitalize } from '../../../../utils/strings';
import PromoChipTag from '../../../Reservation/components/PromoChipTag';
import SubscriptionBasketCard from './SubscriptionBasketCard';
import { Subscription } from '../../../../services/api/subscription';
import TicketIcon from '../../../../assets/images/icons/ticket';
import { useSearchParams } from 'react-router-dom';

interface BasketCardProps {
  event?: HomeEvent;
  showEventCard?: boolean;
  subscription?: Subscription;
}

const BasketCard = (props: BasketCardProps) => {
  const { tickets: selectedTickets, finalPrice, srcPrice } = useAppSelector(app => app.shoppingCartSlice);
  const { event, showEventCard, subscription } = props;
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  let [urlParams, setUrlParams] = useSearchParams();
  const isRewardFromUrl = urlParams.get('isReward');

  if (props.subscription) {
    return <SubscriptionBasketCard subscription={props.subscription} />;
  }

  if (!event) {
    return null;
  }

  return (
    <Card variant="contained" sx={{ p: 3, mb: 3 }}>
      {isDesktop && showEventCard ? (
        <>
          <Stack direction="row" spacing={1}>
            <Box pb={0} height={100}>
              <img
                height={100}
                src={event.cover!}
                alt="event cover"
                style={{ borderRadius: 20, objectFit: 'contain' }}
              />
            </Box>
            <Box>
              <Stack direction="column" spacing={1}>
                <Typography variant="h4">{event.title}</Typography>
                <Typography variant="xl" color="primary" fontWeight={600}>
                  {`${capitalize(format(new Date(event?.beginAt!), "eee dd MMMM - HH'h'mm"))}`}
                </Typography>
                <Stack direction="row" justifyContent="space-between">
                  <Stack direction="row" alignItems="end">
                    <LocationOnIcon sx={{ width: 20, height: 20, mr: 1 }} />
                    <Typography variant="xl" color={greyScale[700]}>
                      {event?.address?.name} {`, `} {event?.address?.city}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Box>
          </Stack>
          <Box mt={2} mb={2}>
            <Divider />
          </Box>
        </>
      ) : null}
      {selectedTickets?.map((ticket, index) => (
        <div key={ticket.categoryUuid + index}>
          <Stack direction="row" justifyContent="space-between" mb={1}>
            <Typography variant="md" color={greyScale[500]} alignItems="center" display="flex">
              {' '}
              <span className="font-bold text-lg">{ticket.quantity} x </span>
              <TicketIcon size="16" className="ml-1 mr-4 w-[16px]" />
              {` ${ticket.categoryName == null ? '' : `${ticket.categoryName}`} 
            `}
            </Typography>
            {!isRewardFromUrl && (
              <Typography
                variant="lg"
                color={ticket.promo ? greyScale[800] : greyScale[600]}
                fontWeight={600}
                sx={{ textDecoration: ticket.promo ? 'line-through' : 'none' }}
              >{`${Intl.NumberFormat('fr-FR', {
                style: 'currency',
                currency: 'EUR'
              }).format(ticket.srcPrice * ticket.quantity)}`}</Typography>
            )}
          </Stack>
          {ticket.promo && (
            <Stack direction="row" justifyContent="space-between" mb={1}>
              <PromoChipTag promo={ticket.promo} quantity={ticket.quantity} srcPrice={ticket.srcPrice} />
              {!isRewardFromUrl && (
                <Typography variant="lg" color={greyScale[600]} fontWeight={600}>{`${Intl.NumberFormat('fr-FR', {
                  style: 'currency',
                  currency: 'EUR'
                }).format(ticket.finalPrice * ticket.quantity)}`}</Typography>
              )}
            </Stack>
          )}
        </div>
      ))}
      <Divider />
      <Stack direction="row" justifyContent="space-between" mt={2}>
        <Typography variant="md" color={greyScale[500]}>
          {t('checkout.total')}
        </Typography>
        {!isRewardFromUrl && (
          <Typography
            variant="lg"
            color={greyScale[600]}
            fontWeight={600}
            sx={{ textDecoration: selectedTickets.find(e => e.promo) ? 'line-through' : 'none' }}
          >{`${Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'EUR'
          }).format(srcPrice)}`}</Typography>
        )}
      </Stack>
      {selectedTickets.find(e => e.promo) && (
        <Stack direction="row" justifyContent="space-between" mt={2}>
          <Typography variant="md" color={greyScale[500]}></Typography>
          {!isRewardFromUrl && (
            <Typography variant="lg" color={greyScale[600]} fontWeight={600}>{`${Intl.NumberFormat('fr-FR', {
              style: 'currency',
              currency: 'EUR'
            }).format(finalPrice)}`}</Typography>
          )}
        </Stack>
      )}
    </Card>
  );
};

export default BasketCard;
