import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { isDesktop, isMobile } from 'react-device-detect';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger
} from '@/components/ui/drawer';
// import {
//   Dialog,
//   DialogContent,
//   DialogDescription,
//   DialogFooter,
//   DialogHeader,
//   DialogTitle,
//   DialogTrigger
// } from '@/components/ui/dialog';
import * as Dialog from '@radix-ui/react-dialog';
import { useFormik } from 'formik';
import { Stack } from '@mui/system';
import { t, use } from 'i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
// import 'add-to-calendar-button/assets/css/atcb.css';
import palette from '../../themes/default/colors/palette';
import greyScale from '../../themes/default/colors/greyscale';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import ticketApi, { Ticket } from '../../services/api/ticket';
import RevealModal from './RevealModal';
import UnhandledError from '../../components/UnhandledError';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import noReveal from '../../assets/images/card/no-reveal.png';
import { Variants, motion, useAnimation } from 'framer-motion';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { authSlice } from '../../services/auth';
import eventApi, { Event } from '../../services/api/event';
import NoTransferModal from './NoTransferModal';
import ResponsiveTilt from '../../components/ResponsiveTilt';
import useTokenUri from '../../hooks/useTokenUri';
import { getWebsiteConfig } from '../whiteLabel/themeFactory';
import QrCodeScannerRoundedIcon from '@mui/icons-material/QrCodeScannerRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import DetailsCard from './DetailsCard';
import CategoryCard from './CategoryCard';
import { Person, Sell } from '@mui/icons-material';
import SyncAltRoundedIcon from '@mui/icons-material/SyncAltRounded';
import NorthRoundedIcon from '@mui/icons-material/NorthRounded';
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from '@/components/ui/carousel';
import { Mail } from 'lucide-react';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';

import { useTheme as useShadCnTheme } from '@/components/theme-provider';
import TransferButton from './TransferEnCoursButton/indes';
import TransferDetailModal from '../TicketsSelection/TransferDetailModal';
import ClockImage from './ClockImage';
import TicketInTransfer from './TicketInTransfer';
import AlreadyTransfered from './AlreadyTransfered';
import { current } from '@reduxjs/toolkit';
import CustomCarousel from './Carousel';
import UserForm from './UserForm';
import * as Yup from 'yup';
import TicketBody from './TicketBody';
import TicketsSelectionButtons from './TicketsSelectionButtons';
import ModalBody from './ModalBody';
import ProgressBar from './ProgressBar';
import BilletsTransferer from './BilletsTransferer';
import { PopoverClose } from '@radix-ui/react-popover';
import RightChevron from './RightChevron';
import LeftChevron from './LeftChevron';
import Paginator from './Paginator';
interface NftCardProps {
  eventId: string;
  ticket: Ticket;
  event: Event;
  open: any;
  isActive: boolean;
  status: string;
  setOpen: () => void;
  setTicketInfo: (info: TicketInfo) => void;
  currentInfo: TicketInfo;
}
interface TicketInfo {
  status: boolean;
  email: string;
  uuid: string;
}
function SampleNextArrow(props: any) {
  const { onClick } = props;
  return (
    <Stack
      display="block"
      bottom="-13%"
      right={24}
      position="absolute"
      sx={{ zIndex: 300, transform: 'translateY(-50%)' }}
    >
      <IconButton onClick={onClick} sx={{ backgroundColor: palette.dark3 }}>
        <NavigateNextIcon sx={{ color: '#FFF', width: 20, height: 20 }}></NavigateNextIcon>
      </IconButton>
    </Stack>
  );
}

function SamplePrevArrow(props: any) {
  const { onClick } = props;
  return (
    <Stack
      display="block"
      bottom="-13%"
      position="absolute"
      left={24}
      sx={{ transform: 'translate(0, -50%)', zIndex: 300 }}
      onClick={onClick}
    >
      <IconButton onClick={onClick} sx={{ backgroundColor: palette.dark3 }}>
        <NavigateBeforeIcon sx={{ color: '#FFF', width: 20, height: 20 }}></NavigateBeforeIcon>
      </IconButton>
    </Stack>
  );
}

const getRandomTransformOrigin = () => {
  const value = (16 + 40 * Math.random()) / 100;
  const value2 = (15 + 36 * Math.random()) / 100;
  return {
    originX: value,
    originY: value2
  };
};

const variants: Variants = {
  start: (i: number) => ({
    rotate: [0, 2, 0, -2, 0],
    transition: {
      delay: 0.5,
      repeat: 1,
      duration: 0.8,
      bounce: 0.5,
      power: 0.5
    }
  }),
  reset: {
    rotate: 0
    // x: 0
  }
};

const NftCard = (props: NftCardProps) => {
  const { nftImage } = useTokenUri({ ticket: props.ticket });
  const controls = useAnimation();
  const {
    data: status,
    isLoading,
    isError
  } = ticketApi.endpoints.getTicketTransferStatus.useQuery({
    ticketUuid: props.ticket.uuid!,
    eventUuid: props.event.uuid
  });
  const updateInfo = () => {
    props.setOpen();
    console.log(status);
    if (status?.status == 'IN_TRANSFER') {
      props.setTicketInfo({ status: true, email: status.receiverEmail, uuid: props.ticket.uuid });
    } else if (status?.status) {
      props.setTicketInfo({ status: false, email: status.receiverEmail, uuid: props.ticket.uuid });
    }
  };

  useEffect(() => {
    if (!props.ticket.isOpened && props.ticket.nftId !== null) {
      controls.start('start');
    } else {
      controls.start('reset');
    }
  }, [props.ticket, controls]);

  useEffect(() => {
    // every 5 seconds
    const interval = setInterval(() => {
      controls.start('start');
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  return (
    <div className="relative flex items-center justify-center h-[400px]">
      {props?.status === 'IN_TRANSFER' && (
        <>
          {isDesktop ? (
            <Button
              onClick={updateInfo}
              className="absolute  top-[60%] left-1/2 transform -translate-x-1/2  z-10 bg-[#584CF4] text-white rounded-full  flex items-center"
            >
              <svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg" fill="#ffffff" className="h-6 w-6">
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    fill="#fcfcfc"
                    fillRule="evenodd"
                    d="M207.960546,159.843246 L210.399107,161.251151 C210.637153,161.388586 210.71416,161.70086 210.580127,161.933013 C210.442056,162.172159 210.144067,162.258604 209.899107,162.117176 L207.419233,160.68542 C207.165323,160.8826 206.846372,161 206.5,161 C205.671573,161 205,160.328427 205,159.5 C205,158.846891 205.417404,158.291271 206,158.085353 L206,153.503423 C206,153.22539 206.231934,153 206.5,153 C206.776142,153 207,153.232903 207,153.503423 L207,158.085353 C207.582596,158.291271 208,158.846891 208,159.5 C208,159.6181 207.986351,159.733013 207.960546,159.843246 Z M206.5,169 C211.746705,169 216,164.746705 216,159.5 C216,154.253295 211.746705,150 206.5,150 C201.253295,150 197,154.253295 197,159.5 C197,164.746705 201.253295,169 206.5,169 Z"
                    transform="translate(-197 -150)"
                  ></path>
                </g>
              </svg>
              <span className="ml-2 whitespace-nowrap">Transfert en cours</span>
              {/* Include your SVG icon here */}
            </Button>
          ) : (
            <Drawer>
              <DrawerTrigger asChild>
                <Button
                  onClick={updateInfo}
                  className="absolute w-32  top-[60%] left-1/2 transform -translate-x-1/2  z-10 bg-[#584CF4] text-white rounded-full  flex items-center"
                >
                  <svg
                    viewBox="0 0 19 19"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#ffffff"
                    className="h-3 w-3 ml-0 mr-1"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        fill="#fcfcfc"
                        fillRule="evenodd"
                        d="M207.960546,159.843246 L210.399107,161.251151 C210.637153,161.388586 210.71416,161.70086 210.580127,161.933013 C210.442056,162.172159 210.144067,162.258604 209.899107,162.117176 L207.419233,160.68542 C207.165323,160.8826 206.846372,161 206.5,161 C205.671573,161 205,160.328427 205,159.5 C205,158.846891 205.417404,158.291271 206,158.085353 L206,153.503423 C206,153.22539 206.231934,153 206.5,153 C206.776142,153 207,153.232903 207,153.503423 L207,158.085353 C207.582596,158.291271 208,158.846891 208,159.5 C208,159.6181 207.986351,159.733013 207.960546,159.843246 Z M206.5,169 C211.746705,169 216,164.746705 216,159.5 C216,154.253295 211.746705,150 206.5,150 C201.253295,150 197,154.253295 197,159.5 C197,164.746705 201.253295,169 206.5,169 Z"
                        transform="translate(-197 -150)"
                      ></path>
                    </g>
                  </svg>
                  <span className="text-xs ml-0 whitespace-nowrap">Transfert en cours</span>
                  {/* Include your SVG icon here */}
                </Button>
              </DrawerTrigger>
              <DrawerContent onPointerDownOutside={e => e.preventDefault()}>
                {props.currentInfo?.status ? (
                  <>
                    <TicketInTransfer
                      isDrawer={true}
                      eventId={props.eventId}
                      email={props.currentInfo.email}
                      uuid={props.currentInfo.uuid}
                      setOpen={() => {}}
                    />
                    <div className="h-[50px]"></div>
                  </>
                ) : (
                  <>
                    <AlreadyTransfered setOpen={() => {}} />
                    <div className="h-[50px]"></div>
                  </>
                )}
              </DrawerContent>
            </Drawer>
          )}
        </>
      )}
      <div>
        {props.ticket.isOpened ? (
          <>
            <div className={`filter ${props?.status === 'IN_TRANSFER' ? 'grayscale' : ''}`}>
              <ResponsiveTilt>
                <Stack alignItems="center" justifyContent="center" height="auto" m={3} borderRadius={8}>
                  <div className="p-1 bg-opacity-90 rounded-lg text-gray-400 transition-all ease-out">
                    <motion.div
                      // whileHover={{ scale: 1.06 }}
                      // whileTap={{ scale: 0.9 }}
                      // onClick={onClick}
                      className="nft-image-card"
                    >
                      <motion.img
                        className={`left-0 h-[300px]`}
                        // src="https://tickie-dev-2.s3.amazonaws.com/event/d85ea2eb-4297-4ac1-8932-02e328a22911/nft/1.webp"
                        src={nftImage}
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        transition={{ type: 'spring', stiffness: 300, bounce: 0.35 }}
                      />
                    </motion.div>
                  </div>
                </Stack>
              </ResponsiveTilt>
            </div>
          </>
        ) : (
          <Stack alignItems="center" justifyContent="center">
            <ResponsiveTilt>
              <motion.div
                // style={{
                //   transformOrigin: getRandomTransformOrigin(),
                // }}
                style={{
                  ...getRandomTransformOrigin()
                }}
                // -- controls --
                custom={0}
                variants={variants}
                animate={controls}
              >
                <motion.div
                  className={`nft-image-card flex justify-center w-full ${
                    props?.status === 'IN_TRANSFER' ? 'filter grayscale' : ''
                  }`}
                  whileHover={{ scale: 1.06 }}
                  whileTap={{ scale: 0.9 }}
                  // onClick={onClick}
                >
                  <Stack
                    alignItems="center"
                    justifyContent="end"
                    height={{ xs: 300, md: 300 }}
                    nft-image-card
                    width={{ xs: 230, md: 230 }}
                    m={3}
                    sx={{ backgroundImage: `url(${noReveal})`, backgroundSize: 'cover' }}
                  >
                    {props.ticket.nftId !== null && props?.status !== 'IN_TRANSFER' ? (
                      <Button
                        variant="gradient"
                        sx={{
                          borderRadius: 100,
                          mb: 3,
                          height: { xs: 36, sm: 36, md: 46 }, // Adjusted height for extra small and small screens
                          width: { xs: 110, sm: 110, md: 'auto' },
                          px: { xs: 4, sm: 3 },
                          fontSize: { xs: '0.6rem', sm: '0.6rem', md: '1rem' },
                          whiteSpace: 'nowrap'
                        }}
                        onClick={props.open}
                      >
                        {t('myTickets.discoverTicket')}
                      </Button>
                    ) : (
                      props?.status !== 'IN_TRANSFER' && (
                        <Button variant="gradient" sx={{ borderRadius: 100, mb: 3, height: 46 }} onClick={props.open}>
                          {t('myTickets.discoverTicket')}
                          {/* {props.status === 'IN_TRANSFER' && <CircularProgress sx={{ color: '#FFF', ml: 1 }} size={24} />} */}
                        </Button>
                      )
                    )}
                  </Stack>
                </motion.div>
              </motion.div>
            </ResponsiveTilt>
            <Typography></Typography>
          </Stack>
        )}
      </div>
    </div>
  );
};

function TicketDetails() {
  const [ticketTranfer, setTicketTransfer] = useState(false);
  const [active, setActive] = useState<number>(0);
  const [transferEnCours, setTransferEnCours] = useState(false);
  function resetTransferEnCours() {
    setTransferEnCours(false);
  }
  const [confirmSelection, setConfirmSelection] = useState(false);
  const [selectedTickets, setSelectedTickets] = useState<{ id: number; uuid: string; category: string }[]>([]);
  const [validForm, setValidForm] = useState(false);
  const { eventId } = useParams();
  const [currentInfo, setInfo] = useState({ status: false, email: '', uuid: '' });
  const { data: event } = eventApi.endpoints.getEventDetails.useQuery(eventId ?? '');
  const { data: eventWithTickets } = ticketApi.endpoints.getTicketDetails.useQuery(eventId!, { skip: !eventId });
  const allTicketsApproved = eventWithTickets?.tickets.every(ticket => ticket?.status === 'TRANSFER_APPROVED');
  const [openRevealModal, setRevealModal] = useState(false);
  const mapRef = useRef<null | HTMLDivElement>(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const [isAnimationOpen, setAnimationOpen] = useState(false);
  const [openTicket] = ticketApi.endpoints.open.useMutation();
  const { darkTheme } = useAppSelector(state => state.themeSlice);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const sliderRef = useRef<Slider>(null);
  const [transferModal, setTransferModal] = useState(false);
  const [isTransfert, setIsTransfert] = useState(false);
  const { isAuth } = useAppSelector(state => state.authSlice);
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  const [showTransfertPage, setShowTransfertPage] = useState(false);
  const [open, setOpen] = useState(false);

  const filteredTickets = eventWithTickets?.tickets.filter(
    ticket => ticket?.status !== 'IN_TRANSFER' && ticket?.status !== 'TRANSFER_APPROVED'
  );
  const handleTicketTransferStatus = (status: boolean) => {
    setTicketTransfer(status);
  };
  const handleConfirmSelection = () => {
    setConfirmSelection(true);
    handleIncrementProgress();
  };
  const [progress, setProgress] = useState(30);

  const handleIncrementProgress = () => {
    // Increment progress by a fixed amount (e.g., 10)
    const newProgress = progress + 35;
    // Ensure progress does not exceed the maximum value (e.g., 100)
    setProgress(newProgress);
  };
  const handleTicketSelect = (ticketId: number, ticketUuid: string, category: string) => {
    setSelectedTickets(prevState => {
      // Check if ticket is already selected
      const index = prevState.findIndex(ticket => ticket.id === ticketId);
      if (index !== -1) {
        // Deselect ticket if already selected
        return prevState.filter(ticket => ticket.id !== ticketId);
      } else {
        // Select ticket if not selected
        return [...prevState, { id: ticketId, uuid: ticketUuid, category: category }];
      }
    });
  };
  const {
    data: tickets,
    isLoading,
    isError
  } = ticketApi.endpoints.getTicketDetails.useQuery(eventId!, { skip: !eventId });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: '',
      lastName: '',
      email: ''
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required(t('transfer.firstNameMessage')!),
      lastName: Yup.string().required(t('transfer.lastNameMessage')!),
      email: Yup.string().email(t('transfer.emailMessage')!).required(t('transfer.emailMessage')!)
    }),
    onSubmit: async values => {
      try {
        setValidForm(true);
        console.log(formik.values.email, formik.values.firstName, formik.values.lastName);
      } catch (error) {}
    }
  });

  const { data: status } = ticketApi.endpoints.getTicketTransferStatus.useQuery({
    ticketUuid: tickets ? tickets?.tickets[active]?.uuid : '',
    eventUuid: eventId ? eventId : ''
  });
  const EditForm = () => {
    setValidForm(false);
    setProgress(prev => prev - 35);
  };
  const editTicketSelection = () => {
    setConfirmSelection(false);
    setProgress(prev => prev - 35);
  };
  const handleCancelTicketSelection = () => {
    handleCancelForm();
    setValidForm(false);
    setConfirmSelection(false);
    setSelectedTickets([]);
    setProgress(30);
  };
  const handleCancelForm = () => {
    setProgress(30);
    formik.setTouched({});
    formik.setErrors({}); // Clearing all errors
    formik.values.email = '';
    formik.values.firstName = '';
    formik.values.lastName = '';
  };
  const shadCnTheme = useShadCnTheme();

  const appId = getWebsiteConfig()?.id;
  const setActiveS = (index: number) => {
    setActiveSlide(index);
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (tickets && tickets.tickets?.find(t => t?.status === 'TO_BE_MINTED')) {
        dispatch(ticketApi.util.invalidateTags([{ type: 'ticket-details', id: eventId! }]));
      }
    }, 3000);
    return () => clearInterval(intervalId);
  }, [dispatch, tickets]);

  const revealNft = async (ticketUuid: string, eventUuid: string) => {
    await openTicket(ticketUuid).unwrap();
    setRevealModal(true);
    dispatch(ticketApi.util.invalidateTags([{ type: 'ticket-details', id: eventUuid }]));
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={`calc(100vh)`}>
        <CircularProgress />
      </Box>
    );
  }

  if (!isAuth) {
    navigate('/my-tickets');
  }

  if (isError) {
    return <UnhandledError />;
  }

  const settings = {
    className: 'slick-center',
    dots: tickets?.tickets && tickets?.tickets?.length > 10 ? false : true,
    speed: 500,
    slidesToScroll: 1,
    centerMode: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    customPaging: (i: any) => (
      <Typography
        variant="h6"
        color={i == activeSlide ? (darkTheme ? 'white' : 'black') : darkTheme ? greyScale[800] : greyScale[300]}
      >
        ●
      </Typography>
    ),
    // centerPadding: '60px',
    // beforeChange: (current: number, next: number) => setActiveSlide(next),
    afterChange: (current: number) => setActiveSlide(current),
    responsive: [
      {
        breakpoint: 10000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: tickets?.tickets && tickets?.tickets?.length > 2 ? 3 : 1,
          infinite: true,
          dots: tickets?.tickets && tickets?.tickets?.length > 10 ? false : true,
          centerMode: true
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: tickets?.tickets && tickets?.tickets?.length > 2 ? 3 : 1,
          infinite: true,
          dots: tickets?.tickets && tickets?.tickets?.length > 10 ? false : true
        }
      },
      {
        breakpoint: 1124,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: tickets?.tickets && tickets?.tickets?.length > 10 ? false : true
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: tickets?.tickets && tickets?.tickets?.length > 10 ? false : true
        }
      },

      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: tickets?.tickets && tickets?.tickets?.length > 10 ? false : true
        }
      }
    ]
  };

  if (showTransfertPage) {
    // here we have the transfer page
  }

  function AppBar() {
    const theme = useTheme();
    console.log(theme.palette.primary);
    return (
      <Stack direction="row" zIndex="tooltip" position="absolute" left={0} top={0} m={1}>
        <Link to={appId === 'NANCY' ? '/' : '/my-tickets'}>
          <IconButton>
            <ArrowBackIcon color="primary" />
          </IconButton>
        </Link>
      </Stack>
    );
  }

  if (isAnimationOpen) {
    return (
      <>
        {tickets && (
          <RevealModal
            ticket={tickets.tickets[activeSlide]}
            event={tickets.event}
            openRevealModal={openRevealModal}
            handleCloseRevealModal={() => setAnimationOpen(false)}
          />
        )}
      </>
    );
  }

  // const logout = async () => {
  //   try {
  //     dispatch(authSlice.actions.removeCredentials());
  //     // dispatch(baseApi.util.resetApiState());
  //     localStorage.clear();
  //     await logout();
  //     navigate('/my-tickets');
  //   } catch (error) {}
  // };

  const theme = useTheme();
  const logo = getWebsiteConfig()?.logo;
  const orgaName = getWebsiteConfig()?.name;

  if (!tickets || tickets?.tickets?.length === 0) {
    return (
      <>
        <Typography variant="h6" textAlign="center" color={palette.error} mt={12}>
          {' '}
          {t('myTickets.noTicketFound')}
        </Typography>
        <UnhandledError />
      </>
    );
  }

  const mobileSubHeader = () => (
    <Stack direction="column" pt={6} mt={-5} width="100%">
      <Stack direction="row" justifyContent="center">
        <img src={logo} width={40} />
      </Stack>
      <Stack direction="row" justifyContent="left" alignItems="center" m={3} spacing={1}>
        <Box
          bgcolor={palette.dark2}
          borderRadius={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          p={0.5}
        >
          <img src={logo} width={20} />
        </Box>
        <Typography
          variant="md"
          fontWeight={700}
          // color={greyScale[50]}
          textAlign="center"
        >
          {orgaName}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="center" alignItems="end">
        <Typography
          variant="h4"
          //  color={greyScale[50]}
          textAlign="center"
        >
          {tickets?.event?.title}
        </Typography>
        <Typography
          variant="xl"
          //  color={greyScale[50]}
          textAlign="center"
        >
          &nbsp;{' '}
          {tickets.tickets[activeSlide].nftId === null && (
            <span
              style={{
                backgroundColor: 'orange',
                borderRadius: 100,
                paddingLeft: 6,
                paddingRight: 6,
                paddingBottom: 2,
                fontSize: 13
              }}
            >
              mint
            </span>
          )}
        </Typography>
      </Stack>
      <Typography variant="lg" color="primary" textAlign="center" mt={0.5}>
        {tickets.event.subTitle}
      </Typography>
    </Stack>
  );
  const desktopSubHeader = () => (
    <Stack direction="column" pt={6} mt={3} width="100%" maxWidth={450}>
      <TransferDetailModal open={open} onClose={() => setOpen(false)}>
        {currentInfo?.status ? (
          <TicketInTransfer
            isDrawer={false}
            eventId={eventId!}
            email={currentInfo.email}
            uuid={currentInfo?.uuid}
            setOpen={() => setOpen(false)}
          />
        ) : (
          <AlreadyTransfered setOpen={() => setOpen(false)} />
        )}
      </TransferDetailModal>
      <Typography variant="h6" textAlign="left" mt={3}>
        BILLET #{tickets.tickets[activeSlide].nftId} &nbsp;
        {event?.chain === 'CHILIZ' && <img alt="matic" src="/images/chains/chiliz.png" height={16} />}
        {event?.chain === 'POLYGON' && <img alt="matic" src="/images/chains/polygon.png" height={16} />}
      </Typography>
      <Stack direction="row" justifyContent="left" alignItems="center" m={2} ml={0} spacing={1}>
        <Box
          bgcolor={palette.dark2}
          borderRadius={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          p={0.5}
        >
          <img src={logo} width={20} />
        </Box>
        <Typography
          variant="sm"
          fontWeight={700}
          //  color={greyScale[50]}
          textAlign="left"
        >
          {orgaName}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="left" alignItems="end">
        <Typography
          variant="h5"
          // color={greyScale[50]}
          textAlign="left"
        >
          {tickets?.event?.title}
        </Typography>
        <Typography
          variant="xl"
          // color={greyScale[50]}
          textAlign="left"
        >
          &nbsp;{' '}
          {tickets.tickets[activeSlide].nftId === null && (
            <span
              style={{
                backgroundColor: 'orange',
                borderRadius: 100,
                paddingLeft: 6,
                paddingRight: 6,
                paddingBottom: 2,
                fontSize: 13
              }}
            >
              mint
            </span>
          )}
        </Typography>
      </Stack>
      <Typography variant="md" color="primary" textAlign="left" mt={0.5}>
        {tickets.event.subTitle}
      </Typography>
      <Stack direction="column" justifyContent="center" alignItems="center">
        {appId !== 'NANCY' && (
          <Button
            variant="gradient"
            fullWidth
            // disabled={tickets.tickets[active].status === 'IN_TRANSFER'}
            onClick={async () => {
              const encryptedPrivateKey = localStorage.getItem('encryptedPrivateKey') ?? '';
              // if (encryptedPrivateKey !== '') {
              if (tickets.tickets[active]?.status === 'IN_TRANSFER') {
                setOpen(true);
                if (status?.status == 'IN_TRANSFER') {
                  setInfo({ status: true, email: status?.receiverEmail, uuid: tickets?.tickets[active]?.uuid });
                } else if (status?.status) {
                  setInfo({ status: false, email: status?.receiverEmail, uuid: tickets?.tickets[active]?.uuid });
                }
              } else {
                navigate(`/ticket/event/${tickets.event?.uuid}`);
              }

              // } else {
              // await logout();
              // }
            }}
            sx={{
              borderRadius: 3,
              height: 48,
              px: 4,
              backgroundImage:
                tickets.tickets[active]?.status === 'IN_TRANSFER'
                  ? `linear-gradient(to right, #9497A0, #D5D1E2)`
                  : undefined,
              my: 2,
              zIndex: 0
            }}
          >
            <Stack direction="row" justifyContent="center" alignItems="center" minWidth={100} spacing={1}>
              <QrCodeScannerRoundedIcon />
              <Typography variant="lg" fontWeight={800} className="leading-10">
                {t('myTickets.showMyTickets')}
              </Typography>
            </Stack>
          </Button>
        )}
        {import.meta.env.VITE_NODE_ENV !== 'production' && appId !== 'NANCY' && (
          <Stack direction="row" justifyContent="center" alignItems="center" width="100%" mb={3} spacing={1}>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => setTransferModal(true)}
              disabled={tickets.tickets[active]?.status === 'IN_TRANSFER'}
              sx={{
                borderRadius: 3,
                height: 48,
                px: 4,
                zIndex: 0,
                color: theme.palette.mode === 'dark' ? 'white' : 'black'
                // border: '2px solid linear-gradient(45deg, #A020F0 30%, #21CBF3 90%)',
                // '&:hover': {
                //   borderColor: 'linear-gradient(45deg, #A020F0 30%, #21CBF3 90%)'
                // }
              }}
            >
              {t('transfer.sellButton')}
              <NorthRoundedIcon />
            </Button>

            <Dialog.Root>
              <Dialog.Trigger asChild>
                <Button
                  variant="outlined"
                  fullWidth
                  disabled={tickets.tickets[active]?.status === 'IN_TRANSFER'}
                  onClick={() => {
                    handleCancelForm();
                    handleCancelTicketSelection();
                  }}
                  sx={{
                    borderRadius: 3,
                    height: 48,
                    px: 4,
                    zIndex: 0,
                    color: theme.palette.mode === 'dark' ? 'white' : 'black'

                    // borderImageSlice: 1,
                    // backgroundClip: 'padding-box',
                    // borderImageSource: 'linear-gradient(45deg, #A020F0 30%, #21CBF3 90%)'
                    // border: '2px solid linear-gradient(45deg, #A020F0 30%, #21CBF3 90%)',
                    // '&:hover': {
                    //   borderColor: 'linear-gradient(45deg, #A020F0 30%, #21CBF3 90%)'
                    // }
                  }}
                >
                  {t('transfer.transferButton')}
                  <SyncAltRoundedIcon />
                </Button>
              </Dialog.Trigger>
              <Dialog.Portal>
                <Dialog.Overlay className="bg-[rgba(255, 255, 255, 0.5)] backdrop-blur fixed inset-0 transition-opacity duration-300 ease-in-out" />
                <Dialog.Content
                  onInteractOutside={e => e.preventDefault()}
                  className="fixed top-[50%] left-[50%] max-h-[90vh] w-[90vw] max-w-[650px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-[#ffffff] dark:bg-[#181A20] p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none transform transition-transform duration-300 ease-in-out overflow-y-auto  scrollbar-thin dark:scrollbar-thumb-gray-800 dark:scrollbar-track-[#181A20]"
                >
                  {allTicketsApproved ? (
                    <Dialog.Title className="w-full text-center text-[#F75555]">Billet déjà transféré</Dialog.Title>
                  ) : (
                    !transferEnCours && (
                      <Dialog.Title className="w-full text-center">{t('transfer.dialogDrawerTitle')}</Dialog.Title>
                    )
                  )}
                  {!transferEnCours && !allTicketsApproved && (
                    <div className="flex flex-col items-center justify-center">
                      <ProgressBar progress={progress} />
                    </div>
                  )}
                  {allTicketsApproved && <BilletsTransferer />}
                  {!validForm && !allTicketsApproved && (
                    <Dialog.Description className="w-full text-center text-gray-500">
                      {t('transfer.userFormMessageFirst')}
                      <br /> {t('transfer.userFormMessageSecond')}
                    </Dialog.Description>
                  )}
                  {!validForm && !allTicketsApproved && (
                    <UserForm
                      formik={formik}
                      annuler={handleCancelForm}
                      handleIncrementProgress={handleIncrementProgress}
                    />
                  )}

                  {!confirmSelection && validForm && (
                    <>
                      <Dialog.Description className="w-full text-center text-gray-500">
                        {t('transfer.ticketSelectionMessage')}
                      </Dialog.Description>
                      <Dialog.Description>
                        {selectedTickets.length}/{filteredTickets && filteredTickets.length}{' '}
                        {t('transfer.BilletsSelectionnesMessage')}
                      </Dialog.Description>
                    </>
                  )}
                  {!confirmSelection &&
                    validForm &&
                    eventWithTickets?.tickets.map(
                      ticket =>
                        ticket?.status !== 'IN_TRANSFER' &&
                        ticket?.status !== 'TRANSFER_APPROVED' && (
                          <TicketBody
                            type={ticket.type ? ticket.type : ''}
                            category={ticket.ticketCategory.name ? ticket.ticketCategory.name : ''}
                            firstName={ticket.firstName}
                            lastName={ticket.lastName}
                            selectedTickets={selectedTickets}
                            key={ticket.id}
                            tokenUri={ticket.tokenUri}
                            ticketId={ticket.id}
                            ticketUuid={ticket?.uuid}
                            status={ticket?.status}
                            onSelect={handleTicketSelect}
                          />
                        )
                    )}
                  {!confirmSelection && validForm && (
                    <TicketsSelectionButtons
                      selectedTickets={selectedTickets.length}
                      handleCancelTicketSelection={handleCancelTicketSelection}
                      handleConfirmSelection={handleConfirmSelection}
                    />
                  )}
                  {validForm && confirmSelection && eventWithTickets && eventId && (
                    <ModalBody
                      resetTransferEnCours={resetTransferEnCours}
                      setTransferEnCours={() => setTransferEnCours(true)}
                      editTicketSelection={editTicketSelection}
                      EditForm={EditForm}
                      handleCancelTicketSelection={handleCancelTicketSelection}
                      selectedTickets={selectedTickets}
                      coverImage={eventWithTickets.event.cover}
                      eventName={eventWithTickets.event.title}
                      eventUuid={eventId}
                      firstName={formik.values.firstName ?? ''}
                      lastName={formik.values.lastName ?? ''}
                      email={formik.values.email ?? ''}
                    />
                  )}
                </Dialog.Content>
              </Dialog.Portal>
            </Dialog.Root>
          </Stack>
        )}
      </Stack>
    </Stack>
  );

  return (
    <Box>
      {AppBar()}
      {!isDesktop && mobileSubHeader()}
      <Grid container justifyContent="center" alignItems="start">
        <Grid
          item
          xs={12}
          md={7}
          alignItems="start"
          justifyContent="start"
          display="flex"
          height="100%"
          pl={isDesktop ? 6 : 0}
        >
          <Box
            width="100%"
            margin="auto"
            sx={
              isDesktop
                ? {
                    mt: 10,
                    bgcolor: shadCnTheme.theme === 'dark' ? '#000' : '',
                    borderRadius: 2,
                    py: 3,
                    border: shadCnTheme.theme === 'dark' ? '1px solid #333333' : ''
                  }
                : {}
            }
            className="ticket-slider-card pl-0 pr-0"
          >
            {/* <Slider {...settings} ref={sliderRef} className="ticket-slider-card"> */}
            {/* <Slider {...settings} ref={sliderRef}>

            className="ticket-slider-card">
            {/* <Slider {...settings} ref={sliderRef}>

              {tickets.tickets.map((t, i) => (
                <NftCard
                  currentInfo={currentInfo}
                  setTicketInfo={setInfo}
                  status={t.status}
                  key={t.uuid}
                  open={() => {
                    // sliderRef?.current?.slickGoTo(0);
                    // setActiveSlide(2);
                    setAnimationOpen(true);
                    revealNft(tickets.tickets[activeSlide].uuid, tickets.event.uuid);
                  }}
                  setOpen={() => setOpen(true)}
                  isActive={activeSlide == i}
                  ticket={t}
                  event={tickets.event}
                />
              ))}
            </Slider> */}

            <Slider {...settings} ref={sliderRef}>
              <CustomCarousel setActiveS={setActiveS} active={active}>
                {tickets.tickets.map(
                  (t, i) =>
                    eventId && (
                      <NftCard
                        eventId={eventId}
                        currentInfo={currentInfo}
                        setTicketInfo={setInfo}
                        status={t?.status}
                        key={t.uuid}
                        open={() => {
                          // sliderRef?.current?.slickGoTo(0);
                          // setActiveSlide(2);
                          setAnimationOpen(true);
                          revealNft(tickets.tickets[activeSlide]?.uuid, tickets.event.uuid);
                        }}
                        setOpen={() => setOpen(true)}
                        isActive={activeSlide == i}
                        ticket={t}
                        event={tickets.event}
                      />
                    )
                )}
              </CustomCarousel>
            </Slider>

            {/* <Carousel
              opts={{
                align: 'center',
                loop: true
              }}
              className="w-full ">
              <CarouselContent>
                {tickets.tickets.map((t, i) => (
                  <CarouselItem key={i} className="md:basis-1/2 lg:basis-3/7">
                    <div className="p-1">
                      <NftCard
                        key={t.uuid}
                        open={() => {
                          // sliderRef?.current?.slickGoTo(0);
                          // setActiveSlide(2);
                          setAnimationOpen(true);
                          revealNft(tickets.tickets[activeSlide].uuid, tickets.event.uuid);
                        }}
                        isActive={activeSlide == i}
                        ticket={t}
                        event={tickets.event}
                      />{' '}
                    </div>
                  </CarouselItem>
                ))}
              </CarouselContent>

              <CarouselPrevious />
              <CarouselNext />
            </Carousel> */}
            <div className="flex relative flex-row w-full mt-2 mb-0">
              <div className="flex flex-row w-20 mt-0 mb-0 justify-start items-center">
                {active > 0 && <LeftChevron click={() => setActive(i => i - 1)} />}
              </div>
              <Paginator ticketNumber={tickets.tickets.length} activeTicket={active} />

              <div className="flex flex-row w-20 mt-0 mb-0 justify-end">
                {active < tickets.tickets.length - 1 && <RightChevron click={() => setActive(i => i + 1)} />}
              </div>
            </div>
            <Stack direction="row" justifyContent="center" alignItems="center">
              <Typography variant="lg" color={greyScale[500]} textAlign="center" mt={2}>
                {activeSlide + 1} / {tickets.tickets.length}
              </Typography>
            </Stack>
          </Box>
        </Grid>

        <Grid item xs={12} md={5} sx={{ position: 'relative' }}>
          {!isDesktop && (
            <Typography variant="h4" textAlign="center" mt={3}>
              BILLET #{tickets.tickets[activeSlide].nftId} &nbsp;
              {event?.chain === 'CHILIZ' && <img alt="matic" src="/images/chains/chiliz.png" height={16} />}
              {event?.chain === 'POLYGON' && <img alt="matic" src="/images/chains/polygon.png" height={16} />}
            </Typography>
          )}
          <Stack direction="column" justifyContent="center" alignItems="center" mb={8} p={3}>
            {isDesktop && desktopSubHeader()}
            {appId !== 'NANCY' && <CategoryCard tickets={tickets} activeSlide={activeSlide} />}
            <DetailsCard
              activeSlide={activeSlide}
              tickets={tickets}
              setAnimationOpen={setAnimationOpen}
              revealNft={revealNft}
            />
          </Stack>
        </Grid>
      </Grid>

      {transferModal && (
        <NoTransferModal showModal={transferModal} setShowModal={setTransferModal} isTransfer={isTransfert} />
      )}

      {appId !== 'NANCY' && !isDesktop && (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
          <Stack direction="row" spacing={3} justifyContent="center" mx={2} my={1.5}>
            <Popover>
              <PopoverTrigger asChild>
                {import.meta.env.NODE_ENV !== 'production' && (
                  <IconButton sx={{ height: 48, width: 48, borderRadius: 4 }}>
                    <MoreVertRoundedIcon />
                  </IconButton>
                )}
              </PopoverTrigger>
              <PopoverContent className="w-80 bg-transparent">
                <div className="grid gap-4 ">
                  <Box
                    sx={{
                      backgroundColor: theme.palette.mode === 'dark' ? palette.dark2 : '#B2BEB5',
                      borderRadius: 4,
                      ml: 1,
                      mb: 2
                    }}
                  >
                    <Stack
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      zIndex={0}
                      width="100%"
                      p={3}
                      spacing={1}
                    >
                      <Button
                        onClick={() => navigate(`/transfer-details/${eventId}`)}
                        disabled={tickets.tickets[active]?.status === 'IN_TRANSFER'}
                        sx={{
                          borderRadius: 3,
                          height: 48,
                          px: 4,
                          zIndex: 0,
                          color: theme.palette.mode === 'dark' ? 'white' : 'black'
                          // border: '2px solid linear-gradient(45deg, #A020F0 30%, #21CBF3 90%)',
                          // '&:hover': {
                          //   borderColor: 'linear-gradient(45deg, #A020F0 30%, #21CBF3 90%)'
                          // }
                        }}
                      >
                        {t('transfer.sellButton')}
                        <NorthRoundedIcon />
                      </Button>
                      {/* <Dialog.Root>
                        <Dialog.Trigger asChild>
                            <Button
                              variant="outlined"
                              fullWidth
                              onClick={() => {
                                handleCancelForm();
                                handleCancelTicketSelection();
                              }}
                              sx={{
                                borderRadius: 3,
                                height: 48,
                                px: 4,
                                zIndex: 0,
                                color: 'white',
                                border: '2px solid linear-gradient(45deg, #A020F0 30%, #21CBF3 90%)',
                                '&:hover': {
                                  borderColor: 'linear-gradient(45deg, #A020F0 30%, #21CBF3 90%)'
                                }
                              }}>
                              Transférer
                              <SyncAltRoundedIcon />
                            </Button>
                        </Dialog.Trigger>
                        <Dialog.Portal>
                          <Dialog.Overlay className="bg-[rgba(255, 255, 255, 0.5)] backdrop-blur fixed inset-0 transition-opacity duration-300 ease-in-out" />
                          <Dialog.Content className="fixed top-[50%] left-[50%] max-h-[90vh] w-[90vw] max-w-[650px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-[#181A20] p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none transform transition-transform duration-300 ease-in-out overflow-y-auto  scrollbar-thin scrollbar-thumb-gray-800 scrollbar-track-[#181A20]">
                            {allTicketsApproved ? (
                              <Dialog.Title className="w-full text-center text-[#F75555]">
                                Billet déjà transféré
                              </Dialog.Title>
                            ) : (
                              <Dialog.Title className="w-full text-center">Transférer mes billets</Dialog.Title>
                            )}
                            {!transferEnCours && !allTicketsApproved && (
                              <div className="flex flex-col items-center justify-center">
                                <ProgressBar progress={progress} />
                              </div>
                            )}
                            {allTicketsApproved && <BilletsTransferer />}
                            {!validForm && !allTicketsApproved && (
                              <Dialog.Description className="w-full text-center text-gray-500">
                                Renseignez les informations de la personne à qui vous
                                <br /> souhaitez transférer vos billets.
                              </Dialog.Description>
                            )}
                            {!validForm && !allTicketsApproved && (
                              <UserForm
                                formik={formik}
                                annuler={handleCancelForm}
                                handleIncrementProgress={handleIncrementProgress}
                              />
                            )}

                            {!confirmSelection && validForm && (
                              <>
                                <Dialog.Description className="w-full text-center text-gray-500">
                                  Sélectionnez les billets que vous voulez transférer. ⚠️ Vous n’y aurez plus accès une
                                  fois que cette personne aura accepté le transfert.
                                </Dialog.Description>
                                <Dialog.Description>
                                  {selectedTickets.length}/{eventWithTickets?.tickets.length} billets sélectionnés
                                </Dialog.Description>
                              </>
                            )}
                            {!confirmSelection &&
                              validForm &&
                              eventWithTickets?.tickets.map(
                                ticket =>
                                  ticket.status !== 'IN_TRANSFER' &&
                                  ticket.status !== 'TRANSFER_APPROVED' && (
                                    <TicketBody
                                      type={ticket.type ? ticket.type : ''}
                                      category={ticket.ticketCategory.name ? ticket.ticketCategory.name : ''}
                                      firstName={ticket.firstName}
                                      lastName={ticket.lastName}
                                      selectedTickets={selectedTickets}
                                      key={ticket.id}
                                      tokenUri={ticket.tokenUri}
                                      ticketId={ticket.id}
                                      ticketUuid={ticket.uuid}
                                      status={ticket.status}
                                      onSelect={handleTicketSelect}
                                    />
                                  )
                              )}
                            {!confirmSelection && validForm && (
                              <TicketsSelectionButtons
                                selectedTickets={selectedTickets.length}
                                handleCancelTicketSelection={handleCancelTicketSelection}
                                handleConfirmSelection={handleConfirmSelection}
                              />
                            )}
                            {validForm && confirmSelection && eventWithTickets && eventId && (
                              <ModalBody
                                setTransferEnCours={() => setTransferEnCours(true)}
                                editTicketSelection={editTicketSelection}
                                EditForm={EditForm}
                                handleCancelTicketSelection={handleCancelTicketSelection}
                                selectedTickets={selectedTickets}
                                coverImage={eventWithTickets.event.cover}
                                eventName={eventWithTickets.event.title}
                                eventUuid={eventId}
                                firstName={formik.values.firstName ?? ''}
                                lastName={formik.values.lastName ?? ''}
                                email={formik.values.email ?? ''}
                              />
                            )}
                          </Dialog.Content>
                        </Dialog.Portal>
                      </Dialog.Root> */}
                      <Drawer>
                        <DrawerTrigger asChild>
                          <Button
                            variant="outlined"
                            fullWidth
                            disabled={tickets.tickets[active]?.status === 'IN_TRANSFER'}
                            onClick={() => {
                              handleCancelForm();
                              handleCancelTicketSelection();
                            }}
                            sx={{
                              borderRadius: 3,
                              height: 48,
                              px: 4,
                              zIndex: 0,
                              color: theme.palette.mode === 'dark' ? 'white' : 'black'
                              // borderImageSlice: 1,
                              // backgroundClip: 'padding-box',
                              // borderImageSource: 'linear-gradient(45deg, #A020F0 30%, #21CBF3 90%)',
                              // border: '2px solid linear-gradient(45deg, #A020F0 30%, #21CBF3 90%)',
                              // '&:hover': {
                              //   borderColor: 'linear-gradient(45deg, #A020F0 30%, #21CBF3 90%)'
                              // }
                            }}
                          >
                            {t('transfer.transferButton')}
                            <SyncAltRoundedIcon />
                          </Button>
                        </DrawerTrigger>

                        <DrawerContent onPointerDownOutside={e => e.preventDefault()} className="dark:bg-[#181A20]">
                          <ScrollArea className="overflow-auto max-h-[100vh]">
                            <div className="mx-auto w-full max-w-sm">
                              <DrawerHeader>
                                {allTicketsApproved ? (
                                  <DrawerTitle className="w-full text-center text-[#F75555]">
                                    Billet déjà transféré
                                  </DrawerTitle>
                                ) : (
                                  !transferEnCours && (
                                    <DrawerTitle className="w-full text-center">
                                      {t('transfer.dialogDrawerTitle')}
                                    </DrawerTitle>
                                  )
                                )}
                                {!transferEnCours && !allTicketsApproved && (
                                  <div className="flex flex-col items-center justify-center">
                                    <ProgressBar progress={progress} />
                                  </div>
                                )}
                                {allTicketsApproved && <BilletsTransferer />}
                                {!validForm && !allTicketsApproved && (
                                  <DrawerDescription className="w-full text-center text-gray-500">
                                    {t('transfer.userFormMessageFirst')}
                                    <br /> {t('transfer.userFormMessageSecond')}
                                  </DrawerDescription>
                                )}
                                {!confirmSelection && validForm && (
                                  <>
                                    <DrawerDescription className="w-full text-center text-gray-500">
                                      {t('transfer.ticketSelectionMessage')}
                                    </DrawerDescription>
                                    <DrawerTitle className="mb-0 text-start">
                                      {selectedTickets.length}/{filteredTickets && filteredTickets.length}{' '}
                                      {t('transfer.BilletsSelectionnesMessage')}
                                    </DrawerTitle>
                                  </>
                                )}
                              </DrawerHeader>
                              {!validForm && !allTicketsApproved && (
                                <UserForm
                                  formik={formik}
                                  annuler={handleCancelForm}
                                  handleIncrementProgress={handleIncrementProgress}
                                />
                              )}

                              <div className="pl-5 pr-5">
                                <div className="max-h-80 overflow-y-auto">
                                  {!confirmSelection &&
                                    validForm &&
                                    eventWithTickets?.tickets.map(
                                      ticket =>
                                        ticket?.status !== 'IN_TRANSFER' &&
                                        ticket?.status !== 'TRANSFER_APPROVED' && (
                                          <TicketBody
                                            type={ticket.type ? ticket.type : ''}
                                            category={ticket.ticketCategory.name ? ticket.ticketCategory.name : ''}
                                            firstName={ticket.firstName}
                                            lastName={ticket.lastName}
                                            selectedTickets={selectedTickets}
                                            key={ticket.id}
                                            tokenUri={ticket.tokenUri}
                                            ticketId={ticket.id}
                                            ticketUuid={ticket.uuid}
                                            status={ticket?.status}
                                            onSelect={handleTicketSelect}
                                          />
                                        )
                                    )}
                                </div>

                                {!confirmSelection && validForm && (
                                  <TicketsSelectionButtons
                                    selectedTickets={selectedTickets.length}
                                    handleCancelTicketSelection={handleCancelTicketSelection}
                                    handleConfirmSelection={handleConfirmSelection}
                                  />
                                )}
                              </div>

                              <div className="p-5">
                                {validForm && confirmSelection && eventWithTickets && eventId && (
                                  <ModalBody
                                    resetTransferEnCours={resetTransferEnCours}
                                    setTransferEnCours={() => setTransferEnCours(true)}
                                    editTicketSelection={editTicketSelection}
                                    EditForm={EditForm}
                                    handleCancelTicketSelection={handleCancelTicketSelection}
                                    selectedTickets={selectedTickets}
                                    coverImage={eventWithTickets.event.cover}
                                    eventName={eventWithTickets.event.title}
                                    eventUuid={eventId}
                                    firstName={formik.values.firstName ?? ''}
                                    lastName={formik.values.lastName ?? ''}
                                    email={formik.values.email ?? ''}
                                  />
                                )}
                              </div>
                            </div>
                          </ScrollArea>
                        </DrawerContent>
                      </Drawer>
                    </Stack>
                  </Box>
                </div>
              </PopoverContent>
            </Popover>

            <Button
              variant="contained"
              // disabled={tickets.tickets[active].status === 'IN_TRANSFER'}
              onClick={async () => {
                // const encryptedPrivateKey = localStorage.getItem('encryptedPrivateKey') ?? '';
                // if (encryptedPrivateKey !== '') {
                if (tickets.tickets[active]?.status === 'IN_TRANSFER') {
                  setOpen(true);
                  if (status?.status == 'IN_TRANSFER') {
                    setInfo({ status: true, email: status?.receiverEmail, uuid: tickets.tickets[active].uuid });
                  } else if (status?.status) {
                    setInfo({ status: false, email: status?.receiverEmail, uuid: tickets.tickets[active].uuid });
                  }
                } else {
                  navigate(`/ticket/event/${tickets.event.uuid}`);
                }
                // } else {
                //   await logout();
                // }
              }}
              sx={{
                borderRadius: 3,
                height: 48,
                px: 4,
                backgroundImage:
                  tickets.tickets[active]?.status === 'IN_TRANSFER'
                    ? `linear-gradient(to right, #9497A0, #D5D1E2)`
                    : undefined,
                width: { xs: '100%', md: 'auto' }
              }}
            >
              <Stack direction="row" justifyContent="center" alignItems="center" minWidth={100} spacing={1}>
                <QrCodeScannerRoundedIcon />
                <Typography variant="lg" fontWeight={800} className="leading-10">
                  {t('myTickets.showMyTickets')}
                </Typography>
              </Stack>
            </Button>
          </Stack>
        </Paper>
      )}
    </Box>
  );
}

export default TicketDetails;
