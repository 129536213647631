import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';

const columnVariants = cva('flex-col', {
  variants: {
    variant: {
      default: ''
    }
  },
  defaultVariants: {
    variant: 'default'
  }
});

const Column = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof columnVariants>
>(({ className, variant, ...props }, ref) => (
  <div ref={ref} className={cn(columnVariants({ variant }), className)} {...props} />
));
Column.displayName = 'Column';

export { Column, columnVariants };
