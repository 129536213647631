const primary = {
  100: '#EEEDFE',

  200: '#BCB7FB',

  300: '#007DBC',

  400: '#007DBC',

  500: '#007DBC'
};

export default primary;
