import background from './background';
const palette = {
  primary: '#584CF4',

  secondary: '#27D4FF',

  text: '#212121',

  success: '#07BD74',

  info: '#246BFD',

  warning: '#FACC15',

  error: '#F75555',

  buttonSecondary: '#EEEDFE',

  backgroundDark: '#0D0C10',

  dark1: '#0b0c0f',

  dark2: '#1F222A',

  dark3: '#35383F',

  dark4: '#101217'
};

export default palette;
