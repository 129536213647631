import setDefaultOptions from 'date-fns/setDefaultOptions';
import AppRouter from './AppRouter';
import './i18n';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { useAppSelector } from './redux/hooks';
// import { useWeb3Auth, Web3AuthProvider } from './services/web3/web3auth';
import { fr } from 'date-fns/esm/locale';
import { CHAIN_CONFIG_TYPE } from './config/chainConfig';
import { getClubThemeFromHostname, getWebsiteConfig } from './pages/whiteLabel/themeFactory';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import userApi from './services/api/user';
import { Crisp } from 'crisp-sdk-web';
import { ShadCnThemeProvider } from '@/components/theme-provider';

setDefaultOptions({ locale: fr });
Crisp.configure('1ef11ac2-df46-4dc7-8919-3983981d11ae');

//
//
function App() {
  const { darkTheme } = useAppSelector(state => state.themeSlice);
  const clubTheme = getClubThemeFromHostname(darkTheme); // Get the theme based on hostname
  const { data: user } = userApi.endpoints.getUser.useQuery();
  // const {} = useWeb3Auth();

  const chain: CHAIN_CONFIG_TYPE = import.meta.env.VITE_NODE_ENV === 'production' ? 'polygonMainnet' : 'polygonTestnet';
  const web3AuthNetwork = import.meta.env.VITE_NODE_ENV === 'production' ? 'cyan' : 'testnet';
  const appTitle = getWebsiteConfig()?.title;
  const appDescription = getWebsiteConfig()?.description;

  const change_favicon = (favicon: string) => {
    const linkElement = document.querySelector("link[rel*='icon']") as HTMLLinkElement | null;
    if (linkElement) {
      linkElement.type = 'image/x-icon';
      linkElement.rel = 'shortcut icon';
      linkElement.href = favicon;
    } else {
      const newLinkElement = document.createElement('link');
      newLinkElement.type = 'image/x-icon';
      newLinkElement.rel = 'shortcut icon';
      newLinkElement.href = favicon;
      document.getElementsByTagName('head')[0].appendChild(newLinkElement);
    }
  };

  useEffect(() => {
    if (user) {
      user.email && Crisp.user.setEmail(user.email);
      user.firstName && Crisp.user.setNickname(user?.firstName + ' ' + user?.lastName);
      // user.avatar && Crisp.user.setAvatar(user.avatar);
      user.phone && Crisp.user.setPhone(user.phone ?? '');
    }
  }, [user]);

  useEffect(() => {
    // Change the favicon dynamically
    change_favicon(getWebsiteConfig()?.favicon);
  }, []);

  return (
    // <Web3AuthProvider chain={chain} web3AuthNetwork={web3AuthNetwork}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={clubTheme}>
        <ShadCnThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
          <Helmet>
            <title>{appTitle}</title>
            <meta name="description" content={appDescription} />
          </Helmet>
          <CssBaseline />
          <AppRouter />
        </ShadCnThemeProvider>
      </ThemeProvider>
    </StyledEngineProvider>
    // </Web3AuthProvider>
  );
}

export default App;
