/* eslint-disable camelcase */

import baseApi from './base';
import { PlacesReservationDto } from './payment';

export interface TicketCategory {
  id: number;
  uuid: string;
  name: string;
  description: string | null;
  price: number;
  maxPerPerson: number;
  minPerPerson: number;
  quantityAvailable: number;
  quantitySold: number | null;
  startSaleDate: Date | null;
  endSaleDate: Date | null;
  range: number | null;
  status: 'ACTIVE' | 'NOT_ACTIVE';
  isAvailable: boolean;
  updatedAt: Date;
  createdAt: Date;
  deletedAt: Date | null;
  ticketCategoryId: number | null;
  eventId: number;
  organizationId: number;
  subTicketCategory: TicketCategory[];
  // codePromos: TicketCategoriesOnCodePromo[];
}

type CanBuyTicketsForm = {
  categories: PlacesReservationDto[];
  eventUuid: string;
  purchaseIntentUuid?: string;
};

type TicketCategoryForm = {
  eventUuid: string;
  promoCode?: string;
};

type TicketCategoriesOnCodePromo = {
  codePromoId: number;
  ticketCategoryId: number;
  assignedAt: Date;
  assignedBy: string;
  // codePromo: PromoCodeDiscount;
};

type PromoCodeDiscount = {
  id: number;
  uuid: string;
  promoCode: string;
  start: Date;
  end: Date;
  discountAmount: number;
  discountType: 'PERCENTAGE_OFF' | 'FIXED_AMOUNT_OFF';
  maxUsage: number;
  quantityUsed: number;
  description: string;
  expired: boolean;
  emailReference: string;
  currencyCode: string;
  updatedAt: Date;
  createdAt: Date;
  deletedAt: Date | null;
  eventId: number;
  organizationId: number;
};

const ticketCategoriesApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getTicketCategories: builder.query<TicketCategory[], TicketCategoryForm>({
      query: params => ({
        method: 'GET',
        url: `/ticket-categories/event/${params.eventUuid}`,
        params: { promoCode: params.promoCode }
      })
    }),
    canBuyTickets: builder.mutation<TicketCategory[], CanBuyTicketsForm>({
      query: body => ({
        method: 'POST',
        url: `/ticket-categories/can-buy-tickets`,
        body
      })
    })
  })
});

export default ticketCategoriesApi;
