// EventBody.tsx

import React from 'react';

interface EventProps {
  coverImage: string;
  eventName: string;
}
/*className="h-auto shadow-lg p-2"
                style={{ objectFit: 'fill', width: '25%' }}*/

function EventBody({ coverImage, eventName }: EventProps) {
  return (
    <div className="flex flex-row md:flex-row justify-start items-center ml-5">
      <img
        src={coverImage}
        alt="Your Image"
        className="rounded-lg  h-auto shadow-lg"
        style={{ objectFit: 'fill', width: '25%' }}
      />
      <div className="m-5">
        <h2>{eventName}</h2>
      </div>
    </div>
  );
}

export default EventBody;
