import { useEffect, useState } from 'react';
import { Box, Stack, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { motion } from 'framer-motion';
import { isBefore } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import noReveal from '../../../assets/images/card/no-reveal.png';
import { Add, Lock } from '@mui/icons-material';
import { PitchNft } from '../../../utils/seasonUtils';

interface CardProps {
  item: PitchNft;
  index: number;
  positionArray: any[];
  controls: any;
  shift: number;
  animationSpeed: number;
}

const Card: React.FC<CardProps> = ({ item, index, positionArray, controls, shift, animationSpeed }) => {
  const [previousShift, setPreviousShift] = useState<number>(0);
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md')); // 900 - 1199
  const [tokenData, setTokenData] = useState(null);
  const { quantity, beginAt, eventUuid } = item;
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the JSON data from the token URI
    fetch(item.tokenUri)
      .then(response => response.json())
      .then(data => {
        return setTokenData(data);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, [item]);

  useEffect(() => {
    if (isCardDisplayed()) animate();
    setPreviousShift(shift);
  }, [shift]);

  const isCardDisplayed = (): boolean => !(shift < 0 && shift + index < 0) && !(6 - index <= shift);

  const getInitialPosition = () => positionArray[(shift <= previousShift ? 1 : 0) * 5];

  const isFirstCard = (): boolean => index + shift === 1;

  const animate = () => {
    controls.start('animate');
  };

  const handleRedirection = (e: any) => {
    console.log(item);
    if (isFirstCard()) {
      e.stopPropagation();
      if (quantity > 0) {
        navigate(`/ticket-details/${eventUuid}`);
      } else if (beginAt && isBefore(new Date(), new Date(beginAt))) {
        navigate(`/event/${eventUuid}`);
      } else if (eventUuid) {
        navigate(`/event/${eventUuid}`);
      } else {
        return null;
      }
    }
  };

  const handleTooltip = () => {
    const { quantity, beginAt, isLocked } = item;
    if (isFirstCard()) {
      if (quantity > 0) {
        return;
      } else if (isLocked) {
        return 'Cet événement n’est pas encore disponible.';
      } else if (beginAt && isBefore(new Date(), new Date(beginAt))) {
        return 'Vous ne possédez pas encore ce billet. Cliquez dessus pour le récupérer avant qu’il n’y en ait plus !';
      } else {
        return null;
      }
    }
  };

  const cardVariants = {
    initial: {
      y: getInitialPosition().y,
      x: getInitialPosition().x,
      scale: getInitialPosition().scale,
      rotateZ: getInitialPosition().rotate,
      // boxShadow: '0 0 4px rgba(255, 255, 255, 0.5)',
      borderRadius: 15
    },
    animate: {
      y: positionArray[Math.min(Math.max(index + shift, 0), positionArray.length - 1)].y,
      x: positionArray[Math.min(Math.max(index + shift, 0), positionArray.length - 1)].x,
      scale: positionArray[Math.min(Math.max(index + shift, 0), positionArray.length - 1)].scale,
      rotateZ: positionArray[Math.min(Math.max(index + shift, 0), positionArray.length - 1)].rotate,
      // boxShadow: '0 0 4px rgba(255, 255, 255, 0.5)',
      borderRadius: 15
    }
  };
  const selectCard = () => {
    const { quantity, isOpened, isLocked, beginAt } = item;

    if (quantity) {
      if (isOpened) {
        return (
          <Box
            position="relative"
            width={isDesktop ? '300px' : '160px'}
            zIndex={999}
            className="cursor-pointer transform transition duration-500 hover:scale-110"
          >
            <Box position="relative" zIndex={999} height={'100%'}>
              <img
                src={
                  // @ts-ignore
                  tokenData?.image
                }
                height={'100%'}
                className="w-full h-full"
              />
              {Array(Math.min(item.quantity, 3))
                .fill(1)
                .map((c, index) => (
                  <Box
                    top={index * 15}
                    left={index * 15}
                    zIndex={-index}
                    position="absolute"
                    sx={{ transform: `scale(${1 - index * 0.05})` }}
                  >
                    <img
                      src={
                        // @ts-ignore
                        tokenData?.image
                      }
                      alt="Remy Sharp"
                      width={isDesktop ? '300px' : '160px'}
                    />
                  </Box>
                ))}
            </Box>
            {item.quantity > 1 && (
              <Stack
                zIndex={999}
                direction="row"
                position="absolute"
                top={-42}
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  zIndex={999}
                  position="absolute"
                  display="flex"
                  alignItems="center"
                  height={32}
                  border={`1.5px solid yellow`}
                  py={0.5}
                  px={2}
                  borderRadius={4}
                  sx={{
                    background: 'linear-gradient(135deg, #868E96 42.19%, #344746 100%)'
                  }}
                >
                  <Typography color="white" fontWeight={600} ml={0.5} zIndex={100}>
                    x{item.quantity}
                  </Typography>
                </Box>
              </Stack>
            )}
          </Box>
        );
      } else
        return (
          <Stack
            zIndex={100}
            direction="column"
            width={isDesktop ? '300px' : '160px'}
            sx={{
              aspectRatio: '4/5'
            }}
            className="cursor-pointer transform transition duration-500 hover:scale-110"
            borderRadius={4}
            alignItems="center"
            justifyContent="center"
            p={0}
          >
            <img src={noReveal} alt="open me" className="w-full" />
          </Stack>
        );
    } else {
      if (isLocked) {
        return (
          <Stack
            direction="column"
            width={isDesktop ? '300px' : '160px'}
            sx={{
              aspectRatio: '4/5',
              // background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.28) 0%, rgba(0, 0, 0, 0.30) 100%), #2B2D33',
              border: '1px solid grey'
            }}
            borderRadius={4}
            alignItems="center"
            justifyContent="center"
            p={1}
            className="cursor-pointer transform transition duration-500 hover:scale-110"
          >
            {/* <img src={button} alt="add me" className="w-[30%] mb-5" /> */}

            <Lock sx={{ color: 'grey' }} />
          </Stack>
        );
      } else {
        return (
          <Stack
            direction="column"
            width={isDesktop ? '300px' : '160px'}
            sx={{
              aspectRatio: '4/5',
              backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2322BB9C' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`
            }}
            borderRadius={4}
            alignItems="center"
            justifyContent="center"
            p={1}
            className="cursor-pointer transform transition duration-500 hover:scale-110"
          >
            <Add sx={{ color: '#22BB9C' }} />
          </Stack>
        );
      }
    }
  };

  return isCardDisplayed() ? (
    <motion.div
      style={{ zIndex: 100 - index }}
      transition={{ duration: animationSpeed }}
      animate={controls}
      variants={cardVariants}
      initial="initial"
      className="fixed"
    >
      <Tooltip title={handleTooltip()}>
        <Box
          display="flex"
          position={'relative'}
          justifyContent={index % 2 === 0 ? 'start' : 'end'}
          zIndex={100 - index}
          onClick={e => handleRedirection(e)}
        >
          {selectCard()}
        </Box>
      </Tooltip>
    </motion.div>
  ) : (
    <></>
  );
};

export default Card;
