import { PromoCodeResponse } from '../services/api/promo-code';

export const computeDiscount = (price: number, promo?: PromoCodeResponse) => {
  if (!promo) {
    return price;
  }
  if (promo.discountType === 'PERCENTAGE_OFF') {
    return price - (price * promo.discountAmount) / 100 < 0 ? 0 : price - (price * promo.discountAmount) / 100;
  } else if (promo.discountType === 'FIXED_AMOUNT_OFF') {
    return price - promo.discountAmount < 0 ? 0 : price - promo.discountAmount;
  } else {
    return price;
  }
};

export const computeApplyPromoCode = (ticketCategoryId?: number, promoCode?: PromoCodeResponse) => {
  return promoCode?.ticketCategories?.find(e => e.ticketCategoryId === ticketCategoryId);
};
