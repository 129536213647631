import TicketIcon from '@/assets/images/icons/ticket';
import HomeCountDownRenderer from '@/components/HomeCountownRender';
import { getWebsiteConfig } from '@/pages/whiteLabel/themeFactory';
import eventApi, { HomeEvent } from '@/services/api/event';
import { capitalize } from '@/utils/strings';
import { CircularProgress } from '@mui/material';
import { format } from 'date-fns';
import Countdown from 'react-countdown';
import { useNavigate } from 'react-router-dom';

interface EventPosterProps {
  isCustomHome?: boolean;
}

const EventPoster = ({ isCustomHome }: EventPosterProps) => {
  const homeBanner = getWebsiteConfig().banner;
  // TODO filter les events au niveau du backend
  const navigate = useNavigate();
  const orgaUuids = getWebsiteConfig().orgaUuids;
  const filterOrgaEvents = isCustomHome ? (e: HomeEvent) => orgaUuids.includes(e.orgaId) : () => true;
  function getNearestEvent(e: HomeEvent[] | undefined) {
    if (e) {
      const now = new Date();
      // Filter out past events and sort the upcoming ones by beginAt
      const sortedEvents = e
        .filter(
          event =>
            (new Date(event.beginAt) > now || (new Date(event.beginAt) < now && now < new Date(event.endAt))) &&
            event.type == 'EVENT' &&
            filterOrgaEvents(event)
        )
        .sort((a, b) => new Date(a.beginAt).getTime() - new Date(b.beginAt).getTime());
      // Return the first event from the sorted list or null if there are no upcoming events
      return sortedEvents.length ? sortedEvents[0] : null;
    } else {
      return null;
    }
  }

  const data = eventApi.endpoints.getEvents.useQuery('', {
    selectFromResult: ({ data }) => {
      return {
        events: isCustomHome
          ? getNearestEvent(data)
          : data?.length !== 0
          ? data?.reduce((max, obj) => (obj.totalTickets > max.totalTickets ? obj : max))
          : null
      };
    }
  });
  const { isLoading } = eventApi.endpoints.getEvents.useQuery('');

  const { events } = data;
  return (
    <div>
      <div
        className="-z-20 top-0 left-0 absolute w-full h-full   md:max-h-[650px]  bg-cover backdrop-blur-5xl bg-no-repeat"
        style={{
          backgroundImage: `url(${events?.cover})`,
          backgroundRepeat: 'no-repeat'
        }}
      >
        <div className="-z-20 top-0 left-0 absolute w-full h-full backdrop-blur-sm bg-gradient-to-t  from-white/[1] dark:from-dark1/[1] from-0% via-white/50 dark:via-black/30 to-blue-900/50 bg-no-repeat ">
          <div className="-z-10 bottom-0 left-0 absolute w-full h-96  bg-cover bg-gradient-to-t from-white/[1] dark:from-dark1/[1] dark:to-black/1 bg-no-repeat " />
        </div>
      </div>
      <div className="w-full flex flex-col min-h-[460px] justify-center items-center  relative ">
        {/* <img src={events?.cover} alt="bg" className="absolute w-full h-full -z-1  object-cover" /> */}

        <h2 className="text-white mb-1 z-10">À l’affiche</h2>
        {!isLoading ? (
          <>
            {events ? (
              <>
                <h1 className="text-white mt-2 z-10 text-5xl font-bold text-center">{events?.title}</h1>
                <div className="flex  flex-col sm:flex-row">
                  <div className="flex flex-col justify-center items-center mr-4 md:w-[400px] mb-10">
                    <div className="text-red-500 text-xl z-10 text-center px-2">{events?.subTitle}</div>
                    <div className="text-white text-xl font-bold  mt-3 z-10">
                      {events && `${capitalize(format(new Date(events?.beginAt), 'eeee dd MMMM'))}`}
                    </div>
                    <div className="text-white text-xl  font-bold mb-3 z-10">
                      {' '}
                      {events && `${capitalize(format(new Date(events?.beginAt), "HH'h'mm"))}`}
                    </div>
                    <div className="text-gray-200 z-10 text-xl  text-bold">{events?.address?.name}</div>
                  </div>
                  <div className="text-white flex flex-col justify-center items-center mr-4">
                    <Countdown date={new Date(events?.beginAt)} renderer={HomeCountDownRenderer} />
                    <div
                      onClick={() => navigate(`/event/${events?.uuid}`)}
                      className="w-[250px] h-[50px] flex justify-center rounded-md items-center bg-red-600  cursor-pointer z-10 mb-10  text-white  hover:bg-red-600/90 focus:ring-4 focus:outline-none focus:ring-red-600/50 font-bold  text-md  px-5 py-2.5 text-center   dark:focus:ring-red-600/55  "
                    >
                      Choisir ma place
                      <TicketIcon size="17" className="ml-5 w-[17px]" />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="text-2xl font-bold md:text-5xl z-20 text-center mt-5">
                  Il n’y a aucun événement en ligne, <br />
                  revenez plus tard 👋{' '}
                </div>
              </>
            )}
          </>
        ) : (
          <CircularProgress sx={{ color: '#fff' }} size={80} />
        )}
      </div>
    </div>
  );
};

export default EventPoster;
