import { Box, Typography, IconButton } from '@mui/material';
import { Stack } from '@mui/system';
import { useState } from 'react';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { t } from 'i18next';
import { isDesktop, isIOS, isMacOs } from 'react-device-detect';
import { UserSubscription } from '../../../../services/api/subscription';
import { Ticket } from '../../../../services/api/ticket';
import NextSlideButton from '../NextSlideButton';
import PreviousSlideButton from '../PreviousSlideButton';
import AddToWalletButton from '../AddToWalletButton';
import QrCodeComponent from '../QrCodeComponent';
import TicketInformations from '../TicketInformations';
import ResponsiveTilt from '../../../../components/ResponsiveTilt';
import { useAppSelector } from '../../../../redux/hooks';
import { Event } from '../../../../services/api/event';
import PrintPTicketButton from '../PrintTicketButton';
import AddToGoogleWalletButton from '../AddToGoogleWallet';
import { Row } from '@/components/ui/row';

interface ShowQrCodeLayoutProps {
  scanType: 'SUBSCRIPTION' | 'TICKET';
  event?: Event;
  tickets?: Ticket[];
  userSubscription?: UserSubscription;
}

const ShowQrCodeLayout = ({ scanType, event, tickets, userSubscription }: ShowQrCodeLayoutProps) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const navigate = useNavigate();
  useAppSelector(state => state.themeSlice);
  //TODO handle not found event

  const style = {
    boxShadow: 24,
    borderRadius: 8,
    padding: '32px',
    maxWidth: 370,
    width: { xs: '100%', sm: 370 },
    height: 'auto'
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextSlideButton />,
    prevArrow: <PreviousSlideButton />,
    afterChange: (current: number) => setActiveSlide(current),
    customPaging: (i: any) => {
      if (i == activeSlide) {
        return <span className="flex justify-center item-center w-4 h-4 bg-white rounded-full -mt-6 "></span>;
      } else {
        return <span className="flex w-4 h-4  bg-gray-300 rounded-full opacity-50 -mt-6"></span>;
      }
    }
  };

  const appBar = () => {
    return (
      <Stack direction="row" alignItems="center" spacing={1} m={2} position="absolute" zIndex={100}>
        <IconButton
          onClick={() =>
            scanType == 'SUBSCRIPTION' ? navigate('/my-tickets') : navigate(`/ticket-details/${event?.uuid}`)
          }
        >
          <ArrowBackIcon className="text-white" />
        </IconButton>
        <Typography variant="h5" className="text-white">
          {event && event?.title}
        </Typography>
      </Stack>
    );
  };

  const ticketSeperator = (isScanned: boolean) => {
    if (isScanned) {
      return (
        <>
          <div
            style={{
              border: 'none',
              borderTop: '6px dashed black',
              width: '85%',
              position: 'absolute',
              top: '36.5%'
            }}
          />
          <div
            style={{
              border: 'none',
              borderTop: '6px dashed black',
              width: '85%',
              position: 'absolute',
              top: '40.2%'
            }}
          />
          <div
            style={{
              backgroundColor: 'black',
              height: 24,
              width: '100%',
              position: 'absolute',
              top: '37%'
            }}
          />
        </>
      );
    } else {
      return (
        <div
          style={{
            border: 'none',
            borderTop: '6px dashed black',
            width: '85%',
            position: 'absolute',
            top: '38.3%'
          }}
        />
      );
    }
  };

  return (
    <div className="bg-dark1">
      {appBar()}
      <Slider {...settings}>
        {/* TODO orderby logic shoud be in backend */}
        {scanType == 'TICKET' ? (
          tickets?.map(ticket => (
            <ResponsiveTilt>
              <div
                className={`flex !important justify-center items-center w-[full] h-[100vh]  bg-[url('${event?.cover}')] px-10 pt-16 pb-32 overflow-auto`}
              >
                <Box sx={style} className={`sm:h-[720px] relative m-auto sel `}>
                  <div className={`w-[50px] h-[50px] bg-[#0b0c0f] rounded-full absolute top-[35%] -left-6`}></div>
                  <div className={`w-[50px] h-[50px] bg-[#0b0c0f] rounded-full absolute top-[35%] -right-6`}></div>
                  <Stack direction="column" justifyContent="center" spacing={3}>
                    <TicketInformations ticket={ticket} type={'TICKET'} />
                    <Stack direction="row" justifyContent="center" alignItems="center">
                      {ticketSeperator(ticket.isScanned)}
                    </Stack>

                    <Stack direction="column" alignItems="center" justifyContent="center" pt={6}>
                      {ticket.isScanned && (
                        <div className="rounded-full bg-red-500  px-2 -mt-4 mb-2">
                          <Typography variant="sm" fontWeight={700}>
                            {t('myTickets.scanned')}
                          </Typography>
                        </div>
                      )}
                      {<QrCodeComponent ticketQrCode={ticket.qrCode} securityLevel="LOW" />}
                    </Stack>
                    <div className="flex flex-col justify-center  space-y-2 -mx-4 mt-6">
                      {isDesktop && <PrintPTicketButton tickets={tickets} event={event} />}
                      <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
                        <AddToWalletButton type="apple" ticket={ticket} />
                        <AddToGoogleWalletButton type="google" ticket={ticket} />
                      </div>

                      {/* <AddToWalletButton type="google" ticket={ticket} /> */}
                    </div>
                  </Stack>
                  {/* background images effects */}
                  <div
                    style={{ backgroundImage: `url(${event?.cover})` }}
                    className={`-z-20 rounded-3xl top-0 left-0 absolute w-full h-full bg-cover backdrop-blur-2xl`}
                  ></div>
                  <div className="-z-20  rounded-3xl top-0 left-0 absolute w-full h-full backdrop-blur-xl bg-gradient-to-t  from-black/[.06] to-blue-500"></div>
                </Box>
              </div>
            </ResponsiveTilt>
          ))
        ) : (
          <ResponsiveTilt>
            <div
              className={`flex !important justify-center items-center w-[full] h-[100vh]  bg-[url('${userSubscription?.subscription?.cover}')] px-10 pt-16 pb-32 overflow-auto`}
            >
              <Box sx={style} className={`sm:h-[720px] relative m-auto sel `}>
                <div className={`w-[50px] h-[50px] bg-[#0b0c0f] rounded-full absolute top-[35%] -left-6`}></div>
                <div className={`w-[50px] h-[50px] bg-[#0b0c0f] rounded-full absolute top-[35%] -right-6`}></div>
                <Stack direction="column" justifyContent="center" spacing={3}>
                  <TicketInformations subscription={userSubscription} type={'SUBSCRIPTION'} />
                  <Stack direction="row" justifyContent="center" alignItems="center">
                    {ticketSeperator(false)}
                  </Stack>
                  <Stack direction="column" alignItems="center" justifyContent="center" pt={9}>
                    {false && (
                      <div className="rounded-full bg-red-500  px-2 -mt-4 mb-2">
                        <Typography variant="sm" fontWeight={700}>
                          {t('myTickets.scanned')}
                        </Typography>
                      </div>
                    )}
                    {<QrCodeComponent ticketQrCode={userSubscription?.qrCode ?? ''} securityLevel="LOW" />}
                  </Stack>
                  {(isIOS || isMacOs) && (
                    <div className="flex justify-center  space-x-2 -mx-4">
                      {/* <AddToWalletButton type="apple" ticket={ticket} /> */}
                      {/* <AddToWalletButton type="google" ticket={ticket} /> */}
                    </div>
                  )}
                </Stack>

                {/* background images effects */}
                <div
                  style={{ backgroundImage: `url(${event?.cover})` }}
                  className={`-z-20 rounded-3xl top-0 left-0 absolute w-full h-full bg-cover backdrop-blur-2xl`}
                ></div>
                <div className="-z-20  rounded-3xl top-0 left-0 absolute w-full h-full backdrop-blur-xl bg-gradient-to-t  from-black/[.06] to-blue-500"></div>
              </Box>
            </div>
          </ResponsiveTilt>
        )}
      </Slider>
    </div>
  );
};

export default ShowQrCodeLayout;
