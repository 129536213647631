import { Box, Button, Dialog, Typography } from '@mui/material';
import { Stack } from '@mui/system';

interface NoTransferModalProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  isTransfer: boolean;
}

function NoTransferModal(props: NoTransferModalProps) {
  const { showModal, setShowModal } = props;

  return (
    <>
      <Dialog open={showModal} onClose={() => setShowModal(false)}>
        <Box p={3}>
          <Stack direction="column" justifyContent="center" alignItems="center" spacing={3} mt={1}>
            <Typography variant="md" textAlign="center">
              {props.isTransfer
                ? "Le transfert n'est pas disponible pour ce billet"
                : "La revente n'est pas disponible pour ce billet"}{' '}
            </Typography>

            <Button type="submit" variant="contained" sx={{ px: 8 }} onClick={() => setShowModal(false)}>
              Retour
            </Button>
          </Stack>
        </Box>
      </Dialog>
    </>
  );
}

export default NoTransferModal;
