import { useParams } from 'react-router-dom';
import ShowQrCodeLayout from '../components/SowQrCodeLayout';
import UnhandledError from '../../../components/UnhandledError';
import { CircularProgress, Stack } from '@mui/material';
import reservationApi from '../../../services/api/reservation';

const ShowReservationQrCodes = () => {
  const { eventUuid, reservationUuid } = useParams();

  const {
    data: reservation,
    isLoading,
    isError
  } = reservationApi.endpoints.getReservationDetails.useQuery(
    { eventUuid: eventUuid!, reservationUuid: reservationUuid! },
    { skip: !eventUuid || !reservationUuid }
  );

  if (isError) {
    return <UnhandledError />;
  }

  return (
    <div>
      {isLoading || !reservation ? (
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} m={2}>
          <CircularProgress />
        </Stack>
      ) : (
        <ShowQrCodeLayout event={reservation.event} tickets={reservation.tickets} scanType="TICKET" />
      )}
    </div>
  );
};

export default ShowReservationQrCodes;
