import { useParams } from 'react-router-dom';
import ticketApi from '../../../services/api/ticket';
import ShowQrCodeLayout from '../components/SowQrCodeLayout';
import UnhandledError from '../../../components/UnhandledError';
import { CircularProgress, Stack } from '@mui/material';

const ShowEventQrCodes = () => {
  const { eventUuid } = useParams();

  const {
    data: event,
    isLoading,
    isError
  } = ticketApi.endpoints.getTicketDetails.useQuery(eventUuid!, { skip: !eventUuid });

  if (isError) {
    return <UnhandledError />;
  }

  return (
    <div>
      {isLoading || !event ? (
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} m={2}>
          <CircularProgress />
        </Stack>
      ) : (
        <ShowQrCodeLayout event={event.event} tickets={event?.tickets} scanType="TICKET" />
      )}
    </div>
  );
};

export default ShowEventQrCodes;
