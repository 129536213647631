import { Button, Box, Typography, useMediaQuery } from '@mui/material';
import { Stack } from '@mui/system';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import Confetti from 'react-confetti';
import { useWindowSize } from '@react-hook/window-size';
import { motion, useAnimationControls } from 'framer-motion';
import annimationVideo from '../../../../assets/videos/animation_mobile.mp4';
import annimationVideoDesktop from '../../../../assets/videos/animation_desktop.mp4';
import { Collectible, Event } from '../../../../services/api/event';
import palette from '../../../../themes/default/colors/palette';
import rarityTextStyle from '../../../../utils/rarityTextColor';
import { getWebsiteConfig } from '../../../whiteLabel/themeFactory';

interface RevealModalProps {
  openRevealModal: boolean;
  handleCloseRevealModal: () => void;
  event: Event;
  collectible: Collectible | null;
  // qrCode: string;
  // seatInfos: Seat[];
}

const RevealModal = (props: RevealModalProps) => {
  const { collectible } = props;
  const [width, height] = useWindowSize();
  const appId = getWebsiteConfig().id;
  const [isVideoVisible, setVideoVisible] = useState(true);
  const [isButtonVisible, setButtonVisible] = useState(false);
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  const controls = useAnimationControls();
  const controls2 = useAnimationControls();

  return (
    <>
      {collectible?.rarity == 'COMMON' || isVideoVisible ? null : <Confetti width={width} height={height} />}
      <Box position="relative">
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          color="#FFF"
          height="100vh"
          bgcolor={palette.dark1}
        >
          <Typography variant="h5" textAlign="center">
            {collectible?.rarity == 'COMMON' ? '' : t('myTickets.congratulations')}
          </Typography>
          <Stack direction="column" justifyContent="center" spacing={3}>
            {collectible?.url && (
              <Box display="flex" justifyContent="center" mt={2}>
                <motion.div animate={controls} initial={{ scale: 0.3 }}>
                  <Stack alignItems="center" justifyContent="center" height={330} m={3} borderRadius={8}>
                    <motion.div
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                      // onClick={onClick}
                      className="nft-image-card"
                    >
                      <motion.img
                        // TODO for demo purpose we put the winner
                        src={collectible?.url}
                        // src={nftImage}
                        height={330}
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        transition={{ type: 'spring', stiffness: 300, bounce: 0.35 }}
                      />
                    </motion.div>
                  </Stack>
                </motion.div>
              </Box>
            )}
            {collectible?.rarity == 'INVITE' ? null : (
              <Typography variant="h5" px={3} textAlign="center">
                <Trans
                  i18nKey={'myTickets.congratulationsSentence'}
                  values={{
                    rarity: rarityTextStyle(collectible?.rarity ?? 'COMMON')?.value
                  }}
                  components={[rarityTextStyle(collectible?.rarity ?? 'COMMON')?.component ?? <span></span>]}
                />
              </Typography>
            )}
            {collectible?.rarity == 'INVITE' ? null : (
              <Stack direction="column" justifyContent="center" alignItems="center">
                <Typography variant="lg" textAlign="center">
                  {/* TODO sporsora */}
                  {collectible?.rarity == 'COMMON'
                    ? appId == 'SPORSORA'
                      ? "Il ne s'agit pas billet gagnant mais nous sommes impatients de vous retrouver le jour de la cérémonie !"
                      : t('myTickets.lostTickieTakaZ5Sentence')
                    : t('myTickets.congratulationsRewardSentence')}
                </Typography>
                <Box pt={-2.5} />
                <Typography
                  variant="xl"
                  textAlign="center"
                  color={rarityTextStyle(collectible?.rarity ?? 'COMMON')?.color}
                >
                  {collectible?.reward}
                </Typography>
              </Stack>
            )}
            <Stack direction="row" width="100%" spacing={1} justifyItems="center" justifyContent="center">
              {isButtonVisible ? (
                <Button variant="ContainedSecondary" onClick={props.handleCloseRevealModal} sx={{ width: 200 }}>
                  {t('myTickets.continue')}
                </Button>
              ) : null}
            </Stack>
          </Stack>
        </Stack>
        <motion.div animate={controls2}>
          <Box
            position="absolute"
            zIndex={1}
            top={0}
            left={0}
            right={0}
            bottom={0}
            display={isVideoVisible ? 'flex' : 'none'}
            alignItems="center"
            bgcolor="black"
            justifyContent="center"
          >
            <Box width="100%" height="100%">
              <video
                src={isDesktop ? annimationVideoDesktop : annimationVideo}
                autoPlay
                muted
                playsInline
                onEnded={() => {
                  setButtonVisible(true);
                  controls2.start({ opacity: 0, transition: { duration: 1 } });
                  controls.start({ scale: 1, transition: { duration: 1 } });
                  setTimeout(() => {
                    setVideoVisible(false);
                  }, 1000);
                }}
                style={{ objectFit: 'cover', height: '100vh', width: '100vw' }}
              ></video>
            </Box>
          </Box>
        </motion.div>
      </Box>
    </>
  );
};

export default RevealModal;
