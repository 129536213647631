/* eslint-disable camelcase */
import baseApi from './base';
import { Event } from './event';
import { Ticket } from './ticket';

type Reservation = {
  id: number;
  uuid: string;
  validity: Date;
  status: string;
  firstName: string;
  lastName: string;
  email: string;
  userLanguage: string | null;
  srcPrice: number;
  finalPrice: number;
  currencyCode: string | null;
  updatedAt: Date;
  createdAt: Date;
  deletedAt: Date | null;
  userId: number;
  promoCodeDiscountId: number | null;
  eventId: number;
  event: Event;
  tickets: Ticket[];
};

interface GetReservationDetailsParams {
  eventUuid: string;
  reservationUuid: string;
}

const reservationApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getUserReservations: builder.query<Reservation[], void>({
      query: eventUuid => ({
        url: `/reservations/user`,
        method: 'GET',
        params: { eventUuid }
      })
      // providesTags: (result, error, arg) => [{ type: 'ticket-details', id: arg }]
    }),
    getReservationDetails: builder.query<Reservation, GetReservationDetailsParams>({
      query: ({ eventUuid, reservationUuid }) => ({
        url: `/reservations/details/${reservationUuid}`,
        method: 'GET'
        // params: { eventUuid }
      })
      // providesTags: (result, error, arg) => [{ type: 'ticket-details', id: arg }]
    })
  })
});

export default reservationApi;
