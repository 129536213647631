import { useState } from 'react';
import { Stack, Typography, Box, ButtonBase, useMediaQuery, Link, useTheme } from '@mui/material';
import greyScale from '../../themes/default/colors/greyscale';
import i18next, { t } from 'i18next';
import ChangeLanguageModal from '../ChangeLanguageModal';
import { languageList } from '../../i18n';
import { useAppSelector } from '../../redux/hooks';
import { getWebsiteConfig } from '../../pages/whiteLabel/themeFactory';
import DarkModeSwitch from '../DarkModeSwitch';
import { SocialFooterIcon } from '@/pages/CustomHome/components/Footer/SocialFooterIcon';

const CustomFooter = () => {
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  const logo = getWebsiteConfig().logo;
  const appId = getWebsiteConfig().id;
  const appName = getWebsiteConfig().name;

  const [changeLanguageModalOpen, setChangeLanguageModalOpen] = useState(false);
  const { darkTheme } = useAppSelector(state => state.themeSlice);
  const language = languageList.find(l => l.code === i18next.resolvedLanguage);
  const theme = useTheme(); // Access the theme object

  const icons = [
    { url: getWebsiteConfig().social.instagram, img: '/socialMedias/instagram.png' },
    { url: getWebsiteConfig().social.twitter, img: '/socialMedias/twitter.png' },
    { url: getWebsiteConfig().social.facebook, img: '/socialMedias/facebook.png' }
  ];

  return (
    <footer>
      <ChangeLanguageModal
        changeLanguageModalOpen={changeLanguageModalOpen}
        handleCloseChangeLanguageModal={() => setChangeLanguageModalOpen(false)}
      />
      <Box bgcolor={darkTheme ? '#000' : greyScale[100]} p={3} pt={isDesktop ? 3 : 0.5} width="100%" bottom={0}>
        <Stack direction="column" alignItems="center" justifyContent="center" width="100%" mt={2}>
          <Stack
            width="100%"
            direction={isDesktop ? 'row' : 'column'}
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
            spacing={2}
          >
            <Stack direction="row" alignItems="center">
              <Stack direction="row" alignItems="center" spacing={1}>
                <Link href="https://www.blagnac-rugby.fr/" target="_blank">
                  <img height={'50px'} src={logo} alt="logo" />
                </Link>
                {appId !== 'TICKIE' && (
                  <Link href="https://www.tickie.io" target="_blank">
                    <img height={'50%'} src={'/poweredBy.png'} alt="poweredBy" />
                  </Link>
                )}
              </Stack>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={3}>
              <ButtonBase onClick={() => setChangeLanguageModalOpen(true)}>
                <Typography variant="sm" fontWeight={700} color={greyScale[500]}>
                  {`${language?.language} (${language?.code.toUpperCase()})`}
                </Typography>
              </ButtonBase>
              {/* <ButtonBase>
                <Typography variant="sm" fontWeight={700} color={greyScale[500]}>
                  € EUR
                </Typography>
              </ButtonBase> */}
              <DarkModeSwitch />
            </Stack>
            <Stack direction="row">
              {icons.map((icon, index) => (
                <SocialFooterIcon key={index} url={icon.url} img={icon.img} />
              ))}
            </Stack>
          </Stack>
          <Box
            sx={{
              display: 'flex',
              flexDirection: !isDesktop ? 'column' : 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              mt: 3,
              mb: 4,
              borderTop: `1px solid ${'gray'}`,
              color: 'rgba(173, 173, 173, 0.85)'
            }}
            height={'100%'}
            width={'100%'}
          ></Box>

          <Stack
            direction={isDesktop ? 'row' : 'column'}
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            spacing={2}
          >
            <Typography variant="sm" fontWeight={700} color={greyScale[500]} textAlign="center">
              © 2024 {appName}, tous droits reservés.
            </Typography>
            <Stack
              direction={isDesktop ? 'row' : 'column'}
              spacing={2}
              pb={isDesktop ? 0 : 6}
              alignItems="center"
              justifyContent={isDesktop ? 'right' : 'center'}
              flexWrap="wrap"
            >
              <Link
                fontSize={14}
                fontWeight={700}
                color={greyScale[500]}
                href="https://tickieapp.notion.site/FAQ-3a1ebb1e6b47402a9b5dc9751191b17a"
                target="_blank"
              >
                {t('footer.faq')}
              </Link>
              <Link
                fontSize={14}
                fontWeight={700}
                color={greyScale[500]}
                href="https://tickieapp.notion.site/Politique-de-confidentialit-d08f2ac3727b45d896a07510518035fb"
                target="_blank"
              >
                {t('footer.privacyPolicy')}
              </Link>
              <Link
                fontSize={14}
                fontWeight={700}
                color={greyScale[500]}
                href="https://tickieapp.notion.site/Conditions-g-n-rales-de-vente-c2dcaf87635c441182bab9982d20e426"
                target="_blank"
              >
                {t('footer.saleConditions')}
              </Link>
              <Link
                fontSize={14}
                fontWeight={700}
                color={greyScale[500]}
                href="https://tally.so/r/3X5gvO"
                target="_blank"
              >
                {t('footer.contactUs')}
              </Link>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </footer>
  );
};

export default CustomFooter;
